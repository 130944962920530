"use strict";

/**
 * Propriedades esperadas pelo DeviceDropdownModel.
 */
export interface DeviceDropdownModelProperties {
    id: string;
    name: string;
    hostname: string;
    type?: Object;
    availableFeatures: Array<string>;
}

/**
 * @ngdoc service
 * @name nms.components.ui.device:DeviceDropdownModel
 * @description Modelo de dados utilizados pela diretiva device-dropdown.
 * # DeviceDropdownModel
 * Factory in the nms.
 */
var app = angular.module("nms");

app.service("DeviceDropdownModel",
    function () {
        function DeviceDropdownModel(device: DeviceDropdownModelProperties) {
            const { id, name, type = null, hostname, availableFeatures } = device;
            this.id = id;
            this.name = name;
            this.hostname = hostname;
            this.type = type;
            this.availableFeatures = availableFeatures;
        }

        return DeviceDropdownModel;
    }
);
