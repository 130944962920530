import { Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ErrorResponse } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

export interface DownloadOperationResponse {
    downloadStatus: string;
    commandKey: string;
    serialNumber: string;
    errorResponse: ErrorResponse;
}

export interface DownloadRequestParameters {
    serialNumber: string;
    fileType: number;
    fileUrl: string;
}

/**
 * Classe responsável por controlar as requisições de download.
 */
@Injectable({
    providedIn: "root"
})
export class DownloadService {

    constructor(private http: HttpClient,
                private toastr: NmsToastrService,
                @Inject(ANGULARJS_TRANSLATE) private translate: any) {}

    public sendDownloads(fileUrl: string, fileType: number, serialNumbers: string[]): void {
        const downloadRequests: DownloadRequestParameters[] = serialNumbers.map((serialNumber) => {
            return {serialNumber, fileType, fileUrl};
        });

        this.http.post<void>("/acs-client/api/downloadAsync", downloadRequests).subscribe(() => {
            this.toastr.info(this.translate.instant("download.request.multiples.cpes"));
        });
    }

}
