import { Component, Inject, OnInit } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SimpleModalComponent } from "ngx-simple-modal";
import { DeviceBackupService } from "../device-backup.service";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";

/**
 * Modelo esperado para a modal BackupConfigurationViewModalComponent.
 */
interface BackupConfigurationViewRequest {
    deviceHostname: string;
    cfgId: number;
    backupConfiguration: string;
}

/**
 * Componente responsável por abrir a modal com o conteúdo textual do backup da configuração.
 */
@Component({
    selector: "backup-configuration-view-modal",
    templateUrl: "./backup-configuration-view-modal.component.html",
    styleUrls: ["./backup-configuration-view-modal.component.scss"]
})
export class BackupConfigurationViewModalComponent extends SimpleModalComponent<BackupConfigurationViewRequest, any>
    implements BackupConfigurationViewRequest, OnInit {
    deviceHostname: string;
    backupConfiguration: string;
    cfgId: number;
    isLoading: boolean = true;

    constructor(@Inject(ANGULARJS_TRANSLATE) private readonly $translate: any,
        private readonly deviceBackupService: DeviceBackupService,
        private readonly nmsDialogService: NmsDialogService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        try {
            this.backupConfiguration = await this.deviceBackupService.getConfigText(this.cfgId).toPromise();
            this.isLoading = false;
        } catch(error) {
            this.nmsDialogService.openDialog({
                description: this.$translate.instant("backup.devices.table.actions.message.error.unableToRetrieveDcb"),
                acceptLabel: "OK"
            });
            this.close();
        }
    }

    getTitle() {
        return this.$translate.instant("backup.devices.view.modal.title").replace("{0}", this.deviceHostname);
    }

    getSearchPlaceholder() {
        return this.$translate.instant("backup.devices.view.modal.search.placeholder");
    }

}