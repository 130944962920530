import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe utilizado para substituir caracteres de nova linha ("\n" e "&#10;")
 * por caractere de quebra de linha "<br>"
 */
@Pipe({name: "replaceLineBreak"})
export class ReplaceLineBreakPipe implements PipeTransform {

    transform(value: string): string {
        return value.replace(/\n|\\n|&#10;/g, "<br>");
    }
}
