import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BackupJobResult, SchedulerJob, SchedulerJobDetails } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { TimeTriggerData } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { ExecutionOrigin } from "../../template/template-instance/template-instance-models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { SchedulerError } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { DeviceBackupErrorResponseHandlerService } from "../../device/device-backup/device-backup-error-response-handler.service";
import { SchedulerErrorResponseHandlerService } from "../scheduler-error-response-handler.service";

/**
 * Classe responsável por realizar as requisições referentes aos Agendamentos.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerListService {
    private readonly baseUrl: string;
    private readonly CLOSE: boolean = true;
    closeModalEmmiter = new EventEmitter<boolean>();

    constructor(private readonly httpClient: HttpClient,
        @Inject(ANGULARJS_TRANSLATE) public readonly translate,
        private nmsDialogService: NmsDialogService,
        private schedulerErrorHandlerService: SchedulerErrorResponseHandlerService) {
            this.baseUrl = "/scheduler/job";
    }

    getAll(): Observable<Array<SchedulerJobDetails>> {
        return this.httpClient
            .get<Array<SchedulerJobDetails>>(this.baseUrl)
            .pipe(map((items) => this.handleSchedulerJobsDetails(items)));
    }

    getById(idScheduler: string): Observable<SchedulerJobDetails> {
        return this.httpClient
            .get<SchedulerJobDetails>(`${this.baseUrl}/${idScheduler}`)
            .pipe(map((item) => this.handleSchedulerJobDetails(item)));
    }

    getSchedulerJobResultById(idSchedulerJob: string): Observable<BackupJobResult> {
        return this.httpClient.get<BackupJobResult>(`${this.baseUrl}/${idSchedulerJob}/result`);
    }

    executeJobsOf(idsScheduler: Array<string>): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/execute`, idsScheduler);
    }

    removeSchedulers(schedulerIdstoRemove: Array<string>): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Content-Type": "application/json" }), body: schedulerIdstoRemove };

        return this.httpClient.delete(`${this.baseUrl}`, httpOptions);
    }

    disableJob(jobId: string): Observable<any> {
        return this.httpClient.put(`${this.baseUrl}/${jobId}/disable`, null);
    }

    enableJob(jobId: string): Observable<any> {
        return this.httpClient.put(`${this.baseUrl}/${jobId}/enable`, null);
    }

    getExecutionResultsForApply(templateApplicationId: string) {
        const basePath = "/templateInstance";

        return this.httpClient.get(`${basePath}/result/${templateApplicationId}/APPLY/${ExecutionOrigin.SCHEDULER}`)
    }

    getExecutionResults(templateApplicationId: string) {
        const basePath = "/templateInstance";

        return this.httpClient
            .get(`${basePath}/equipmentAssociationResults/${templateApplicationId}/${ExecutionOrigin.SCHEDULER}`)
    }

    save(schedulerJob: SchedulerJob): void {
        this.httpClient.post(`${this.baseUrl}`, schedulerJob)
            .subscribe(
                () => this.closeModalEmmiter.emit(this.CLOSE),
                (httpErrorResponse: HttpErrorResponse) => this.handleSaveError(httpErrorResponse)
            );
    }

    private handleSaveError(errorResponse: HttpErrorResponse) {
        if (errorResponse?.error?.code === SchedulerError.INVALID_BACKUP_DEVICE_MODEL_SCHEDULER_ERROR) {
            const { description, details } = this.schedulerErrorHandlerService.buildErrorDescriptionDetails(errorResponse.error);
            const message = description + details;
            this.nmsDialogService.openDialog({ description: message, acceptLabel: "OK", bindHtml: true }, { maxWidth: "800px" });
        }
    }

    private handleSchedulerJobsDetails(jobsDetails: SchedulerJobDetails[]) {
        return jobsDetails.map(jobDetails => this.handleSchedulerJobDetails(jobDetails));
    }

    private handleSchedulerJobDetails(jobDetails: SchedulerJobDetails) {
        jobDetails.schedulerJob.triggers.forEach((trigger) => {
            const triggerTime = trigger.triggerData as TimeTriggerData;
            if (triggerTime.initialDate) {
                triggerTime.initialDate = this.convertToDate(triggerTime.initialDate);
            }

            if (triggerTime.finalDate) {
                triggerTime.finalDate = this.convertToDate(triggerTime.finalDate);
            }
        });
        jobDetails.nextExecution = this.convertToDate(jobDetails.nextExecution);
        jobDetails.lastExecution = this.convertToDate(jobDetails.lastExecution);
        return jobDetails;
    }

    private convertToDate(date: Date) {
        return date ? new Date(date) : null;
    }
}
