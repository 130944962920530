"use strict";

import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";

/**
* @ngdoc Controller
* @name nms.management.ManageUserModalController
* @description Realiza gerência de um usuário.
*/
var app = angular.module("nms.management");

app.controller("ManageUserModalController", ["$rootScope", "$scope", "ManageUsersService", "FormValidatorService",
    "usernamesAlreadyInUse", "$translate", "userData", "securitySystemConfig", "selfEditing", "ldapMode", "LDAP_MODE",
    "PermissionsValidatorService", "rulesMatchingModes", "ruleRestrictionTypes", "NmsToastrService",
    "TranslationHelperService",
    function($rootScope, $scope, ManageUsersService, FormValidatorService, usernamesAlreadyInUse, $translate,
        userData, securitySystemConfig, selfEditing, ldapMode, LDAP_MODE, PermissionsValidatorService, rulesMatchingModes,
            ruleRestrictionTypes, nmsToastrService: NmsToastrService,
            translationHelperService: TranslationHelperService) {
        var VALIDATION_IGNORE_FIELDS = ["confirmPassword-encryptedPassword", "password-encryptedPassword",
            "confirmNewPassword-encryptedPassword", "newPassword-encryptedPassword", "currentPassword-encryptedPassword"];
        var isEdit = !_.isNull(userData);
        $scope.usernamesAlreadyInUse = usernamesAlreadyInUse;
        $scope.securitySystemConfig = securitySystemConfig;
        $scope.isSelfEditing = selfEditing;
        $scope.ldapMode = ldapMode;

        /*
         * Armazena as informações para posterior validação e conversão para o modelo de comunicação com o back-end.
         */
        $scope.form = {
            account: {
                idPreffix: "user-modal-account",
                title: $translate.instant("manage.user.account"),
                fieldsTranslatePreffix: "manage.user.account.form.",
                data: {}
            },
            features: {
                idPreffix: "user-modal-features",
                title: $translate.instant("manage.permissions.features"),
                data: {},
                fireInitialTableResize: true
            },
            templates: {
                idPreffix: "user-modal-templates",
                title: $translate.instant("manage.permissions.templates"),
                data: {}
            },
            devices: {
                idPreffix: "user-modal-devices",
                title: $translate.instant("manage.permissions.devices"),
                data: {},
                fireInitialTableResize: true
            },
            locations: {
                idPreffix: "user-modal-locations",
                title: $translate.instant("manage.permissions.locations"),
                data: {}
            },
            securitySettings: {
                data: {}
            }
        };

        /*
         * Modelos para comunicação com as diretivas de tabs.
         */
        $scope.accountOwner = {
            model: $scope.form.account.data
        };
        $scope.featuresOwner = {
            model: $scope.form.features.data
        };
        $scope.templatesOwner = {
            model: $scope.form.templates.data,
            rulesMatchingModes: rulesMatchingModes,
            ruleRestrictionTypes: ruleRestrictionTypes
        };
        $scope.devicesOwner = {
            model: $scope.form.devices.data
        };
        $scope.locationsOwner = {
            model: $scope.form.locations.data
        };

        /*
         * Tabelas renderizadas dentro das tabs não ativas precisam disparar o evento "tableColumnsHasChange" para recalcular o
         * redimensionamento de colunas quando ativadas.
         * Para tanto, basta incluir esta função no evento de "select" do "uib-tab" no html, passando a tab a ser usada.
         */
        $scope.fireTableChanges = function(tab) {
            if (tab.fireInitialTableResize) {
                $rootScope.$broadcast("tableColumnsHasChange", true);
                tab.fireInitialTableResize = false;
            }
        };

        /**
        * Retorna o valor do tooltip correto para quando a alteração de permissões estiver bloqueada.
        * A alteração de permissões é bloqueada quando as permissões do usuário estão configuradas por grupo ou quando o Ldap está
        * configurado por autorização.
        */
        $scope.getTooltipForBlocked = function() {
            if ($scope.form.account.data.permission === "group") {
                return $translate.instant("manage.user.modal.permissions.by.group");
            } else if ($scope.ldapMode === LDAP_MODE.AUTHORIZATION) {
                return $translate.instant("manage.user.ldap.authorization.permissions.by.group");
            } else {
                return "";
            }
        };

        /**
        * Edita os dados pessoais do usuário logado.
        * Dados contemplados: password, name, phone e email.
        */
        var editBasic = function() {
            ManageUsersService.editBasic($scope.form.account.data)
                .then(function(user) {
                    $scope.confirm(user);
                }, function(error) {
                    $rootScope.showDialog({type: "error", translateKey: "manage.user.modal.account.edit.currentPassword.error"});
                });
        };

       /**
       * Método que verifica se o checkbox para restringir a aplicação de templates esta desmarcado, porém a lista de regras
       * esta preenchida.
       * @return {boolean} false se a condição for verdadeira, true caso contrário.
       */
        var checkValidateRestrictions = function(form) {
            if (!$scope.isSelfEditing
                && PermissionsValidatorService.isCheckDisabledAndRestrictionsNotEmpty($scope.form.templates.data)) {
                showDataLossConfirmationModal();
                return false;
            }
            return true;
        };

        /**
        * Exibe modal de confirmação para informar que a lista de restrições será perdida
        */
        var showDataLossConfirmationModal = function() {
            $rootScope.showDialog({
                translateKey: "manage.permissions.templates.disable.check.restrictions",
                isConfirm: true
            }).then(function() {
                saveUser();
            });
        };

        /**
        * Salva o usuário, invocando o serviço de edição ou inserção do mesmo. Se a operação finalizar com sucesso, fecha a modal
        * e exibe mensagem de sucesso para o usuário.
        */
        var saveUser = function() {
            var saveCallback = function(user) {
                $scope.confirm(user);
                const message = translationHelperService
                    .translateWithReplacement("manage.user.modal.finish.success", user.username);
                nmsToastrService.success(message);
            };

            if (isEdit) {
                if ($scope.isSelfEditing) {
                    editBasic();
                } else {
                    ManageUsersService.editUser($scope.form).then(saveCallback);
                }
            } else {
                ManageUsersService.createUser($scope.form).then(saveCallback);
            }
        };

        /**
         * Valida o usuário usando o FormValidatorService. Caso o usuário seja válido, prossegue com o salvamento.
         */
        $scope.validateAndSaveUser = function() {
            var isValid = checkValidateRestrictions($scope.form)
                && FormValidatorService.validate($scope.manageUserForm, $scope.form, VALIDATION_IGNORE_FIELDS)
                && ($scope.isSelfEditing || PermissionsValidatorService.validate($scope.form));

            if (isValid) {
                saveUser();
            }
        };

        $scope.isLdapAuthorization = function() {
            return $scope.ldapMode === LDAP_MODE.AUTHORIZATION;
        };

        /**
         * Prepara os dados para edição, incluindo os dados de usuário recebidos nos modelos de comunicação de cada uma das
         * diretivas de tabs.
         */
        var prepareDataForEdition = function(userData) {
            _.defaults($scope.accountOwner.model, {
                user: userData.user.username,
                administrator: userData.user.administrator,
                permission: userData.user.groupName ? "group" : "individual",
                group: userData.user.groupName || null,
                changePassword: false,
                password: "",
                confirmPassword: "",
                name: userData.user.name || "",
                phone: userData.user.phone || "",
                email: userData.user.email || ""
            });
            $scope.featuresOwner.model.editData = userData.permissionsModel.personalPermissionModels;
            $scope.devicesOwner.model.editData = userData.permissionsModel.devicePermissionModels;
            $scope.locationsOwner.model.editData = userData.permissionsModel.locationPermissionModels;
            $scope.templatesOwner.model.editData = userData.permissionsModel.templatePermissionModel;
            // Apenas mantem os dados de userSecuritySettings para enviar para edição, mas não permite que o usuário faça
            // alterações.
            $scope.form.securitySettings.data = userData.userSecuritySettings;
        };

        var init = function() {
            $scope.modalTitle = $translate.instant("manage.user.modal.add");
            if (isEdit) {
                $scope.modalTitle = selfEditing
                    ? $translate.instant("manage.user.modal.edit.account")
                    : $translate.instant("manage.user.modal.edit");
                prepareDataForEdition(userData);
            }
        };

        init();
    }
]);
