
const createMainFunctionStatement = () =>
`
    def run(device):
        # your code here
`;

const createGetDeviceIpEquipmentMethod = () =>
`
    device.getHostname()
`;

const createGetDeviceNameEquipmentMethod = () =>
`
    device.getName()
`;

const createGetDeviceModelEquipmentMethod = () =>
`
    device.getModel()
`;

const createGetDeviceIsDmosEquipmentMethod = () =>
`
    device.isDmOs()
`;

const createGetDeviceFirmwareEquipmentMethod = () =>
`
    device.getFirmware()
`;

const createGetCliCommandMethod = () =>
`
    device.executeCliCommand('SomeCommand')
`;

export default {
    createMainFunctionStatement,
    createGetDeviceIpEquipmentMethod,
    createGetDeviceNameEquipmentMethod,
    createGetDeviceModelEquipmentMethod,
    createGetDeviceIsDmosEquipmentMethod,
    createGetDeviceFirmwareEquipmentMethod,
    createGetCliCommandMethod
};
