import { Injectable } from "@angular/core";
import { ToastrService, ActiveToast, IndividualConfig } from "ngx-toastr";

/**
 * Wrapper para ToastrService.
 * Responsável por apresentar o toastrs na tela.
 */
@Injectable({
    providedIn: "root",
})
export class NmsToastrService {
    constructor(private toastr: ToastrService) { }

    /** Exibe toast de sucesso. */
    success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.toastr.success(message, title, override);
    }

    /**
    * Exibe toast de erro.
    * Este tipo de toast o comportamento padrão é não fechar automático, porem é possível sobrescrever essa configuração.
    */
    error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.toastr.error(message, title, { disableTimeOut: true, ...override });
    }

    /** Exibe toast de informação. */
    info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.toastr.info(message, title, override);
    }

    /** Exibe toast de advertência. */
    warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return this.toastr.warning(message, title, override);
    }

    /**
     * Remove todos ou um único toast por id.
     */
    clear(toastId?: number): void {
        this.toastr.clear(toastId);
    }

    /**
     * Remove e destrói um toast único por id.
     */
    remove(toastId: number): boolean {
        return this.toastr.remove(toastId);
    }

    /**
     * Determina se a mensagem do toast já está sendo exibida.
     */
    findDuplicate(message: string, resetOnDuplicate: boolean, countDuplicates: boolean): ActiveToast<any> {
        return this.toastr.findDuplicate(message, resetOnDuplicate, countDuplicates);
    }
}