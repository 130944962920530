import { Injectable } from "@angular/core";
import { DeviceFreemarkerExpressionService } from "../freemarker/device-freemarker-expression.service";
import { DevicePythonExpressionService } from "../python/device-python-expression.service";
import { ExpressionService } from "@nms-ng2/app/shared/models/expressions.interface";

/**
 * Classe responsável por retornar o serviço corresponde as expressões relacionadas ao
 * tipo de linguagem selecionada (Freemarker ou Python) para templates CLI.
 */
@Injectable({
    providedIn: "root"
})
export class DeviceLanguageServiceResolver {

    private devicePythonExpressionsService: DevicePythonExpressionService
    private deviceFreemarkerExpressionsService: DeviceFreemarkerExpressionService;

    constructor(private pythonExpressionsService: DevicePythonExpressionService,
                private freemarkerExpressionsService: DeviceFreemarkerExpressionService) {
        this.devicePythonExpressionsService = pythonExpressionsService;
        this.deviceFreemarkerExpressionsService = freemarkerExpressionsService;
    }

    resolve(isPythonTemplate: boolean): ExpressionService {
        if (isPythonTemplate) {
            return this.devicePythonExpressionsService;
        }

        return this.deviceFreemarkerExpressionsService;
    }
}