import { Injectable } from "@angular/core";
import { TemplateInstanceIdentifierService } from "../../../template-instance/template-instance-identifier.service";
import { TemplateInstance } from "../../../template-instance/template-instance-models";
import { IncludeBlockOptions, Variable } from "../../../template-instance/variables/variables.models";

/**
 * Serviço relacionado ao tratamento de restrições de variáveis.
 */
@Injectable({
    providedIn: "root"
})
export class VariablesRestrictionsService {

    constructor(private templateInstanceIdentifier: TemplateInstanceIdentifierService) { }

    variableHasRestrictionsOptions(variable: Variable, variablesRestrictions, templateInstance: TemplateInstance,
            equipment?): boolean {
        if (equipment && !variable.globalScope) {
            const equipmentIdentifier = equipment.equipmentIdentifier;
            const variableRestrictions = _.filter(variablesRestrictions, "var.name", variable.name);
            const deviceAssociation = _.find(variableRestrictions[0].deviceAssociations, {
                deviceId: this.templateInstanceIdentifier.resolveIdentifier(equipmentIdentifier)
            });
            const equipmentAssociation = templateInstance.equipmentAssociations.find((association) =>
                _.isEqual(association.equipmentIdentifier, equipmentIdentifier)
            );

            const templateIds = _.map(equipmentAssociation.templateAssociations, "templateId");

            return _.some(deviceAssociation.templatesWithRestrictions, (templateWithRestrictions) => {
                return _.includes(templateIds, templateWithRestrictions);
            });
        }

        return IncludeBlockOptions.DO_NOT_GET !== variable.includeAndBlockOptionMode;
    }
}
