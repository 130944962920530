"use strict";

var app = angular.module("nms.templates");

app.directive("codeBlock", ["$rootScope", "TemplateService", "CodeBlockService", "TEMPLATE_LANGUAGE", "$filter", "VARIABLES",
    function($rootScope, TemplateService, CodeBlockService, TEMPLATE_LANGUAGE, $filter, VARIABLES) {
        return {
            restrict: "E",
            scope: {
                id: "@",
                formField: "@",
                data: "=",
                userVariables: "&?",
                equipmentVariables: "&?",
                checkedVariables: "&?",
                hideUserVariables: "=",
                disabled: "=",
                expressionsProvider: "&",
                showInlineExpressions: "=",
                expressionsAggregatorsProvider: "&?",
                formatCommand: "&?",
                tooltipVariable: "&",
                language: "="
            },
            templateUrl: "templates/features/template/components/ui/code-block/code-block.html",
            replace: true,
            compile: function compile() {
                return {
                    pre: function preLink(scope, iElement) {
                        var timer;
                        var elementEditor = ace.edit(iElement.find(".code-editor")[0]);
                        scope.expressionsAggregators = scope.expressionsAggregatorsProvider ? scope.expressionsAggregatorsProvider() : [];
                        scope.expressions = scope.expressionsProvider();
                        scope.tooltip = scope.tooltipVariable();
                        let currentCheckedVariables = [];
                        iElement.find(".resizable").resizable();

                        if (scope.expressionsAggregators.length > 0) {
                            scope.expressions = scope.expressionsAggregators
                                    .map( exp => exp.expressions)
                                    .flat()
                                    .reverse();
                        }

                        if (scope.formatCommand) {
                            var formatCommand = scope.formatCommand();

                            if (formatCommand !== null) {
                                CodeBlockService.setStartCommand(formatCommand.start);
                                CodeBlockService.setEndCommand(formatCommand.end);
                            }
                        }

                        var getVariables = function() {
                            if (scope.checkedVariables) {
                                currentCheckedVariables = scope.checkedVariables();
                            }
                            return currentCheckedVariables;
                        };

                        var setupDisabledAce = function(editor) {
                            editor.setOptions({
                                readOnly: true,
                                highlightActiveLine: false,
                                highlightGutterLine: false,
                                showPrintMargin: false
                            });
                            editor.renderer.$cursorLayer.element.style.opacity = 0;
                            editor.commands.commmandKeyBinding = {};
                            iElement.find(".ace_status-indicator").remove();
                        };

                        var setupEnabledAce = function(editor) {
                            editor.setOptions({
                                readOnly: false,
                                highlightActiveLine: true,
                                highlightGutterLine: true,

                                enableSnippets: true,
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                autoScrollEditorIntoView: true,
                                showPrintMargin: false
                            });
                            editor.renderer.$cursorLayer.element.style.opacity = 1;
                        };

                        var setupResizeAce = function(editor) {
                            iElement.find(".resizable").resizable("enable");
                            iElement.find(".resizable").resizable({
                                handles: "s",
                                minHeight: 200,
                                stop: $(this).css("width", ""),
                                resize: editor.resize()
                            });

                            $(".ui-resizable-handle").on("mousemove", function() {
                                editor.resize();
                            });
                        };

                        /**
                        * Remove eventuais '\r' (carriage-return) de textos provenientes de copy/paste no Chrome.
                        **/
                        var handlePastedText = function(data) {
                            var rawText = angular.copy(data.text);
                            data.text = $filter("cleanLineBreak")(rawText);
                        };

                        const confirmAndInsertText = function(editor, variables, expression) {
                            if (variables.some(variable => variable.type === VARIABLES.ACTION)) {
                                $rootScope.showDialog({
                                    translateKey: "templateform.variables.messages.thereAreActionVariablesToInsert",
                                    isConfirm: true
                                }).then(function() {
                                    scope.insertText(editor, expression);
                                });
                            } else {
                                scope.insertText(editor, expression);
                            }
                        };

                        scope.hasCheckedVariables = () => {
                            return scope.checkedVariables && scope.checkedVariables().length > 0
                                && !scope.checkedVariables().every(variable => variable.type === VARIABLES.ACTION);
                        }

                        scope.isFreemarkerLanguageAndHasEquipmentVariables = () => {
                            return scope.language === TEMPLATE_LANGUAGE.FREEMARKER &&
                                   scope.equipmentVariables &&
                                   scope.equipmentVariables().length > 0;
                        }

                        scope.insertText = function(editor, expressionText) {
                            if (!scope.disabled) {
                                if (editor.selection.isEmpty()) {
                                    editor.session.insert(editor.getCursorPosition(), expressionText);
                                } else {
                                    editor.session.replace(editor.selection.getRange(), expressionText);
                                }
                            }
                        };

                        scope.loadAce = function(editor) {
                            var lang = ace.require("ace/lib/lang");
                            ace.require("ace/ext/language_tools");
                            editor.$blockScrolling = Infinity;
                            editor.setTheme("ace/theme/chrome");

                            switch(scope.language) {
                                case TEMPLATE_LANGUAGE.FREEMARKER:
                                    editor.getSession().setMode("ace/mode/ftl");
                                    break;
                                case TEMPLATE_LANGUAGE.PYTHON:
                                    editor.getSession().setMode("ace/mode/python");
                                    editor.setOptions({
                                        enableBasicAutocompletion: true,
                                        enableSnippets: true
                                    });

                                    break;
                            }

                            editor.setFontSize(13);

                            if (scope.disabled) {
                                setupDisabledAce(editor);
                            } else {
                                setupEnabledAce(editor);

                                scope.insertCheckedVariables = function() {
                                    const vars = scope.checkedVariables();

                                    if (vars.length === 0) {
                                        $rootScope.showDialog({
                                            translateKey: "templateform.variables.messages.noCheckedVariableToInsert"
                                        });
                                    } else {
                                        confirmAndInsertText(editor, vars, CodeBlockService.getVariablesExpression(vars));
                                    }
                                };

                                scope.insertEquipmentVariables = function() {
                                    scope.insertText(editor, CodeBlockService.getVariablesExpression(scope.equipmentVariables()));
                                };

                                scope.insertExpression = function(expression) {
                                    const vars = getVariables();
                                    confirmAndInsertText(editor, vars, CodeBlockService.getExpressionValue(expression, vars));
                                };

                                editor.commands.addCommand({
                                    name: "showKeyboardShortcuts",
                                    bindKey: {win: "Ctrl-Alt-h", mac: "Command-Alt-h"},
                                    exec: function(editor) {
                                        ace.config.loadModule("ace/ext/keybinding_menu", function(module) {
                                            module.init(editor);
                                            editor.showKeyboardShortcuts();
                                        });
                                    }
                                });

                                ace.config.loadModule("ace/ext/statusbar", function(module) {
                                    new module.StatusBar(editor, iElement.find(".ace-status-bar"));
                                });

                                ace.config.loadModule("ace/autocomplete/text_completer", function(module) {
                                    editor.completers = [{getCompletions: module.getCompletions}];
                                });

                                if (!scope.hideUserVariables) {
                                    editor.completers.push(CodeBlockService.getUserVariablesCompleter(scope.userVariables(), getVariables()));
                                }

                                if (scope.isFreemarkerLanguageAndHasEquipmentVariables()) {
                                    editor.completers.push(CodeBlockService.getEquipmentVariablesCompleter(scope.equipmentVariables()));
                                }
                                editor.completers.push(CodeBlockService.getExpressionsCompleter(getVariables(), scope.expressions, lang));

                                editor.on("paste", handlePastedText);
                            }

                            setupResizeAce(editor);
                        };

                        var showErrorAnnotations = function(editor) {
                            $rootScope.hideLoadingPanel = true;
                            scope.data = editor.getValue();
                            var isEmptyDocument = scope.data.match(/^\s*$/);

                            if (!scope.disabled && !isEmptyDocument && !_.get(editor, "completer.activated")) {
                                TemplateService.validateCommands({
                                    command: scope.data,
                                    templateLanguage: scope.language
                                })
                                .then(function(response) {
                                    editor.session.setAnnotations([]);
                                }).catch(function(response) {
                                    editor.session.setAnnotations([response]);
                                });
                            }
                            $rootScope.hideLoadingPanel = false;
                        };

                        var checkForErrors = function(editor) {
                            clearTimeout(timer);

                            timer = setTimeout(function() {
                                showErrorAnnotations(editor);
                            }, 1000);
                        };

                        scope.aceChanged = function(editor) {
                            if (!scope.disabled) {
                                checkForErrors(editor[1]);
                            }
                        };

                        var unregisterDisabledWatch = scope.$watch("disabled", function(newVal, oldVal) {
                            if (newVal !== oldVal) {
                                scope.loadAce(elementEditor);
                            }
                        });

                        var hasCheckedVariablesChanged = function() {
                            var newCheckedVariables = scope.checkedVariables ? scope.checkedVariables() : [];

                            return !_.isEqual(newCheckedVariables, currentCheckedVariables);
                        };

                        var unregisterCheckedVarsWatch = scope.$watch(hasCheckedVariablesChanged, function(hasChangeVariable) {
                            if (hasChangeVariable) {
                                scope.loadAce(elementEditor);
                            }
                        });

                        scope.$on("$destroy", function() {
                            unregisterDisabledWatch();
                            unregisterCheckedVarsWatch();
                            elementEditor.destroy();
                            var el = elementEditor.container;
                            el.parentNode.removeChild(el);
                            elementEditor.container = null;
                            elementEditor.renderer = null;

                            elementEditor = null;
                        });
                    }
                };
            }
        };
    }
]);
