import { EventEmitter, Inject, Injectable } from "@angular/core";
import { NmsNotificationToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-notification-toastr.service";
import { ActiveToast, IndividualConfig } from "ngx-toastr";
import { TranslationHelperService } from "../util/translation-helper.service";
import { NotificationPreferencesService } from "../notification-preferences/notification-preferences.service";
import { CustomNotificationLinkByType, NotificationMessage } from "./nms-notification.models";
import { NmsNotificationService } from "./nms-notification.service";
import { NmsToasterLink } from "../../components/elements/nms-toastr/nms-toastr-model";
import { STATE, NMS_STATES, GPON_ONU_SERVICE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Responsável por mostrar o toastr na tela.
 */
@Injectable({
    providedIn: "root"
})
export class NmsNotificationFeedbackService {
    updateNotificationBadge: EventEmitter<void> = new EventEmitter<void>();
    updateNotificationSeen: EventEmitter<void> = new EventEmitter<void>();

    private readonly NOTIFICATIONS_WITH_CUSTOM_LINK: Partial<CustomNotificationLinkByType> = {
        SCHEDULER_UPDATED: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("schedulerUpdated")
                ];
            }
        },
        SCHEDULER_DELETED: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("schedulerDeleted")
                ];
            }
        },
        SCHEDULER_ADDED: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("schedulerAdded")
                ];
            }
        },
        SCHEDULED_BY_FREQUENCY: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("ScheduledByFrequency")
                ];
            }
        },
        SCHEDULED_BY_FREQUENCY_ADMIN: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("ScheduledByFrequencyAdmin")
                ];
            }
        },
        SCHEDULED_BY_TR069_EVENT: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("ScheduledByTr069Event")
                ];
            }
        },
        SCHEDULED_BY_TR069_EVENT_ADMIN: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("ScheduledByTr069EventAdmin")
                ];
            }
        },
        SCHEDULED_BY_SNMP_TRAP: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("ScheduledBySnmpTrap")
                ];
            }
        },
        SCHEDULED_BY_SNMP_TRAP_ADMIN: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    this.getLinkNotificationScheduler("ScheduledBySnmpTrapAdmin")
                ];
            }
        },
        ONU_DISCOVERED: {
            getLinks: (notification?: NotificationMessage): Array<NmsToasterLink> => {
                const { detailsParameters } = notification.detailedResponse;
                const serialNumber = detailsParameters[0];
                const name = detailsParameters[3];
                const loadTablePreferences = false;

                return [
                    {
                        id: "discoveredOnu",
                        title: this.translationService.translateWithReplacement(
                            "nms-notification.link.onuDiscovered.view",
                            serialNumber
                        ),
                        action: () => {
                            const filters = { serialNumber };
                            this.$state.go(
                                this.gponOnuService.getGponOnuUISref(),
                                { name, filters, loadTablePreferences },
                                { inherit: false }
                            );
                        }
                    },
                    {
                        id: "discoveredOnuOlt",
                        title: this.translationService.translate("nms-notification.link.onuDiscovered.olt"),
                        action: () => {
                            const filters = {};
                            this.$state.go(
                                this.gponOnuService.getGponOnuUISref(),
                                { name, filters, loadTablePreferences },
                                { inherit: false }
                            );
                        }
                    }
                ];
            }
        },
        NEW_CPE_REGISTERED: {
            getLinks: (notification?: NotificationMessage): Array<NmsToasterLink> => {
                const { detailsParameters } = notification.detailedResponse;
                return [
                    {
                        id: "newCpeRegistered",
                        title: this.translationService.translate("nms-notification.link.parameters"),
                        action: () => this.$state.go(this.nmsStates.cwmpParameters, { serialNumber: detailsParameters[0] })
                    }
                ];
            }
        },
        TEMPLATE_APPLICATION_FINISHED: {
            getLinks: (): Array<NmsToasterLink> => {
                return [
                    {
                        id: "templateApplicationFinished",
                        title: this.translationService.translate("nms-notification.link.templateInstance.view"),
                        action: () => this.$state.go(this.nmsStates.templateInstance.list, {})
                    }
                ];
            }
        },
        CPE_TRANSFER_COMPLETED: {
            getLinks: (notification?: NotificationMessage): Array<NmsToasterLink> => {
                const { detailsParameters } = notification.detailedResponse;
                return [
                    {
                        id: "cpeTransferCompleted",
                        title: this.translationService.translate("nms-notification.link.parameters"),
                        action: () => this.$state.go(this.nmsStates.cwmpParameters, { serialNumber: detailsParameters[0] })
                    }
                ];
            }

        }
    };

    constructor(
        private readonly toastrService: NmsNotificationToastrService,
        private readonly translationService: TranslationHelperService,
        private readonly notificationPreferencesService: NotificationPreferencesService,
        private readonly notificationService: NmsNotificationService,
        @Inject(STATE) private readonly $state: any,
        @Inject(NMS_STATES) private readonly nmsStates: any,
        @Inject(GPON_ONU_SERVICE) private readonly gponOnuService: any
    ) {}

    public async showNotification(notification: NotificationMessage): Promise<void> {
        const isDoNotDisturbEnabled = await this.notificationPreferencesService.getDoNotDisturb().toPromise();

        if (!isDoNotDisturbEnabled && notification.enabled) {
            const { status, detailedResponse } = notification;
            const title = this.translationService.translateWithReplacement(
                detailedResponse.titleKey,
                ...detailedResponse.titleParameters
            );
            const details = this.translationService.translateWithReplacement(
                detailedResponse.detailsKey,
                ...detailedResponse.detailsParameters
            );

            const links = this.NOTIFICATIONS_WITH_CUSTOM_LINK[notification.type]
                ? this.NOTIFICATIONS_WITH_CUSTOM_LINK[notification.type].getLinks(notification)
                : [];

            /*
             *   Define os valores tapToDismiss=false e closeButton=true.
             *   Isto é necessário para que o componente toastr ignore o clique no
             *   checkbox como trigger do evento de fechar o toastr.
             */
            const [tapToDismiss, closeButton] = [false, true];

            const individualConfiguration: Partial<IndividualConfig> = {
                disableTimeOut: !notification.autoClose,
                timeOut: notification.delayBeforeCloseInSeconds * 1000,
                onActivateTick: true,
                tapToDismiss,
                closeButton,
                //@ts-ignore
                links,
                type: notification.type,
                status: notification.status
            };

            const toast: ActiveToast<any> = this.toastrService[status.toLowerCase()](details, title, individualConfiguration);
            toast.onAction.subscribe((toastr): any => toastr.action());
            this.notificationService.toggleReadProperty(false, notification.id).subscribe();
        } else {
            this.updateNotificationBadge.emit();
        }

        this.updateNotificationSeen.emit();
    }

    private getLinkNotificationScheduler(id: string) {
        return {
            id,
            title: this.translationService.translate("nms-notification.link.scheduler.view"),
            action: () => this.$state.go(this.nmsStates.scheduler.list, {}, { inherit: false })
        };
    }
}
