/**
 * Representa a frequência na qual um agendamento pode ser disparado.
 */
 export enum TriggerType {
    ON_DEMAND = "ON_DEMAND",
    ONCE = "ONCE",
    EACH_HOUR = "EACH_HOUR",
    DAILY = "DAILY",
    BUSINESS_DAY = "BUSINESS_DAY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
    CRON = "CRON",
    TR069_EVENT = "TR069_EVENT",
    TRAP = "TRAP"
}

/**
 * Realiza o mapeamento entre as enum Tr069Event e os valores apresentados na tela.
 */
export const EVENTS_MAP: {[key in Tr069Event]: string} = {
    BOOTSTRAP: "0 BOOTSTRAP",
    BOOT: "1 BOOT",
    VALUE_CHANGE: "4 VALUE CHANGE",
    TRANSFER_COMPLETE: "7 TRANSFER COMPLETE",
    DIAGNOSTICS_COMPLETE: "8 DIAGNOSTICS COMPLETE",
    REQUEST_DOWNLOAD: "9 REQUEST DOWNLOAD"
};

/**
 * Representa os eventos TR-069 que um agendamento pode utilizar.
 */
export enum Tr069Event {
    BOOTSTRAP = "BOOTSTRAP",
    BOOT = "BOOT",
    VALUE_CHANGE = "VALUE_CHANGE",
    TRANSFER_COMPLETE = "TRANSFER_COMPLETE",
    DIAGNOSTICS_COMPLETE = "DIAGNOSTICS_COMPLETE",
    REQUEST_DOWNLOAD = "REQUEST_DOWNLOAD"
}

/**
 * Representa os eventos das traps SNMP.
 */
export enum SnmpTrap {
    ONU_DISCOVERED = "ONU_DISCOVERED",
    ONU_DOWN_UP = "ONU_DOWN_UP",
    ONU_DYING_GASP = "ONU_DYING_GASP",
    PON_LINK_DOWN_UP = "PON_LINK_DOWN_UP",
    ETHERNET_LINK_DOWN = "ETHERNET_LINK_DOWN",
    ETHERNET_LINK_UP = "ETHERNET_LINK_UP",
    DMOS_DYING_GASP = "DMOS_DYING_GASP",
    APPLY_CONFIG = "APPLY_CONFIG"
}

/**
 * Enum no qual representa os parâmetros que poderão ser recebidos nas traps SNMP.
 */
export enum SnmpTrapInformation {
    DEV_NO = "DEV_NO",
    LOCAL_ID = "LOCAL_ID",
    SLOT_NO = "SLOT_NO",
    PORT_NO = "PORT_NO",
    PON_PORT = "PON_PORT",
    PORT_ID = "PORT_ID",
    ONU_ID = "ONU_ID",
    USER = "USER",
    DEVICE_IP = "DEVICE_IP",
    OLT_IP = "OLT_IP",
    ONU_SERIAL_NUMBER = "ONU_SERIAL_NUMBER"
}

/**
 * Representação do tipo de disparo do job quando for evento de traps.
 */
export interface SnmpTrapTriggerData extends TriggerData {
    snmpTrap: SnmpTrap;
}

/**
 * Realiza o mapeamento entre as enum SnmpTrap e os valores apresentados na tela.
 */
export interface SnmpTrapType {
    snmpTrap: SnmpTrap;
    label: string;
}

/**
 * ViewModel da frequência que um agendamento pode ser disparado.
 */
export interface FrequencyTriggerType {
    id: TriggerType;
    label: string;
}

/**
 * Interface que representa um Disparador de agendamento.
 * As interfaces @see InformTriggerData e @see TimeTriggerData devem estendê-la e incluir seus próprios atributos.
 */
export interface TriggerData {} // NOSONAR

/**
 * Representação do tipo de disparo do job quando evento TR-069.
 */
export interface InformTriggerData extends TriggerData {
    eventCodes: Array<Tr069Event>;
}
/**
 * Representação da tipo de disparo do job quando Data/hora.
 */
export interface TimeTriggerData extends TriggerData {
    initialDate: Date;
    finalDate: Date | null;
    finalDateEnabled: boolean;
}

/**
 * Representação do tipo de disparo do job quando Data/hora e expressão Cron.
 */
export interface CronTriggerData extends TimeTriggerData {
    cronExpression: string;
}

/**
 * Representação do modelo de disparo.
 */
export interface Trigger {
    triggerEnabled?: boolean;
    triggerType: TriggerType;
    triggerData: TriggerData;
}