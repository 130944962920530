import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    NotificationPreferencesService,
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences.service";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { NmsToasterLink } from "./nms-toastr-model";
import { NmsNotificationUtilsService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-utils.service";

/**
 * Componente de toaster customizado para poder adicionar links com eventos de onClick
 */
@Component({
    selector: "[nms-toastr-component]",
    styleUrls: ["./nms-toastr.component.scss"],
    templateUrl: "./nms-toastr.component.html",
    /* É obrigatório adicionar a linha abaixo, o componente ngx-toaster não tem a opção
     * de sobrescrevê-lo sendo necessário adicioná-lo.
     * Essa é a animação do componente padrão ngx-toastr
     */
    animations: [
        trigger("flyInOut", [
            state("inactive", style({ opacity: 0 })),
            state("active", style({ opacity: 1 })),
            state("removed", style({ opacity: 0 })),
            transition("inactive => active", animate("{{ easeTime }}ms {{ easing }}")),
            transition("active => removed", animate("{{ easeTime }}ms {{ easing }}"))
        ])
    ],
    preserveWhitespaces: false
})
export class NmsToastrComponent extends Toast implements OnDestroy, OnInit {

    private readonly MAX_LINES = 5;
    private readonly MAX_NUMBER_CHARACTERS = 140;

    disableNotificationsOfType: boolean;
    checkboxDisableId: string;
    showSeeMoreLink = false;

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
        private notificationPreferencesService: NotificationPreferencesService,
        private nmsNotificationUtilsService: NmsNotificationUtilsService
    ) {
        super(toastrService, toastPackage);
        this.disableNotificationsOfType = false;
        this.checkboxDisableId = `disable-notification-${Date.now()}`;
    }

    ngOnInit(): void {
        this.verifySeeMoreLink(this.toastPackage.message);
    }

    ngOnDestroy(): void {
        this.verifyAndDisableNotificationOfType();
    }

    /**
     * Recebe a mensagem com quebras de linha usando "&#10;" e converte para "\n".
     */
    getMessage(message: string) {
        const normalizedMessage = this.nmsNotificationUtilsService.replaceBreakLineFromAsciiAndHTMLToNewLine(message);
        return this.nmsNotificationUtilsService
            .prepareMessageToShow(normalizedMessage, this.MAX_LINES, this.MAX_NUMBER_CHARACTERS);
    }

    openSeeMoreLink(message: string) {
        this.nmsNotificationUtilsService.openSeeMoreLink(message);
    }

    /**
     * Evento na qual iremos sobrescrevê-lo ao clicar no link do toast.
     */
    action(link: NmsToasterLink) {
        event.stopPropagation();
        this.toastPackage.triggerAction(link);
        this.toastrService.clear(this.toastPackage.toastId);
        return false;
    }

    /**
     * Limita a área de fechamento do toastr.
     * O componente do checkbox fica fora do limite da área de clique do fechamento
     * do toastr, dessa forma, ao clicar no checkbox o toastr não será fechado automáticamente.
     *
     * Para que esse comportamento seja possível é necessário que as opções tapToDismiss=false
     * e closeButton=true.
     */
    close(): void {
        const { tapToDismiss, closeButton } = this.options;

        if (!tapToDismiss && closeButton) {
            this.remove();
        }
    }

    /**
     * Verifica se o usuário marcou a opção de desabilitar uma notificationType específica
     * e chama o serviço caso ele tenha marcado a checkbox.
     */
    private verifyAndDisableNotificationOfType() {
        //@ts-ignore
        const { type: notificationType, status } = this.options;

        if (this.disableNotificationsOfType && notificationType) {
            this.notificationPreferencesService.disableNotificationByTypeAndStatus(notificationType, status).subscribe();
        }
    }

    /**
     * Avalia as condições para a exibição do link "Veja mais".
     */
    private verifySeeMoreLink(message: any) {
        this.showSeeMoreLink = this.nmsNotificationUtilsService
            .verifySeeMoreLink(message, this.MAX_LINES, this.MAX_NUMBER_CHARACTERS);
    }
}