import { RuleType } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { CpePythonExpressionService } from "../services/expressions/python/cpe-python-expression.service";
import {
    TemplateInterface,
    TemplateOptions,
    ExternalOptions,
    TemplateType,
    Expression,
    CommandFormat,
} from "./template.interface";
import { TemplateCommonsComponent } from "./template-commons.component";
import { DeviceLanguageServiceResolver } from "../services/expressions/resolver/device-language.service.resolver";

/**
 * Implementação de TemplateInterface para templates do tipo TR069.
 */
export class TemplateTR069Component extends TemplateCommonsComponent implements TemplateInterface {

    constructor(private cpePythonExpressionService: CpePythonExpressionService,
                private deviceLanguageServiceResolver: DeviceLanguageServiceResolver) {
        super();
     }

    getExpressions(): Expression[] | null {
        return this.cpePythonExpressionService.getExpressions();
    }

    getExpressionsAggregatorProvider() {
        return this.cpePythonExpressionService.loadExpressions();
    }

    /**
     * @inheritdoc
     */
    getFormatCommand(): CommandFormat | null {
        return this.cpePythonExpressionService.getFormatCommandPython();
    }

    /**
     * @inheritdoc
     */
    getTooltipVariable(isPythonTemplate: boolean): string {
        return this.cpePythonExpressionService.getVariableTooltip();
    }

    /**
     * @inheritdoc
     * Templates TR-069 não aceitam as seguintes características:
     * - equipmentModelRestrictionTypes: ["IS_DMOS", "NOT_DMOS"] @see RuleType
     * - variableTypes: ["READ_ONLY", "ACTION"]
     */
    createTemplateOptions(externalOptions: ExternalOptions): TemplateOptions {
        let equipmentModelRestrictionTypes = Object.assign({}, externalOptions.deviceModelRestrictionTypes);
        delete equipmentModelRestrictionTypes[RuleType.IS_DMOS];
        delete equipmentModelRestrictionTypes[RuleType.NOT_DMOS];

        let variableTypes = Object.assign({}, externalOptions.variableTypes);
        delete variableTypes["READ_ONLY"];
        delete variableTypes["ACTION"];

        const templateOptions: TemplateOptions = {
            equipmentModelRestrictionTypes,
            variableTypes,
            isTR069Template: true,
            matchingRulesKey: "templateForm.basic.cpe.model.restriction",
            templateLanguage: "PYTHON"
        };

        return templateOptions;
    }

    /**
     * @inheritdoc
     *
     * Templates do tipo TR-069 ainda não implementam esta feature.
     */
    getEquipmentVariables(variables: any) {
        return [];
    }

    getCommands(commands: string = "", isRemovalEnabled: boolean): string {
        return this.cpePythonExpressionService.getCommands(commands, isRemovalEnabled);
    }

    /**
     * @inheritdoc
     */
    getShowInlineExpressions(): boolean {
        return false;
    }

    /**
     * @inheritdoc
     */
    isTemplateOfType(templateType: TemplateType): boolean {
        return templateType === "TR_069";
    }
}