"use strict";

/**
* @ngdoc service
* @name nms.templates.ConfigRESTService
* @description
* # ConfigRESTService
* Factory in the nms.templates.
*/
angular.module("nms.templates")
.service("ConfigRESTService", ["Restangular", "TEMPLATE_TYPE", function(Restangular, TEMPLATE_TYPE) {
    const service: any = {};
    const basePath = "/configuration";

    const simpleGetRequest = (requestPath: string) => {
        return Restangular.all(basePath + requestPath)
            .customGET()
            .then(function (resp) {
                return resp.plain();
            });
    };

    const handleRequestFromTemplateType = (templateType: string, cliRequest, tr069request) => {
        if (TEMPLATE_TYPE[templateType].name === TEMPLATE_TYPE.CLI.name) {
            return cliRequest();
        }

        return tr069request();
    };

    service.templateStatus = function () {
        return simpleGetRequest("/template/status");
    };

    service.templateVariableTypes = function () {
        return simpleGetRequest("/template/variable/types");
    };

    service.templateDeviceVariables = function () {
        return simpleGetRequest("/template/device/variables");
    };

    service.templateInstanceStatus = function () {
        return simpleGetRequest("/templateinstance/status");
    };

    service.searchCriteria = function (moduleName) {
        return simpleGetRequest(`/${moduleName}/searchcriteria`);
    };

    service.auditType = function () {
        return simpleGetRequest("/audit/types");
    };

    service.auditOperation = function () {
        return simpleGetRequest("/audit/operations");
    };

    service.auditName = function () {
        return simpleGetRequest("/audit/names");
    };

    service.auditResult = function () {
        return simpleGetRequest("/audit/results");
    };

    service.cliCommandOptionExtractionFilterTypes = function () {
        return simpleGetRequest("/template/cliCommand/optionExtraction/filterTypes");
    };

    service.rulesMatchingModes = function () {
        return simpleGetRequest("/template/rules/rulesMatchingModes");
    };

    service.deviceModelRestrictionTypes = function () {
        return simpleGetRequest("/template/device/model/restriction/types");
    };

    service.cpeModelRestrictionTypes = function () {
        return simpleGetRequest("/template/cpe/model/restriction/types");
    };

    service.deviceAvailableFields = function () {
        return simpleGetRequest("/templateinstance/device/fields");
    };

    service.cpeAvailableFields = function () {
        return simpleGetRequest("/templateinstance/cpe/fields");
    };

    service.templateDeviceAvailableFields = function () {
        return simpleGetRequest("/template/device/fields");
    };

    service.templateCpeAvailableFields = function () {
        return simpleGetRequest("/template/cpe/fields");
    };

    service.favoriteVariables = function () {
        return Restangular.all("/template/favoriteVariables")
            .customGET()
            .then(function (resp) {
                return resp.plain();
            });
    };

    service.getRuleRestrictionTypes = function(templateType: string) {
        return handleRequestFromTemplateType(templateType, service.deviceModelRestrictionTypes, service.cpeModelRestrictionTypes);
    };

    service.getRuleAvailableFields = function(templateType: string) {
        return handleRequestFromTemplateType(templateType, service.deviceAvailableFields, service.cpeAvailableFields);
    };

    service.getRuleAvailableTemplateFields = function(templateType: string) {
        return handleRequestFromTemplateType(templateType, service.templateDeviceAvailableFields,
            service.templateCpeAvailableFields);
    };

    return service;
}]);
