import { Subject } from "rxjs";
import { EquipmentAssociation, EquipmentDetails, EquipmentIdentifier } from "../template-instance-models";

export interface TemplateInstanceGlobalVariablesDetails {
    expand: boolean,
    vars: Array<TemplateInstanceVariable>;
}

export interface TemplateInstanceLocalVariablesDetails {
    expand: boolean,
    equipmentLocalVars: Array<EquipmentLocalVars>;
}

export interface EquipmentLocalVars {
    localVars: Array<TemplateInstanceVariable>
    apply: boolean;
    equipmentDetails: EquipmentDetails;
    equipmentIdentifier: EquipmentIdentifier;
    equipment: EquipmentAssociation;
    status: string;
    templatesSavedValues: Array<any>;
    templateAssociations: Array<any>;
}

export interface TemplateInstanceVariable {
    autoSelection: boolean;
    availableOptions: Array<string>; // opções disponíveis para seleção
    devices: Array<any>;
    status: string;
    oldStatusList: Array<string>; // status da última aplicação
    loadSnmpTrap: boolean;
    value: Array<string>;
    variable: Variable;
    autoReloadCounterNotifier: {
        start: Subject<void>;
        stop: Subject<void>;
    };
    extractedOptionsToBlock: Array<string>; // valores que serão bloqueados
    extractedOptions: Array<string>; // valores extraídos pelos comandos
    devicesList: Array<number>; // lista de resource ids dos devices a qual a variável foi aplicada
    appliedValue: string;
}

export interface Variable {
    automaticReloadDelay: number;
    blockingParams: InclusionBlockingParams;
    commands: string;
    defaultValue: string;
    description: string;
    editable: boolean;
    enableAutomaticReload: boolean;
    enableSnmpLoadTrap: boolean;
    favorite: boolean;
    globalScope: boolean;
    includeAndBlockOptionMode: IncludeBlockOptions;
    inclusionParams: InclusionBlockingParams;
    mandatory: boolean;
    name: string;
    rangeMax: number;
    rangeMin: number;
    regex: string;
    type: string;
    options: any[];
    allowAutoSelection: boolean;
}

export interface InclusionBlockingParams {
    commands: string;
    extractOptionsFilterResults: boolean;
    extractResultsFromCliResults: boolean;
    hasTransformationLogic: boolean;
    regexToExtractOptions: string;
    rulesMatchingMode: string;
    specificOccurrences: string;
    useSpecificOccurrences: boolean;
    transformationLogicCommands: string;
    ruleOptions: Array<RuleOption>;
}

export interface RuleOption {
    ruleType: string;
    values: Array<string>;
}

export enum IncludeBlockOptions {
    DO_NOT_GET = "DO_NOT_GET",
    BLOCK = "BLOCK",
    INCLUDE = "INCLUDE",
    INCLUDE_AND_BLOCK = "INCLUDE_AND_BLOCK"
}