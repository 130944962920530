import { EventEmitter, Inject, Injectable } from "@angular/core";
import {
    DeviceBackupConfigurationView,
    DeviceBackupConfiguration,
    DcbBackupResult,
    RemoveBackupStatus,
    DcbCompatibleDevices,
    DcbRestoreRequest
} from "./device-backup.models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";

/**
 * Serviço responsável pelas requisições de backup de equipamentos.
 */
@Injectable({
    providedIn: "root"
})
export class DeviceBackupService {
    public readonly LOCALE_EN_I18N = "en";
    public readonly LOCALE_PT_BR_I18N = "pt-br";
    public readonly restoreEventEmitter: EventEmitter<DcbRestoreRequest> = new EventEmitter<DcbRestoreRequest>();

    private dateTimeIntl: Intl.DateTimeFormat;
    private readonly baseUrl: string;

    constructor(@Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
                private readonly httpClient: HttpClient,
                private nmsDialogService: NmsDialogService) {
        this.dateTimeIntl = new Intl.DateTimeFormat(this.getLocale(), {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
        this.baseUrl = "/dcb";
    }

    getAll(): Observable<Array<DeviceBackupConfigurationView>> {
        return this.httpClient
            .get<Array<DeviceBackupConfigurationView>>(this.baseUrl)
            .pipe(map((backup) => this.handleDcbJobDetails(backup)));
    }

    importBackups(backupsToImport: FileList): Observable<any> {
        const formData = new FormData();

        Array.from(backupsToImport).forEach(file => formData.append("files", file));

        return this.httpClient.post(`${this.baseUrl}/import`, formData);
    }

    getBackupResults(backupIds: Array<number>): Observable<Array<DcbBackupResult>> {
        return this.httpClient.post<Array<DcbBackupResult>>(`${this.baseUrl}/results`, backupIds);
    }

    exportBackups(ids: number[]): Observable<HttpResponse<Blob>> {
        return this.httpClient.post(`${this.baseUrl}/export`, ids, { responseType: "blob", observe: "response" });
    }

    removeBackups(backupIds: number[]): Observable<Array<RemoveBackupStatus>> {
        const httpOptions = { headers: new HttpHeaders({ "Content-Type": "application/json" }), body: backupIds };
        return this.httpClient.delete<Array<RemoveBackupStatus>>(`${this.baseUrl}/remove`, httpOptions);
    }

    getDcbCompatibleDevices(backupId: number): Observable<Array<DcbCompatibleDevices>> {
        return this.httpClient.get<Array<DcbCompatibleDevices>>(`${this.baseUrl}/${backupId}/compatibleDevices`);
    }

    restoreBackup(dcbRestoreRequest: DcbRestoreRequest): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/restoreBackup`, dcbRestoreRequest);
    }

    isPtBr(locale: string): boolean {
        return /pt[-_]br/i.test(locale.toLowerCase());
    }

    getConfigText(backupId: number): Observable<string> {
        return this.httpClient.get(`${this.baseUrl}/${backupId}/view`, { responseType: "text" });
    }

    isDeviceSupported(backupId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/${backupId}/isDeviceSupported`);
    }

    executeIfDcbServiceOnline(executeCallback, executeError?) {
        return this.getStatus().then(() => {
            executeCallback();
        }).catch(() => {
            executeError();
        });
    }

    async getStatus(): Promise<boolean> {
        return await new Promise(async (resolve, reject) => {
            this.httpClient.get<boolean>(`${this.baseUrl}/status`).subscribe(
                () => resolve(true),
                () => reject(false)
            );
        });
    }

    /**
     * Formata a data nos seguintes formatos
     * Ex.:
     * PT - 21 de jan. de 2003 07:06
     * EN - Nov 11, 2022, 12:09 PM
     *
     * @param date - Data
     * @return
     */
    formatDateDescription(date: Date): string {
        if (date) {
            return this.dateTimeIntl.format(date);
        }

        return "";
    }

    private handleDcbJobDetails(backups: DeviceBackupConfiguration[]) {
        return backups.map((backup) => {
            const backupdDate = new Date(backup.lastBackupDate);
            let backupView: DeviceBackupConfigurationView = {
                jobName: backup.jobName,
                lastBackupDateTimestamp: backupdDate.getTime(),
                lastBackupDateView: this.formatDateDescription(backupdDate),
                lastBackupDate: backupdDate,
                cfgId: backup.cfgId,
                deviceName: backup.deviceName,
                deviceHostname: backup.deviceHostname,
                deviceModel: backup.deviceModel,
                deviceFirmware: backup.deviceFirmware,
                deviceModelName: backup.deviceModelName
            }
            return backupView;
        });
    }

    /**
     * Retorna o locale utilizado em i18n.
     */
    getLocale(): string {
        if (this.isPtBr(this.translate.use())) {
            return this.LOCALE_PT_BR_I18N;
        }
        return this.LOCALE_EN_I18N;
    }
}
