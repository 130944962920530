import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { JobExecutionStatus, SchedulerJob, SchedulerJobDetails, SchedulerJobDetailsView, TemplateApplicationJobData } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";
import { TimeTriggerService } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/time-trigger.service";
import { CronTriggerService } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/cron-trigger/cron-trigger.service";
import {
    Trigger,
    CronTriggerData,
    TimeTriggerData,
    InformTriggerData,
    TriggerType,
    Tr069Event,
    EVENTS_MAP,
    SnmpTrapTriggerData
} from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Serviço responsável pela conversão de @see SchedulerJobDetails em @see SchedulerJobDetailsView.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerJobDetailToViewConverterService {

    constructor(
        private readonly triggersService: TriggersService,
        private readonly timeTriggerService: TimeTriggerService,
        private readonly cronTriggerService: CronTriggerService,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any
    ) {}

    readonly TRIGGER_TYPES_WITH_EMPTY_TRIGGER_DATA = [TriggerType.ON_DEMAND];
    readonly DISABLED_MESSAGE = this.translate.instant("scheduler.table.disabled");

    convert({ schedulerJob, nextExecution, lastExecution, author }: SchedulerJobDetails): SchedulerJobDetailsView {
        const jobName = schedulerJob.name;
        const { type: jobType, enabled, status } = schedulerJob;
        const triggers = this.buildTriggersText(schedulerJob.triggers);
        const hasOnlyTriggersWithEmptyData = this.hasOnlyTriggersWithEmptyTriggerData(schedulerJob.triggers);
        const translatedStatus = this.getTranslateStatus(schedulerJob);

        return {
            jobId: schedulerJob.id,
            jobName,
            jobType: this.translate.instant(`scheduler.table.jobType.${jobType}`),
            triggers,
            nextExecution: this.getNextExecution(hasOnlyTriggersWithEmptyData, enabled, nextExecution),
            nextExecutionTimeStamp: this.getNextExecutionTimestamp(hasOnlyTriggersWithEmptyData, enabled, nextExecution),
            lastExecution: this.triggersService.formatDateDescription(lastExecution),
            lastExecutionTimeStamp: this.getDateTimestamp(lastExecution),
            modifiedBy: author,
            enabled,
            status,
            translatedStatus
        };
    }

    private getTranslateStatus({ status }: SchedulerJob) {
        switch (status) {
            case JobExecutionStatus.EXECUTING:
                return this.translate.instant("scheduler.table.status.executing");
            case JobExecutionStatus.PARTIAL:
                return this.translate.instant("scheduler.table.status.partial");
            case JobExecutionStatus.FAIL:
                return this.translate.instant("scheduler.table.status.fail");
            case JobExecutionStatus.SUCCESS:
                return this.translate.instant("scheduler.table.status.success");
            case JobExecutionStatus.REQUESTED:
                return this.translate.instant("scheduler.table.status.requested");
            default:
                return this.translate.instant("scheduler.table.status.notRequested");
        }
    }

    private getNextExecution(hasOnlyTriggersWithEmptyData, enabled, nextExecution) {
        if (enabled) {
            return hasOnlyTriggersWithEmptyData ? "" : this.triggersService.formatDateDescription(nextExecution);
        }

        return this.DISABLED_MESSAGE;
    }

    private getNextExecutionTimestamp(hasOnlyTriggersWithEmptyData, enabled, nextExecution) {
        if (enabled && !hasOnlyTriggersWithEmptyData) {
            return this.getDateTimestamp(nextExecution);
        }

        return null;
    }

    private buildTriggersText(triggers: Array<Trigger>): string {
        return triggers
            .map((trigger: Trigger) => {
                const triggerType = trigger.triggerType;
                const triggerData = trigger.triggerData;

                if (this.triggersService.isCronTrigger(triggerType)) {
                    return this.buildCronTriggerText(triggerType, triggerData as CronTriggerData)
                } else if (this.triggersService.isTimeTrigger(triggerType)) {
                    return this.buildDateTimeTriggerText(triggerType, triggerData as TimeTriggerData);
                } else if (this.triggersService.isInformTrigger(triggerType)) {
                    return this.buildInformTriggerText(trigger.triggerData as InformTriggerData);
                } else if (this.triggersService.isSnmpTrapTrigger(triggerType)) {
                    return this.buildSnmpTrapTriggerText(trigger.triggerData as SnmpTrapTriggerData);
                }
            })
            .join(", ");
    }

    private buildCronTriggerText(triggerType: TriggerType, triggerData: CronTriggerData): string {
        const timeTrigger = this.timeTriggerService.getFrequencyTriggerTypeLabel(
            triggerType,
            triggerData.initialDate
        );
        const cronTrigger = this.cronTriggerService.translateCronExpression(triggerData);

        return `${timeTrigger} (${cronTrigger})`
    }

    private buildInformTriggerText(triggerData: InformTriggerData) {
        return triggerData.eventCodes.map((event: Tr069Event) => {
            return `${this.translate.instant("scheduler.table.tr069Event")} ${EVENTS_MAP[event]}`;
        }).join(", ");
    }

    private buildSnmpTrapTriggerText(triggerData: SnmpTrapTriggerData) {
        const trap = this.translate.instant("scheduler.trap.trigger." + triggerData.snmpTrap);
        return `${this.translate.instant("scheduler.table.snmpTrap")} ${trap}`;
    }

    private buildDateTimeTriggerText(triggerType: TriggerType, triggerData: TimeTriggerData) {
        return this.timeTriggerService.getFrequencyTriggerTypeLabel(
            triggerType,
            triggerData.initialDate
        );
    }

    private getDateTimestamp(date: Date) {
        return date ? date.getTime() : null;
    }

    /**
     * Verifica se os triggers do agendamento são dos tipos que não necessitam
     * dados complementares na propriedade triggerData.
     */
    private hasOnlyTriggersWithEmptyTriggerData(triggers: Array<Trigger>) {
        return triggers.every(trigger => this.TRIGGER_TYPES_WITH_EMPTY_TRIGGER_DATA.includes(trigger.triggerType));
    }
}
