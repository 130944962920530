import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import { NotificationsModalComponent } from "../notifications-modal/notifications-modal.component";
import { NmsNotificationService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification.service";
import { Subscription } from "rxjs";
import { NmsNotificationFeedbackService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-feedback.service";
import { NmsNotificationUtilsService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-utils.service";

/**
 * Icone de notificações.
 */
@Component({
    selector: "notifications-bell",
    templateUrl: "./notifications-bell.component.html",
    styleUrls: ["./notifications-bell.component.css"]
})
export class NotificationsBellComponent implements OnInit, OnDestroy {
    unseenNotificationsAmount = 0;
    updateNotificationBadgeEvent: Subscription;
    updateNotificationSeenEvent: Subscription;
    notificationCentralCloseEvent: Subscription;
    isModalOpened: boolean;

    constructor(private modalFactory: ModalFactory,
                private nmsNotificationService: NmsNotificationService,
                private nmsNotificationFeedbackService: NmsNotificationFeedbackService,
                private nmsNotificationUtilsService: NmsNotificationUtilsService) {
    }

    ngOnInit(): void {
        this.getUnseenNotificationsAmount();

        this.notificationCentralCloseEvent = this.nmsNotificationUtilsService.notificationCentralCloseEvent.subscribe(() => {
            this.isModalOpened = false;
        });

        this.updateNotificationSeenEvent = this.nmsNotificationFeedbackService.updateNotificationSeen.subscribe(() => {
            if(this.isModalOpened) {
                this.setAllAsSeen();
            }
        });

        this.updateNotificationBadgeEvent = this.nmsNotificationFeedbackService.updateNotificationBadge.subscribe(() => {
            if(!this.isModalOpened){
                this.unseenNotificationsAmount++;
            }
        });
    }

    getUnseenNotificationsAmount() {
        this.nmsNotificationService.getUnseenNotificationsAmount().subscribe((notifications) => {
            this.unseenNotificationsAmount = notifications;
        });
    }

    setAllAsSeen() {
        this.nmsNotificationService.setAllAsSeen().subscribe(() => {
            this.unseenNotificationsAmount = 0;
        });
    }

    /**
     * Abre a modal com a lista de notificações.
     */
    openNotifications() {
        this.isModalOpened = true;
        this.setAllAsSeen();
        this.modalFactory.openAsyncModal(NotificationsModalComponent, {});
    };

    ngOnDestroy() {
        this.updateNotificationBadgeEvent.unsubscribe();
        this.notificationCentralCloseEvent.unsubscribe();
        this.updateNotificationSeenEvent.unsubscribe();
    }

}