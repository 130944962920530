import { Injectable, Inject } from "@angular/core";
import {
  ANGULARJS_ROOTSCOPE,
  ANGULARJS_TRANSLATE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { NmsToasterLink } from "./nms-toastr-model";

/**
 * Serviço utilizado para criar e exibir os toaster de sucesso e erro com um determinado link.
 */
@Injectable({
  providedIn: "root",
})
export class NmsToastrLinkService {
  constructor(
    private toastr: NmsToastrService,
    @Inject(ANGULARJS_TRANSLATE) private translate: any
  ) {}

  /**
   * Método responsável por exibir um toaster de sucesso juntamente com a ação do link criado. O toaster permanecerá na
   * página e somente irá desaparecer após um clique do usuário.
   */
  public showToastSuccess(message: string, links: NmsToasterLink[]): void {
    this.toastr
      .success(message, null, {
        // @ts-ignore
        links: links,
        disableTimeOut: true,
      })
      .onAction.subscribe((toastr) => toastr.action());
  }

  /**
   * Método responsável por exibir um toaster de erro juntamente com a ação do link criado. O toaster permanecerá na
   * página e somente irá desaparecer após um clique do usuário.
   */
  public showToastError(message: string, links: Array<NmsToasterLink>): void {
    this.toastr
      .error(message, null, {
        // @ts-ignore
        links: links,
      })
      .onAction.subscribe((toastr) => toastr.action());
  }
}
