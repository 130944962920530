import { Injectable, Inject } from "@angular/core";
import {
    ANGULARJS_ROOTSCOPE,
    AUTHENTICATION_SERVICE,
    LICENSE_SERVICE,
    DEVICE_DISCOVER_SERVICE,
    NMS_FEATURES,
    ANGULARJS_TRANSLATE,
    CUSTOM_DEVICE
} from "../upgraded-provider/upgraded-providers";

/**
 * Serviço que engloba ações relacionadas a permissões.
 */
@Injectable({
    providedIn: "root"
})
export class PermissionsActionsService {
    noUserPermissionMessage: string;

    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        @Inject(AUTHENTICATION_SERVICE) private readonly authenticationService: any,
        @Inject(LICENSE_SERVICE) private readonly licenseService: any,
        @Inject(DEVICE_DISCOVER_SERVICE) private readonly deviceDiscoverService: any,
        @Inject(NMS_FEATURES) private readonly nmsFeatures: any,
        @Inject(CUSTOM_DEVICE) private readonly customDevice: any
    ) {
        this.noUserPermissionMessage = this.translate.instant("findAddDevice.error.noUserPermission");
    }

    isUserAdministrator() {
        if (this.rootScope.isAdmin) {
            return true;
        }

        this.rootScope.showDialog({ translateKey: "credentials.modal.noUserPermission" });

        return false;
    }

    verifyUserPermissions() {
        return this.verifyUserPermission(this.nmsFeatures.templateInstance.feature);
    }

    verifyMultivendorLicence(devices) {
        if (_.some(devices, { modelCode: this.customDevice.modelCode })) {
            const isMultivendorAllowed = this.licenseService.isMultivendorAllowed();

            if (!isMultivendorAllowed) {
                this.rootScope.showDialog({
                    translateKey: "devicelist.error.notMultivendorLincense"
                });
                return false;
            }
        }
        return true;
    }

    verifyUserPermission(permission) {
        const hasPermission = this.authenticationService.hasPermission(permission);

        if (!hasPermission) {
            this.noUserPermission();
            return false;
        }

        return true;
    }

    /**
     * Função que verifica se o usuário tem permissão para editar as configurações do(s) device(s), e remove os devices sem
     * permissão da lista de selecionados, também define o tipo de mensagem a ser exibida dependendo da página onde foi
     * realizada a requisição.
     * @param {array} devices: lista de devices selecionados
     * @param {boolean} showDetailedMessage: flag que indica se a mensagem deve ser detalhada ou não
     * @param {function} successCallback: callback para edição das configurações
     * @param {array} params: array de parâmetros a serem enviados para o successCallback
     */
    verifyDevicesPermission(devices, showDetailedMessage, successCallback, params) {
        const devicesForPermissionsCheck = [];

        _.forEach(devices, (device) => {
            devicesForPermissionsCheck.push({
                devModelCode: device.modelCode,
                vendorCode: device.vendorCode,
                locationId: device.locationId,
                name: device.name
            });
        });

        this.deviceDiscoverService.getDevicesWithoutManagerPermission(devicesForPermissionsCheck).then((response) => {
            const devicesWithoutPermissions = response.plain();

            if (_.isEmpty(devicesWithoutPermissions)) {
                params.push(devices);
                successCallback.apply(null, params);
            } else if (showDetailedMessage) {
                const devicesWithoutPermission = _.map(devicesWithoutPermissions, "name");
                const devicesWithoutPermissionsParams = _.map(
                    devicesWithoutPermission,
                    (name) => `${name}: ${this.noUserPermissionMessage}`
                );

                if (devices.length === devicesWithoutPermissions.length) {
                    this.showErrorMessageForAllDevicesWithoutPermission(devices, devicesWithoutPermissionsParams);
                } else {
                    this.showConfirmationMessageForSomeDevicesWithoutPermission(
                        devices, devicesWithoutPermission, devicesWithoutPermissionsParams, params, successCallback);
                }
            } else {
                this.noUserPermission();
            }
        });
    }

    hasFeature(device, feature) {
        const availableFeatures = device.availableFeatures;

        return _.includes(availableFeatures, feature);
    }

    noUserPermission = () => {
        this.rootScope.showDialog({ translateKey: "findAddDevice.error.noUserPermission" });
    }

    private showErrorMessageForAllDevicesWithoutPermission(devices, devicesWithoutPermissionsParams) {
        this.rootScope.showDialogSingularOrPlural({
            translateObjs: [
                {
                    pluralKey: "findAddDevice.error.devicesCanNotBeEdited",
                    singularKey: "findAddDevice.error.deviceCanNotBeEdited",
                    isPlural: devices.length > 1,
                    insideMsgParams: [devices.length]
                }
            ],
            listParams: devicesWithoutPermissionsParams,
            maxChars: 128
        });
    }

    private showConfirmationMessageForSomeDevicesWithoutPermission(devices, devicesWithoutPermission,
        devicesWithoutPermissionsParams, params, successCallback) {
        this.rootScope.showDialogSingularOrPlural({
                translateObjs: [
                    {
                        pluralKey: "findAddDevice.error.devicesCanBeEdited",
                        singularKey: "findAddDevice.error.deviceCanBeEdited",
                        isPlural: devices.length - devicesWithoutPermissionsParams.length > 1,
                        insideMsgParams: [devices.length - devicesWithoutPermissionsParams.length]
                    },
                    {
                        pluralKey: "findAddDevice.error.othersDevicesCantBeEdited",
                        singularKey: "findAddDevice.error.otherDeviceCantBeEdited",
                        isPlural: devicesWithoutPermissionsParams.length > 1,
                        insideMsgParams: [devicesWithoutPermissionsParams.length]
                    }
                ],
                listParams: devicesWithoutPermissionsParams,
                maxChars: 128,
                isConfirm: true
            })
            .then(() => {
                const devicesToEdit = _.filter(devices, (device) => {
                    return !_.includes(devicesWithoutPermission, device.name);
                });
                params.push(devicesToEdit);
                this.rootScope.unselectNotEditableDevices(devicesWithoutPermission);
                successCallback.apply(null, params);
            });
    }
}
