import { AfterViewInit, Component, Host, Inject, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NmsWizardStep, NmsWizardStepComponent, NmsWizardStepValidationResponse } from "@nms-angular-toolkit/nms-wizard";
import {
    MatchingRules,
    Rule,
    RuleCriteria,
    RuleType
} from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE, TEMPLATE_SERVICE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { TemplateComponent } from "../../template.component";
import { Subscription } from "rxjs";
import {
    CpeField,
    CpeFieldValues,
    DeviceField,
    DeviceFieldValues,
    EquipmentRuleOption
} from "../../../template-instance/template-instance-models";
import { TemplateType } from "../../template.interface";
import { TemplateUtils } from "../../template.utils";

/**
 * Componente responsável por gerenciar o passo Básico da criação/edição
 * de Template utilizando o componente de Step do NmsWizard.
 */
@Component({
  selector: "template-step-basic",
  templateUrl: "./template-step-basic.component.html"
})
export class TemplateStepBasicComponent extends NmsWizardStepComponent implements OnDestroy {

    model: NmsWizardStep;
    context: any;
    controller: TemplateComponent;
    status: [];
    rulesMatchingModes: RuleCriteria[];
    matchingRules: MatchingRules;
    onTemplateTypeChangedSubscription: Subscription;

    @ViewChild("stepForm", { static: true }) form: NgForm;

    constructor(@Inject(TEMPLATE_SERVICE) public templateService: any,
                @Inject(ANGULARJS_TRANSLATE) private oldTranslate: any,
                @Inject(ANGULARJS_ROOTSCOPE) private  $rootScope: any,
                @Host() controller: TemplateComponent,
                private templateUtils: TemplateUtils) {
        super();
        this.controller = controller;

        this.controller.isNmsTemplate = this.hasNmsTemplateKeyword();

        this.status = this.controller.getParentScopeVariable("status");
        this.rulesMatchingModes = this.controller.getParentScopeVariable("rulesMatchingModes");
        this.matchingRules = this.initMatchingRules();
        this.controller.getTemplateOptions();
        this.onTemplateTypeChangedSubscription = this.controller.templateTypeChanged$.subscribe(this.clearMatchingRules);
    }

    /**
     * Seta as regras do matching rules para o valor default de acordo com o equipmentField.
     */
    private clearMatchingRules = (field: DeviceFieldValues | CpeFieldValues) => {
        this.matchingRules.rules.forEach(rule => {
            rule.ruleType = RuleType.CONTAINS;
            rule.values = [""];
            rule.field = field;
        });
    }

    private initMatchingRules(): MatchingRules {
        const equipmentRestrictionFilter = this.controller.template.equipmentRestrictionFilter;
        const ruleOptions: Rule[] = equipmentRestrictionFilter.equipmentRuleOption.map((ruleOption) => {
            return {
                ruleType: ruleOption.ruleType,
                values: ruleOption.values,
                field: ruleOption.equipmentField ? ruleOption.equipmentField.value : undefined
            } as Rule;
        });

        return {
            criteria: equipmentRestrictionFilter.rulesMatchingMode,
            rules: ruleOptions,
            useRules: this.controller.template.restrictTemplate
        };
    }

    setContext(context: NmsWizardStep): void {
        this.context = context;
    }

    async loadContent(): Promise<void> {
        return await new Promise((f) => setTimeout(f, 1000));
    }

    allowPrevious(): NmsWizardStepValidationResponse {
        return NmsWizardStepValidationResponse.getDefaultValidationResponse();
    }

    allowNext(): NmsWizardStepValidationResponse {
        return this.validate();
    }

    validate() {
        if (this.form.controls.name.errors && this.form.controls.name.errors.required) {
            let message = this.oldTranslate.instant("form.error.requiredFieldName");

            return new NmsWizardStepValidationResponse(false, message);
        }

        if (this.form.controls.name.errors && this.form.controls.name.errors.pattern) {
            let message = this.oldTranslate.instant("form.error.invalidFieldName");

            return new NmsWizardStepValidationResponse(false, message);
        }

        if (this.form.controls.status.errors && this.form.controls.status.errors.required) {
            let message = this.oldTranslate.instant("templateform.error.unselectedFieldStatus");

            return new NmsWizardStepValidationResponse(false, message);
        }

        if (this.isRulesDisabledButNotEmpty()) {
            this.resetMatchingRulesIfNeeded(this.getDefaultFieldRestriction());
        }

        const { useRules, rules } = this.matchingRules;

        if (!this.templateService.validateModelRestrictionRules(useRules, rules)) {
            let message = this.oldTranslate.instant("templateform.error.emptyRestrictionModel");

            return new NmsWizardStepValidationResponse(false, message);
        }

        return new NmsWizardStepValidationResponse(true);
    }

    goingToNextStep(): NmsWizardStep {
        this.controller.template.applyCommands = this.controller
            .getTemplateComponent().getCommands(this.controller.template.applyCommands, true, this.controller.isPythonTemplate);

        if (this.controller.template.removeCommandsEnabled) {
            this.controller.template.removeCommands = this.controller
                .getTemplateComponent().getCommands(
                    this.controller.template.removeCommands,
                    this.controller.template.removeCommandsEnabled,
                    this.controller.isPythonTemplate
                );
        }

        const templateType = this.controller.templateType.value;

        this.controller.template.equipmentRestrictionFilter.rulesMatchingMode = this.matchingRules.criteria;
        this.controller.template.equipmentRestrictionFilter.equipmentRuleOption =
            this.matchingRules.rules.map(rule => this.buildEquipmentRuleOption(templateType, rule));
        this.controller.template.restrictTemplate = this.matchingRules.useRules;

        return null;
    }

    isChangeNameAllowed(): boolean {
        return this.controller.hasTemplateConfigPermission && !this.controller.isNmsTemplate;
    }

    hasNmsTemplateKeyword() {
        if (this.controller.template) {
            return _.some(this.controller.template.keywords, (keyword) => {
                return keyword.toLowerCase() === this.controller.systemKeyword.toLowerCase();
            });
        }

        return false;
    }

    validateKeyword(keyword) {
        return keyword.text.toLowerCase() !== this.controller.systemKeyword.toLowerCase();
    }

    addTagClass(keyword) {
        return keyword.text.toLowerCase() === this.controller.systemKeyword.toLowerCase() ? "system-keyword" : "";
    }

    ngOnDestroy(): void {
        this.onTemplateTypeChangedSubscription.unsubscribe();
    }

    /**
     * Exibe uma mensagem para o usuário caso hajam regras vazias.
     * Aqui o usuário tomará ciência de que as regras vazias serão removidas.
     * Podendo escolher se prossegue com a remoção ou volta e preenche o que falta.
     */
    checkRestrictionWhenDisabled(state :boolean): void {

        if(!state && this.hasEmptyOrPartialFiledRules()) {
            setTimeout(() => {
                this.matchingRules.useRules = true;
            }, 0.1);
            this.$rootScope
            .showDialog({ translateKey: "templateform.warning.emptyRulesOnRestriction",  isConfirm: true })
            .then(() =>{
                //O usuário optando por seguir, o botão precisa ser setado para false para que as regras de remoção possam ser executadas
                this.matchingRules.useRules = false;
                this.resetMatchingRulesIfNeeded(this.getDefaultFieldRestriction())
            });
        }
    }

    private buildEquipmentRuleOption(templateType: TemplateType, rule): EquipmentRuleOption {
        return {
            ruleType: rule.ruleType,
            values: rule.values,
            equipmentField: this.buildEquimentField(templateType, rule.field)
        }
    }

    /**
     * FIXME: Existe um método em TemplateInstanceEquipmentInterface que converte cria o equipmentField de acordo com o tipo de
     * aplicação de template. Acredito que valeria separar esses métodos em classes separadas que pudessem ser reaproveitadas
     * tanto em template quanto templateInstance.
     */
    private buildEquimentField(templateType: TemplateType, field): DeviceField | CpeField {
        if (this.templateUtils.isCliTemplate(templateType)) {
            return {
                value: DeviceFieldValues[field],
                type: "device"
            } as DeviceField;
        }

        return {
            value: CpeFieldValues[field],
            type: "cpe"
        } as CpeField;
    }

    private getDefaultFieldRestriction(): DeviceFieldValues | CpeFieldValues {
        return this.templateUtils.isCliTemplate(this.controller.template.type)
            ? DeviceFieldValues.MODEL
            : CpeFieldValues.PRODUCT_CLASS;
    }

    /**
     * Verifica se o checkbox está desmarcado porém com regras inseridas
     */
    private isRulesDisabledButNotEmpty(): boolean {
        return !this.matchingRules.useRules && this.matchingRules.rules.length > 0
    }

    /**
     * Filtra as regras que possuem valores preenchidos e limpa os que possuem valores vazios
     */
    private resetMatchingRulesIfNeeded(field: DeviceFieldValues | CpeFieldValues) {
        this.matchingRules.rules = this.matchingRules.rules.filter(rule => rule.values.every(v => v.trim() != ""));
        if (this.matchingRules.rules.length == 0) {
            this.setDefaultRulesValues(field);
        }
    }

    /**
     * Avalia se dentre as restrições configuradas existe alguma com regra vazia.
     * A regra vazia padão será ignorada.
     */
    private hasEmptyOrPartialFiledRules(): boolean {

        if(this.matchingRules.rules.length == 1){
            const defaultRule = this.getDefaultEmptyRule(this.getDefaultFieldRestriction());
            const rule = this.matchingRules.rules[0];
            return rule.values.some(v => v.trim() == "") ? !(rule.field == defaultRule.field
                && rule.ruleType == defaultRule.ruleType) : false;
        }

        return this.matchingRules.rules.some(rule => rule.values.some(v => v.trim() == ""));
    }

    /**
     * Seta as regras para o valor default de acordo com o field
     */
    private setDefaultRulesValues(field: DeviceFieldValues | CpeFieldValues) {
        this.matchingRules.rules = [this.getDefaultEmptyRule(field)];
    }

    private getDefaultEmptyRule(field: DeviceFieldValues | CpeFieldValues): Rule {
        return {
            ruleType: RuleType.CONTAINS,
            values: [""],
            field: field
        };
    }
}
