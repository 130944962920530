import { ChangeTemplateConfirmOptions, TemplateConfirmationConfig } from "./template.interface";

/**
 * Classe utilizada para funcionalidades que possuem implementações similares para templates CLI e TR-069.
 */
export class TemplateCommonsComponent {

    createChangeOptionsConfirmationConfig(options: ChangeTemplateConfirmOptions): TemplateConfirmationConfig {
        const { changeOptionsValue, confirmMessageKey } = options.changeOptions;
        const { confirmationModalFn, postConfirmationFn } = options.confirmationModalFn(changeOptionsValue);
        const confirmationCallbackFn = () => {
            if (postConfirmationFn) {
                postConfirmationFn();
            }

            options.loadOptionsByTemplate(changeOptionsValue);
        };

        const cancelationChangeLanguageFn = () => {
            if (cancelationChangeLanguageFn){
                options.cancelationCallbackFn(changeOptionsValue);
            }
        };
        return {
            confirmationCallbackFn,
            cancelationCallbackFn: () => cancelationChangeLanguageFn(),
            confirmationModalFn,
            confirmationTranslationKey: confirmMessageKey
        };
    }
}
