import { ApplicationRef, Inject, Injectable } from "@angular/core";
import { NmsDialogModel, NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import {
    ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE, CUT_STRING_FILTER
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { DialogOptions } from "./dialog.models";

/**
 * Encapsula as chamadas de ações de dialog para o Angular.
 */
@Injectable({
    providedIn: "root"
})
export class DialogService {

    constructor(
        @Inject(CUT_STRING_FILTER) private cutStringFilter,
        @Inject(ANGULARJS_TRANSLATE) public readonly translate,
        private nmsDialogService: NmsDialogService,
        private applicationRef: ApplicationRef) { }

    /**
     * Abre dialog para confirmação da ação do usuário.
     * @param needsShowConfirmationModal Função que verifica se a modal de confirmação deverá ou não ser mostrada.
     *                                   Caso esta função retorne false, a modal não será apresentada e o confirmCallbackFn
     *                                   será chamado.
     * @param translateKey Chave de tradução da mensagem a ser apresentada na confirmação da ação.
     * @param confirmCallbackFn Função de callback ao ser executada através do botão confirmar da dialog de confirmação.
     * @param cancelCallbackFn Função de callback que será executada através do botão cancelar da dialog de confirmação.
     */
    confirm(needsShowConfirmationModal: () => boolean, translateKey: string,
        confirmCallbackFn: () => void, cancelCallbackFn?: () => void) {
        if (needsShowConfirmationModal()) {
            this.openDialog({
                    translateKey: translateKey,
                    isConfirm: true
                })
                .then(confirmCallbackFn, () => { if (cancelCallbackFn) cancelCallbackFn(); })
        } else {
            confirmCallbackFn();
        }
    }

    /**
     * Função reponsável por exibir popups no sistema.
     *
     * @param options - Dados para construção da Dialog.
     * @returns Promise.
     */
    openDialog(options: DialogOptions): Promise<void> {
        let msg = angular.isString(options.message) ? options.message : this.translate.instant(options.translateKey);

        if (options.params) {
            if (options.paramsInsideMessage) {
                for (var i = 0; i < options.params.length; i++) {
                    msg = msg.replace("{" + i + "}", options.params[i]);
                }
            } else {
                for (var j = 0; j < options.params.length; j++) {
                    msg += "<br>" + this.cutStringFilter(options.params[j], options.maxChars);
                }
            }
        }

        options.message = msg;
        options.title = this.getDialogTitle(options);

        const dialog = this.nmsDialogService.openDialog({
            title: options.title,
            description: options.message,
            bindHtml: true,
            isConfirm: options.isConfirm
        } as NmsDialogModel);

        this.applicationRef.tick();

        return dialog;
    }

    private getDialogTitle(options: DialogOptions) {
        if (options.isConfirm) {
            return this.translate.instant("popups.confirm.confirm");
        }

        return this.getAlertTitlekey(options);
    };

    private getAlertTitlekey(options: DialogOptions) {
        return options && options.title || this.getAlertTitle(options);
    }

    private getAlertTitle(options: DialogOptions) {
        let typeName: string;
        switch (options.type) {
            case "alert":
                typeName = this.translate.instant("popups.alert.alert");
                break;
            case "information":
                typeName = this.translate.instant("popups.alert.information");
                break;
            case "error":
            default:
                typeName = this.translate.instant("popups.alert.error");
                break;
        }
        return typeName;
    }
}