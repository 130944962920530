import { NmsToasterLink } from "../../components/elements/nms-toastr/nms-toastr-model";

/**
 * Tipos de notificação suportadas pelo Maestro.
 * É importante que todas as notificações sejam cadastradas aqui, mesmo que não tenham um layout de notificação padrão, desta
 * forma centralizamos a definição dos tipos.
 */
export enum NotificationType {
    NEW_CPE_REGISTERED = "NEW_CPE_REGISTERED",
    CPE_GLOBAL_CREDENTIALS = "CPE_GLOBAL_CREDENTIALS",
    CPE_SPECIFIC_CREDENTIALS = "CPE_SPECIFIC_CREDENTIALS",
    CPE_DOWNLOAD_PENDING = "CPE_DOWNLOAD_PENDING",
    CPE_DOWNLOAD_FAILED = "CPE_DOWNLOAD_FAILED",
    CPE_TRANSFER_COMPLETED = "CPE_TRANSFER_COMPLETED",
    CPE_TRANSFER_FAILED = "CPE_TRANSFER_FAILED",
    ONU_DISCOVERED = "ONU_DISCOVERED",
    TEMPLATE_APPLICATION_EQUIPMENT = "TEMPLATE_APPLICATION_EQUIPMENT",
    SCHEDULER_ADDED = "SCHEDULER_ADDED",
    SCHEDULER_UPDATED = "SCHEDULER_UPDATED",
    SCHEDULER_DELETED = "SCHEDULER_DELETED",
    SCHEDULER_EXECUTION = "SCHEDULER_EXECUTION",
    SCHEDULER_WITH_NOT_ALLOWED_DEVICES = "SCHEDULER_WITH_NOT_ALLOWED_DEVICES",
    BACKUP_DEVICE = "BACKUP_DEVICE",
    BACKUP_DEVICE_TASK = "BACKUP_DEVICE_TASK",
    BACKUP_DEVICE_RETENTION_CONFIG = "BACKUP_DEVICE_RETENTION_CONFIG",
    TEMPLATE_APPLICATION_FINISHED = "TEMPLATE_APPLICATION_FINISHED",
    AUDIT_RETENTION_CONFIG = "AUDIT_RETENTION_CONFIG",
    DISCOVERED_ADDED_DEVICE = "DISCOVERED_ADDED_DEVICE",
    DEVICE_GLOBAL_MANAGEMENT_PROTOCOL = "DEVICE_GLOBAL_MANAGEMENT_PROTOCOL",
    DEVICE_SPECIFIC_MANAGEMENT_PROTOCOL = "DEVICE_SPECIFIC_MANAGEMENT_PROTOCOL",
    USER_PERMISSION = "USER_PERMISSION",
    GROUP_PERMISSION = "GROUP_PERMISSION",
    SCHEDULED_BY_FREQUENCY = "SCHEDULED_BY_FREQUENCY",
    SCHEDULED_BY_TR069_EVENT = "SCHEDULED_BY_TR069_EVENT",
    SCHEDULED_BY_SNMP_TRAP = "SCHEDULED_BY_SNMP_TRAP",
    SCHEDULED_BY_FREQUENCY_ADMIN = "SCHEDULED_BY_FREQUENCY_ADMIN",
    SCHEDULED_BY_TR069_EVENT_ADMIN = "SCHEDULED_BY_TR069_EVENT_ADMIN",
    SCHEDULED_BY_SNMP_TRAP_ADMIN = "SCHEDULED_BY_SNMP_TRAP_ADMIN"
}

/**
 * Modelo de dados que representa o evento lançado para uma notificação que teve seu estado alterado,
 * ou foi removida.
 */
export interface NotificationStateChangeEvent {
    notificationId: string;
    read: boolean;
    runFadeOutAnimation: boolean;
};

/**
 *  Enum relacionado aos status das notificações.
 */
export enum NotificationStatus {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    INFO = "INFO",
    WARNING = "WARNING"
}

/**
 * Modelo de dados no qual representa o detalhamento das notificações.
 */
export interface DetailedResponse {
    titleKey: string;
    titleParameters: Array<string>;
    detailsKey: string;
    detailsParameters: Array<string>;
};

/**
 * Modelo de dados relacionado as notificações.
 */
export interface NotificationMessage {
    id: string;
    type: NotificationType;
    status: NotificationStatus;
    detailedResponse: DetailedResponse;
    autoClose: boolean;
    enabled: boolean;
    createdAt: string;
    delayBeforeCloseInSeconds: number;
    read: boolean;
};

/**
 * Filtros para busca de notificações. (all, read, unread)
 */
export enum NotificationFilter {
    ALL = "all",
    READ = "read",
    UNREAD = "unread"
};

/**
 * Sumário da quantidade de notificações pelo filtro.
 *
 * @see {@link NotificationFilter}
 */
export type NotificationSummary = {
    [type in NotificationFilter]: number
};

/**
 * Representa o agrupamento de notificações de um determinado dia (00:00 - 23:59).
 */
export interface DailyNotificationsMessage {
    /**
     * Data do agrupamento das mensagens seguindo o formato 'YYYY-mm-dd'.
     */
    createdAt: string;
    notifications: Array<NotificationMessage>;
}

/** Possíveis tipos de notificação */
export type NmsNotificationVariant = "error" | "success" | "partial" | "info";

/** Modelo de requisição para notificações paginadas */
export interface NotificationPagingRequest {
    pageNumber: number;
    pageSize: number;
}

/**
 * Representa a requisição de lista de notificações paginada
 * e filtrada.
 */
export interface NotificationRequest {
    pagingRequest: NotificationPagingRequest;
    filterRequest?: { read: boolean };
}

/**
 * Estado da notificação para controlar animação ao clicar em remover.
 */
export type NmsNotificationFadeState = "in" | "out";

/**
 * Representa um link customizado de notificação.
 */
export interface CustomNotificationLink {
    getLinks: (notification?: NotificationMessage) => Array<NmsToasterLink>;
};

/**
 * Modelo utilizado nos links das notificações por tipo.
 */
export type CustomNotificationLinkByType = {
    [key in NotificationType]: CustomNotificationLink;
};