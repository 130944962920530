import {
    Injectable,
    KeyValueDiffers,
    IterableDiffers,
    KeyValueDiffer,
    IterableDiffer,
    KeyValueChanges,
    IterableChanges
} from "@angular/core";
import { Rule, MatchingRules } from "./matching-rules.models";

interface RuleDiffer {
    typeDiffer: KeyValueDiffer<string, any>;
    valuesDiffer: IterableDiffer<string>;
}

/**
 * Serviço para detectar alterações em @see MatchingRules.
 */
@Injectable({
    providedIn: "root"
})
export class MatchingRulesDifferService {
    private modeUseRulesDiffer: KeyValueDiffer<string, any>;
    private rulesListDiffer: IterableDiffer<Rule>;
    private rulesDiffers: Map<string, RuleDiffer>;

    constructor(private readonly keyValuediffers: KeyValueDiffers, private readonly iterableDiffers: IterableDiffers) {}

    public createDiffers(matchingRules: MatchingRules) {
        this.modeUseRulesDiffer = this.keyValuediffers.find(matchingRules).create();
        this.rulesListDiffer = this.iterableDiffers.find(matchingRules.rules).create();
        this.rulesDiffers = new Map();
        matchingRules.rules.forEach((rule) => {
            this.rulesDiffers.set(rule.id, this.buildRuleDiffers(rule));
        });
    }

    public detectChanges(matchingRules: MatchingRules): boolean {
        const modeUseRuleschanges = this.modeUseRulesDiffer.diff(matchingRules);
        const rulesListChanges = this.rulesListDiffer.diff(matchingRules.rules);
        const haveRulesChanges = this.detectRuleChanges(matchingRules);

        return this.hasMatchingRulesChanges(modeUseRuleschanges, rulesListChanges, haveRulesChanges);
    }

    public addRuleToRulesDiffers(rule: Rule) {
        this.rulesDiffers.set(rule.id, this.buildRuleDiffers(rule));
    }

    public deleteRuleFromRulesDiffers(ruleId: string) {
        this.rulesDiffers.delete(ruleId);
    }

    public destroyDiffers() {
        this.modeUseRulesDiffer = null;
        this.rulesListDiffer = null;
        this.rulesDiffers = null;
    }

    private detectRuleChanges(matchingRules: MatchingRules): boolean {
        let rulesTypeChanges = undefined;
        let rulesValuesChanges = undefined;

        return Array.from(this.rulesDiffers.keys()).some((ruleId) => {
            const ruleDiffer: RuleDiffer = this.rulesDiffers.get(ruleId);
            const rule: Rule = matchingRules.rules.find((rule) => rule.id === ruleId);

            if (!rule){
                return false;
            }

            rulesTypeChanges = ruleDiffer.typeDiffer.diff(rule);
            rulesValuesChanges = ruleDiffer.valuesDiffer.diff(_.clone(rule.values));

            return rulesTypeChanges || rulesValuesChanges;
        });
    }

    private hasMatchingRulesChanges(
        modeUseRuleschanges: KeyValueChanges<string, any> | null,
        rulesListChanges: IterableChanges<Rule> | null,
        haveRulesChanges: boolean
    ) {
        return modeUseRuleschanges !== null || rulesListChanges !== null || haveRulesChanges;
    }

    private buildRuleDiffers(rule: Rule): RuleDiffer {
        return {
            typeDiffer: this.keyValuediffers.find(rule).create(),
            valuesDiffer: this.iterableDiffers.find(rule.values).create()
        };
    }
}
