import { Injectable } from "@angular/core";
import { ToastrService, ActiveToast, IndividualConfig } from "ngx-toastr";
import { NmsToastrService } from "./nms-toastr.service";

/**
 * Wrapper para ToastrService.
 * Responsável por apresentar os toastrs da central de notificações na tela.
 */
@Injectable({
    providedIn: "root",
})
export class NmsNotificationToastrService extends NmsToastrService {

    private readonly TOASTR_CLASS = "ngx-toastr nms-notification-toastr";

    constructor(toastr: ToastrService) {
        super(toastr);
    }

    /** Exibe toast de sucesso. */
    success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return super.success(message, title, { ...override, toastClass: this.TOASTR_CLASS });
    }

    /**
    * Exibe toast de erro.
    * Este tipo de toast o comportamento padrão é não fechar automático, porem é possível sobrescrever essa configuração.
    */
    error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return super.error(message, title, { disableTimeOut: true, ...override, toastClass: this.TOASTR_CLASS });
    }

    /** Exibe toast de informação. */
    info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return super.info(message, title, { ...override, toastClass: this.TOASTR_CLASS });
    }

    /** Exibe toast de advertência. */
    warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        return super.warning(message, title, { ...override, toastClass: this.TOASTR_CLASS });
    }
}