const createMainFunctionStatement = () =>
`
    def run(cpe):
        # your code here
`;

const createAddObjectTr069Method = () =>
`
    cpe.addObject('InternetGatewayDevice.SomePath.SomeObject.')
`;

const createDeleteObjectTr069Method = () =>
`
    cpe.deleteObject('InternetGatewayDevice.SomePath.SomeObject.1')
`;

const createDownloadTr069Method = () =>
`
    cpe.download('http://file_server/file', 1)
`;

const createGetParameterNamesTr069Method = () =>
`
    cpe.getParameterNames('InternetGatewayDevice.SomePath.', True)
`;

const createGetParameterValuesTr069Method = (parameters) =>
`
    cpe.getParameterValues(${parameters})
`;

const createSetParameterValuesTr069Method = (varName) =>
`
    cpe.setParameterValues('InternetGatewayDevice.SomePath.SomeParameter', ${varName})
`;

const createMultipleSetParameterValuesTr069Method = (paramValues) =>
`
    cpe.setParameterValues({${paramValues}})
`;

const createRebootTR069Method = () =>
`
    cpe.reboot()
`;

const createGetConnectionRequestUrlCpeMethod = () =>
`
    cpe.getConnectionRequestUrl()
`;

const createGetHardwareVersionCpeMethod = () =>
`
    cpe.getHardwareVersion()
`;

const createGetHostnameCpeMethod = () =>
`
    cpe.getHostname()
`;

const createGetLastInformCpeMethod = () =>
`
    cpe.getLastInform()
`;

const createGetManufacturerCpeMethod = () =>
`
    cpe.getManufacturer()
`;

const createGetProductModelCpeMethod = () =>
`
    cpe.getProductModel()
`;

const createGetSerialNumberCpeMethod = () =>
`
    cpe.getSerialNumber()
`;

const createGetProvisioningCodeCpeMethod = () =>
`
    cpe.getProvisioningCode()
`;

const createGetSoftwareVersionCpeMethod = () =>
    `
    cpe.getSoftwareVersion()
`;

export default {
    createMainFunctionStatement,
    createAddObjectTr069Method,
    createDeleteObjectTr069Method,
    createDownloadTr069Method,
    createGetParameterNamesTr069Method,
    createGetParameterValuesTr069Method,
    createSetParameterValuesTr069Method,
    createMultipleSetParameterValuesTr069Method,
    createRebootTR069Method,
    createGetConnectionRequestUrlCpeMethod,
    createGetHardwareVersionCpeMethod,
    createGetHostnameCpeMethod,
    createGetLastInformCpeMethod,
    createGetManufacturerCpeMethod,
    createGetProductModelCpeMethod,
    createGetSerialNumberCpeMethod,
    createGetSoftwareVersionCpeMethod,
    createGetProvisioningCodeCpeMethod
};
