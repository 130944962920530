"use strict";

import {
    DeletionStatus,
    TemplateInfo,
    TemplateInstance,
    TemplateInstanceIdentifier,
    TemplateInstanceResult,
    TotalKeywords,
    TemplateInstanceEquipmentsCommandsRequest,
    TemplateCommandResponse,
    EquipmentFilterResponse,
    EquipmentSearchFilter,
    TemplateInstanceCommandsRequest,
    ScheduledTemplateApplication,
    ExecutionOrigin,
    DeviceModelTemplateAssociation,
    IdentifierType,
    TemplateApplicationStatus
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { TemplateAssociationResult } from "@nms-ng2/app/shared/models/application-results.models";
import { PaginationFilter, PaginationResponse } from "app/shared/utils/rest-pagination/rest-pagination";
import { TemplateInstanceRESTService } from "./template-instance-rest-service";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

export interface TemplateInstanceService {
    getPaginated(filters: PaginationFilter): Promise<PaginationResponse<TemplateInstance>>;
    hasSearchCriteria(filters: any): boolean;
    getSearchType(searchField: any): any;
    getScheduledTemplateApplicationAndConvertVars(templateInstanceId: string): Promise<ScheduledTemplateApplication>;
    checkUncheckAll(selected: any, templateInstances: any): any;
    readPageFiltersFromSessionStorage(filters: any, pageDetails: any): void;
    setPageFiltersSession(filters: any): void;
    getVarRestrictions(equipmentAssociations: any, templates: any): any;
    getTemplateIdsFromDevices(equipmentAssociations: any);
    getTemplateIds(templateArray: any): any[];
    getTemplateNames(templates: any): any[];
    verifyRemoveCommands(templateInstanceId: string): Promise<TemplateInfo>;
    removeTemplateInstance(templateInstanceId: string): Promise<void>;
    getEquipmentsCommands(
        commandRequest: TemplateInstanceEquipmentsCommandsRequest,
        httpConfig: any
    ): Promise<TemplateCommandResponse[]>;
    getCommands(commandRequest: TemplateInstanceCommandsRequest): Promise<TemplateCommandResponse[]>;
    convertArrayToMap(array: any): any;
    getTemplateInstanceIds(templateInstances: Array<TemplateInstance>): Array<string>;
    getTemplateInstanceNamesFromIds(templateInstanceIds: any, templateInstanceList: any): any[];
    getFailureIdsDeletions(response: Array<DeletionStatus>): Array<string>;
    removeFromNMS(templateInstanceIds: Array<string>): Promise<Array<DeletionStatus>>;
    apply(scheduledTemplateApplication: ScheduledTemplateApplication): Promise<TemplateInstanceIdentifier | void>;
    executeTemplateInstances(templateInstanceIds: Array<string>): Promise<void>;
    applyAndOpenResultModal(scheduledTemplateApplication: ScheduledTemplateApplication, onClose: () => void,
        onSaveTemplateInstance?: () => void): void;
    getResultForTemplateInstance(resultTemplateInstanceId: string, executionOrigin: ExecutionOrigin,
        applicationType: string): Promise<TemplateInstanceResult>;
    getJoinedProperties(array: any, property: any): any;
    getFirmwareVersionsIntersectionFromRangeRules(templates: any): any;
    validateTemplateRestriction(checkedTemplates: Array<any>, equipments: Array<any>): Promise<any[]>;
    getRemoveResultForTemplateInstance(templateInstanceId: string, applicationType?: string): Promise<TemplateInstanceResult>;
    getResultForEquipment(templateInstanceId: string, executionOrigin: ExecutionOrigin): Promise<TemplateAssociationResult[]>;
    setOrRemoveSearchCriteriaFromSessionStorage(filters: any): void;
    getTotalKeywords(): Promise<TotalKeywords>;
    checkUncheckAllEquipmentAssociationsToApply(selected: any, equipmentAssociations: any): void;
    isAllEquipmentAssociationChecked(equipmentAssociations: any): boolean;
    getDeviceTemplatesAssociationsByVariable(deviceTemplateAssociations: any): any;
    selectMultiple(initialIndex: any, finalIndex: any, checkedItems: any, items: any): any;
    validateDeviceForTemplate(resourceId: any, templateId: any): any;
    extractTemplateIds(templateInstance: TemplateInstance): any;
    isTemplateInstanceApplying(templateInstanceId: string): Promise<boolean>;
    getEquipmentsByRestrictions(
        templateType: string,
        equipmentSearchFilter: EquipmentSearchFilter
    ): Promise<EquipmentFilterResponse>;
    getTemplateInstanceResults(templateInstanceId: string, executionOrigin: ExecutionOrigin):
        Promise<{[key: string]: {[key:string]: TemplateApplicationStatus}}>;
}

var app = angular.module("nms.templates");

app.factory("TemplateInstanceService", [
    "TemplateInstanceRESTService",
    "TemplateRESTService",
    "$q",
    "$rootScope",
    "$translate",
    "ngDialog",
    "TemplateInstanceValidatorService",
    "ConverterService",
    "APPLICATION_STATUS",
    "UserPreferencesService",
    "TemplateInstanceIdentifierService",
    "NmsToastrService",
    "TEMPLATE_TYPE",
    "APPLICATION_COMMANDS",
    "EXECUTION_ORIGIN",
    function (
        TemplateInstanceRESTService: TemplateInstanceRESTService,
        TemplateRESTService,
        $q,
        $rootScope,
        $translate,
        ngDialog,
        TemplateInstanceValidatorService,
        ConverterService,
        APPLICATION_STATUS,
        UserPreferencesService,
        TemplateInstanceIdentifierService,
        nmsToastrService: NmsToastrService,
        TEMPLATE_TYPE,
        APPLICATION_COMMANDS,
        EXECUTION_ORIGIN
    ): TemplateInstanceService {
        const service = {} as TemplateInstanceService;

        service.getPaginated = function (filters: PaginationFilter): Promise<PaginationResponse<TemplateInstance>> {
            return TemplateInstanceRESTService.getPaginated(filters);
        };

        service.hasSearchCriteria = function (filters) {
            if (!filters.searchTerm) {
                return false;
            }
            return true;
        };

        service.getSearchType = function (searchField) {
            if (searchField) {
                return searchField.fieldType;
            } else {
                return "TEXT";
            }
        };

        service.getScheduledTemplateApplicationAndConvertVars = function (templateInstanceId: string):
            Promise<ScheduledTemplateApplication> {
            return TemplateInstanceRESTService.get(templateInstanceId)
                .then((scheduledTemplateApplication: ScheduledTemplateApplication) => {
                    const templateInstance = scheduledTemplateApplication.templateInstance;

                    if (templateInstance.status !== APPLICATION_STATUS.APPLYING) {
                        templateInstance.equipmentAssociations.forEach(equipment => equipment.apply = false);
                    }
                    return scheduledTemplateApplication;
                });
        };

        service.checkUncheckAll = function (selected, templateInstances) {
            if (selected) {
                return templateInstances.slice();
            } else {
                return [];
            }
        };

        service.readPageFiltersFromSessionStorage = function (filters, pageDetails) {
            filters = UserPreferencesService.loadPageFilterValues(filters, "instance");

            if (filters.currentPage) {
                pageDetails.currentPage = filters.pageNumber + 1;
            }
            if (filters.searchValue) {
                pageDetails.search.value = filters.searchValue;
            }
            if (filters.searchColumn) {
                pageDetails.search.field = filters.searchColumn;
            }
        };

        service.setPageFiltersSession = function (filters) {
            UserPreferencesService.savePageFilterValues(filters, "instance");
        };

        service.getVarRestrictions = function (equipmentAssociations, templates) {
            var templateIds = service.getTemplateIdsFromDevices(equipmentAssociations);

            return TemplateRESTService.getVarRestrictions(templateIds).then(function (variableTemplateAssociationsResponse) {
                return new Promise((resolve, reject) => {
                    var variableTemplateAssociations = [];

                    variableTemplateAssociationsResponse.forEach(function (currentVariableTemplateAssociation) {
                        var variableTemplateAssociation: any = {
                            var: angular.copy(currentVariableTemplateAssociation.var),
                            deviceAssociations: []
                        };

                        equipmentAssociations.forEach(function (device) {
                            var templateIds = _.map(device.templateAssociations, "templateId");
                            var deviceAssociation: any = {
                                deviceId: TemplateInstanceIdentifierService.resolveIdentifier(device.equipmentIdentifier),
                                templatesWithRestrictions: [],
                                templateIds: [],
                                extractedOptions: { rawOutput: "", result: "", options: null }
                            };

                            currentVariableTemplateAssociation.templateIds.forEach(function (templateId) {
                                if (_.includes(templateIds, templateId)) {
                                    deviceAssociation.templateIds.push(templateId);
                                }
                            });

                            currentVariableTemplateAssociation.templatesWithRestrictions.forEach(function (templateId) {
                                if (_.includes(templateIds, templateId)) {
                                    deviceAssociation.templatesWithRestrictions.push(templateId);
                                }
                            });

                            if (!_.isEmpty(deviceAssociation.templateIds)) {
                                variableTemplateAssociation.deviceAssociations.push(deviceAssociation);
                            }
                        });

                        variableTemplateAssociations.push(variableTemplateAssociation);
                    });

                    const validateReadOnlyVariablesResponse = TemplateInstanceValidatorService.validateReadOnlyVariables(
                        variableTemplateAssociations,
                        templates
                    );

                    const validateActionVariablesResponse = TemplateInstanceValidatorService.validateActionVariables(
                        variableTemplateAssociations,
                        templates
                    );

                    if (!validateReadOnlyVariablesResponse.result) {
                        showMessageForReadOnlyVariable(validateReadOnlyVariablesResponse);
                        return reject();
                    }

                    if (!validateActionVariablesResponse.result) {
                        return $rootScope
                            .showDialog({
                                isConfirm: true,
                                translateKey: validateActionVariablesResponse.messageKey,
                                params: [validateActionVariablesResponse.messageParams],
                                paramsInsideMessage: true
                            })
                            .then(function () {
                                resolve(_.uniq(variableTemplateAssociations));
                            });
                    }

                    return resolve(_.uniq(variableTemplateAssociations));
                });
            });
        };

        const showMessageForReadOnlyVariable = function (validateReadOnlyVariablesResponse) {
            $rootScope.showDialog({
                translateKey: validateReadOnlyVariablesResponse.messageKey,
                params: validateReadOnlyVariablesResponse.messageParams,
                maxChars: 128
            });
        };

        service.getTemplateIds = function (templateArray) {
            var templatesId = [];
            if (Array.isArray(templateArray)) {
                templateArray.forEach(function (template) {
                    templatesId.push(template.templateId);
                });
                return templatesId;
            }
            return null;
        };

        service.getTemplateNames = function (templates) {
            var templatesName = [];
            templates.forEach(function (template) {
                templatesName.push(template.templateName);
            });
            return templatesName;
        };

        service.verifyRemoveCommands = function (templateInstanceId: string): Promise<TemplateInfo> {
            return TemplateInstanceRESTService.verifyRemoveCommands(templateInstanceId);
        };

        service.removeTemplateInstance = function (templateInstanceId: string): Promise<void> {
            return TemplateInstanceRESTService.removeTemplateInstance(templateInstanceId);
        };

        service.getEquipmentsCommands = function (commandRequest, httpConfig): Promise<TemplateCommandResponse[]> {
            // FIXME - Conversão devido a issue: https://github.com/mbenford/ngTagsInput/issues/678
            commandRequest.templateInstance.keywords = ConverterService.convertArrayObjectToStringArray(
                commandRequest.templateInstance.keywords
            );

            return TemplateRESTService.getEquipmentsCommands(commandRequest, httpConfig);
        };

        service.getCommands = function (commandRequest) {
            return TemplateRESTService.getCommands(commandRequest);
        };

        service.convertArrayToMap = function (array) {
            return ConverterService.convertArrayToMap(array);
        };

        service.getTemplateInstanceIds = function (templateInstances: Array<TemplateInstance>): Array<string> {
            const templateInstanceIds = [];

            templateInstances.forEach(function (templateInstance) {
                templateInstanceIds.push(templateInstance.id);
            });

            return templateInstanceIds;
        };

        service.getTemplateInstanceNamesFromIds = function (templateInstanceIds, templateInstanceList) {
            var templateInstanceNames = [];

            templateInstanceIds.forEach(function (templateInstanceFromId) {
                templateInstanceList.forEach(function (templateInstanceFromList) {
                    if (templateInstanceFromId === templateInstanceFromList.id) {
                        templateInstanceNames.push(templateInstanceFromList.name);
                        return;
                    }
                });
            });

            return templateInstanceNames;
        };

        service.getFailureIdsDeletions = function (response: Array<DeletionStatus>): Array<string> {
            const fails = [];
            response.forEach(function (responseItem) {
                if (!responseItem.success) {
                    fails.push(responseItem.id);
                }
            });

            return fails;
        };

        service.removeFromNMS = function (templateInstanceIds: string[]): Promise<Array<DeletionStatus>> {
            return TemplateInstanceRESTService.removeFromNMS(templateInstanceIds);
        };

        service.apply = function (scheduledTemplateApplication: ScheduledTemplateApplication):
            Promise<TemplateInstanceIdentifier | void> {
            scheduledTemplateApplication.templateInstance.modifiedBy = localStorage.getItem("username");
            const templateInstance: TemplateInstance = jQuery.extend(true, {}, scheduledTemplateApplication.templateInstance);
            const schedulerJob = scheduledTemplateApplication.schedulerJob;

            // FIXME - Conversão devido a issue: https://github.com/mbenford/ngTagsInput/issues/678
            templateInstance.keywords = ConverterService.convertArrayObjectToStringArray(templateInstance.keywords);

            const newSchedulerTemplateApplication = { templateInstance, schedulerJob };

            if (!templateInstance.status) {
                return TemplateInstanceRESTService.apply(newSchedulerTemplateApplication);
            }

            return TemplateInstanceRESTService.update(newSchedulerTemplateApplication);
        };

        service.isTemplateInstanceApplying = function(templateInstanceId: string) : Promise<boolean> {
            return TemplateInstanceRESTService.isTemplateInstanceApplying(templateInstanceId);
        };

        service.executeTemplateInstances = function(templateInstanceIds: string[]) : Promise<void> {
           return TemplateInstanceRESTService.executeTemplateInstances(templateInstanceIds);
        };

        service.applyAndOpenResultModal = function (
            scheduledTemplateApplication: ScheduledTemplateApplication,
            onClose: () => void,
            onSaveTemplateInstance?: () => void
        ) {
            const executionOrigin = EXECUTION_ORIGIN.TEMPLATE_APPLICATION;
            const { templateInstance } = scheduledTemplateApplication;

            service.apply(scheduledTemplateApplication).then((response?: TemplateInstanceIdentifier) => {
                const templateInstanceId = response ? response.templateInstanceId : templateInstance.id;
                const hideResultDetails =
                    templateInstance.equipmentAssociations.length > APPLICATION_COMMANDS.MAX_EQUIPMENTS_TO_SHOW_RESULT_DETAILS;

                ngDialog
                    .open({
                        template: "templates/features/template/template-instance/modals/results-modal.html",
                        controller: "ResultsModalCtrl",
                        className: `${hideResultDetails ? 'medium-modal hide-details' : 'big-modal'}`,
                        resolve: {
                            results: function () {
                                return service.getResultForTemplateInstance(templateInstanceId, executionOrigin, "APPLY")
                                    .then(response => {
                                        response.presentationMode = templateInstance.presentationMode;
                                        return response;
                                    });

                            },
                            templateType: function () {
                                return templateInstance.type;
                            },
                            hideResultDetails() {
                                return hideResultDetails
                            },
                            executionOrigin() {
                                return executionOrigin
                            },
                            templateInstanceId() {
                                return templateInstanceId
                            }
                        }
                    })
                    .closePromise.then(function () {
                        $rootScope.hideLoadingPanel = false;
                        onClose();
                    });

                nmsToastrService.info($translate.instant("toastr.templateInstanceSavedSuccessfully"));

                if (angular.isDefined(onSaveTemplateInstance)) {
                    onSaveTemplateInstance();
                }
            });
        };

        service.getTemplateIdsFromDevices = function (devices) {
            var templateIds = [];

            devices.forEach(function (device) {
                service.getTemplateIds(device.templateAssociations).forEach(function (templateId) {
                    templateIds.push(templateId);
                });
            });

            return templateIds;
        };

        service.getJoinedProperties = function (array, property) {
            return _.chain(array)
                .map(function (item) {
                    return item[property];
                })
                .filter(function (property) {
                    return !_.isEmpty(property);
                })
                .join(", ")
                .value();
        };

        service.validateTemplateRestriction = function (checkedTemplates, equipments): Promise<Array<string>> {
            const templatesRules = checkedTemplates.reduce((accumulator, current) => {
                return ({
                    ...accumulator, [current.name]: current.equipmentRestrictionFilter
                });
            }, {});

            const templateRestrictionFilter = {
                equipmentIdentifiers: equipments.map(equipment => equipment.equipmentIdentifier),
                templatesRules
            };

            return TemplateInstanceRESTService.validateTemplateRestriction(templateRestrictionFilter);
        };

        service.getResultForTemplateInstance = function (
            templateInstanceId: string,
            executionOrigin: ExecutionOrigin,
            applicationType: string,
        ): Promise<TemplateInstanceResult> {
            return TemplateInstanceRESTService.getResultForTemplateInstance(templateInstanceId, executionOrigin, applicationType);
        };

        service.getRemoveResultForTemplateInstance = function (
            templateInstanceId: string,
            applicationType?: string
        ): Promise<TemplateInstanceResult> {
            return TemplateInstanceRESTService.getRemoveResultForTemplateInstance(templateInstanceId, applicationType);
        };

        service.getResultForEquipment = function (
            templateInstanceId: string,
            executionOrigin: ExecutionOrigin
        ): Promise<TemplateAssociationResult[]> {
            return TemplateInstanceRESTService.getApplicationResultDetails(templateInstanceId, executionOrigin);
        };

        service.setOrRemoveSearchCriteriaFromSessionStorage = function (filters) {
            UserPreferencesService.savePageFilterValues(filters, "instance");
        };

        service.getTotalKeywords = function (): Promise<TotalKeywords> {
            return TemplateInstanceRESTService.getTotalKeywords();
        };

        service.checkUncheckAllEquipmentAssociationsToApply = function (selected, equipmentAssociations) {
            equipmentAssociations.forEach(function (equipmentAssociation) {
                equipmentAssociation.apply = selected;
            });
        };

        service.isAllEquipmentAssociationChecked = function (equipmentAssociations) {
            var allChecked = true;
            equipmentAssociations.forEach(function (equipmentAssociation) {
                if (!equipmentAssociation.apply) {
                    allChecked = false;
                    return;
                }
            });
            return allChecked;
        };

        service.getDeviceTemplatesAssociationsByVariable = function (deviceTemplateAssociations) {
            return TemplateInstanceRESTService.getDeviceTemplatesAssociationsByVariable(deviceTemplateAssociations);
        };

        service.selectMultiple = function (initialIndex, finalIndex, checkedItems, items) {
            var selectedItems = [];

            for (var i = initialIndex; i <= finalIndex; i++) {
                selectedItems.push(items[i]);
            }

            var areAllItemsSelected = _.every(selectedItems, function (item) {
                return _.indexOf(checkedItems, item) != -1;
            });

            if (areAllItemsSelected) {
                _.forEach(selectedItems, function (item) {
                    var index = _.indexOf(checkedItems, item);
                    checkedItems.splice(index, 1);
                });
            } else {
                _.forEach(selectedItems, function (item) {
                    var isDeviceAlreadySelected = _.indexOf(checkedItems, item);
                    if (_.isEqual(isDeviceAlreadySelected, -1)) {
                        checkedItems.push(item);
                    }
                });
            }

            return checkedItems;
        };

        service.validateDeviceForTemplate = function (resourceId, templateId) {
            var deviceModelTemplateAssociation: DeviceModelTemplateAssociation = {
                deviceIdentifier: {
                    resourceId: resourceId,
                    type: IdentifierType.DEVICE_IDENTIFIER
                },
                templateId: templateId
            };

            return TemplateInstanceValidatorService.validateTemplateInstancePermission().then(
                function () {
                    return TemplateInstanceRESTService.validateDeviceForTemplate(deviceModelTemplateAssociation);
                },
                function () {
                    $rootScope.showDialog({ translateKey: "templateinstancelisting.userHasNoPermission" });

                    return $q.reject();
                }
            );
        };

        service.extractTemplateIds = function (templateInstance: TemplateInstance) {
            return _.chain(templateInstance.equipmentAssociations)
                .map(function (equipmentAssociation) {
                    return equipmentAssociation.templateAssociations;
                })
                .flatten()
                .map(function (templateAssociation) {
                    return templateAssociation.templateId;
                })
                .uniq()
                .value();
        };

        service.getEquipmentsByRestrictions = function(
                templateType: string, equipmentSearchFilter: EquipmentSearchFilter): Promise<EquipmentFilterResponse> {

            if (templateType === TEMPLATE_TYPE.CLI.name) {
                return TemplateInstanceRESTService.getDevicesByRestrictions(equipmentSearchFilter);
            }
            return TemplateInstanceRESTService.getCpesByRestrictions(equipmentSearchFilter);
        }

        service.getTemplateInstanceResults = function(templateInstanceId: string, executionOrigin: ExecutionOrigin):
                Promise<{[key: string]: {[key:string]: TemplateApplicationStatus}}> {
            return TemplateInstanceRESTService.getTemplateInstanceResults(templateInstanceId, executionOrigin);
        }

        return service;
    }
]);
