import { Inject, Injectable } from '@angular/core';
import {
    TranslatableSet,
    Translatable,
    TranslatableGroup,
    TranslationKey,
    TranslatedText,
    TranslationMap,
    TranslationEnum,
    EnumIdentifier
} from "../../models/translation.models";
import { ANGULARJS_TRANSLATE } from "../upgraded-provider/upgraded-providers";

/**
 * Serviço responsável por prover métodos auxiliares para lidar com tradução
 */
@Injectable({
    providedIn: "root"
})
export class TranslationHelperService {

    private readonly LOCALE_EN_I18N = "en";
    private readonly LOCALE_PT_BR_I18N = "pt-br";

    constructor(@Inject(ANGULARJS_TRANSLATE) private readonly _translate: any) {}

    translate(key: TranslationKey): TranslatedText {
        return this._translate.instant(key);
    }

    /**
     * Traduz uma mensagem unindo prefixo e chave informados.
     *
     * @param prefix Prefixo utilizada nas chaves de tradução.
     * @param key    Chave que será ao prefixo.
     *
     * @returns mensagem traduzida.
     */
    translateByPrefix(prefix: string, key: string): string {
        return this.translate(prefix + key);
    }

    translateWithReplacement(key: TranslationKey, ...replacements: string[]): TranslatedText {
        let translatedMessage = this.translate(key);

        for (let index = 0; index < replacements.length; index++) {
            translatedMessage = translatedMessage.replace(`{${index}}`, replacements[index])
        }

        return translatedMessage;
    }

    translateFromMap(map: TranslationMap, key: string): TranslatedText {
        return this.translateFromObject(map, key);
    }

    translateFromEnum<T extends EnumIdentifier>(enumObj: TranslationEnum<T>, enumValue: T): TranslatedText {
        return this.translateFromObject(enumObj, enumValue as string);
    }

    /**
     * Verifica em @see Translatable a existência do atributo translationKey, caso exista, faz a tradução.
     */
    translateObject(object: Translatable, overwrite = false) {
        if (object.translationKey && (!object.translate || overwrite)) {
            object.translate = this.translate(object.translationKey);
        }
    }

    /**
     * Percorre um objeto onde cada chave tem um @see Translatable como valor.
     * Tenta traduzir essa objeto
     */
    translateObjectGroup<T extends Translatable>(objectGroup: TranslatableGroup<T>, overwrite = false) {
        this.translateMultipleObjects(objectGroup, overwrite);
    }

    /**
     * Percorre um array de @see Translatable e tenta traduzir cada item
     */
    translateCollection<T extends Translatable>(collection: T[], overwrite = false) {
        this.translateMultipleObjects(collection, overwrite);
    }

    formatDateDescription(date: Date, options: Intl.DateTimeFormatOptions, normalizeTimezone?: boolean): string {
        if (date) {
            if (normalizeTimezone) {
                /** Necessário para tratar o fuso horário para garantir que estará de acordo com a localidade */
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            }

            return new Intl.DateTimeFormat(this.getLocale(), options).format(date);
        }

        return "";
    }

    /**
     * Recebe um objeto e uma chave e tenta traduzir essa chave desse objeto
     */
    private translateFromObject(object: any, objectKey: string): TranslatedText {
        let translation = objectKey;

        if (object[objectKey]) {
            translation = this._translate.instant(object[objectKey]);
        }

        return translation;
    }

    /**
     * Percorre um array ou um objeto com @see Translatable e tenta traduzir cada item
     */
    private translateMultipleObjects<T extends Translatable>(collection: TranslatableSet<T>, overwrite = false) {
        _.forEach(collection, (object: T) => {
            this.translateObject(object, overwrite);
        });
    }

    private getLocale(): string {
        if (this.isPtBr(this._translate.use())) {
            return this.LOCALE_PT_BR_I18N;
        }

        return this.LOCALE_EN_I18N;
    }

    private isPtBr(locale: string): boolean {
        return /pt[-_]br/i.test(locale.toLowerCase());
    }
}
