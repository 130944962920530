import { AfterViewInit, Component, Inject } from "@angular/core";
import {
    NmsWizardStep,
    NmsWizardStepComponent,
    NmsWizardStepValidationResponse
} from "@nms-angular-toolkit/nms-wizard";
import { Device } from "../../../device-backup.models";
import { EquipmentSelectionType } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import {
    MatchingRulesTranslationKeys,
    RuleCriteriasSet,
    RuleFieldsSet,
    RuleType,
    RuleTypesSet
} from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { forkJoin } from "rxjs";
import {
    DevicesSelectionValidatorService
} from "@nms-ng2/app/modules/device/device-backup/create-backup-modal/tabs/backup-devices-tab/devices-selection-validator.service";
import { ANGULARJS_TRANSLATE,
    CONFIG_REST_SERVICE,
    MONITORING_SERVICE,
    NG_DIALOG,
    TEMPLATE_TYPE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { DevicesFilter } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { SchedulerListService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.service";
import { BackupDevicesFilter } from "./backup-devices-tab.model";
import { CreateBackupService } from "../../create-backup.service";
import { MatchingRulesUtilsService } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules-utils.service";
import { InventoryService } from "@nms-ng2/app/shared/services/inventory/inventory.service";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { DeviceBackupService } from "../../../device-backup.service";

/**
 * Aba de seleção de equipamentos para criação de backup.
 */
@Component({
    selector: "backup-devices-tab",
    templateUrl: "./backup-devices-tab.component.html",
    styleUrls: ["./backup-devices-tab.component.scss"]
})
export class BackupDevicesTabComponent extends NmsWizardStepComponent implements AfterViewInit {
    model: BackupDevicesFilter = new BackupDevicesFilter();
    selectedDevices: Device[] = [];
    equipmentSelectionTypeEnum = EquipmentSelectionType;
    rulesId: string = "backupDeviceTabId";
    ruleCriterias: RuleCriteriasSet;
    ruleTypes: RuleTypesSet;
    rulesAvailableFields: any;
    availableFields: RuleFieldsSet;
    ruleTranslation: MatchingRulesTranslationKeys;
    ruleTypesFilter: any;
    loadMatchingRules: boolean = false;

    constructor(@Inject(NG_DIALOG) private ngDialog: any,
                @Inject(CONFIG_REST_SERVICE) private configRestService: any,
                @Inject(MONITORING_SERVICE) private monitoringService: any,
                @Inject(TEMPLATE_TYPE) private readonly templateType: any,
                @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
                private createBackupService: CreateBackupService,
                private devicesSelectionValidatorService: DevicesSelectionValidatorService,
                private schedulerListService: SchedulerListService,
                private inventoryService: InventoryService,
                private matchingRulesUtils: MatchingRulesUtilsService,
                private nmsDialogService: NmsDialogService,
                private dcbService: DeviceBackupService) {
        super();
        this.model.devicesFilter = {} as DevicesFilter;
        this.model.devicesFilter.equipmentSelectionType = this.equipmentSelectionTypeEnum.SPECIFIC;
    }

    ngAfterViewInit() {
        this.ruleTranslation = this.initRuleTranslation();
        this.model.matchingRules = this.model.matchingRules || this.matchingRulesUtils.initMatchingRules();
        this.handleLoadContent();
    }

    async setContext(context: BackupDevicesFilter): Promise<void> {
        this.isLoading.next(true);
        this.model = Object.assign(this.model, context);
        if (this.model.devicesFilter.equipmentSelectionType === EquipmentSelectionType.SPECIFIC) {
            const devicesResourceId = this.model.devicesFilter.deviceFilterRules.map(rule => parseInt(rule.values[0]));

            const res = await this.inventoryService.getEquipmentInfoListByIds(devicesResourceId).toPromise();
            this.selectedDevices = res as Device[];
            this.isLoading.next(false);
        }

        this.setDeviceFilterRules();
    }

    async loadContent(): Promise<void> {
        return await new Promise(async () => {
            const ruleValues = await this.initMatchingRulesDirective()

            this.ruleCriterias = ruleValues.ruleCriterias as RuleCriteriasSet;
            this.ruleTypes = ruleValues.ruleTypes as RuleTypesSet;
            this.rulesAvailableFields = ruleValues.rulesAvailableFields;
            this.ruleTypesFilter = this.createRuleTypesFilter(this.ruleTypes);
            this.availableFields = this.initAvailableFields(this.rulesAvailableFields);
            this.isLoading.next(false);
            this.loadMatchingRules = true;
        });
    }

    allowPrevious(): NmsWizardStepValidationResponse {
        return NmsWizardStepValidationResponse.getDefaultValidationResponse();
    }

    allowNext(): NmsWizardStepValidationResponse {
        this.setDeviceFilterRules();
        return this.validateDevicesSelection();
    }

    allowFinish(): NmsWizardStepValidationResponse {
        return this.validateDevicesSelection();
    }

    finishAction(tabs: Array<NmsWizardStep>): void {
        this.setDeviceFilterRules();
        const schedulerJob = this.createBackupService.buildDeviceBackupScheduler(tabs);
        this.schedulerListService.save(schedulerJob);
    }

    removeDevice(device: Device) {
        const index = this.selectedDevices.indexOf(device);

        if (index !== -1) {
            this.selectedDevices.splice(index, 1);
        };
    }

    validateDcbServerBeforeOpenDevicesModal() {
        this.dcbService.executeIfDcbServiceOnline(() => {
            this.openDevicesModal();
        });
    }

    private initMatchingRulesDirective() {
        return forkJoin(
            {
                ruleCriterias: this.configRestService.rulesMatchingModes(),
                ruleTypes: this.configRestService.deviceModelRestrictionTypes(),
                rulesAvailableFields: this.configRestService.getRuleAvailableFields(this.templateType.CLI.name)
            }
        ).toPromise();
    }

    private setDeviceFilterRules() {
        const devicesFilter = this.model.devicesFilter;
        devicesFilter.equipmentSelectionType = this.equipmentSelectionTypeEnum[devicesFilter.equipmentSelectionType];
        devicesFilter.rulesCriteria = this.model.matchingRules.criteria;
        devicesFilter.deviceFilterRules = this.devicesSelectionValidatorService.getDevicesFilterRules(
            devicesFilter.equipmentSelectionType, this.selectedDevices, this.model.matchingRules);
    }

    private validateDevicesSelection(): NmsWizardStepValidationResponse {
        try {
            this.dcbService.getStatus();
            if (!this.areDevicesSelected()) {
                const message = this.model.devicesFilter.equipmentSelectionType === EquipmentSelectionType.FILTER
                    ? this.translate.instant("backup.devices.modal.create.devices.selectionType.filter.emptyFilterValue")
                    : this.translate.instant("backup.devices.modal.create.devices.noDevices");
                return new NmsWizardStepValidationResponse(false, message);
            }
            return new NmsWizardStepValidationResponse(true);
        } catch(error) {
            return new NmsWizardStepValidationResponse(false);
        }
    }

    private areDevicesSelected() {
        return this.devicesSelectionValidatorService.validateDevicesSelection(this.model.devicesFilter.equipmentSelectionType,
            this.selectedDevices, this.model.matchingRules);
    }

    openDevicesModal() {
        this.ngDialog
            .openConfirm({
                template: "templates/features/template/template-instance/modals/add-device-modal.html",
                controller: "AddDeviceModalGenericCtrl",
                className: "extra-large-modal",
                closeByNavigation: true,
                resolve: {
                    devicesAlreadyAdded: () => this.selectedDevices,
                    deviceModelRestrictionTypes: () =>
                        this.configRestService.deviceModelRestrictionTypes(),
                    monitoringSettings: () => this.monitoringService.getMonitoringSettings()
                }
            })
            .then((selectedDevices: Device[]) => {
                this.selectedDevices.push(...selectedDevices);
            });
    }

    private initAvailableFields(rulesAvailableFields: any): RuleFieldsSet {
        const availableFields = {};

        Object.keys(rulesAvailableFields).forEach((key, index) => {
            availableFields[key] = {
                translate: rulesAvailableFields[key]
            };

            if (index === 0) {
                availableFields[key].initial = true;
            }
        });

        return availableFields;
    }

    private initRuleTranslation(): MatchingRulesTranslationKeys {
        return {
            isBlocked: "",
            matchingRules: "templateForm.basic.device.model.restriction",
            criteriaBelow: "templateForm.basic.device.model.restriction.criteria.below",
            atLeastOneRule: "popups.alert.atLeastOneRestriction"
        };
    }

    private createRuleTypesFilter(ruleTypes: RuleTypesSet) {

        const mapOmitField = {
            FIRMWARE: [RuleType.AFTER, RuleType.BEFORE, RuleType.IS_DMOS, RuleType.NOT_DMOS],
            IP_HOSTNAME: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN,
                RuleType.GREATER_THAN, RuleType.GREATER_THAN_OR_EQUAL, RuleType.NOT_BETWEEN,
                RuleType.LESS_THAN, RuleType.LESS_THAN_OR_EQUAL
            ],
            MODEL: [
                RuleType.AFTER, RuleType.BEFORE, RuleType.BEFORE,
                RuleType.BETWEEN, RuleType.NOT_BETWEEN, RuleType.GREATER_THAN_OR_EQUAL,
                RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ],
            NAME: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN_OR_EQUAL, RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ],
            SERIAL_NUMBER: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN_OR_EQUAL, RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ],
            STATUS: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE,
                RuleType.BETWEEN, RuleType.NOT_BETWEEN, RuleType.GREATER_THAN_OR_EQUAL,
                RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ]
        };

        return function(field) {
            if (Object.keys(mapOmitField).includes(field)) {
                return _.omit(ruleTypes, mapOmitField[field]);
            }

            return ruleTypes;
        }
    }

}
