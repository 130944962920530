import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";

var app = angular.module("nms.dynamicDevice");

app.controller("DynamicDeviceController", [
    "$rootScope",
    "$scope",
    "$state",
    "NesDataCacheService",
    "DomainHandlerService",
    "NesRestService",
    "ContentManager",
    "LoadingPaneService",
    "$log",
    "NesEditConfigService",
    "DevicePermissionService",
    "$window",
    "NMS_STATES",
    "DropdownDeviceService",
    "$translate",
    "NodeInfoCacheManager",
    "NodeInfoCache",
    "UserPreferencesService",
    "$stateParams",
    "ListService",
    "SessionPreferencesService",
    "DevicePollingService",
    "monitoringSettings",
    "CPU_USAGE",
    "MEMORY_USAGE",
    "AutoUpdaterService",
    "TreeFilterService",
    "TreeSelectionManager",
    "hasConfigPermission",
    "EquipmentsModalsActionsService",
    "NmsToastrService",
    "TranslationHelperService",
    function (
        $rootScope,
        $scope,
        $state,
        NesDataCacheService,
        DomainHandlerService,
        NesRestService,
        ContentManager,
        LoadingPaneService,
        $log,
        NesEditConfigService,
        DevicePermissionService,
        $window,
        NMS_STATES,
        DropdownDeviceService,
        $translate,
        NodeInfoCacheManager,
        NodeInfoCache,
        UserPreferencesService,
        $stateParams,
        ListService,
        SessionPreferencesService,
        DevicePollingService,
        monitoringSettings,
        CPU_USAGE,
        MEMORY_USAGE,
        AutoUpdaterService,
        TreeFilterService,
        TreeSelectionManager,
        hasConfigPermission,
        EquipmentsModalsActionsService,
        nmsToastrService: NmsToastrService,
        translationHelperService: TranslationHelperService,
    ) {
        $log.log("DeviceController: (" + Date.now() + ")");
        var CONTENT_MODIFIED_CANCEL_MODAL_NAME = "contentModifiedCancelModalName";
        const globalConfig = EquipmentsModalsActionsService.getDefaultGlobalConfig();
        var windowIdentifier = "configNodeForID=";
        var persistentProperties = ["path", "pathKeys"];

        $scope.dropdownTitle = $translate.instant($state.current.data.pageTitle);
        $scope.device = $stateParams.device;
        $scope.content = { modified: false };
        $scope.filters = SessionPreferencesService.getFilters();
        $scope.shortcuts = DropdownDeviceService.getShortcutToConfig(globalConfig);
        $scope.zabbixUrl = monitoringSettings.url;
        $scope.CPU_USAGE = CPU_USAGE;
        $scope.MEMORY_USAGE = MEMORY_USAGE;
        $scope.infoTooltip = $translate.instant("title.informationAccordionTooltip");
        $scope.configTooltip = $translate.instant("title.configurationAccordionTooltip");

        $scope.deviceTreeTextFinderConfig = {
            id: "tree-text-finder-input",
            filterContent: function (event, isFirstPolling) {
                var isPolling = event.name === "deviceLoaded" && !isFirstPolling;
                TreeFilterService.filter($scope.deviceTreeTextFinderConfig.searchTerm, isPolling);
            },
            searchableElementSelector: ".searchable-element",
            enabled: false,
            performSearchOnEvents: ["deviceLoaded"],
            clearSearch: function (event, isFirstPolling) {
                if (_.get(event, "name") !== "deviceLoaded" || isFirstPolling) {
                    TreeFilterService.clearSearch();
                }
            },
            baseElementSelector: ".tree",
            minTermSize: 3,
            placeholder: $translate.instant("tree.search.placeholder"),
            hotkey: {
                combo: "ctrl+shift+f",
                description: $translate.instant("tree.search.placeholder"),
                callback: function () {
                    angular.element("#" + $scope.deviceTreeTextFinderConfig.id).focus();
                }
            }
        };

        var closeLoadingPane = function () {
            LoadingPaneService.closeLoadingPane();
        };

        var onActivationCallback = function () {
            LoadingPaneService.updateLoadingPane("nes.services.config.retrieving");
        };

        var initStructure = function () {
            $scope.data = NesDataCacheService.getCurrentData();
            $scope.schema = NesDataCacheService.getSchema();
            NodeInfoCacheManager.createNodeInfoCache($scope.schema);

            /* FIXME: Devido a natureza assincrona da busca em cima de um webworker alguns problemas surgiram:
             *
             * - Quando um elemento de uma lista é removido via CLI e um polling é solicitado
             *   existe um lag entre a árvore ser atualizada, onde os pais do elemento ficam por algum tempo presentes na árvore
             *
             * - Quando um elemento é adicionado via CLI um comportamento semelhante ocorre, onde a árvore permanece vazia por
             *   um periodo até que o filtro seja realizado
             */
            TreeFilterService.init().then(function () {
                $scope.$broadcast("deviceLoaded", isFirstPolling);
            });
            processPathAndSetContent($scope.schema);
        };

        var permissionAction = function () {
            $scope.showViewOnlyBanner = false;
            $rootScope.readOnly = false;
            $rootScope.hasPermission = true;
            var onDataRetrievedCallback = function () {
                deviceRequestFinishedCallback();
                closeLoadingPane();
            };
            var onSchemaRetrievedCallback = function () {
                NesDataCacheService.reloadData($scope.device.id, onDataRetrievedCallback);
            };

            NesDataCacheService.loadSchema($scope.device.id, onSchemaRetrievedCallback);
            $rootScope.$emit("title:append", $scope.device.name);
            $rootScope.$broadcast("updateDeviceHeaderByDeviceId", $scope.device.id);
        };

        var noPermissionAction = function () {
            $scope.showViewOnlyBanner = false;
            $rootScope.readOnly = true;
            $rootScope.hasPermission = false;
        };

        var readOnlyAction = function () {
            permissionAction();
            $rootScope.readOnly = true;
            $scope.showViewOnlyBanner = true;
        };

        var openDeviceOnPath = function (path) {
            ContentManager.clear();
            $state.params.path = path;
            processPathAndSetContent($scope.schema);
        };

        $scope.init = function () {
            NesDataCacheService.clear();
            NodeInfoCacheManager.clear();
            ContentManager.clear();
            TreeFilterService.clearSearch();

            $scope.shortcuts = DropdownDeviceService.getShortcutToConfig(globalConfig, $scope.device, openDeviceOnPath);
        };

        $scope.tryOpenInfoConfig = function () {
            if (hasConfigPermission) {
                DevicePermissionService.checkPermissions($scope.device, permissionAction, readOnlyAction, noPermissionAction);
            } else {
                readOnlyAction();
            }
        };

        var isFirstPolling = true;
        var deviceRequestFinishedCallback = function () {
            $log.log("Request Finished");
            AutoUpdaterService.getAutoUpdaterModel().setMessage(null);
            initStructure();
            $rootScope.$broadcast("reloadTreeNode");
            $scope.content.modified = false;
            isFirstPolling = false;
        };

        $scope.$on("CONTENT_MODIFIED", function (event, newValue) {
            if (!$rootScope.readOnly) {
                $scope.content.modified |= newValue;
            }
        });

        var processPathAndSetContent = function (deviceContainer) {
            var content = null;

            if ($state.params.path) {
                content = getContent($state.params.path, null, true, true);
            } else if (ContentManager.getNode()) {
                var node = ContentManager.getNode();
                var pathKeys = ContentManager.getPathKeys();

                content = getContent(node.paths.schemaJsonPath, pathKeys, false, false);
            } else if ($state.params.fullPath) {
                var resolvedPathKeys = DomainHandlerService.resolveRequiredPathKeys(
                    $state.params.fullPath,
                    $state.params.pathKeys
                );
                content = getContent($state.params.fullPath, resolvedPathKeys, true, true);
            } else {
                var nodePath = UserPreferencesService.loadPreferences(
                    {},
                    windowIdentifier + $scope.device.id,
                    persistentProperties
                );

                if (!_.isEmpty(nodePath)) {
                    content = getContent(nodePath.path, nodePath.pathKeys, false, true);

                    var sessionKeys = nodePath.keys;
                    var nodeKeys = content ? getNodeKeysValues(content.node, content.keys) : "";

                    content = !sessionKeys || sessionKeys === nodeKeys ? content : null;
                }
            }

            setContent(content, deviceContainer);
        };

        var setContent = function (content, deviceContainer) {
            if (content === null) {
                content = {
                    node: deviceContainer,
                    canonicalPath: "/",
                    path: "$"
                };
            }

            ContentManager.clear();
            clearStateParams();
            ContentManager.setContent(content);
        };

        var clearStateParams = function () {
            $state.params.fullPath = null;
            $state.params.pathKeys = null;
        };

        var isListNodePath = function (schemaJsonPath) {
            var parts = schemaJsonPath.split(".");
            if (parts[parts.length - 3] === "lists") {
                return true;
            }

            return false;
        };

        var getContent = function (schemaJsonPath, pathKeys, setFilters, refreshTree) {
            var content = null;
            var node = DomainHandlerService.getSchemaNode(schemaJsonPath);
            var dataNode = DomainHandlerService.getDataNodeForSchemaNode(node, pathKeys);

            var isList = isListNodePath(schemaJsonPath);

            /*
             * No caso de links externos (links da GPON ONUs, Summary, atalhos de dropdown para nós especificos da Info/Config),
             * uma mensagem de erro é exibida quando um item de lista não existe mais na configuração do device.
             * Nesses casos, o nome do nó na mensagem de erro é definido no atributo displayName e enviado para o $state.params.
             */
            if (isList && dataNode == null) {
                handlePathNotFoundError($state.params.displayName);
                return null;
            }

            var keys = [];
            var canonicalPath = "/";

            if (node.parentPaths.schemaJsonPath) {
                var schemaNode = isList ? DomainHandlerService.getSchemaNode(node.parentPaths.schemaJsonPath) : node;

                canonicalPath = DomainHandlerService.getPath(schemaNode);
                var cachedNode = NodeInfoCache.getNode(canonicalPath);
                keys = ListService.getKeyNames(cachedNode);

                if (setFilters) {
                    $scope.filters.showConfig = $scope.filters.showConfig || cachedNode.isConfiguration;
                    $scope.filters.showStatus = $scope.filters.showStatus || !cachedNode.isConfiguration;
                }
            }

            content = {
                node: node,
                path: schemaJsonPath,
                refreshTree: refreshTree,
                canonicalPath: canonicalPath,
                keys: keys,
                pathKeys: pathKeys
            };

            return content;
        };

        var getNodeKeysValues = function (node, keys) {
            if (node && keys) {
                var nodeKeyValues = ListService.getListKeyValues(node.leaves, keys);
                return _.pluck(nodeKeyValues, "value").join(";");
            }

            return "";
        };

        var handlePathNotFoundError = function (displayName) {
            if (displayName) {
                $rootScope.showDialog({
                    translateKey: "device.pathNotFound",
                    params: [$state.params.displayName],
                    paramsInsideMessage: true
                });
            }
        };

        var hasChangesToDiscard = function () {
            return $scope.content.modified;
        };

        var popupConfirmDiscardChanges = function (actionOnConfirmDiscard) {
            // TODO: [US54236][SETH]Implementar esta função de modo genérico que possa ser chamada de qualquer lugar do sistema

            var isUserLoggedOff = localStorage.getItem("isAuthenticated") !== "true";
            if (!hasChangesToDiscard() || isUserLoggedOff) {
                leavePage(actionOnConfirmDiscard);
            } else {
                $rootScope
                    .showDialog({
                        translateKey: "device.discardChanges",
                        isConfirm: true,
                        ngDialogOptions: {
                            name: CONTENT_MODIFIED_CANCEL_MODAL_NAME
                        }
                    })
                    .then(function () {
                        leavePage(actionOnConfirmDiscard);
                    });
            }
        };

        var leavePage = function (actionOnConfirmDiscard) {
            UserPreferencesService.saveExitValue(windowIdentifier + $scope.device.id, "true");
            TreeSelectionManager.clear();
            setDeviceContextToSessionStorage();
            ContentManager.clear();
            actionOnConfirmDiscard();
        };

        var setDeviceContextToSessionStorage = function () {
            var schemaNode = ContentManager.getNode();

            if (schemaNode) {
                var schemaPath = schemaNode.paths.schemaJsonPath;
                var pathKeys = ContentManager.getPathKeys();
                var properties: any = { path: schemaPath, pathKeys: pathKeys };

                UserPreferencesService.savePreferences(properties, windowIdentifier + $scope.device.id, persistentProperties);
            }
        };

        $scope.requestPolling = function () {
            DevicePollingService.requestPolling($scope.device.id);
        };

        var confirmAndApply = function (response) {
            let translateKey = (!$rootScope.currentFormIsInvalid)
                             ? "device.applyChanges"
                             : "device.applyChangesWithWarning"

            $rootScope
                .showDialog({
                    translateKey: translateKey,
                    isConfirm: true
                })
                .then(function () {
                    LoadingPaneService.showLoadingPane("device.applyingConfiguration");

                    var onSuccessCallback = function () {
                        const message = translationHelperService.translate("nes.services.config.applySuccess");
                        nmsToastrService.success(message);
                    };

                    var onCacheUpdateCallback = deviceRequestFinishedCallback;

                    NesEditConfigService.editConfig(
                        response,
                        onActivationCallback,
                        onCacheUpdateCallback,
                        onSuccessCallback,
                        closeLoadingPane
                    );
                });
        };

        var verifyChangesAndApply = function () {
            var applyConfigurationPromise = NesRestService.createPatchOfOperations(
                NesDataCacheService.getOriginalData(),
                NesDataCacheService.getCurrentData()
            );

            applyConfigurationPromise.then(function (response) {
                if (_.isEmpty(response.operations)) {
                    const message = translationHelperService.translate("device.noChanges");
                    nmsToastrService.info(message);
                } else {
                    confirmAndApply(response);
                }
            });

            applyConfigurationPromise.catch(function () {
                const message = translationHelperService.translate("device.checkForConfigChangesFailed");
                nmsToastrService.error(message);
            });
        };

        var handleFinishedPoll = function (deviceId) {
            // TODO: Reavaliar lógica ao carregar a página.
            // Do jeito que está, são feitas duas chamadas de getDevice para o NES ao abrir a página.
            // Uma ao abrir e outro pelo timer
            var onCacheUpdateAndActivationCallback = function () {
                deviceRequestFinishedCallback();
                closeLoadingPane();
            };

            $rootScope.$broadcast("updateDeviceHeaderByDeviceId", $scope.device.id);

            if ($scope.content.modified) {
                NesDataCacheService.reloadDataIfConfigurationChanged(deviceId, configurationChangedCallback, closeLoadingPane);
            } else {
                NesDataCacheService.reloadData(deviceId, onCacheUpdateAndActivationCallback);
            }
        };

        $scope.submit = function () {
            if ($rootScope.readOnly || !$scope.hasPermission) {
                const message = translationHelperService.translate("device.applyChangesNotPermission");
                nmsToastrService.error(message);
                return;
            }

            verifyChangesAndApply();
        };

        $scope.cancel = function () {
            popupConfirmDiscardChanges(function () {
                $state.go(NMS_STATES.manageDevices);
            });
        };

        var pollingStatusModel: any = {
            nextReloadTextTranslateKey: "device.polling.status.timeRemaining",
            loadingTextTranslateKey: "device.polling.status.loadingDataFromDevice",
            lastUpdateTextTranslateKey: "device.polling.status.lastPollingTime",
            updateButtonTranslateKey: "device.polling.status.loadDataFromDevice",
            updateFinishedCallback: handleFinishedPoll
        };

        AutoUpdaterService.setAutoUpdaterModel(pollingStatusModel);

        $scope.doLogoff = function () {
            popupConfirmDiscardChanges(function () {
                $rootScope.doLogoff();
            });
        };

        $scope.hideBanner = function () {
            $scope.showViewOnlyBanner = false;
        };

        var configurationChangedCallback = function () {
            const message = translationHelperService.translate("device.configHashHasChanged");
            nmsToastrService.error(message);
            deviceRequestFinishedCallback();
        };

        $scope.$watch("content.modified", function (newValue) {
            var modified = !!newValue;
            var updateButtonEnabled = !modified;
            var message = newValue ? $translate.instant("yang.config.change.readonly.not.updatable") : null;

            if (modified) {
                AutoUpdaterService.stopUpdating();
            }

            AutoUpdaterService.getAutoUpdaterModel().setUpdateButtonEnabled(updateButtonEnabled);
            AutoUpdaterService.getAutoUpdaterModel().setMessage(message);
        });

        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (UserPreferencesService.loadExitValue(windowIdentifier + $scope.device.id) !== "true") {
                event.preventDefault();
                popupConfirmDiscardChanges(function () {
                    $state.go(toState, toParams);
                });
            }
            UserPreferencesService.saveExitValue(windowIdentifier + $scope.device.id, "false");
        });

        $scope.onConfigInfoFilterChange = function () {
            $rootScope.$broadcast("reloadContent");
        };

        $scope.toggleFilters = function () {
            $scope.filters.showStatus = !$scope.filters.showStatus;
            $scope.filters.showConfig = !$scope.filters.showConfig;
        };

        $scope.$watch("device", function () {
            $scope.tryOpenInfoConfig();
        });

        $scope.$watch(
            function () {
                return localStorage.getItem("username");
            },
            function (newValue, oldValue) {
                if (newValue != null && newValue !== oldValue) {
                    $rootScope
                        .showDialog({
                            translateKey: "device.changesLost"
                        })
                        .closePromise.then(function () {
                            $state.current.ignoreConfirmation = true;
                            $window.location.reload();
                        });
                }
            }
        );

        var unregisterUpdateWarningSettings = $rootScope.$watch("currentFormIsInvalid", function () {
            $scope.warningSettings = {
                show: $rootScope.currentFormIsInvalid,
                header: "nes.services.config.invalidValues",
                warnings: []
            }
        });

        $rootScope.$on("$destroy", function() {
            unregisterUpdateWarningSettings();
        });
    }
]);
