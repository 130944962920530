import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { VariablesService } from "../../../services/variables.service";
import {
    SnmpTrap,
    SnmpTrapInformation,
    SnmpTrapTriggerData,
    Trigger,
    TriggerType
} from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { TemplateInstance } from "../../template-instance-models";
import { TemplateInstanceVariable } from "../variables.models";
import { JobType, SchedulerJob } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";
import { AutocompleteOption } from "@nms-angular-toolkit/nms-autocomplete";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Componente responsável por mostrar o campo com as opções correlacionadas
 * de eventos e informações de Trap SNMP para carregamento de valores de
 * variáveis em aplicação de template.
 */
@Component({
    selector: "snmp-trap-object-selection",
    templateUrl: "./snmp-trap-object-selection.component.html",
    styleUrls: ["./snmp-trap-object-selection.component.scss"]
})
export class SnmpTrapObjectSelectionComponent implements OnInit {

    @Input() variable: TemplateInstanceVariable;
    @Input() templateInstance: TemplateInstance;
    @Input() schedulerJob: SchedulerJob;
    @Input() snmpTrapsSelected: {[key: string]:string} = {};
    @Output() onCheckCallback: EventEmitter<any> = new EventEmitter();

    autocompleteOptionList: Array<AutocompleteOption>;
    autocompleteOption: AutocompleteOption;

    variableName: string = "";
    valuesSelectedByUser = [];
    autoCompleteReference: string;
    private readonly snpmTrapOptions = [
        {
            label: SnmpTrap.ONU_DISCOVERED,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.PON_PORT,
                SnmpTrapInformation.ONU_SERIAL_NUMBER
            ]
        },
        {
            label: SnmpTrap.ONU_DOWN_UP,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.PON_PORT,
                SnmpTrapInformation.ONU_ID
            ]
        },
        {
            label: SnmpTrap.ONU_DYING_GASP,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.PON_PORT,
                SnmpTrapInformation.ONU_ID
            ]
        },
        {
            label: SnmpTrap.PON_LINK_DOWN_UP,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.PON_PORT
            ]
        },
        {
            label: SnmpTrap.ETHERNET_LINK_DOWN,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.PORT_NO
            ]
        },
        {
            label: SnmpTrap.ETHERNET_LINK_UP,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.PORT_NO
            ]
        },
        {
            label: SnmpTrap.DMOS_DYING_GASP,
            options: [
                SnmpTrapInformation.DEVICE_IP
            ]
        },
        {
            label: SnmpTrap.APPLY_CONFIG,
            options: [
                SnmpTrapInformation.DEVICE_IP,
                SnmpTrapInformation.USER
            ]
        }
    ];

    constructor(private variablesService: VariablesService,
        private triggersService: TriggersService,
        @Inject(ANGULARJS_TRANSLATE) private translate
    ) { }

    ngOnInit(): void {
        this.variableName = this.variable.variable.name;
        this.autoCompleteReference = `autocomplete_${this.variableName}`;
        this.valuesSelectedByUser = this.variable.value;

        if (this.schedulerJob) {
            this.selectAutoCompleteOptionIfNeeded();
        } else {
            this.initSchedulerJob();
        }

        this.variable.loadSnmpTrap = this.variable.loadSnmpTrap === undefined ? true : this.variable.loadSnmpTrap;
        this.autocompleteOptionList = this.buildAutoCompleteListOptions();
    }

    onUseTrapValueChange(): void {
        if (!this.variable.loadSnmpTrap) {
            this.handleVariableSnmpLoadUnchecked();
        } else {
            this.handleVariableSnmpLoadChecked();
        }

    }

    onTrapAssociatedChange = (value: AutocompleteOption | null) => {
        this.autocompleteOption = value;
        if (this.autocompleteOption) {
            const [snmpTrap, trapInformation] = this.autocompleteOption.id.split(":");
            this.snmpTrapsSelected[this.variableName] = snmpTrap;

            this.templateInstance.snmpTrapVariableAssociations[this.variable.variable.name] = SnmpTrapInformation[trapInformation];
            this.schedulerJob.type = JobType.TEMPLATE_APPLICATION;
            this.schedulerJob.triggers.forEach(trigger => {
                if (this.triggersService.isSnmpTrapTrigger(trigger.triggerType)) {
                    (trigger.triggerData as SnmpTrapTriggerData).snmpTrap = SnmpTrap[snmpTrap];
                }
            })
        } else {
            delete this.snmpTrapsSelected[this.variableName];
            delete this.templateInstance?.snmpTrapVariableAssociations[this.variableName];
        }
    }

    private buildAutoCompleteListOptions(): Array<AutocompleteOption> {
        return this.snpmTrapOptions.map(option => {
            return option.options.map<AutocompleteOption>(info => {
                return {
                    id: `${option.label}:${info}`,
                    label: this.translate.instant(`scheduler.trap.trigger.${option.label}`),
                    detailOption: this.translateSnmpInformation(info),
                    detailSeparator: " - ",
                    isBoldDetail: true
                }
            })
        }).reduce((autoCompleteOptionsList, autoCompleteOption) => {
            return autoCompleteOptionsList.concat(autoCompleteOption)
        }, []);
    }

    private initSchedulerJob(): void {
        const initialTrigger: Trigger = {
            triggerEnabled: true,
            triggerType: TriggerType.TRAP,
            triggerData: {}
        }
        this.schedulerJob = {
            triggers: [initialTrigger]
        } as SchedulerJob;
    }

    private selectAutoCompleteOptionIfNeeded() {
        const snmpTrapInformation = this.templateInstance?.snmpTrapVariableAssociations[this.variable.variable.name];
        const trigger = this.schedulerJob.triggers.find((trigger) => {
            return this.triggersService.isSnmpTrapTrigger(trigger.triggerType)
        });
        const snpmTriggerData = trigger.triggerData as SnmpTrapTriggerData;
        const snmpTrap = snpmTriggerData.snmpTrap;

        if (snmpTrap && snmpTrapInformation) {
            this.autocompleteOption = {
                id: `${snmpTrap}:${snmpTrapInformation}`,
                label: this.translate.instant(`scheduler.trap.trigger.${snmpTrap}`),
                detailOption: this.translateSnmpInformation(snmpTrapInformation),
                detailSeparator: " - "
            }
        }
    }

    private handleVariableSnmpLoadUnchecked(): void {
        if (this.templateInstance?.snmpTrapVariableAssociations[this.variableName]) {
            delete this.snmpTrapsSelected[this.variableName];
            delete this.templateInstance?.snmpTrapVariableAssociations[this.variableName];
        }

        if (this.variablesService.isListType(this.variable)) {
            this.variable.value = this.valuesSelectedByUser;
        }

        if (this.variablesService.variableHasRestrictionsOptions(this.variable.variable)) {
            this.variablesService.extractDeviceOptions();
        }
    }

    private handleVariableSnmpLoadChecked(): void {
        if (this.variablesService.isListType(this.variable)) {
            this.valuesSelectedByUser = Object.assign([], this.variable.value);
            this.variable.value.splice(1, this.variable.value.length);
        }

        const [snmpTrap, trapInformation] = this.autocompleteOption.id.split(":");
        this.onCheckCallback.emit();
        this.templateInstance.snmpTrapVariableAssociations[this.variable.variable.name] = SnmpTrapInformation[trapInformation];
    }

    private translateSnmpInformation(snmpTrapInformation: SnmpTrapInformation): string {
        const suffix = snmpTrapInformation.toLocaleLowerCase().replace(new RegExp("_", "g"), ".");
        return this.translate.instant(`templateinstanceform.snmp.variable.option.${suffix}`);
    }
}
