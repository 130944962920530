import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    CpeErrorResponseHandlerService,
} from "@nms-ng2/app/modules/device/cpe-error-response-handler.service";
import { NmsToastrLinkService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-link.service";
import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import {
    ErrorDescriptionDetails,
    ErrorResponse
} from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { NmsToasterLink } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-model";


export interface RebootOperationResponse {
    rebootStatus: string;
    serialNumber: string;
    errorResponse: ErrorResponse;
}

/**
 * Classe responsável por exibir as notificações de reboot.
 */
@Injectable({
    providedIn: "root",
})
export class RebootService {

    rebootUrl: string;

    constructor(private http: HttpClient,
                private errorResponseHandlerService: CpeErrorResponseHandlerService,
                private nmsToastrLinkService: NmsToastrLinkService,
                private toastr: NmsToastrService,
                @Inject(ANGULARJS_TRANSLATE) private translate: any,
                @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any) {
        this.rebootUrl = "/acs-client/api/reboot";
        this.showSuccessRebootNotifications = this.showSuccessRebootNotifications.bind(this);
        this.showErrorRebootNotifications = this.showErrorRebootNotifications.bind(this);
    }

    public sendRebootRequest(serialNumbers: string[]): void {
        this.showMessageToSendRequest(serialNumbers);
        this.http.post<RebootOperationResponse[]>(this.rebootUrl, serialNumbers)
            .subscribe(
                (rebootOperationResponses) => {
                    this.showRebootResponsesNotification(rebootOperationResponses);
                }
            );
    }

    private showMessageToSendRequest(serialNumbers: string[]) {
        const messageRebootToSend = (serialNumbers.length == 1)
                                  ? this.translate.instant("reboot.request.one.cpe")
                                  : this.translate.instant("reboot.request.multiples.cpes")
        this.toastr.info(messageRebootToSend);
    }

    private showRebootResponsesNotification(rebootResponses: RebootOperationResponse[]): void {
        let responseStatusGroups = _.groupBy(rebootResponses, "rebootStatus");

        this.showRebootNotifications(responseStatusGroups.SUCCESS,
            "reboot.multiple.cpes.success.message",
            "reboot.one.cpe.success.message",
            this.showSuccessRebootNotifications);
        this.showRebootNotifications(responseStatusGroups.FAILED,
            "reboot.multiple.cpes.failed.message",
            "reboot.one.cpe.failed.message",
            this.showErrorRebootNotifications);
    }

    private showRebootNotifications(responses: RebootOperationResponse[],
                                    multipleCpesMessageKey: string,
                                    singleCpeMessageKey: string,
                                    showNotification: Function): void {
        if (responses && responses.length > 0) {
            showNotification(responses, multipleCpesMessageKey, singleCpeMessageKey);
        }
    }

    private showSuccessRebootNotifications(rebootResponses: RebootOperationResponse[],
                                           multipleCpesMessageKey: string,
                                           singleCpeMessageKey: string): void {
        if (rebootResponses.length > 1) {
            let serialNumbers = rebootResponses.map(response => response.serialNumber);
            let message: string = this.translate.instant(multipleCpesMessageKey).replace("{0}", this.concatDetailsMessage(serialNumbers));
            this.nmsToastrLinkService.showToastSuccess(message, this.createSuccessDetailsLink(rebootResponses));
        } else {
            let message: string = this.translate.instant(singleCpeMessageKey).replace("{0}", rebootResponses[0].serialNumber);
            this.nmsToastrLinkService.showToastSuccess(message, []);
        }
    }

    private createSuccessDetailsLink(rebootResponses: RebootOperationResponse[]): NmsToasterLink[] {
        let message: string = this.translate.instant("reboot.multiple.cpes.success.message")
            .replace("{0}", this.concatDetailsMessage(rebootResponses.map(response => response.serialNumber)));

        var link = {
            id: rebootResponses.map((response) => response.serialNumber).join("-"),
            title: this.translate.instant("toastr.details.link"),
            action: () =>
                this.$rootScope.showDialog({ message, insertScrollOnDetailsMessage: true, type: "information", alignLeft: false })
        };

        return [link];
    }

    private showErrorRebootNotifications(rebootResponses: RebootOperationResponse[],
                                         multipleCpesMessageKey: string,
                                         singleCpeMessageKey: string): void {
        let message: string;

        const errorMessages: { serialNumber: string, errorMessage: ErrorDescriptionDetails }[] = rebootResponses
            .map(response => {
                let separator = rebootResponses.length > 1 ? "<br>&emsp;&emsp;" : "<br>";
                return {
                    serialNumber: response.serialNumber,
                    errorMessage: this.errorResponseHandlerService
                        .buildErrorDescriptionDetails(response.errorResponse, separator)
                };
            });

        if (errorMessages.length > 1) {
            let serialNumbers = ` ${rebootResponses.map(reboot => reboot.serialNumber).join(", ")}`;
            message = this.translate.instant(multipleCpesMessageKey).replace("{0}", serialNumbers);
        } else {
            let mainMessage = this.translate.instant(singleCpeMessageKey).replace("{0}", rebootResponses[0].serialNumber);
            let failTypeMask = this.translate.instant("cwmp.parameters.request.failType");
            let failTypeMessage = failTypeMask.replace("{0}", errorMessages[0].errorMessage.description);

            message = `${mainMessage}<br>${failTypeMessage}`;
        }

        this.nmsToastrLinkService.showToastError(message, this.createErrorDetailsLink(errorMessages));
    }

    private createErrorDetailsLink(errorMessages: {
                                       serialNumber: string,
                                       errorMessage: ErrorDescriptionDetails
                                   }[]): NmsToasterLink[] {
        let message: string;
        let alignLeft: boolean = false;

        if (errorMessages.length > 1) {
            alignLeft = true;
            message = errorMessages.map(message => {
                let errorDetails: string = `&emsp;${message.serialNumber}: ${message.errorMessage.description}`;

                if (message.errorMessage.details) {
                    errorDetails += `<br>&emsp;&emsp;${message.errorMessage.details}`;
                }

                return errorDetails;
            }).join("<br><br>");
        } else {
            message = errorMessages[0].errorMessage.details;
        }

        if (!_.isEmpty(message)) {
            let linkUniqueId = errorMessages.map(response => response.serialNumber).join("-");
            return [this.createToastLink(linkUniqueId, message, "error", alignLeft)];
        }

        return [];
    }

    private createToastLink(linkId: string, message: string, type: string, alignLeft: boolean): NmsToasterLink {
        return {
            id: linkId,
            title: this.translate.instant("toastr.details.link"),
            action: () => this.$rootScope.showDialog({ message, insertScrollOnDetailsMessage: true, type, alignLeft })
        };
    }

    private concatDetailsMessage(downloadMessages: string[]): string {
        const lastMessage = downloadMessages.pop();

        return `${downloadMessages.join(", ")} ${this.translate.instant("general.and")} ${lastMessage}`;
    }
}
