import { Injectable } from "@angular/core";
import { CommandsVariablesService } from "../commands/commands-variables.service";
import {
    TemplateInstanceGlobalVariablesDetails,
    TemplateInstanceLocalVariablesDetails,
    TemplateInstanceVariable
} from "../../../template-instance/variables/variables.models";

/**
 * Serviço responsável por tratamentos relacionados as variáveis de auto-seleção.
 */
@Injectable({
    providedIn: "root"
})
export class AutoSelectionVariableService {

    constructor(private commandsVariablesService: CommandsVariablesService) { }

    getAutoSelectionVarsWithTrapValueDependency(globalVarsDetails: TemplateInstanceGlobalVariablesDetails,
        equipmentLocalVarsDetails: TemplateInstanceLocalVariablesDetails) {
        let trapVariableRegexes: Array<RegExp> = [];
        let trapVariables: Array<string> = [];
        let autoSelectionVariables: Array<TemplateInstanceVariable> = [];

        const fillTrapVariablesData = (variable) => {
            trapVariables.push(variable.variable.name);
            trapVariableRegexes.push(new RegExp("{" + variable.variable.name + "}"));
            trapVariableRegexes.push(new RegExp("<" + variable.variable.name + ">"));
        };

        globalVarsDetails.vars.forEach(variable => {
            if (variable.loadSnmpTrap) {
                fillTrapVariablesData(variable);
            }

            if (variable.autoSelection) {
                autoSelectionVariables.push(variable);
            }
        });

        autoSelectionVariables.push(...equipmentLocalVarsDetails.equipmentLocalVars
            .flatMap((equipment) => equipment.localVars)
            .filter((variable) => variable.autoSelection));

        return this.getAutoSelectionVariablesWithTrapValueDependency(autoSelectionVariables, trapVariableRegexes, trapVariables);
    }

    isAutoSelection(varDetails: TemplateInstanceVariable) {
        return varDetails.autoSelection == undefined
            ? varDetails.variable.allowAutoSelection
            : varDetails.autoSelection;
    }

    private getAutoSelectionVariablesWithTrapValueDependency(autoSelectionVariables: Array<TemplateInstanceVariable>,
        trapVariableRegexes: Array<RegExp>, trapVariables: Array<string>) {
        let autoSelectionVariablesWithTrapValueDependency = [];
        autoSelectionVariables.forEach((autoSelectionVar) => {
            const extractionCommands = this.commandsVariablesService.getVariablesCommands(autoSelectionVar.variable);
            trapVariableRegexes.forEach((regex: RegExp) => {
                _.forEach(extractionCommands, (extractionCommand) => {
                    if (autoSelectionVariablesWithTrapValueDependency.indexOf(autoSelectionVar.variable.name) === -1) {
                        const variableHasDependecy = regex.exec(extractionCommand)
                            || this.isTrapVariableInsideAutoSelectionVariableCommand(trapVariables, extractionCommand);
                        if (variableHasDependecy) {
                            autoSelectionVariablesWithTrapValueDependency.push(autoSelectionVar.variable.name);
                        }
                    }
                });
            });
        });

        return autoSelectionVariablesWithTrapValueDependency;
    }

    private isTrapVariableInsideAutoSelectionVariableCommand(trapVariables: Array<string>, extractionCommand: string): boolean {
        return trapVariables.some((varName) => {
            const regex = `\\\${${varName}(\\\[.*\\\])?}|${varName}\\\?|\\\ ${varName}\\\ |${varName}>`;
            const regexByVar = new RegExp(regex, "g");
            return regexByVar.test(extractionCommand);
        });
    }

}
