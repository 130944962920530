import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";

/**
 * Controller para modal de leaflist.
 *
 * @author joao.otavio
 */
var app = angular.module("nms.dynamicDevice");

app.controller("LeafListModalController", ["$scope", "leafListTemplate", "config", "labelModal", "parentPath",
    "shouldWatchChanges", "pathKeys", "isTransient", "NmsToastrService", "TranslationHelperService",
    function($scope, leafListTemplate, config, labelModal, parentPath, shouldWatchChanges, pathKeys, isTransient,
        nmsToastrService: NmsToastrService, translationHelperService: TranslationHelperService) {
        $scope.leaflist = Object.assign({}, leafListTemplate);
        $scope.parentPath = parentPath;
        $scope.config = config;
        $scope.labelModal = labelModal;
        $scope.hasErrorInForm = false;
        $scope.shouldWatchChanges = shouldWatchChanges;
        $scope.pathKeys = pathKeys;
        $scope.isTransient = isTransient;

        $scope.confirmChangeValues = function() {
            if ($scope.hasErrorInForm) {
                $scope.showToastError();
                return false;
            }

            $scope.confirm($scope.leaflist.values);
        };

        $scope.$watch("form.$invalid", function(invalid) {
            if(_.isBoolean(invalid) && invalid) {
                $scope.hasErrorInForm = true;
            } else {
                $scope.hasErrorInForm = false;
            }
        });

        $scope.showToastError = function() {
            const message =  translationHelperService.translate("nes.services.config.invalidValues");
            nmsToastrService.error(message);
        };
    }
]);
