import { TemplateCommonsComponent } from "./template-commons.component";
import { Inject } from "@angular/core";
import { DeviceFreemarkerExpressionService } from "../services/expressions/freemarker/device-freemarker-expression.service";
import {
    CommandFormat,
    Expression,
    ExternalOptions,
    TemplateInterface,
    TemplateOptions,
    TemplateType
} from "./template.interface";
import { TEMPLATE_LANGUAGE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { DeviceLanguageServiceResolver } from "../services/expressions/resolver/device-language.service.resolver";

/**
 * Implementação de TemplateInterface para templates do tipo CLI.
 */
export class TemplateCliComponent extends TemplateCommonsComponent implements TemplateInterface {

    constructor(private freemarkerExpressionService: DeviceFreemarkerExpressionService,
                @Inject(TEMPLATE_LANGUAGE) private templateLanguageProvider: any,
                private deviceLanguageServiceResolver: DeviceLanguageServiceResolver) {
        super();
    }

    /**
     * @inheritdoc
     * Expressões disponíveis:
     * - If
     * - If/else
     * - Switch
     * - List (loop)
     * - Assign
     * - Comment
     * @returns Lista de componentes customizados para templates CLI.
     */
    getExpressions(isPythonTemplate: boolean): Expression[] | null {
        return this.deviceLanguageServiceResolver.resolve(isPythonTemplate).createExpressions();
    }

    /**
     * @inheritdoc
     * Templates CLI não agregam expressões customizadas no code-block.
     */
    getExpressionsAggregatorProvider(isPythonTemplate: boolean) {
        return this.deviceLanguageServiceResolver.resolve(isPythonTemplate).getExpressionsAggregatorProvider();
    }

    /**
     * @inheritdoc
     * As variáveis de templates para "devices" na linguagem Freemarker seguem o formato "${VAR_NAME}".
     *
     * As variáveis de templates para "devices" na linguagem Python seguem o formato "VAR_NAME".
     */
    getFormatCommand(isPythonTemplate: boolean): CommandFormat | null {
        return this.deviceLanguageServiceResolver.resolve(isPythonTemplate).getFormatCommand();
    }

    /**
     * @inheritdoc
     */
    getTooltipVariable(isPythonTemplate: boolean): string {
        return this.deviceLanguageServiceResolver.resolve(isPythonTemplate).getVariableTooltip();
    }

    /**
     * Em templates CLI - python não haverá variáveis do tipo 'ReadOnly' nem do tipo 'Action',
     * assim como ocorre nas variáveis dos templates TR-069.
     * @inheritdoc
     */
    createTemplateOptions(externalOptions: ExternalOptions): TemplateOptions {
        let {
            variableTypes,
            deviceModelRestrictionTypes: equipmentModelRestrictionTypes,
            templateLanguage
        } = externalOptions;

        const isPythonTemplate = templateLanguage === this.templateLanguageProvider.PYTHON;
        let variableTypesTemplate = Object.assign({}, variableTypes);
        if (isPythonTemplate) {
            delete variableTypesTemplate["READ_ONLY"];
            delete variableTypesTemplate["ACTION"];
        }

        const templateOptions: TemplateOptions = {
            equipmentModelRestrictionTypes,
            variableTypes: variableTypesTemplate,
            isTR069Template: false,
            matchingRulesKey: "templateForm.basic.device.model.restriction",
            templateLanguage
        };

        return templateOptions;
    }

    /**
     *  @inheritdoc
     */
     getEquipmentVariables(variables: any) {
        return variables;
    }

    /**
     * Método responsável por inicializar a string de um comando a ser utilizado no template.
     * Caso o parametro de entrada seja null/undefined, ele será redefinido para string vazia.
     * Caso haja comandos já definidos na string, retorna a string como ela veio.
     */
    getCommands(commands, isRemovalEnabled: boolean, isPythonTemplate: boolean): string {
        return this.deviceLanguageServiceResolver.resolve(isPythonTemplate).getCommands(commands, isRemovalEnabled);
    }

    /**
     * @inheritdoc
     */
    getShowInlineExpressions(isPythonTemplate: boolean): boolean {
        return this.deviceLanguageServiceResolver.resolve(isPythonTemplate).getShowInlineExpressions();
    }

    /**
     * @inheritdoc
     */
    isTemplateOfType(templateType: TemplateType): boolean {
        return templateType === "CLI";
    }
}