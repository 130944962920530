import { Inject, Injectable } from "@angular/core";
import {
    TemplateInstanceGlobalVariablesDetails,
    TemplateInstanceLocalVariablesDetails,
    TemplateInstanceVariable
} from "../../../template-instance/variables/variables.models";
import { TemplateInstance } from "../../../template-instance/template-instance-models";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Serviço relacionado aos tratamentos relacionados as variáveis que permitem
 * carregar seu valor atrávés de Traps SNMP.
 */
@Injectable({
    providedIn: "root"
})
export class SnmpTrapVariablesService {

    constructor(private nmsDialogService: NmsDialogService, @Inject(ANGULARJS_TRANSLATE) private translate) { }

    /**
     * Busca variáveis globais a serem ignoradas na extração devido ao uso de Traps SNMP
     * para carregar seus valores (variáveis locais não podem habilitar o carregamento de
     * valores através de traps).
     * @param globalVarsDetails - Variáveis globais
     * @returns - Lista de nomes de variáveis a serem ignoradas
     */
    getSnmpTrapEnabledVariablesToIgnore(globalVarsDetails: TemplateInstanceGlobalVariablesDetails) {
        return globalVarsDetails.vars
            .filter((globalVarDetails) => globalVarDetails.loadSnmpTrap)
            .map(globalVarDetails => globalVarDetails.variable.name);
    }

    validateTrapSnmpVariableToTemplateApplication(templateInstance: TemplateInstance, snmpTrapsSelected: {[key: string]:string}) {
        return this.validateEmptyMandatorySnmpTrapVariables(templateInstance) &&
                    this.validateDistinctSnmpTrapVariables(snmpTrapsSelected);
    }

    getUseSnmpTrapCheckValue(varDetails: TemplateInstanceVariable) {
        return varDetails.loadSnmpTrap == undefined
                    ? varDetails.variable.enableSnmpLoadTrap
                    : varDetails.loadSnmpTrap;
    }

    private validateEmptyMandatorySnmpTrapVariables(templateInstance: TemplateInstance): boolean {
        const trapSnmpVariableNames = templateInstance.globalVars
                                        .filter((variable) => variable.loadSnmpTrap)
                                        .map(variable => variable.name);

        const associatedSnmpTrapVariableNames = Object.keys(templateInstance.snmpTrapVariableAssociations);
        const emptyMandatorySnmpTrapVariables =
                trapSnmpVariableNames.filter(variable => !associatedSnmpTrapVariableNames.includes(variable));

        if (emptyMandatorySnmpTrapVariables.length > 0) {
            const description = this.translate.instant("templateinstanceform.error.emptyMandatorySnmpTrapVariables")
                                                .concat(emptyMandatorySnmpTrapVariables.join("<br>"));
            this.nmsDialogService.openDialog({
                description,
                bindHtml: true
            });

            return false;
        }

        return true;
    }

    private validateDistinctSnmpTrapVariables(snmpTrapsSelected: {[key: string]: string}): boolean {
        const distinctSnmpTrap = [...new Set(Object.values(snmpTrapsSelected))];

        if (distinctSnmpTrap.length > 1) {
            this.nmsDialogService.openDialog({
                description: this.translate.instant("templateinstanceform.error.invalidSameSnmpVariables")
            });

            return false;
        }

        return true;
    }
}
