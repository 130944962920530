"use strict";
import { DeviceFieldValues } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { Observable, from } from "rxjs";

/**
 * Modelo para os parâmetros passados pela URL para tabela de Devices
 */
 export interface DeviceUrlFilterParameter {
    operatorType?: "AND" | "OR";
    exhibitionName?: string;
    formattedHostname?: string;
    model?: string;
    serialNumber?: string;
    fwVersion?: string;
    path?: string;
    translatedStatus?: string;
    lastUpdate?: string;
    [key: string]: string
}

export type DeviceEquipmentFieldToTableField = {
    [key in DeviceFieldValues]: string
}

const DEVICE_FIELD_TO_TABLE_FIELD: DeviceEquipmentFieldToTableField = {
    NAME:          "exhibitionName",
    IP_HOSTNAME:   "formattedHostname",
    MODEL:         "model",
    SERIAL_NUMBER: "serialNumber",
    FIRMWARE:      "fwVersion",
    LOCATION:      "path",
    STATUS:        "translatedStatus",
    LAST_UPDATE:   "lastUpdate"
}

/**
 * @ngdoc controller
 * @name nms.controller:DevicesTableController
 * @description Controller para componente da tabela de devices (directive#devicesTable).
 */
var app = angular.module("nms");

app.controller("DevicesTableController", [
    "$rootScope",
    "$scope",
    "$state",
    "$window",
    "$log",
    "NgTableParams",
    "DevicesActionsService",
    "$translate",
    "CredentialsService",
    "TableFilterService",
    "UserPreferencesService",
    "CPU_USAGE",
    "MEMORY_USAGE",
    "DeviceDropdownModelService",
    "BROADCAST_EVENTS",
    "TABLE_PROPERTIES",
    "DevicesTableService",
    "DEVICE_LIST",
    "NMS_FEATURES",
    "NMS_STATES",
    "PollingService",
    "DeviceCommonService",
    "TEMPLATE_TYPE",
    "PermissionsActionsService",
    "ngZone",
    function (
        $rootScope,
        $scope,
        $state,
        $window,
        $log,
        NgTableParams,
        DevicesActionsService,
        $translate,
        CredentialsService,
        TableFilterService,
        UserPreferencesService,
        CPU_USAGE,
        MEMORY_USAGE,
        DeviceDropdownModelService,
        BROADCAST_EVENTS,
        TABLE_PROPERTIES,
        DevicesTableService,
        DEVICE_LIST,
        NMS_FEATURES,
        NMS_STATES,
        PollingService,
        DeviceCommonService,
        TEMPLATE_TYPE,
        PermissionsActionsService,
        ngZone
    ) {
        var deviceMap: any = {};
        var preferencesKeys = DEVICE_LIST.PREFERENCE_KEYS;
        $scope.allDevices = [];
        $scope.CPU_USAGE = CPU_USAGE;
        $scope.MEMORY_USAGE = MEMORY_USAGE;
        $scope.zabbixUrl = $scope.monitoringSettings.url;

        $scope.columns = [
            { field: "name", filter: { exhibitionName: "text" }, editable: false },
            { field: "hostname", sortable: "formattedHostname", filter: { formattedHostname: "text" } },
            { field: "model", width: "240" },
            { field: "serialNumber" },
            { field: "fwVersion", validateVersionFormat: true },
            { field: "path" },
            { field: "status", sortable: "translatedStatus", filter: { translatedStatus: "text" } },
            { field: "lastUpdate", sortable: "lastSuccessfulPollingFinish" },
            { field: "cpuUsage", sortable: "cpuUsageAbsolute" },
            { field: "memoryUsage", sortable: "memoryUsageAbsolute" }
        ];

        $scope.tableParams = new NgTableParams(
            {
                page: 1,
                count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
                sorting: { name: "asc" }
            },
            {
                dataset: [],
                filterOptions: { filterFn: TableFilterService.getFilter($scope.columns) }
            }
        );

        $scope.selection = {
            id: "devices-list-selector",
            checked: [],
            table: $scope.tableParams
        };

        $scope.tableModel = {
            options: {
                translatePrefix: "devicelist.tablecolumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        $scope.globalFilter = "";
        $scope.showOnlyDmOS = false;

        /**
         * Define os filtros da tabela de acordo com os parâmetros recebidos.
         *
         * Caso os parâmetros estejam seguindo o modelo usado no matching rules,
         * converte as chaves dos campos para os nomes utilizados na tabela
         */
        function refreshFiltersFromParameters() {
            if ($scope.queryParameters && Object.keys($scope.queryParameters).length > 0) {
                Object.keys(DEVICE_FIELD_TO_TABLE_FIELD).forEach((key) => {
                    if ($scope.queryParameters[key]) {
                        $scope.queryParameters[DEVICE_FIELD_TO_TABLE_FIELD[key]] = $scope.queryParameters[key];
                        delete $scope.queryParameters[key]
                    }
                })

                $scope.showOnlyDmOS = ($scope.queryParameters.dmOs === "true");
                $scope.tableParams.parameters().initCustomFilters = $scope.queryParameters;
                $scope.tableParams.parameters().overrideWithCustomFilters = true;
            }
        };

        $scope.applyGlobalFilter = function () {
            angular.extend($scope.tableParams.filter(), { $: $scope.globalFilter });
        };

        $scope.refreshShowOnlyDmOs = function () {
            var filterValue = undefined;

            if ($scope.showOnlyDmOS) {
                filterValue = true;
            }

            angular.extend($scope.tableParams.filter(), { dmOs: filterValue });
        };

        $scope.hasFeature = function (device) {
            return PermissionsActionsService.hasFeature(device, NMS_FEATURES.summary.feature);
        };

        var loadStoredOptions = function () {
            var options = DevicesTableService.loadStoredOptions($scope.windowIdentifier);

            if (options) {
                $scope.showOnlyDmOS = options.showOnlyDmOS || $scope.showOnlyDmOS;
                $scope.globalFilter = options.globalFilter || $scope.globalFilter;
            }
        };

        var listenToPollings = function () {
            ngZone.runOutsideAngular(() => {
                var updateDeviceInfo = function (deviceInfo) {
                    var deviceToUpdate = deviceMap[deviceInfo.id];
                    if (deviceToUpdate) {
                        var augmentedDevice = DevicesTableService.resolveDeviceInfo(deviceInfo);
                        _.assign(deviceToUpdate, augmentedDevice);
                    }
                };

                var updateDevicesPollingStatus = function (resourceIds, isPollingRunning) {
                    _.forEach(resourceIds, function (resourceId) {
                        deviceMap[resourceId].isPollingRunning = isPollingRunning;
                    });
                };

                PollingService.connectOnWebSocket(updateDeviceInfo, updateDevicesPollingStatus);
            });
        };

        $scope.tryGoToDevice = function (device) {
            if ($scope.hasFeature(device, NMS_FEATURES.summary)) {
                DevicesTableService.tryGoToDevice(device);
            }
        };

        $scope.retrieveDevices = function (): Observable<any> {
            return from(DevicesTableService.fetchDevices($scope.listOwner.devicesProvider));
        };

        function reloadDevicesTable(devices) {
            $scope.allDevices = devices.data;

            if ($scope.listOwner.autoUpdate) {
                $rootScope.loadUpdater = true;
            }

            reloadList();
        }

        $scope.listOwner.getCheckedDevices = function () {
            return $scope.selection.checked;
        };

        function reloadList() {
            $scope.tableParams.settings({ dataset: $scope.allDevices });
            $scope.refreshShowOnlyDmOs($scope.tableParams, $scope.showOnlyDmOS);

            $scope.tableParams.reload();

            if ($scope.tableParams.settings().dataset.length !== $scope.tableParams.total()) {
                $rootScope.$broadcast(BROADCAST_EVENTS.TABLE_PAGINATION.CHANGE_PAGE_TO, 1);
            }

            deviceMap = _.mapKeys($scope.tableParams.settings().dataset, "id");
            $log.log("All devices loaded at", new Date());

            if (!PollingService.isWebSocketConnected()) {
                listenToPollings();
            }
        }

        $scope.init = function () {
            $scope.searchTooltip = TableFilterService.tooltipText;
            loadStoredOptions();
            refreshFiltersFromParameters();

            if (!$scope.listOwner.autoUpdate) {
                $scope.fetchDevices();
            }
        };

        $scope.buildLabelMessage = function () {
            var deviceType = DevicesTableService.getDeviceType();
            return $translate.instant("devicelist.tableActions.showDevicesBy").replace("{0}", deviceType);
        };

        $scope.tryApplyTemplate = function (device) {
            DevicesTableService.tryApplyTemplate(device, TEMPLATE_TYPE.CLI, $scope.selection);
        };

        $scope.viewTemplateApplication = function (device) {
            DevicesTableService.viewTemplateApplication(device, $scope.selection, TEMPLATE_TYPE.CLI);
        };

        $scope.tryOpenEditCredentials = function (device) {
            DevicesTableService.tryOpenEditCredentials(device, $scope.selection);
        };

        $rootScope.unselectNotEditableDevices = function (devicesNotEditable) {
            $scope.selection.checked = _.filter($scope.selection.checked, function (device) {
                return !_.include(devicesNotEditable, device.hostname);
            });
        };

        $scope.tryOpenPropertiesConfig = function (device) {
            DevicesTableService.tryOpenPropertiesConfig(device, $scope.selection);
        };

        $scope.tryGoToDiscoverAddDevices = function (openInNewTab) {
            if (PermissionsActionsService.verifyUserPermission(NMS_FEATURES.discoverAndAddDevices.feature)) {
                var url = $state.href(NMS_STATES.deviceDiscover);
                $window.open(url, openInNewTab ? "_blank" : "_self");
            }
        };

        $scope.requestPolling = function () {
            if (_.isEmpty($scope.selection.checked)) {
                $rootScope.showDialog({ translateKey: "polling.error.noSelectedDevices" });
            } else if (_.every($scope.selection.checked, { isPollingRunning: true })) {
                $rootScope.showDialog({ translateKey: "polling.warning.devicesAlreadyOnPolling" });
            } else {
                PollingService.requestPollings($scope.selection.checked);
            }
        };

        $scope.removeSelectedDevices = function () {
            if (DevicesActionsService.areThereSelectedDevices($scope.selection.checked)) {
                tryRemoveDevices($scope.selection.checked);
            }
        };

        $scope.tryOpenConnectivityTest = function (device) {
            DevicesTableService.tryOpenConnectivityTest(device, $scope.selection);
        };

        $scope.removeDevice = function (device) {
            tryRemoveDevices([device]);
        };

        $scope.deviceDropdownAdditionalShortcuts = [
            {
                label: $translate.instant("device.dropdown.shortcut.remove"),
                isBold: true,
                isVisible: function () {
                    return true;
                },
                action: function (deviceId) {
                    tryRemoveDevices([_.find($scope.allDevices, { id: deviceId })]);
                }
            }
        ];

        function tryRemoveDevices(devices) {
            const message = DevicesTableService.getRemoveDevicesMessage(devices);
            const hasRemoteDevicesToRemove = $scope.hasRemoteDevices(devices);

            $rootScope
                .showDialog({
                    message: message,
                    isConfirm: true
                })
                .then(function () {
                    var successCallback = function () {
                        if (hasRemoteDevicesToRemove) {
                           $scope.fetchDevices();
                        } else {
                            reloadList();
                        }
                    };
                    DevicesTableService.removeDevices(devices, $scope.allDevices, successCallback);
                });
        }

        /**
         * Verifica os devices que possuem 'totalRemotesLabel' diferente de vazio.
         * A partir dessa lista verifica se um dos devices a serem removidos possuem o hostname
         * igual ao hostname contido em 'devicesWithRemoteInName'.
         */
        $scope.hasRemoteDevices = function(devices) {
            const devicesWithRemoteInName = $scope.allDevices.filter((device) => device.totalRemotesLabel !== "");
            return devices.some((device) => {
                return devicesWithRemoteInName.some((remote) => remote.hostname === device.hostname);
            });
        }

        $scope.fetchDevices = function() {
            DevicesTableService.fetchDevices($scope.listOwner.devicesProvider).then((response) => {
                reloadDevicesTable({ data: response });
            });
        }

        $scope.listOwner.updateModel = {
            updateFunction: $scope.retrieveDevices,
            updateFinishedCallback: reloadDevicesTable
        };

        $scope.addDeviceDbClick = function (device) {
            if ($scope.listOwner.addDeviceDbClickFn) {
                $scope.selection.checked = [device];
                $scope.listOwner.addDeviceDbClickFn();
            }
        };

        $scope.selectAndUnselectDevice = function (event, device, index) {
            if ($scope.listOwner.selectAndUnselectDeviceFn) {
                $scope.listOwner.selectAndUnselectDeviceFn(event, device, index, $scope.selection);
            }
        };

        $scope.createDeviceDropdownModel = function (device) {
            return DeviceDropdownModelService.createModelFromManagedDevice(device);
        };

        if ($scope.listOwner.autoUpdate) {
            $rootScope.loadUpdater = true;
        }

        $scope.$on("$destroy", function () {
            var properties = _.pick($scope, preferencesKeys);

            UserPreferencesService.savePreferences(properties, $scope.windowIdentifier, preferencesKeys);
            PollingService.disconnect();
        });

        $scope.init();
    }
]);
