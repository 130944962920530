import { Component, Inject, Input } from "@angular/core";
import { JobType, SchedulerJob } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { Trigger } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { ANGULARJS_ROOTSCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Classe responsável por agrupar e exibir os componentes corretos para possíveis triggers de agendamento
 */
@Component({
    selector: "scheduler",
    templateUrl: "./scheduler.component.html",
    styleUrls: ["./scheduler.component.scss"]
})
export class SchedulerComponent {
    @Input()
    schedulerJob: SchedulerJob;
    @Input()
    showInformTrigger: boolean;
    @Input()
    showTrapTrigger: boolean;
    @Input()
    hasTrapVariableAssociation: boolean;
    @Input()
    hasSchedulerPermission: boolean;
    @Input()
    showDataTimeCheck: boolean;

    triggers: Trigger[];

    constructor(@Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope: any) {}

    ngOnInit(): void {
        this.triggers = this.schedulerJob.triggers;
        this.showDataTimeCheck = this.schedulerJob.type === JobType.TEMPLATE_APPLICATION;
    }

    permissionScheduler() {
        if (!this.hasSchedulerPermission) {
            this.$rootScope.showDialog({
                translateKey: "scheduler.userHasNoPermission"
            });
        }
    }
}
