import { SnmpTrap, SnmpTrapTriggerData } from "../triggers.models";
import { Injectable } from "@angular/core";

/**
 * Classe responsável por realizar a validação do agendamento por SNMP traps.
 */
@Injectable({
    providedIn: "root"
})
export class SnmpTrapTriggerValidator {

    constructor() {}

    /**
     * Não foi possível tipar snmpTrapData, pois na validação caso nenhum valor seja selecionado
     * o snmpTrapData estará com o valor vazio('')
     * @param snmpTrapData
     */
    validate(snmpTrapData: any): void {
        if (snmpTrapData.snmpTrap === "") {
            throw new Error("scheduler.validation.error.invalidSnmpTrap");
        }
    }
}
