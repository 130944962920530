import { Inject, Injectable } from "@angular/core";
import { DeviceFreemarkerExpressionService } from "../services/expressions/freemarker/device-freemarker-expression.service";
import { CpePythonExpressionService } from "../services/expressions/python/cpe-python-expression.service";
import { TemplateTR069Component } from "./template-tr069.component";
import { TemplateCliComponent } from "./template-cli.component";
import { TemplateInterface, TemplateType } from "./template.interface";
import { TEMPLATE_LANGUAGE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { DeviceLanguageServiceResolver } from "../services/expressions/resolver/device-language.service.resolver";

/**
 * Classe responsável por selecionar a implementação correta de TemplateInstance
 * a partir do tipo do template.
 */
@Injectable({
  providedIn: "root"
})
export class TemplateComponentResolverService {
  private templateCpeComponent: TemplateTR069Component;
  private templateDeviceComponent: TemplateCliComponent;

  constructor(private pythonExpressionsService: CpePythonExpressionService,
              private freemarkerExpressionsService: DeviceFreemarkerExpressionService,
              private deviceLanguageServiceResolver: DeviceLanguageServiceResolver,
              @Inject(TEMPLATE_LANGUAGE) private templateLanguageProvider: any) {
    this.templateCpeComponent = new TemplateTR069Component(pythonExpressionsService, deviceLanguageServiceResolver);
    this.templateDeviceComponent = new TemplateCliComponent(freemarkerExpressionsService, templateLanguageProvider,
      deviceLanguageServiceResolver);
  }

  /**
   * Escolhe a implementação correta de TemplateInterface a partir do tipo de template.
   * @param templateType tipo do template a ser utilizado. Pode assumir os valores "CLI" ou "TR069".
   * @returns Implementação escolhida a partir do templateType.
   */
  resolve(templateType: { value: TemplateType }): TemplateInterface {
    if (templateType.value === "TR_069") {
      return this.templateCpeComponent;
    }

    return this.templateDeviceComponent;
  }
}
