import { NotificationStatus, NotificationType } from "../nms-notification/nms-notification.models";

/* Modelo relacionado as preferências de notificações*/
export interface NotificationPreferences {
    notificationType: NotificationType,
    notificationStatus: NotificationStatus,
    autoClose: boolean,
    enabled: boolean,
    delayBeforeCloseInSeconds: number,
    admin: boolean,
    description?: string
}

/* Enum relacionada as notificações por grupo */
export enum NotificationGroup {
    CPE_ONU = "CPE_ONU",
    TEMPLATE_APPLICATION = "TEMPLATE_APPLICATION",
    SCHEDULER = "SCHEDULER",
    DCB = "DCB",
    AUDIT_RETENTION = "AUDIT_RETENTION",
    DEVICE = "DEVICE",
    USERS_GROUPS = "USERS_GROUPS"
}

/* Modelo utilizado nas notificações por grupo */
export type NotificationPreferencesByGroup = {
    [property in NotificationGroup]: NotificationPreferences[];
};

/* Modelo utilizado nas notificações por tipo */
export type NotificationsByType = {
    [property: string]: NotificationPreferences;
};

/* Modelo relacionado aos títulos das preferências */
export type NotificationPreferencesGroupTitle = {
    [property in NotificationGroup]: string;
};

/* Mapa de descrições para preferências de notificações */
export interface NotificationPreferencesDescription {
    [key: string]: { [key in NotificationStatus]?: string; };
}

/* Modelo de dados utilizados nas notificações do usuário */
export interface NotificationPreferencesResponse {
    doNotDisturb: boolean;
    purgeOlderInDays: number;
    notificationPreferences: NotificationPreferencesByGroup;
}

/* Modelo de dados utilizado ao setar as notificações do usuário */
export interface NotificationPreferencesRequest {
    doNotDisturb: boolean;
    notificationPreferences: NotificationPreferences[];
    purgeOlderInDays: number;
}
