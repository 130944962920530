import { Inject, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    NotificationPreferences,
    NotificationPreferencesByGroup,
    NotificationPreferencesDescription
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences-model";
import { UserConfigPreferencesPanel, UserPreferencesPanelTab } from "./user-preferences.models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Serviço para auxiliar na construção e validação dos formulários da tela
 * de configurações do usuário.
 */
@Injectable({
    providedIn: "root"
})
export class UserConfigPreferencesService {
    readonly minPurgeOlderNumber: number = 1;
    readonly maxPurgeOlderNumber: number = 30;
    readonly minDelayAutoCloseNotification: number = 1;
    readonly maxDelayAutoCloseNotification: number = 1800;
    readonly maxLengthName: number = 50;
    readonly maxLengthEmail: number = 60;
    readonly maxLengthPhone: number = 20;

    /**
     * Pattern que permite os formatos de números de telefone aceitos pelo sistema:
     * 9999-8888, +55(11)9999-8888, +55(85)99999888, +55(11)9999 8888, 0055(51)99999-9999, 99998888
     * 99999-8888, +5599999-9888, (11)99999-8888, (11)9999-8888, +55(11)9999-8888, +55(11)99999-8888
     * 99999 8888, +55 (85) 9999 8888, +55 85 9999 8888
     */
    readonly phoneValidatorPattern: string =
        "(((?:\\+|00)\\d{1,3})?)(\\s?)((\\(\\d{2}\\)(\\s)?)|(\\d{2}(\\s)?)?)(\\d{4,5}(-|\\s)?\\d{4})";

    /**
     * Pattern que permite apenas valores entre 1 e 1800,
     * utilizado para validar o campo de configuração de segundos
     * para fechamento automático da notificação.
     */
    readonly notificationCloseDelayPattern: string = "^((1800)|(1[0-7][0-9]{2})|([1-9][0-9]{2})|([1-9][0-9]{1})|([1-9]))$";
    private notificationsDescriptionMap: NotificationPreferencesDescription = {
        DISCOVERED_ADDED_DEVICE: {
            SUCCESS: this.translate.instant("manage.user.notification.preferences.type.discovery.add.device.tooltip")
        },
        SCHEDULED_BY_FREQUENCY: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.scheduled.execution.tooltip")
        },
        SCHEDULED_BY_TR069_EVENT: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.scheduled.execution.tooltip")
        },
        SCHEDULED_BY_SNMP_TRAP: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.scheduled.execution.tooltip")
        },
        SCHEDULED_BY_FREQUENCY_ADMIN: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.only.admin.users.tooltip")
        },
        SCHEDULED_BY_TR069_EVENT_ADMIN: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.only.admin.users.tooltip")
        },
        SCHEDULED_BY_SNMP_TRAP_ADMIN: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.only.admin.users.tooltip")
        },
        CPE_DOWNLOAD_PENDING: {
            INFO: this.translate.instant("manage.user.notification.preferences.type.cpe.download.pending.tooltip")
        },
        CPE_DOWNLOAD_FAILED: {
            ERROR: this.translate.instant("manage.user.notification.preferences.type.cpe.download.failed.tooltip")
        },
        CPE_TRANSFER_COMPLETED: {
            SUCCESS: this.translate.instant("manage.user.notification.preferences.type.cpe.transfer.completed.tooltip")
        },
        CPE_TRANSFER_FAILED: {
            ERROR: this.translate.instant("manage.user.notification.preferences.type.cpe.transfer.failed.tooltip")
        }
    };

    constructor(private formBuilder: FormBuilder, @Inject(ANGULARJS_TRANSLATE) private readonly translate: any) {}

    getNotificationFormGroups(notifications: NotificationPreferences[]): FormGroup {
        const notificationsControls = [];

        for (let i = 0; i < notifications.length; i++) {
            const {
                delayBeforeCloseInSeconds,
                autoClose,
                enabled,
                notificationStatus,
                notificationType,
                description
            } = notifications[i];
            notificationsControls.push({
                delayBeforeCloseInSeconds: [
                    delayBeforeCloseInSeconds,
                    [
                        Validators.pattern(this.notificationCloseDelayPattern),
                        Validators.required,
                        Validators.max(this.maxDelayAutoCloseNotification),
                        Validators.min(this.minDelayAutoCloseNotification)
                    ]
                ],
                autoClose,
                enabled,
                notificationStatus,
                notificationType,
                description
            });
        }

        const notificationFormArrayGroup = this.formBuilder.array(
            notificationsControls.map((notificationControl) => this.formBuilder.group(notificationControl))
        );

        return this.formBuilder.group({ notifications: notificationFormArrayGroup });
    }

    getGeneralDataFormGroup(doNotDisturb: boolean, purgeOlderInDays: number): FormGroup {
        return this.formBuilder.group({
            doNotDisturb: doNotDisturb,
            purgeOlderInDays: this.formBuilder.control(purgeOlderInDays, [
                Validators.required,
                Validators.min(this.minPurgeOlderNumber),
                Validators.max(this.maxPurgeOlderNumber)
            ])
        });
    }

    getUserDataFormGroup(userData): FormGroup {
        return this.formBuilder.group({
            user: [userData.user],
            name: [userData.name, Validators.maxLength(this.maxLengthName)],
            email: [userData.email, [Validators.email, Validators.required, Validators.maxLength(this.maxLengthEmail)]],
            phone: [userData.phone, [Validators.maxLength(this.maxLengthPhone), Validators.pattern(this.phoneValidatorPattern)]]
        });
    }

    getInvalidTabs(userConfigPreferencesFormGroup: FormGroup, panelsList: UserConfigPreferencesPanel[]) {
        const tabs: UserPreferencesPanelTab[] = panelsList.flatMap((panel: UserConfigPreferencesPanel) => panel.tabs);

        return tabs.filter((tab: UserPreferencesPanelTab) => {
            return userConfigPreferencesFormGroup.controls[tab.id] && userConfigPreferencesFormGroup.controls[tab.id].invalid;
        });
    }

    getChangePasswordFormGroup() {
        const MIN_PASSWORD_LENGTH = 4;
        const MAX_PASSWORD_LENGTH = 13;
        const passwordValidators = [Validators.minLength(MIN_PASSWORD_LENGTH), Validators.maxLength(MAX_PASSWORD_LENGTH)];
        const passwordFields = {
            currentPassword: ["", passwordValidators],
            password: ["", passwordValidators],
            confirmPassword: [""]
        };
        const groupPasswordValidators = { validator: this.passwordFormValidator() };

        return this.formBuilder.group(passwordFields, groupPasswordValidators);
    }

    hasSomeNotificationConfigChange(notificationsByGroupCache: NotificationPreferencesByGroup,
            notificationsByGroup: NotificationPreferencesByGroup, userConfigPreferencesFormGroup: FormGroup): boolean {
        const notificationsGroups = Object.keys(notificationsByGroup);
        const notificationTypeWithChanges = notificationsGroups.filter((notificationType) => {

            if (userConfigPreferencesFormGroup.controls[notificationType]) {
                let hasChanges = false;
                const notificationsFormGroup = userConfigPreferencesFormGroup.controls[notificationType] as FormGroup;
                const notificationsFormControl = notificationsFormGroup.getRawValue()["notifications"];
                const changedNotifications = notificationsFormControl.filter((notificationFromForm: NotificationPreferences) => {
                    const currentNotificationCache: NotificationPreferences = this.findCurrentNotification(
                        notificationsByGroupCache,
                        notificationType,
                        notificationFromForm
                    );

                    const currentNotification: NotificationPreferences = this.findCurrentNotification(
                        notificationsByGroup,
                        notificationType,
                        notificationFromForm
                    );

                    if (currentNotification) {
                        const isSameNotificationType =
                            currentNotification.notificationType === notificationFromForm.notificationType;
                        const isSameNotificationStatus =
                            currentNotification.notificationStatus === notificationFromForm.notificationStatus;

                        if (isSameNotificationType && isSameNotificationStatus) {
                            hasChanges =
                                currentNotification.enabled !== notificationFromForm.enabled ||
                                currentNotification.autoClose !== notificationFromForm.autoClose ||
                                currentNotification.delayBeforeCloseInSeconds !== notificationFromForm.delayBeforeCloseInSeconds;

                            if (hasChanges) {
                                this.updateNotificationValues(currentNotificationCache, notificationFromForm);
                            }
                        }

                        return hasChanges;
                    }
                });

                return changedNotifications.length > 0;
            }
        });

        return notificationTypeWithChanges.length > 0;
    }

    public setNotificationsDescription(notificationPreferences: NotificationPreferencesByGroup) {
        Object.keys(notificationPreferences).forEach(key => {
            notificationPreferences[key].forEach(notificationPreference => {
                notificationPreference.description = this.getNotificationPreferencesTooltip(notificationPreference);
            });
        });
    }

    private getNotificationPreferencesTooltip(notification: NotificationPreferences): string {
        if (this.notificationsDescriptionMap[notification.notificationType]) {
            return this.notificationsDescriptionMap[notification.notificationType][notification.notificationStatus];
        }

        return notification.admin ? this.translate.instant("manage.user.notification.preferences.type.admin.tooltip") : null;
    }

    private findCurrentNotification(notificationsByGroup: NotificationPreferencesByGroup, notificationType: string,
            notificationFromForm: NotificationPreferences) {
        return notificationsByGroup[notificationType].find(
            (notificationByGroup: NotificationPreferences) =>
                notificationByGroup.notificationStatus === notificationFromForm.notificationStatus &&
                notificationByGroup.notificationType === notificationFromForm.notificationType
        );
    }

    private updateNotificationValues(notificationByGroup: NotificationPreferences,
            changedNotificationData: NotificationPreferences) {
        notificationByGroup.enabled = changedNotificationData.enabled;
        notificationByGroup.autoClose = changedNotificationData.autoClose;
        notificationByGroup.delayBeforeCloseInSeconds = changedNotificationData.delayBeforeCloseInSeconds;
    }

    private passwordFormValidator() {
        return (formGroup: FormGroup) => {
            const newPasswordControl = formGroup.get("password");
            const confirmPasswordControl = formGroup.get("confirmPassword");
            const passwordControl = formGroup.get("currentPassword");
            const isMatching = newPasswordControl.value === confirmPasswordControl.value;
            const hasValues = newPasswordControl.value && confirmPasswordControl.value;

            if (!isMatching) {
                return {
                    mustMatch: true
                }
            } else if (hasValues && !passwordControl.value) {
                return {
                    currentPasswordEmpty: true
                }
            } else if (passwordControl.value && !hasValues) {
                return {
                    required: true
                }
            } else {
                return null
            }
        };
    }
}
