import { Component, OnInit, Inject, Input, DoCheck, OnDestroy, EventEmitter, Output } from "@angular/core";

import { ANGULARJS_ROOTSCOPE, ANGULARJS_SCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import {
    MatchingRules,
    MatchingRulesTranslationKeys,
    RuleFieldsSet,
    RuleType,
    Rule,
    RuleCriteriasSet,
    RuleTypesSet,
    RuleFieldType
} from "./matching-rules.models";
import { MatchingRulesDifferService } from "./matching-rules-differ.service";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import { TranslatedText } from "@nms-ng2/app/shared/models/translation.models";

/**
 * Componente para definição de regras de restrição e filtro.
 */
@Component({
    selector: "matching-rules",
    templateUrl: "./matching-rules.component.html"
})
export class MatchingRulesComponent implements OnInit, DoCheck, OnDestroy {
    /**
     * Identificador único do componente.
     * Usado no atributo id do HTML para especificação em testes automáticos.
     */
    @Input()
    id: string;

    /**
     * Modelo que representa a entrada e a saída do componente, contendo o input do usuário
     * (modo das regras, regras em si e se elas serão utilizadas).
     */
    @Input()
    matchingRules: MatchingRules;

    /**
     * Critérios disponíveis para seleção.
     * i.e. "todos", "um dos"
     */
    @Input()
    ruleCriterias: RuleCriteriasSet;

    /**
     * Tipos de regras disponíveis para seleção.
     * e.g. "Contém", "Começar com", "Termina com"...
     */
    @Input()
    ruleTypes: RuleTypesSet;

    /**
     * Campos disponíveis que estarão vinculados a regras.
     * A não disponibilidade desse campo indica que as regras definidas estão vinculadas a um campo fixo no backend.
     */
    @Input()
    ruleFields?: RuleFieldsSet;

    /**
     * Chaves de tradução customizáveis.
     */
    @Input()
    translationKeys: MatchingRulesTranslationKeys;

    /**
     * Indica se o componente deve estar desabilitado.
     */
    @Input()
    isBlocked? = false;

    /**
     * Indica se é possível desabilitar o uso das regras definidas pelo usuário.
     * O valor default é true, pois este é o comportamento da maior parte das telas.
     */
    @Input()
    showUseMatchingRulesCheck = true;

    /**
     * Função para filtrar regras com base no campo selecionado.
     * Um exemplo de uso está nas permissões de templates de usuários e grupos, que omite alguns tipos de regras quando o campo
     * selecionado é 'KEYWORD'.
     */
    @Input()
    filterRuleTypes?: (field: string) => RuleTypesSet;

    /**
     *  Evento para enviar ao componente pai a informação de que o checkbox foi clicado e qual valor assumiu.
    */
    @Output("checkBoxRestrictionChanged")
    _checkBoxRestrictionChanged = new EventEmitter<boolean>();

    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
        @Inject(ANGULARJS_SCOPE) private readonly scope: any,
        private readonly matchingRulesDifferService: MatchingRulesDifferService,
        private readonly translationHelper: TranslationHelperService
    ) {}

    ngOnInit() {
        this.translateRuleTypes();
        this.translateRuleCriterias();
        this.translateRuleFields();

        if (this.matchingRules.rules[0] && this.ruleFields) {
            const initialRule = Object.keys(this.ruleFields).find((key) => this.ruleFields[key].initial === true);
            if (initialRule && !this.matchingRules.rules[0].field) {
                this.matchingRules.rules[0].field = initialRule;
            }
        }

        this.matchingRules.rules.forEach((rule) => {
            rule.id = this.createRuleUniqueId();
        });

        this.matchingRulesDifferService.createDiffers(this.matchingRules);

        if (!this.showUseMatchingRulesCheck) {
            this.matchingRules.useRules = true;
        }
    }

    /**
     * FIXME: Foi necessário realizar a chamada do 'this.scope.$apply() ao detectar mudanças no modelo matchingRules para
     * propagar as alterações entre o Angular 8 e o AngularJS. Remover todo o 'ngDoCheck' bem como o MatchingRulesDifferService
     * quando os componentes que utilizam o MatchingRulesComponent forem migrados para o Angular 8.
     */
    ngDoCheck(): void {
        const hasChanges = this.matchingRulesDifferService.detectChanges(this.matchingRules);
        if (hasChanges) {
            setTimeout(() => {
                this.scope.$apply();
            });
        }
    }

    ngOnDestroy(): void {
        this.matchingRulesDifferService.destroyDiffers();
    }

    public addRules($event, index: number): void {
        $event.preventDefault();
        const newMatchingRules: Rule = {
            ruleType: RuleType.CONTAINS,
            values: [""],
            id: this.createRuleUniqueId()
        };

        if (this.ruleFields) {
            const fields = _.keys(this.ruleFields);
            const initialField = _.find(fields, (key) => {
                return this.ruleFields[key].initial;
            });
            newMatchingRules.field = initialField || fields[0];
        }

        ++index;
        this.matchingRules.rules.splice(index, 0, newMatchingRules);
        this.matchingRulesDifferService.addRuleToRulesDiffers(newMatchingRules);
    }

    public removeRules($event, index: number): void {
        $event.preventDefault();

        if (this.matchingRules.rules.length === 1) {
            this.rootScope.showDialog({
                translateKey: this.translationKeys.atLeastOneRule
            });
        } else {
            const removeRuleId = this.matchingRules.rules[index].id;
            this.matchingRules.rules.splice(index, 1);
            this.matchingRulesDifferService.deleteRuleFromRulesDiffers(removeRuleId);
        }
    }

    public getCommonTranslation(mapKey: string): TranslatedText {
        return this.translationHelper.translateFromMap(this.translationKeys, mapKey);
    }

    public getTooltipForIsBlocked(): string {
        return this.isBlocked ? this.translationHelper.translate(this.translationKeys.isBlocked) : "";
    }

    public ruleTypeChanged(rule: Rule): void {
        if (this.isBetweenType(rule.ruleType)) {
            rule.values = [rule.values[0], rule.values[1] || ""];
        } else if (rule.ruleType === RuleType.IS_DMOS || rule.ruleType === RuleType.NOT_DMOS) {
            rule.values = [""];
        } else {
            rule.values = [rule.values[0]];
        }
        this.changeRuleTypeIfNeeded(rule)
    }

    public changeRuleTypeIfNeeded(rule) {
        const filteredRulesTypes = this.getRuleTypes(rule);
        rule.ruleType = _.has(filteredRulesTypes, rule.ruleType) ? rule.ruleType : RuleType.CONTAINS;
    }

    public isDisabled(): boolean {
        return !this.matchingRules.useRules || this.isBlocked;
    }

    public isStringType(ruleType: RuleType): boolean {
        return this.ruleTypes[ruleType].type === RuleFieldType.STRING && !this.isBetweenType(ruleType);
    }

    public isBetweenType(ruleType: RuleType): boolean {
        return ruleType === RuleType.BETWEEN || ruleType === RuleType.NOT_BETWEEN;
    }

    /**
     * Função para filtrar os tipos de regras de acordo filterRuleTypes recebido.
     * Se a regra não existir depois que for filtrado, será atribuido o 'CONTAINS'.
     */
    public getRuleTypes(rule: Rule): RuleTypesSet {
        if (this.filterRuleTypes) {
            return this.filterRuleTypes(rule.field);
        }

        return this.ruleTypes;
    }

    /**
     * Emite para o elemento pai o estado do checkbox das restrições quando ele é clicado.
     * Há um delay entre o momento do clique e o modelo receber o valor, logo o valor assumido é
     * o inverso do valor atual.
     */
    public checkBoxRestrictionChanged(): void {
        this._checkBoxRestrictionChanged.emit(!this.matchingRules.useRules);
    }

    /**
     * Verifica em @see RuleTypesSet a existência do atributo key, caso exista, faz a tradução.
     */
    private translateRuleTypes(): void {
        _.forEach(this.ruleTypes, (ruleMatchingType) => {
            if (_.has(ruleMatchingType, "translationKey")) {
                ruleMatchingType.translate = this.translationHelper.translate(ruleMatchingType.translationKey);
            }
        });
    }

    /**
     * Verifica a existência da chave de tradução no modelo @see RuleCriteriasSet, caso exista, traduz. Caso contrário
     * será usado o valor de value.
     */
    private translateRuleCriterias(): void {
        const criterias: RuleCriteriasSet = {} as RuleCriteriasSet;

        _.forOwn(this.ruleCriterias, (value, key) => {
            criterias[key] =
                _.isObject(value) && _.has(value, "translationKey")
                    ? this.translationHelper.translate(value.translationKey)
                    : value;
        });

        this.ruleCriterias = criterias;
    }

    /**
     * Verifica a existência da chave de tradução no modelo @see RuleFieldsSet, caso exista, traduz. Caso contrário
     * será usado o valor de value.
     */
    private translateRuleFields(): void {
        _.forOwn(this.ruleFields, (value, key) => {
            this.ruleFields[key].translate =
                _.isObject(value) && _.has(value, "translationKey")
                    ? this.translationHelper.translate(value.translationKey)
                    : value.translate;
        });
    }

    /**
     * Cria um ID único para as regras.
     * Isso foi necessário para linkar cada uma das regras ao seu differ em @see MatchingRulesDiffersService.
     */
    private createRuleUniqueId(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    /**
     * Usado para sobrescrever a função comparadora padrão do keyvalue pipe no angular
     * https://angular.io/api/common/KeyValuePipe
     * https://github.com/angular/angular/issues/31420
     * https://github.com/angular/angular/issues/42490
     */
    asIsOrder(a, b) {
        return 1;
    }
}

