import { Cpe } from "../../device/cpes/cpe-model";
import { TemplateInstanceIdentifierService } from "../template-instance/template-instance-identifier.service";
import {
  ANGULARJS_ROOTSCOPE,
  PRESENTATION_MODE,
  TEMPLATE_TYPE,
  TEMPLATE_INSTANCE_SERVICE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Inject, Injectable } from "@angular/core";
import { EquipmentAssociation } from "../template-instance/template-instance-models";

export class EquipmentInfo {
  id: number;
  name: string;
  fwVersion: string;
  modelCode: number;
  vendorCode: number;
  locationId: number;
}
/**
 * Serviço responsável pelas coerências de equipamentos
 */
@Injectable({
    providedIn: "root"
})
export class EquipmentService {
    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        @Inject(PRESENTATION_MODE) private readonly presentationMode,
        @Inject(TEMPLATE_TYPE) private readonly TEMPLATE_TYPE,
        @Inject(TEMPLATE_INSTANCE_SERVICE) private readonly templateInstanceService,
        private readonly templateInstanceIdentifierService: TemplateInstanceIdentifierService) {}

    selectAndUnselectEquipment(equipment, checkedEquipments) {
        const index = checkedEquipments.indexOf(equipment);

        if (index < 0) {
            checkedEquipments.push(equipment);
        } else {
            checkedEquipments.splice(index, 1);
        }

        return checkedEquipments;
    }

    validateAddEquipments(checkedEquipments: Array<Cpe | EquipmentInfo>, equipmentsAlreadyAdded: Array<EquipmentAssociation>,
            presentationMode: string, shouldAddEquipmentsToAllTemplates: boolean, templateAlreadyAdded,
            templateType: string): boolean {
        let isValid = this.validateThereIsSelectedEquipments(checkedEquipments, templateType);

        if (equipmentsAlreadyAdded.length > 0) {
            isValid = isValid && this.validateDuplicatedEquipments(checkedEquipments, equipmentsAlreadyAdded,presentationMode,
                    shouldAddEquipmentsToAllTemplates, templateType);
        }

        if (presentationMode === this.presentationMode.EQUIPMENTS_BY_TEMPLATE) {
            isValid = isValid && this.validateDuplicatedEquipmentsInTemplate(checkedEquipments, equipmentsAlreadyAdded,
                    templateAlreadyAdded, shouldAddEquipmentsToAllTemplates, templateType);
        }

        return isValid;
    }

    handleInvalidEquipments(checkedEquipments, fails, messageKey?) {
        const equipmentsNames = this.getRepeatedEquipmentsNames(fails);

        if (messageKey) {
            this.$rootScope.showDialog({
                translateKey: messageKey,
                params: equipmentsNames,
                maxChars: 37
            });
        }

        this.removeInvalidEquipmentsSelected(checkedEquipments, fails)
    }

    getSpecificTranslationKeys(templateType: string): any | undefined {
        if (templateType === this.TEMPLATE_TYPE.CLI.name) {
            return {
                equipment: "templateinstanceform.device",
                equipments: "templateinstanceform.viewapply.devices",
                selectedEquipments: "templateinstanceform.selectdevices",
                templateEquipmentLabel: "templateinstanceform.viewapply.templatedevicelabel",
                equipmentsByTemplate: "templateinstanceform.templatedevices.results.devicesbytemplate",
                templatesByEquipment: "templateinstanceform.templatedevices.results.templatesbydevice",
                totalEquipmentsByTemplate: "templateinstanceform.viewapply.totaldevicesbytemplate",
                totalTemplatesByEquipment: "templateinstanceform.viewapply.totaltemplatesbydevice",
                equipmentCount: "templateinstanceform.viewapply.templatestatus.devicescount",
                statusByEquipmentLabel: "templateinstanceform.viewapply.templatestatus.statusbydevicelabel",
                viewCommandsLabel: "templateinstanceform.viewapply.viewclicommands",
                addEquipment: "modals.addequipmentmodal.adddevices",
                addTemplateToAllEquipment: "modals.addequipmentmodal.addtemplatetoalldevices",
                addEquipmentToAllTemplate: "modals.addtemplatemodal.adddevicetoalltemplates",
                equipmentByTemplate: "templateinstanceform.templateequipments.devicesbytemplate",
                templateByEquipment: "templateinstanceform.templateequipments.templatesbydevice",
                equipmentSelectionTypeLabel: "templateinstanceform.equipmentselectiontype.selectiondevices",
                equipmentSelectionFilterTipIcon: "templateinstanceform.equipmentselectiontype.tooltipfilterdevices",
                equipmentSelectionAllTipIcon: "templateinstanceform.equipmentselectiontype.tooltipalldevices",
                equipmentSelectionFilterLabel: "templateinstanceform.templatedevices.device.selection.filter.restriction",
                showMatchingEquipmentsLink: "templateinstanceform.templatedevices.device.showMatchingLink"
            };
        } else if (templateType === this.TEMPLATE_TYPE.TR_069.name) {
            return {
                equipment: "templateinstanceform.cpe",
                equipments: "templateinstanceform.viewapply.cpes",
                selectedEquipments: "templateinstanceform.selectcpes",
                templateEquipmentLabel: "templateinstanceform.viewapply.templatecpelabel",
                equipmentsByTemplate: "templateinstanceform.templatedevices.results.cpesbytemplate",
                templatesByEquipment: "templateinstanceform.templatedevices.results.templatesbycpe",
                totalEquipmentsByTemplate: "templateinstanceform.viewapply.totalcpesbytemplate",
                totalTemplatesByEquipment: "templateinstanceform.viewapply.totaltemplatesbycpe",
                equipmentCount: "templateinstanceform.viewapply.templateStatus.cpescount",
                statusByEquipmentLabel: "templateinstanceform.viewapply.templatestatus.statusbycpelabel",
                viewCommandsLabel: "templateinstanceform.viewapply.viewpythoncommands",
                addEquipment: "modals.addequipmentmodal.addcpes",
                addTemplateToAllEquipment: "modals.addequipmentmodal.addtemplatetoallcpes",
                addEquipmentToAllTemplate: "modals.addtemplatemodal.addcpetoalltemplates",
                equipmentByTemplate: "templateinstanceform.templateequipments.cpesbytemplate",
                templateByEquipment: "templateinstanceform.templateequipments.templatesbycpe",
                equipmentSelectionTypeLabel: "templateinstanceform.equipmentselectiontype.selectioncpes",
                equipmentSelectionFilterTipIcon: "templateinstanceform.equipmentselectiontype.tooltipfiltercpes",
                equipmentSelectionAllTipIcon: "templateinstanceform.equipmentselectiontype.tooltipallcpes",
                equipmentSelectionFilterLabel: "templateinstanceform.templatedevices.cpe.selection.filter.restriction",
                showMatchingEquipmentsLink: "templateinstanceform.templatedevices.cpe.showMatchingLink"
            };
        }
    }

    validateThereIsSelectedEquipments(checkedEquipments, templateType: string): boolean {
        if (checkedEquipments.length === 0) {
            this.$rootScope.showDialog({
                translateKey: `addEquipmentModal.error.noEquipmentSelected.${templateType}`
            });
            return false;
        }

        return true;
    }

    validateDuplicateSelectedEquipments(checkedEquipments, equipmentsAlreadyAdded, templateType: string): boolean {
        const fails = [];
        checkedEquipments.forEach((checkedEquipment) => {
            equipmentsAlreadyAdded.forEach((equipmentAlreadyAdded) => {
                if (equipmentAlreadyAdded.id == checkedEquipment.id) {
                    fails.push(checkedEquipment);
                    return;
                }
            });
        });

        if (fails.length > 0) {
            this.handleInvalidEquipments(checkedEquipments, fails, `addEquipmentModal.error.repeatedEquipments.${templateType}`);
            return false;
        }

        return true;
    }

    isCpe(equipment: Cpe | EquipmentInfo): equipment is Cpe {
        return (equipment as Cpe).serialNumber !== undefined;
    }

    isDevice(equipment: Cpe | EquipmentInfo): equipment is EquipmentInfo {
        return (equipment as EquipmentInfo).id !== undefined;
    }

    /**
     * Retorna o identificador de um equipamento.
     * Por padrão os devices possuem um Id, mas CPEs são representados
     * pelo serialNumber.
     * @param equipment - equipamento, podendo ser um CPE ou um EquipmentInfo
     */
    private getEquipmentId(equipment: Cpe | EquipmentInfo) {
        return this.isDevice(equipment) ? equipment.id : equipment.serialNumber;
    }

    /**
     * Retorna o nome de um equipamento.
     * Por padrão os devices possuem um name, mas CPEs são representados
     * pelo serialNumber.
     * @param equipment - equipamento, podendo ser um CPE ou um EquipmentInfo
     */
    private getEquipmentName(equipment: Cpe | EquipmentInfo) {
        return this.isDevice(equipment) ? equipment.name : equipment.serialNumber;
    }

    private getRepeatedEquipmentsNames(equipments: Array<Cpe | EquipmentInfo>) {
        const equipmentsNames = [];
        equipments.forEach((equipment) => {
            const equipmentName = this.getEquipmentName(equipment);
            equipmentsNames.push(equipmentName);
        });
        return equipmentsNames;
    }

    private removeInvalidEquipmentsSelected(checkedEquipments, fails) {
        fails.forEach((fail) => {
            this.selectAndUnselectEquipment(fail, checkedEquipments);
        });
    }

    private validateDuplicatedEquipments(checkedEquipments, equipmentsAlreadyAdded, presentationMode,
            shouldAddEquipmentsToAllTemplates, templateType: string): boolean {
        const fails = [];
        checkedEquipments.forEach((checkedEquipment) => {
            equipmentsAlreadyAdded.forEach((equipmentAlreadyAdded) => {
                if (
                    presentationMode === this.presentationMode.TEMPLATES_BY_EQUIPMENT &&
                    this.templateInstanceIdentifierService.resolveIdentifier(equipmentAlreadyAdded.equipmentIdentifier) ===
                        this.getEquipmentId(checkedEquipment)
                ) {
                    fails.push(checkedEquipment);
                    return;
                }
            });
        });

        if (!shouldAddEquipmentsToAllTemplates && fails.length > 0) {
            this.handleInvalidEquipments(checkedEquipments, fails, `addEquipmentModal.error.repeatedEquipments.${templateType}`);
            return false;
        }

        return true;
    }

    private validateDuplicatedEquipmentsInTemplate(checkedEquipments, equipmentsAlreadyAdded, templateAlreadyAdded,
            shouldAddEquipmentsToAllTemplates, templateType: string): boolean {
        const fails = [];
        checkedEquipments.forEach((checkedEquipment) => {
            const selectedEquipment = equipmentsAlreadyAdded.find((equipment) => {
                return (
                    this.templateInstanceIdentifierService.resolveIdentifier(equipment.equipmentIdentifier) ===
                    this.getEquipmentId(checkedEquipment)
                );
            });

            if (selectedEquipment) {
                templateAlreadyAdded.forEach(function (templateAlready) {
                    const selectedTemplate = selectedEquipment.templateAssociations.find(
                        (templateAssociation) => templateAssociation.templateId === templateAlready.templateId
                    );

                    if (selectedTemplate) {
                        fails.push(checkedEquipment);
                    }
                });
            }
        });

        if (!shouldAddEquipmentsToAllTemplates && fails.length > 0) {
            this.handleInvalidEquipments(checkedEquipments, fails, `addEquipmentModal.error.repeatedEquipments.${templateType}`);

            return false;
        }

        return true;
    }
}
