const createCommentStatement = (comment) =>
`
    # ${comment}
`;

const createFunctionStatement = (functionParams, printVars) =>
`
    def some_function(${functionParams}):
        ${printVars}
`;

const createIfStatement = (firstVarName, printVars) =>
`
    if ${firstVarName} == 'some_value':
        ${printVars}
`;

const createIfElseStatement = (firstVarName, printVars, someOtherMessage) =>
`
    if ${firstVarName} == 'some_value':
        ${printVars}
    else:
        print(${someOtherMessage})
`;

const createParameterizedForStatement = (variableItem, firstVarName) =>
`
    for ${variableItem} in ${firstVarName}:
        print(${variableItem})
`;

const createForStatement = () =>
`
    some_list = ['1','2','3','4']
    for some_list_item in some_list:
        print(some_list_item)
`;

const createMultipleParameterizedForStatement = (variableItem, firstVarName, printVars) =>
`
    for index, ${variableItem} in enumerate(${firstVarName}):
        print(${variableItem})
        ${printVars}
`;

const createPrintStatement = () =>
`
    print('Some message')
`;

const createWhileStatement = (someVariable, loadVar) =>
`
    ${someVariable} = 0
    while ${someVariable} < 1:
        ${loadVar}
        ${someVariable} += 1
`;

const createHasValueTemplateFunction = () =>
`
    if hasValue('someKey'):
        loadValue('someKey')
    else:
        print('some message')
`;

const createLoadValueTemplateFunction = () =>
    `
    loadValue('someKey')
`;

const createSaveValueTemplateFunction = () =>
    `
    saveValue('someKey', 'someValue')
`;

const createStopTemplateFunction = () =>
    `
    stop('Some error message.')
`;

export default {
    createCommentStatement,
    createFunctionStatement,
    createIfStatement,
    createIfElseStatement,
    createParameterizedForStatement,
    createForStatement,
    createMultipleParameterizedForStatement,
    createPrintStatement,
    createWhileStatement,
    createHasValueTemplateFunction,
    createLoadValueTemplateFunction,
    createSaveValueTemplateFunction,
    createStopTemplateFunction,
}