import { AfterViewInit, Component, Inject, OnInit, OnDestroy, ViewChild } from "@angular/core";
import {
    NMS_STATES,
    GPON_ONU_SERVICE,
    STATE,
    ANGULARJS_TRANSLATE,
    GPON_ONUS_STATUS_CONFIG,
    LABEL_COLOR_SERVICE,
    MONITORING_SERVICE,
    DEVICE_DROPDOWN_MODEL_SERVICE,
    DEVICE_DROPDOWN_SHORTCUTS_SERVICE,
    ANGULARJS_SCOPE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import {
    NmsTableComponent, ColumnConfig, FilterOperator, RequestConfig,
    FilterColumnParameters, RequestFilter, PagingFilterType, SortConfig
} from "@nms-angular-toolkit/nms-table";
import { InputSearchChanged } from "@nms-angular-toolkit/nms-input-search";
import { RefreshIntervalService } from "@nms-ng2/app/shared/services/refresh-interval/refresh-interval.service";
import { Observable, Subscription } from "rxjs";
import { DropdownMenu, DropdownShortcut } from "@nms-angular-toolkit/nms-dropdown";
import { DevicesActionsService } from "@nms-ng2/app/shared/services/actions/devices-actions.service";
import { CpeDropdownService } from "../cpes/cpe-dropdown.service";
import {
    FIELD_URL_MAP,
    FILTER_TYPE_BY_SYMBOL,
} from "./gpon-onus.constants";
import { DeviceDropdownShortcut } from "./gpon-onus.models";
import { GponOnusField } from "./gpon-onus.models";

/**
 * Componente responsável pela renderização da tabela paginada de onus.
 */
@Component({
    selector: "gpon-onus",
    templateUrl: "./gpon-onus.component.html",
    styleUrls: ["./gpon-onus.component.scss"]
})
export class GponOnusComponent implements OnInit, AfterViewInit, OnDestroy {

    private readonly baseUrl: string = "/onus/search-all";
    private readonly zabbixUrlPlaceholder: string = "${zabbixUrl}";

    @ViewChild(NmsTableComponent)
    nmsTableComponent: NmsTableComponent;

    autoUpdateInterval: number;
    gponOnusProvider;
    monitoringSettings: any;
    hasSummaryAccess: boolean;
    availableFeaturesByDevice = [];
    nmsTableChangedSubscription: Subscription;

    columns: ColumnConfig[] = [
        { field: GponOnusField.DeviceName, label: "Device", optionalColumn: { type: "dropdown"} },
        { field: GponOnusField.PortViewName, label: "Port", width:"85px" },
        {
            field: GponOnusField.OnuId,
            label: "ID",
            width: "50px",
            optionalColumn: {
                type: "dropdown",
                defaultAction: (gpon) => {
                    this.openDeviceOnOnu(gpon);
                }
            }
        },
        {
            field: GponOnusField.Status,
            label: "Status",
            unsearchable: true,
            optionalColumn: {
                type: "status",
                convert: (status) => {
                    const  { translationKey, bgColor } = this.gponOnuStatusConfig[status.trim().toUpperCase()];
                    const color = this.labelColorService.getBestColorContrastForForeground(bgColor);
                    const label = this.translate.instant(translationKey);

                    return { label, bgColor, color };
                }
            }
        },
        {
            field: GponOnusField.RxPower,
            label: "Rx Power(dBm)",
            width:"90px",
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        {
            field: GponOnusField.TxPower,
            label: "Tx Power(dBm)",
            width:"90px",
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        { field: GponOnusField.Alarms, label: "Alarms" },
        { field: GponOnusField.AlarmsTimes , label: "Alarms Times" },
        {
            field: GponOnusField.SerialNumber,
            label: "Serial Number",
            optionalColumn: {
                type: "dropdown",
                defaultAction: (gpon) => {
                    this.openDeviceOnOnu(gpon);
                }
            }
        },
        { field: GponOnusField.Password, label: "Password" },
        { field: GponOnusField.VendorId, label: "Vendor ID" },
        { field: GponOnusField.EquipmentId, label: "Equipment ID" },
        { field: GponOnusField.OnuName, label: "Name" },
        { field: GponOnusField.OperationalState, label: "Oper.State" },
        { field: GponOnusField.PrimaryStatus, label: "Primary Status" },
        { field: GponOnusField.Distance , label: "Distance(km)" },
        { field: GponOnusField.Ipv4Address , label: "IPv4 Addr." },
        { field: GponOnusField.CwmpCpeIp , label: "TR-069 Mgmt IP" },
        { field: GponOnusField.CwmpCpeLastInform , label: "TR-069 Last Inform" },
        { field: GponOnusField.Tr069AcsProfile, label: "TR-069 Profile" },
        { field: GponOnusField.Ipv4DefaultGateway, label: "IPv4 default gateway" },
        { field: GponOnusField.Ipv4Mode, label: "IPv4 mode", unsearchable: true },
        { field: GponOnusField.Ipv4Vlan, label: "IPV4 VLAN", width:"80px" },
        { field: GponOnusField.RgProfile, label: "RG Profile" },
        { field: GponOnusField.Snmp, label: "SNMP", unsearchable: true },
        { field: GponOnusField.UpstreamFec, label: "Upstream-FEC" },
        { field: GponOnusField.Uptime, label: "Uptime" },
        { field: GponOnusField.LastSeenOnline, label: "Last Seen Online" },
        { field: GponOnusField.Version, label: "Version" },
        { field: GponOnusField.AutoProvisioned, label: "Auto Provisioned" },
        { field: GponOnusField.LineProfile, label: "Line Profile" },
        { field: GponOnusField.ServiceProfile, label: "Service Profile" },
        { field: GponOnusField.ServiceVlans, label: "service VLANs" },
        { field: GponOnusField.FixedBandwidth, label: "Fixed BW(kbit/s)", width:"90px" },
        { field: GponOnusField.AssuredFixedBandwidth, label: "Assured+Fixed BW" },
        { field: GponOnusField.ActiveFwFormatted, label: "Active FW" },
        { field: GponOnusField.StandbyFwFormatted, label: "Standby FW" },
        { field: GponOnusField.SoftwareDownloadState, label: "SW Download State" },
        { field: GponOnusField.LastUpdated, label: "Last Update by Device" },
        { field: GponOnusField.Eth1Link, label: "Eth 1 Link", unsearchable: true },
        {
            field: GponOnusField.Eth1InRate,
            label: "Eth 1 In Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        {
            field: GponOnusField.Eth1OutRate,
            label: "Eth 1 Out Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        { field: GponOnusField.Eth2Link, label: "Eth 2 Link", unsearchable: true },
        {
            field: GponOnusField.Eth2InRate,
            label: "Eth 2 In Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        {
            field: GponOnusField.Eth2OutRate,
            label: "Eth 2 Out Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        { field: GponOnusField.Eth3Link, label: "Eth 3 Link", unsearchable: true },
        {
            field: GponOnusField.Eth3InRate,
            label: "Eth 3 In Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        {
            field: GponOnusField.Eth3OutRate,
            label: "Eth 3 Out Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        { field: GponOnusField.Eth4Link, label: "Eth 4 Link", unsearchable: true },
        {
            field: GponOnusField.Eth4InRate,
            label: "Eth 4 In Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        },
        {
            field: GponOnusField.Eth4OutRate,
            label: "Eth 4 Out Rate",
            unsearchable: true,
            optionalColumn: {
                type: "text",
                buildLink: this.buildZabbixLink.bind(this)
            }
        }
    ];
    options: RequestConfig = {
        serverPagination: {
            baseUrl: this.baseUrl
        }
    };
    operators: Array<FilterOperator> = [];
    dropdownMenus: Array<DropdownMenu>;
    deviceNameShortcuts: Array<DeviceDropdownShortcut> = [];
    initialSort: SortConfig[];

    constructor(@Inject(NMS_STATES) private nmsStates: any,
                @Inject(STATE) private state: any,
                @Inject(GPON_ONU_SERVICE) private gponOnusService: any,
                @Inject(ANGULARJS_TRANSLATE) private translate: any,
                @Inject(GPON_ONUS_STATUS_CONFIG) private gponOnuStatusConfig: any,
                @Inject(MONITORING_SERVICE) private monitoringService: any,
                @Inject(LABEL_COLOR_SERVICE) private labelColorService: any,
                @Inject(DEVICE_DROPDOWN_MODEL_SERVICE) private deviceDropdownModelService: any,
                private refreshIntervalService: RefreshIntervalService,
                private devicesActionService: DevicesActionsService,
                @Inject(DEVICE_DROPDOWN_SHORTCUTS_SERVICE) private deviceDropDownShortcutsService: any,
                private cpeDropdownService: CpeDropdownService,
                @Inject(ANGULARJS_SCOPE) private scope: any) {
        this.gponOnusProvider = this.retrieveGponOnus;
        this.refreshIntervalService.getRefreshInterval((interval) => {
            this.autoUpdateInterval = interval;
        });
        this.dropdownMenus = [
            {
                field: "deviceName",
                getShortcuts: (gpon) => this.getDeviceDropdownShortcuts(gpon) as Array<DropdownShortcut>
            },
            {
                field: "onuId",
                getShortcuts: (gpon) =>
                    this.cpeDropdownService.getTR069Shortcuts(this.openDeviceOnOnu) as Array<DropdownShortcut>
            },
            {
                field: "serialNumber",
                getShortcuts: (gpon) =>
                this.cpeDropdownService.getTR069Shortcuts(this.openDeviceOnOnu) as Array<DropdownShortcut>
            }
        ];

        this.operators = [
            {
                value: "AND",
                label: this.translate.instant("nms-table.filter.and"),
                selected: true
            },
            {
                value: "OR",
                label: this.translate.instant("nms-table.filter.or"),
                selected: false
            },
        ];

        this.initialSort = [
            {
                column: "deviceName",
                direction: "asc"
            },
            {
                column: "portViewName",
                direction: "asc"
            },
            {
                column: "onuId",
                direction: "asc"
            }
        ];
        this.options.serverPagination.filterParams = this.loadStateParams();
    }

    async ngOnInit() {
        this.monitoringSettings = await this.monitoringService.getMonitoringSettings();
    }

    ngAfterViewInit(): void {
        this.retrieveGponOnus = this.retrieveGponOnus.bind(this);
        this.subscribeTableChange();
    }

    ngOnDestroy(): void {
        this.nmsTableChangedSubscription.unsubscribe();
    }

    /**
     * Método responsável por escutar qualquer evento ocorrido na tabela, alteração nos filtros,
     * paginação, ordenação.
     */
    subscribeTableChange() {
        this.nmsTableChangedSubscription = this.nmsTableComponent.contentChangeEvent.subscribe((gponOnus) => {
            this.nmsTableComponent.applyLoading.next(true);
            const devicesIds = [...new Set(gponOnus.map(gpon => gpon.deviceId))];
            Promise.all(devicesIds.map(deviceId => {
                return this.getAvailableFeatures(deviceId).then((availableFeatures) => {
                    this.availableFeaturesByDevice.push({ deviceId: deviceId, features: availableFeatures });
                });
            })).then(() => {
                gponOnus.map((gpon) => this.createDeviceNameShortcuts(gpon));
                this.nmsTableComponent.applyLoading.next(false);
            });
        });
    }

    getDeviceDropdownShortcuts(gpon) {
        if (this.deviceNameShortcuts.length > 0) {
            return this.deviceNameShortcuts.find(shortcut => shortcut.deviceId === gpon.deviceId).shortcuts;
        }

        return [];
    }

    retrieveGponOnus(): Observable<Object> {
        return this.nmsTableComponent.getProvider()
    }

    /** Renderiza o auto-updater somente após a renderização da tabela */
    isTableLoaded() {
        return this.nmsTableComponent !== undefined;
    }

    goToOldUI() {
        this.gponOnusService.setGponOnuUiPreference({ ui: this.nmsStates.gponOnus });
        this.state.go(
            this.nmsStates.gponOnus,
            { hostname: "", device: null, filters: null, name: this.scope.$parent.$resolve.device },
            { inherit: true }
        );
    }

    processFilterChanges = (filterParameters: FilterColumnParameters) => {
        let requestFilters: RequestFilter = {};
        if (filterParameters.inputSearchs.length > 0) {
            requestFilters = filterParameters.inputSearchs.reduce((accumulator, current) => {
                return this.convertInputSearchToFilter(accumulator, current);
            }, {} as RequestFilter);
        }

        this.nmsTableComponent.filter(requestFilters);
    }

    private loadStateParams(): RequestFilter {
        if (this.scope.$parent.$resolve.device) {
            const inputChangeFilter: InputSearchChanged = {
                fieldName: "deviceName",
                fieldValue: `=${this.scope.$parent.$resolve.device}`
            };
            return this.convertInputSearchToFilter({}, inputChangeFilter);
        }
    }

    private buildZabbixLink(row: Object, column: string): string {
        let url = "";

        if (FIELD_URL_MAP[column]) {
            let value = row[FIELD_URL_MAP[column]] || "";
            url = value.replace(this.zabbixUrlPlaceholder, this.monitoringSettings.url);
        }

        return url;
    }

    private getAvailableFeatures(deviceId) {
        return new Promise((resolve) => {
            resolve(this.deviceDropdownModelService.getAvailableFeatures(deviceId));
        });
    }

    private openDeviceOnOnu = (gponOnu) => {
        var pathKeys = this.createOnuInfoConfigPathKeys(gponOnu);
        var schemaJsonPath = "$.containers.config>interface.lists.gpon.template.lists.onu.template";
        var displayName = gponOnu.portViewName + " onu " + gponOnu.onuId;

        var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
        this.devicesActionService.goToDeviceConfigWithParams(gponOnu.deviceName, params);
    };

    private createOnuInfoConfigPathKeys(gponOnu) {
        var portIdValues = gponOnu.portId.split("/");
        var portId: any = { "chassis-id": portIdValues[0], "slot-id": portIdValues[1], "port-id": portIdValues[2] };
        var onuId: any = { id: gponOnu.onuId };

        return [portId, onuId];
    }

    private createDeviceNameShortcuts(gpon) {
        const device = this.deviceDropdownModelService.createModelFromGponOnu(gpon);
        const availableFeatures = this.availableFeaturesByDevice
            .find(availableFeatureByDevice => availableFeatureByDevice.deviceId === gpon.deviceId);
        const deviceShortcuts = this.deviceDropDownShortcutsService
            .getFeaturesShortcuts(availableFeatures.features, device.name, true);

        if (deviceShortcuts.length > 0) {
            deviceShortcuts[deviceShortcuts.length - 1].hasDivider = true;
        }
        const shortcutsByEquipmentType = this.deviceDropDownShortcutsService.getShortcutsByEquipmentType(device, true);
        const allDeviceShortcuts = [...deviceShortcuts, ...shortcutsByEquipmentType];
        this.deviceNameShortcuts.push({ deviceId: device.id, shortcuts: allDeviceShortcuts });
    }

    private convertInputSearchToFilter(filters: RequestFilter, current: InputSearchChanged) {
        const { filterTypeSymbol, value } = this.splitInFilterTypeSymbolAndValue(current.fieldValue);
        return ({
            ...filters, [current.fieldName]: {
                pagingFilterType: this.getPagingFilterType(filterTypeSymbol, current.fieldValue),
                filterValue: value
            }
        });
    }

    /**
     * Separa o valor a ser filtrado e o símbolo que representa o tipo de comparação.
     *
     * Quando o valor não possui nenhum dos símbolos(>,<,=,!,%), exemplo "1/1/1", será
     * retorna um objeto padrão, com o valor do filtro, no seguinte formato {"''", "1/1/1"},
     * que representa o uso do 'CONTAINS'.
     *
     * @param fieldValue Valor inserido do campo de busca da coluna. Ex.: !10; %/12; >9; =DM1234
     * @returns Símbolo para comparação e valor a ser buscado. Ex.: {"=", "DM1234"}
     */
    private splitInFilterTypeSymbolAndValue(fieldValue: string) {
        const defaultFilterTypeRegExp = new RegExp(/^([>,<,=,!,%]{1,2})(.*\S)$/, "g");
        const endsWithFilterTypeRegExp = new RegExp(/^(.*\S)(.*%)$/, "g");
        let { filterTypeSymbol, value } = { filterTypeSymbol: "''", value: fieldValue };

        if (fieldValue.match(defaultFilterTypeRegExp)) {
            ([filterTypeSymbol, value] = this.splitByRegex(defaultFilterTypeRegExp, fieldValue));
        } else if (fieldValue.match(endsWithFilterTypeRegExp)) {
            ([value, filterTypeSymbol] = this.splitByRegex(endsWithFilterTypeRegExp, fieldValue));
        }

        return { filterTypeSymbol, value };
    }

    private splitByRegex(regExp: RegExp, value: string) {
        return value.split(regExp).filter((entry) => entry !== "");
    }

    private getPagingFilterType(symbol: string, fieldValue: string) {
        if (FILTER_TYPE_BY_SYMBOL[symbol]) {
            return FILTER_TYPE_BY_SYMBOL[symbol](fieldValue)
        }

        return PagingFilterType.CONTAINS;
    }

}
