"use strict";

import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

/**
* @ngdoc controller
* @name nms.management.ManageGroupsController
* @description Gerencia os grupos da aplicação.
*/
var app = angular.module("nms.management");

app.controller("ManageGroupsController", ["$rootScope", "$scope", "$translate", "NgTableParams", "TABLE_PROPERTIES",
    "TableFilterService", "UserPreferencesService", "ManageGroupsService", "ManageGroupsActionsService",
    "ManageUsersActionsService", "NmsToastrService",
    function($rootScope, $scope, $translate, NgTableParams, TABLE_PROPERTIES, TableFilterService, UserPreferencesService,
        ManageGroupsService, ManageGroupsActionsService, ManageUsersActionsService, nmsToastrService: NmsToastrService) {
        var availableFilterPropertiesKeys = ["globalFilter"];

        var translationKeys: any = {
            singleElement: "management.groups.tableCell.user",
            multiElements: "management.groups.tableCell.users"
        };

        $scope.MAX_USERS_PER_ROW = 20;
        $scope.searchTooltip = $translate.instant("tooltips.search.searchToolTip");
        $scope.globalFilter
            = UserPreferencesService.loadPreferences({}, "ManageGroupsGlobalFilter", availableFilterPropertiesKeys).globalFilter;

        $scope.columns = [
            {field: "name", width: "215"},
            {field: "numberUsers", width: "165"},
            {field: "users"}
        ];

        $scope.tableParams = new NgTableParams(
            {
                page: 1,
                count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
                sorting: {name: "asc"}
            },
            {
                dataset: [],
                filterOptions: {filterFn: TableFilterService.getFilter($scope.columns)}
            }
        );

        $scope.selection = {
            checked: [],
            table: $scope.tableParams
        };

        $scope.tableModel = {
            options: {
                translatePrefix: "management.groups.tableColumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        var init = function() {
            ManageGroupsService.getAll().then(function(groups) {
                $scope.tableParams.settings({dataset: groups});
            });
        };

        $scope.applyGlobalFilter = function() {
            angular.extend($scope.tableParams.filter(), {$: $scope.globalFilter});
        };

        /**
        * Retorna os usuários de um determinado grupo limitado por MAX_USERS_PER_ROW
        */
        $scope.getLimitedUsersPerRow = function(users) {
            return _.slice(users, 0, $scope.MAX_USERS_PER_ROW);
        };

        $scope.getRemainingUsersMessage = function(users) {
            if (users.length > $scope.MAX_USERS_PER_ROW) {
                var remainingElementsLength = users.length - $scope.MAX_USERS_PER_ROW;
                var label = remainingElementsLength === 1 ? translationKeys.singleElement : translationKeys.multiElements;

                var data: any = {
                    label: $translate.instant(label).replace("{0}", remainingElementsLength)
                };

                return _.template("+${label}")(data);
            }

            return "";
        };

        $scope.openAddGroupModal = function() {
            var callback = function(group) {
                $scope.tableParams.settings().dataset.push(group);
                $scope.tableParams.reload();
            };
            ManageGroupsActionsService.openAddGroupModal($scope.tableParams.data, callback);
        };

        /*
        * Abre a modal para edição de grupo.
        * O Parâmetro groupName é opcional. Ele é usado quando o método é chamado a partir de um click no nome de um grupo da
        * tabela. Quando disparado através do link editar na parte superior da tabela, a lógica levará em conta os elementos
        * contidos em $scope.selection.checked.
        */
        $scope.openEditGroupModal = function(groupName) {
            var callback = function(editedGroup) {
                var oldName = groupName || $scope.selection.checked[0].name;
                var editedGroupIndex = _.findIndex($scope.tableParams.settings().dataset, function(groupInTable) {
                    return groupInTable.name === oldName;
                });

                if (editedGroupIndex !== -1) {
                    $scope.tableParams.settings().dataset[editedGroupIndex].name = editedGroup.name;
                    $scope.tableParams.settings().dataset[editedGroupIndex].description = editedGroup.description;
                    $scope.tableParams.reload();
                }
            };

            var groupsNames = _.map($scope.tableParams.data, "name");

            if (groupName) {
                ManageGroupsActionsService.openEditGroupModal(groupsNames, groupName, callback);
            } else {
                ManageGroupsActionsService
                    .verifyAndOpenEditGroupModal(groupsNames, $scope.selection.checked, callback);
            }
        };

        $scope.openEditUserModal = function(user) {
            var callback = function(user) {
                init();
            };
            ManageUsersActionsService.openEditUserModal(user, callback);
        };

        $scope.verifyAndOpenRemoveGroupModal = function() {
            var callback = function(wasRemoved) {
                if (wasRemoved) {
                    const message = $translate.instant("userList.tableActions.remove.groups.success");
                    nmsToastrService.success(message);
                    init();
                }
            };
            ManageGroupsActionsService.verifyAndOpenRemoveGroupModal($scope.tableModel.tableParams.settings().dataset,
                $scope.selection.checked, callback);
        };

        $scope.$on("$destroy", function() {
            var globalFilter: any = {globalFilter: $scope.globalFilter};
            UserPreferencesService.savePreferences(globalFilter, "ManageGroupsGlobalFilter", availableFilterPropertiesKeys);
        });

        init();
    }
]);
