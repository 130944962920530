"use strict";

/**
* @ngdoc directive
* @name nms.directive:deviceDropdown
* @description Diretiva para criar componente para representação de equipamentos com menu dropdown padrão.
*   @param {string} label é opcional e quando não passado como parâmetro na diretiva, será utilizada a informação de device.name.
*/
angular.module("nms")
.directive("deviceDropdown", ["DeviceDropdownShortcutsService", "BROADCAST_EVENTS",
    function(DeviceDropdownShortcutsService, BROADCAST_EVENTS) {
        return {
            restrict: "E",
            transclude: true,
            templateUrl: "templates/components/ui/device/dropdown/device-dropdown.html",
            scope: {
                device: "=?",
                deviceProvider: "&?",
                additionalShortcuts: "=?",
                availableFeaturesProvider: "&?",
                label: "=?"
            },
            link: function(scope, element, attrs) {
                var contentElement = angular.element(element[0].querySelector(".device-dropdown-content"))[0];
                scope.hasTranscludeContent = _.size(contentElement.children) > 0;
                scope.showDropdown = false;

                function addDivider(shortcuts) {
                    if (_.size(shortcuts) > 0) {
                        shortcuts[shortcuts.length - 1].hasDivider = true;
                    }
                }

                function createShortcuts(device) {
                    const openNewTab = "true" === attrs["openLinksInNewTab"];
                    const featureShortcuts = DeviceDropdownShortcutsService.getFeaturesShortcuts(
                        device.availableFeatures , scope.model.name, openNewTab);
                    const deviceShortcuts = DeviceDropdownShortcutsService.getShortcutsByEquipmentType(device, openNewTab);

                    if (scope.additionalShortcuts) {
                        deviceShortcuts.push(...scope.additionalShortcuts);
                    }

                    addDivider(featureShortcuts);

                    scope.shortcuts = [...featureShortcuts, ...deviceShortcuts];
                    scope.showDropdown = true;
                }

                function initialize(device) {
                    scope.model = device;
                    scope.label = scope.label || device.name;

                    const featuresProvider = scope.availableFeaturesProvider ? scope.availableFeaturesProvider() : null;

                    Promise.resolve(featuresProvider).then((availableFeatures) => {
                        device.availableFeatures = availableFeatures || device.availableFeatures;
                        createShortcuts(device);
                    });
                }

                if (scope.device) {
                    initialize(scope.device);
                } else if (_.isFunction(scope.deviceProvider)) {
                    const provided = scope.deviceProvider();
                    initialize(provided);
                }

                var unregisterDevicePropertiesChangeListener = scope.$on(BROADCAST_EVENTS.DEVICE_NAME_CHANGED,
                    function(event, payload) {
                        if (scope.model.id === payload.device.id) {
                            scope.model.name = payload.device.name;
                        }
                    }
                );

                scope.$on("$destroy", unregisterDevicePropertiesChangeListener);
            }
        };
    }
]);
