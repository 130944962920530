import { Component, Inject, OnDestroy, OnInit, HostListener } from "@angular/core";
import { NmsDialogModel, NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { TranslatedText, TranslationKey } from "@nms-ng2/app/shared/models/translation.models";
import {
    NotificationFilter,
    DailyNotificationsMessage,
    NotificationSummary,
    NotificationRequest,
    NotificationStateChangeEvent
} from "@nms-ng2/app/shared/services/nms-notification/nms-notification.models";
import { NmsNotificationService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification.service";
import {
    NotificationPreferencesService
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences.service";
import { ANGULARJS_TRANSLATE, NMS_STATES, STATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SimpleModalComponent } from "ngx-simple-modal";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import { Observable, Subscription } from "rxjs";
import { NmsNotificationUtilsService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-utils.service";
import { NmsNotificationFeedbackService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-feedback.service";

interface DeletionConfig {
    confirmationCallback: () => Observable<void>;
    titleKey?: TranslationKey;
    titleDialog?: TranslatedText;
    descriptionKey: string;
}

/**
 * Modal de notificações.
 */
@Component({
    selector: "notifications-modal",
    templateUrl: "./notifications-modal.component.html",
    styleUrls: ["./notifications-modal.component.scss"]
})
export class NotificationsModalComponent extends SimpleModalComponent<any, any> implements OnInit, OnDestroy {
    /**
     * Utilizada no scroll infinito no HTML.
     * O throttle assegura que eventos de rolagem (scroll) não sejam disparados com uma frequência
     * maior do que o valor definido em milissegundos.
     * O scrollDistance e o scrollUpDistance ajustam o comportamento com base na direção da rolagem,
     * permitindo definir diferentes distâncias para ativação de eventos dependendo se a rolagem é para cima ou para baixo.
     */
    throttle = 300;
    scrollDistance = 1;
    scrollUpDistance = 2;

    private readonly deletionConfigMap: { [notificationFilter in NotificationFilter]: DeletionConfig };
    notificationFilterOrder: Array<NotificationFilter> =
        [NotificationFilter.ALL, NotificationFilter.READ, NotificationFilter.UNREAD];
    notificationSummary: NotificationSummary = {
        all: 0,
        unread: 0,
        read: 0
    };
    doNotDisturb: boolean = false;
    goToConfigPageTitle: string;
    goToConfigPageMessage: string;
    goToMarkAllAsReadTitle: string;
    goToMarkAllAsUnreadTitle: string;
    selectedFilter: NotificationFilter = NotificationFilter.ALL;
    dailyNotificationsMessage: Array<DailyNotificationsMessage> = new Array<DailyNotificationsMessage>();
    markAllAsUnreadMessage: string;
    markAllAsUnreadNoReadNotificationsMessage: string;
    markAllAsUnreadViewModeNoReadNotificationsMessage: string;
    markAllAsReadMessage: string;
    markAllAsReadNoUnreadNotificationsMessage: string;
    markAllAsReadViewModeNoUnreadNotificationsMessage: string;
    isLoading: boolean = true;
    updateNotificationSeenEvent: Subscription = new Subscription();
    removeNotificatonEvent: Subscription = new Subscription();

    private paginationControl: NotificationRequest = {
        pagingRequest: {
            pageNumber: 0,
            pageSize: 10
        }
    };

    private totalPages: number;

    constructor(private notificationPreferencesService: NotificationPreferencesService,
            private nmsNotificationService: NmsNotificationService,
            private nmsDialogService: NmsDialogService,
            private toastService: NmsToastrService,
            private translationHelperService: TranslationHelperService,
            private nmsNotificationUtilsService: NmsNotificationUtilsService,
            private nmsNotificationFeedbackService: NmsNotificationFeedbackService,
            @Inject(STATE) private $state: any,
            @Inject(NMS_STATES) private nmsStates: any,
            @Inject(ANGULARJS_TRANSLATE) private translate: any) {
        super();
        const deleteModalTitle = this.translate.instant("nms-notification.delete.modal.title");

        this.deletionConfigMap = {
            [NotificationFilter.ALL]: {
                confirmationCallback: () => this.nmsNotificationService.deleteAllNotifications(),
                titleDialog: deleteModalTitle,
                descriptionKey: "nms-notification.delete.all.modal.message"
            },
            [NotificationFilter.READ]: {
                confirmationCallback: () => this.nmsNotificationService.deleteAllReadNotifications(),
                titleDialog: deleteModalTitle,
                descriptionKey: "nms-notification.delete.all.read.modal.message"
            },
            [NotificationFilter.UNREAD]: {
                confirmationCallback: () => this.nmsNotificationService.deleteAllUnreadNotifications(),
                titleDialog: deleteModalTitle,
                descriptionKey: "nms-notification.delete.all.unread.modal.message"
            }
        };
    }

    async ngOnInit(): Promise<void> {
        this.loadTranslations();
        await this.loadInitialData();
        this.updateNotificationSeenEvent = this.nmsNotificationFeedbackService.updateNotificationSeen.subscribe(() => {
            this.updateFilter(this.selectedFilter);
        });
        this.removeNotificatonEvent = this.nmsNotificationService.removeItemList
            .subscribe((event: NotificationStateChangeEvent) => {
                this.handleNotificationDeleteOrReadStateChange(event);
            });
    }

    formatDateDescription(dateString: string): string {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "2-digit",
        };
        const normalizeTimezone = true;
        return this.translationHelperService.formatDateDescription(date, options, normalizeTimezone);
    }

    getEmptyMessage(): string {
        const message = `nms-notification.list.empty.${this.selectedFilter.toLowerCase()}`;

        return this.translate.instant(message);
    }

    isEmptyList(): boolean {
        return this.dailyNotificationsMessage.length === 0 && this.notificationSummary[this.selectedFilter] === 0;
    }

    setDoNotDisturb(doNotDisturb: boolean) {
        this.notificationPreferencesService.setDoNotDisturb(doNotDisturb).subscribe();
    }

    openConfiguration() {
        this.nmsDialogService.openDialog(
            {
                title: this.goToConfigPageTitle,
                description: this.goToConfigPageMessage,
                isConfirm: true
            }
        ).then(() => {
            this.closeModal();
            this.$state.go(this.nmsStates.userManagement.preferences);
        });
    }

    scrollDown(): void {
        this.paginationControl.pagingRequest.pageNumber++;

        if (this.paginationControl.pagingRequest.pageNumber < this.totalPages) {
            this.fetchData(this.selectedFilter);
        }
    };

    async closeModal(){
        await this.close();
        this.nmsNotificationUtilsService.notificationCentralCloseEvent.emit(true);
    }

    async deleteAll() {
        const notificationAmount = this.notificationSummary[this.selectedFilter];
        let deletionConfig = this.deletionConfigMap[this.selectedFilter];

        if (notificationAmount > 1) {
            const notificationAmountStr = notificationAmount.toString();
            let {
                confirmationCallback,
                titleKey,
                titleDialog,
                descriptionKey
            } = deletionConfig;
            const title = titleDialog || this.translate.instant(titleKey);
            const description = this.translationHelperService.translateWithReplacement(descriptionKey, notificationAmountStr);

            this.nmsDialogService.openDialog({
                    title,
                    description,
                    isConfirm: true
                })
                .then(async () => {
                    try {
                        await confirmationCallback().toPromise();
                        this.notificationSummary = await this.nmsNotificationService.getNotificationSummary().toPromise();
                        this.updateFilter(this.selectedFilter);
                        this.toastService.success(this.translate.instant("nms-notification.toastr.multiple.remove.success"));
                    } catch {
                        this.toastService.error(this.translate.instant("nms-notification.toastr.multiple.remove.error"));
                    }
                });
        } else {
            await deletionConfig.confirmationCallback().toPromise();
            this.updateFilter(this.selectedFilter);
        }
    }

    markAllReadUnread(read: boolean) {
        const isMultiple = (read && this.notificationSummary.unread > 1) || (!read && this.notificationSummary.read > 1);
        if (isMultiple) {
            const { title, description } = this.getDialogMarkReadUnreadConfig(read);
            const ammount = read ? this.notificationSummary.unread.toString() : this.notificationSummary.read.toString();
            this.nmsDialogService.openDialog(
                {
                    title,
                    description,
                    isConfirm: true
                }
            ).then(() => {
                this.callReadUnreadAll(read, isMultiple, ammount);
            });
        } else {
            this.callReadUnreadAll(read, isMultiple);
        }
    }

    async updateFilter(filter: NotificationFilter) {
        this.paginationControl.pagingRequest.pageNumber = 0;
        this.isLoading = true;
        this.dailyNotificationsMessage.splice(0, this.dailyNotificationsMessage.length);
        await this.fetchData(filter);
        this.isLoading = false;
    }

    async fetchData(filter: NotificationFilter): Promise <void> {
        const requestWithFilter = {
            ...this.paginationControl,
            filterRequest: {
                read: NotificationFilter.READ === filter
            }
        };
        const notificationRequest: NotificationRequest = filter === NotificationFilter.ALL
            ? this.paginationControl : requestWithFilter;
        this.selectedFilter = filter;
        this.notificationSummary = await this.nmsNotificationService.getNotificationSummary().toPromise();
        const result = await this.nmsNotificationService.getNotificationsList(notificationRequest).toPromise();
        result.pageElements.forEach((item) => {
            let scrollNotification = this.dailyNotificationsMessage.filter((element) => {
                return element.createdAt === item.createdAt;
            });

            if (scrollNotification.length > 0) {
                scrollNotification[0].notifications.push(...item.notifications);
            } else {
                this.dailyNotificationsMessage.push(item);
            }
        });

        this.totalPages = result.totalPages;
    }

    isMarkAsReadDisabled(): boolean {
        const unreadNotificationsAmount = this.notificationSummary[NotificationFilter.UNREAD];
        return this.isMarkAsReadUnreadDisabled(NotificationFilter.READ, unreadNotificationsAmount);
    }

    isMarkAsUnreadDisabled(): boolean {
        const readNotificationsAmount = this.notificationSummary[NotificationFilter.READ];
        return this.isMarkAsReadUnreadDisabled(NotificationFilter.UNREAD, readNotificationsAmount);
    }

    getMarkAsUnreadTooltip(): string {
        let readNotificationsAmount = this.notificationSummary[NotificationFilter.READ];

        if (this.selectedFilter !== NotificationFilter.UNREAD && readNotificationsAmount === 0) {
            return this.markAllAsUnreadNoReadNotificationsMessage;
        } else if (this.selectedFilter === NotificationFilter.UNREAD) {
            return this.markAllAsUnreadViewModeNoReadNotificationsMessage;
        }

        return this.markAllAsUnreadMessage;
    }

    getMarkAsReadTooltip(): string {
        const unreadNotificationsAmount = this.notificationSummary[NotificationFilter.UNREAD];

        if (this.selectedFilter !== NotificationFilter.READ && unreadNotificationsAmount === 0) {
            return this.markAllAsReadNoUnreadNotificationsMessage;
        } else if (this.selectedFilter === NotificationFilter.READ) {
            return this.markAllAsReadViewModeNoUnreadNotificationsMessage;
        }

        return this.markAllAsReadMessage;
    }

    /**
     * Trata a exibição das notificações seguindo as seguintes regras:
     *
     * - Ao clicar no botão de excluir, remove a notificação da lista, após ocorrer a animação.
     * - Ao clicar no ícone "marcar como lida/não lida" com o filtro ativo "Todas", altera
     * o estado da notificação (lida <-> não lida) imediatamente (nesse caso a animação não ocorre).
     * - Ao clicar no ícone "marcar como lida/não lida" com o filtro ativo "Lidas" ou "Não lidas",
     * remove a notificação da lista após a animação
     * - Caso após qualquer uma das ações acima, o grupo (dia) ficar vazio, o mesmo também é removido.
     *
     * @param event - Evento de mudança de estado ou remoção da notificação
     */
    private async handleNotificationDeleteOrReadStateChange(event: NotificationStateChangeEvent): Promise<void> {
        this.dailyNotificationsMessage.forEach((dailyNotification, index) => {
            const notificationIndex = dailyNotification.notifications.findIndex((notification) =>
                event.notificationId === notification.id
            );
            const isNotificationFromThisDay = notificationIndex !== -1;

            if (isNotificationFromThisDay) {
                if (event.runFadeOutAnimation) {
                    dailyNotification.notifications.splice(notificationIndex, 1);

                    if (dailyNotification.notifications.length === 0) {
                        this.dailyNotificationsMessage.splice(index, 1);
                    }
                } else {
                    dailyNotification.notifications[notificationIndex].read = event.read;
                }
            }
        });

        this.notificationSummary = await this.nmsNotificationService.getNotificationSummary().toPromise();
    }

    private loadTranslations() {
        this.goToConfigPageTitle = this.translate.instant("nms-notification.configurations.modal.title");
        this.goToConfigPageMessage = this.translate.instant("nms-notification.configurations.modal.confirm.message");
        this.goToMarkAllAsReadTitle = this.translate.instant("nms-notification.mark.all.as.read");
        this.goToMarkAllAsUnreadTitle = this.translate.instant("nms-notification.mark.all.as.unread");
        this.markAllAsUnreadMessage = this.translate.instant("nms-notification.mark.all.as.unread.tooltip");
        this.markAllAsUnreadNoReadNotificationsMessage =
            this.translate.instant("nms-notification.mark.all.as.unread.no.read.notifications.message");
        this.markAllAsUnreadViewModeNoReadNotificationsMessage =
            this.translate.instant("nms-notification.mark.all.as.unread.view.mode.no.read.notifications.message");
        this.markAllAsReadMessage = this.translate.instant("nms-notification.mark.all.as.read.tooltip");
        this.markAllAsReadNoUnreadNotificationsMessage =
            this.translate.instant("nms-notification.mark.all.as.read.no.unread.notifications.message");
        this.markAllAsReadViewModeNoUnreadNotificationsMessage =
            this.translate.instant("nms-notification.mark.all.as.read.view.mode.no.unread.notifications.message");
    }

    private async loadInitialData() {
        this.doNotDisturb = await this.notificationPreferencesService.getDoNotDisturb().toPromise();
        this.notificationSummary = await this.nmsNotificationService.getNotificationSummary().toPromise();
        const result = await this.nmsNotificationService.getNotificationsList(this.paginationControl).toPromise();
        this.totalPages = result.totalPages;
        this.dailyNotificationsMessage = result.pageElements;

        this.isLoading = false;
    }

    private isMarkAsReadUnreadDisabled(notificationFilter: NotificationFilter, notificationAmount: number): boolean {
        if (this.selectedFilter !== notificationFilter) {
            return notificationAmount === 0;
        }

        return true;
    }

    private getDialogMarkReadUnreadConfig(read: boolean): NmsDialogModel {
        return {
            title: this.getMarkConfirmationTitle(read),
            description: this.getMarkConfirmationDescription(read)
        };
    }

    private getMarkConfirmationTitle(read: boolean): string {
        return (read)
            ? this.goToMarkAllAsReadTitle
            : this.goToMarkAllAsUnreadTitle;
    }

    private getMarkConfirmationDescription(read: boolean): string {
        const markAllAsRead: TranslationKey = "nms-notification.mark.all.as.read.modal.confirm.message";
        const markAllAsUnread: TranslationKey = "nms-notification.mark.all.as.unread.modal.confirm.message";

        return (read)
            ? this.translationHelperService.translateWithReplacement(markAllAsRead, this.notificationSummary.unread.toString())
            : this.translationHelperService.translateWithReplacement(markAllAsUnread, this.notificationSummary.read.toString());
    }

    private async callReadUnreadAll(read: boolean, isMultiple: boolean, ammount: string = ""): Promise<void> {
        try {
            if (read) {
                await this.nmsNotificationService.setAllRead().toPromise();
            } else {
                await this.nmsNotificationService.setAllUnread().toPromise();
            }

            this.updateFilter(this.selectedFilter);
            this.toastService.success(this.getMarkAllReadUnreadActionFeedback(read, ammount, isMultiple));

        } catch {
            const isError = true;
            this.toastService.error(this.getMarkAllReadUnreadActionFeedback(read, ammount, isMultiple, isError));
        }

    }

    private getMarkAllReadUnreadActionFeedback(read: boolean, ammount: string = "", isMultiple: boolean = false,
        isError: boolean = false): string {
        const ammountStrPart = isMultiple ? "multiple" : "single";
        const messageTypeSuffix = isError ? "error" : "success";
        const readUnreadStrPart = read ? "read" : "unread";

        const messageKey = `nms-notification.toastr.${ammountStrPart}.mark.${readUnreadStrPart}.${messageTypeSuffix}`

        return this.translationHelperService.translateWithReplacement(messageKey, ammount);
    }

    ngOnDestroy() {
        this.updateNotificationSeenEvent.unsubscribe();
        this.removeNotificatonEvent.unsubscribe();
    }
}
