import {
    Component,
    OnDestroy,
    Input,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    OnInit,
    NgZone
} from "@angular/core";
import { Subject, Subscription } from "rxjs";

export interface NmsCounterModel {
    /** Indica a partir de onde a contagem começa. */
    countStart: number;
    /** Indica onde a contagem deve terminar. Se não for informado, não emitirá evento de contagem terminada. */
    countEnd?: number;
}

export enum TIME_UNIT {
    MINUTES = "minutes",
    SECONDS = "seconds"
}

/** Componente para realizar contagem. */
@Component({
  selector: "nms-counter",
  templateUrl: "./nms-counter.component.html",
  styleUrls: ["./nms-counter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NmsCounterComponent implements OnInit, OnDestroy {

    /** Notifica o início do contador. */
    @Input()
    startNotifier: Subject<NmsCounterModel>;

    /** Notifica a finalização do contador. */
    @Input()
    stopNotifier: Subject<NmsCounterModel>;

    /** Notifica a pausa do contador. */
    @Input()
    pauseNotifier: Subject<boolean>;

    /** Indica se a contagem é regressiva. */
    @Input()
    isCountdown?: boolean = false;

    /** Chave de tradução para segundos. */
    @Input()
    secondsTranslationKey?: string = "nms.counter.seconds";

    /** Chave de tradução para minutos. */
    @Input()
    minutesTranslationKey?: string = "nms.counter.minutes";

    @Output()
    onCountEnded = new EventEmitter<boolean>();

    /** Define a unidade de tempo que o contador utilizará. Default: Minutos. */
    @Input()
    timeUnit?: TIME_UNIT = TIME_UNIT.MINUTES;

    /** Indica se a contagem está em progresso. */
    isCounting: boolean;
    /** Variável de controle para contagem em segundos. */
    count: number;
    /** Variável de controle para limite de contagem em segundos. */
    countEnd: number;
    /** Quantidade de minutos. */
    minutes: number;
    /** Quantidade de segundos. */
    seconds: number;
    /** Identificador do timer. */
    interval: any;
    /** indica se o timer está pausado. */
    isPaused: boolean;

    startNotifierSubscription: Subscription;
    stopNotifierSubscription: Subscription;
    pauseNotifierSubscription: Subscription;

    constructor(private changeDetector: ChangeDetectorRef, private ngZone: NgZone) {
        this.restartCounter();
    }

    ngOnInit() {
        this.startNotifierSubscription = this.startNotifier.subscribe((countModel: NmsCounterModel) => {
            this.count = countModel.countStart;
            this.minutes = this.calculateMinutes();
            this.seconds = this.calculateSeconds();
            this.countEnd = this.isCountdown && !countModel.countEnd ? 0 : countModel.countEnd;
            let countAction = this.isCountdown ? this.countDown : this.countUp;

            this.verifyChangeDetector();

            this.startCounter(countAction);
        });

        this.stopNotifierSubscription = this.stopNotifier.subscribe(() => {
            this.restartCounter();
            this.verifyChangeDetector();
        });

        this.pauseNotifierSubscription = this.pauseNotifier.subscribe((value) => {
            this.isPaused = value;
        });
    }

    startCounter = (count: Function): void => {
        this.isCounting = true;
        this.verifyChangeDetector();
        let startTime: number = Date.now();
        clearInterval(this.interval)

        this.ngZone.runOutsideAngular(() => {
            this.interval = setInterval(() => {
                if (!this.isPaused) {
                    let currentTime: number = Date.now();
                    let passedTimeInSeconds: number = Math.floor((currentTime - startTime) / 1000);
                    count(passedTimeInSeconds);
                    this.minutes = this.calculateMinutes();
                    this.seconds = this.calculateSeconds();
                    this.verifyChangeDetector();
                    this.hasCountEnded();
                    startTime = currentTime;
                }
            }, 1000);
        });
    }

    /**
     * Realiza a contagem.
     */
    private countUp = (passedTimeInSeconds: number): void => {
        this.count = this.count + passedTimeInSeconds;
    }

    /**
     * Realiza a contagem regressiva.
     */
    private countDown = (passedTimeInSeconds: number): void => {
        this.count = this.count - passedTimeInSeconds;
    }

    /**
     * Calcula os minutos com base no tempo de contagem.
     */
    private calculateMinutes = (): number => {
        return Math.floor(this.count / 60);
    }

    /**
     * Calcula os segundos com base no tempo de contagem e nos minutos.
     */
    private calculateSeconds = (): number => {
        return Math.floor(this.count - this.minutes * 60);
    }

    /**
     * Zera as variáveis de controle da contagem.
     */
    private restartCounter = (): void => {
        this.isCounting = false;
        this.count = 0;
        this.minutes = 0;
        this.seconds = 0;
        clearInterval(this.interval);
    }

    private hasCountEnded = (): void => {
        if (this.countEnd !== undefined && this.count <= this.countEnd) {
            this.stopNotifier.next();
            this.onCountEnded.emit(true);
        }
    }

    /**
     * Verifique se o ChangeDetectorRef foi destruído
     * antes de executar o método detectChanges.
     */
    private verifyChangeDetector() {
        if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.changeDetector.detach();
        clearInterval(this.interval);
        this.startNotifierSubscription.unsubscribe();
        this.stopNotifierSubscription.unsubscribe();
    }
}
