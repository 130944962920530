import { Component, Inject, Input, OnInit } from "@angular/core";
import { SnmpTrap, SnmpTrapType, Trigger } from "../triggers.models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Componente para agendamento de eventos a partir das traps.
 */
@Component({
    selector: "snmp-trap-trigger",
    templateUrl: "./snmp-trap-trigger.component.html",
    styleUrls: ["./snmp-trap-trigger.component.scss"]
})
export class SnmpTrapTriggerComponent implements OnInit {

    @Input("snmpTrapTrigger")
    trigger: Trigger;
    @Input()
    hasSchedulerPermission: boolean;
    @Input()
    hasTrapVariableAssociation: boolean;
    snmpTraps: SnmpTrapType[];

    constructor(@Inject(ANGULARJS_TRANSLATE) private translate: any) {}

    ngOnInit(): void {
      this.snmpTraps = this.loadSnmpTrapsData();
      this.trigger.triggerEnabled = this.trigger.triggerEnabled || this.hasTrapVariableAssociation;
    }

    loadSnmpTrapsData(): SnmpTrapType[] {
        return Object.keys(SnmpTrap).map((key) => {
            const snmpTrap = key as SnmpTrap;
            return {
                snmpTrap,
                label: this.translate.instant("scheduler.trap.trigger." + snmpTrap)
            }
        });
    }

    getSnmpTrapCheckboxTooltip(): string {
        const messageKey =  this.hasTrapVariableAssociation
            ? "scheduler.trap.trigger.check.toolTip.disabled"
            : "templateinstanceform.userHasNoPermission";
        return this.translate.instant(messageKey);
    }
}
