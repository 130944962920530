import { AfterViewInit, Component, Inject, Injector, OnDestroy, OnInit } from "@angular/core";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import {
    NotificationGroup,
    NotificationPreferences,
    NotificationPreferencesByGroup,
    NotificationPreferencesGroupTitle,
    NotificationPreferencesRequest,
    NotificationPreferencesResponse,
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences-model";
import {
    NotificationPreferencesService
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences.service";
import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
    LDAP_MODE,
    MANAGER_USERS_SERVICE,
    SYSTEM_USER_CONFIGURATION_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import {
    GeneralUserPreferencesConfigComponent
} from "./preferences/general-user-preferences-config.component";
import {
    NotificationsUserPreferencesConfigComponent
} from "./preferences/notifications-user-preferences-config.component";
import { UserDataComponent } from "./user-data/user-data.component";
import { Subscription } from "rxjs";
import { UserConfigPreferencesPanel } from "./user-preferences.models";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { UserConfigPreferencesService } from "./user-config-preferences.service";
import { UserPasswordManagementComponent } from "./user-password-management/password-management-component";
import { debounceTime } from "rxjs/operators";

/**
 * Classe responsável por exibir as preferências de usuário.
 */
@Component({
    selector: "user-preferences",
    templateUrl: "./user-preferences.component.html",
    styleUrls: ["./user-preferences.component.scss"]
})
export class UserPreferencesComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly PURGE_OLDER_IN_DAYS_DEFAULT = 7;

    doNotDisturb: boolean;
    currentNotificationsByGroup: NotificationPreferencesByGroup;
    notificationsByGroupCache: NotificationPreferencesByGroup;
    notificationGroupsTitles: NotificationPreferencesGroupTitle;
    purgeOlderInDays: number = this.PURGE_OLDER_IN_DAYS_DEFAULT;
    panelData: { component: any; dynamicInjector: Injector; title: string };
    userData: { name: string; email: string; phone: string, user: string } = { name: "", email: "", phone: "", user: "" };
    activePanel: string;
    panelsList: Array<UserConfigPreferencesPanel>;
    getPreferencesSubscription: Subscription;
    formChangesSubscription: Subscription;
    userConfigPreferencesFormGroup: FormGroup;
    showSaveTip: boolean = false;
    doNotDisturbCache: boolean;
    private isLdapMode: boolean = false;
    private generalDataHasChange: boolean = false;
    private passwordDataHasChange: boolean = false;
    private hasSomeNotificationConfigChange: boolean = false;
    private accountDataHasChange: boolean = false;

    constructor(private readonly notificationPreferencesService: NotificationPreferencesService,
                @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
                @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
                private readonly toastr: NmsToastrService,
                private readonly window: Window,
                private readonly injector: Injector,
                private fb: FormBuilder,
                private nmsDialogservice: NmsDialogService,
                private userConfigPreferencesService: UserConfigPreferencesService,
                @Inject(MANAGER_USERS_SERVICE) private managerUsersService: any,
                @Inject(SYSTEM_USER_CONFIGURATION_SERVICE) private systemUserConfigService: any,
                @Inject(LDAP_MODE) private LDAP_MODE: any
    ) {
        this.userConfigPreferencesFormGroup = this.fb.group({});
    }

    ngOnInit(): void {
        this.systemUserConfigService.getLdapMode().then((ldapMode) => {
            this.isLdapMode = ldapMode === this.LDAP_MODE.AUTHORIZATION;
            this.getPreferencesSubscription = this.notificationPreferencesService.getPreferences()
                .subscribe((notificationPreferencesResponse: NotificationPreferencesResponse) => {
                    const { doNotDisturb, notificationPreferences, purgeOlderInDays } = notificationPreferencesResponse;
                    this.userConfigPreferencesService.setNotificationsDescription(notificationPreferences);
                    this.doNotDisturb = doNotDisturb;
                    this.doNotDisturbCache = this.doNotDisturb;
                    this.currentNotificationsByGroup = notificationPreferences;
                    this.notificationsByGroupCache = angular.copy(this.currentNotificationsByGroup);
                    this.notificationGroupsTitles = this.getGroupTitles(this.currentNotificationsByGroup);
                    this.purgeOlderInDays = purgeOlderInDays;
                    this.fillTabs();
                    this.panelsList[0].onClick();
                });
        });
    }

    ngAfterViewInit(): void {
        this.formChangesSubscription = this.userConfigPreferencesFormGroup.valueChanges
            .pipe(debounceTime(500))
            .subscribe(() => this.showSaveTip = this.hasConfigChanges());
    }

    ngOnDestroy(): void {
        this.formChangesSubscription.unsubscribe();
        this.getPreferencesSubscription.unsubscribe();
    }

    setPanelActive() {
        this.panelsList.forEach((panel) => {
            panel.hasActiveItem = false;
            const hasItem = panel.tabs.find((tab) => tab.id === this.activePanel);
            if (hasItem) {
                panel.hasActiveItem = true;
            }
        });
    }

    saveUserPreferences(): void {
        if (this.userConfigPreferencesFormGroup.invalid) {
            this.highlightTabsWithErrorAndShowMessage();
            return;
        }

        if (!this.hasConfigChanges()) {
            this.rootScope.showDialog({
                type: "alert",
                translateKey: "manage.user.configurations.no.config.changed"
            });
        } else {
            const saveUserAccount = this.passwordDataHasChange || this.accountDataHasChange
                ? this.getManagerUserSaveRequest()
                : Promise.resolve({
                    tabId: "userAccount",
                    isError: false,
                    isRequested: false
                });

            const saveNotificationsPrefs = this.hasSomeNotificationConfigChange || this.generalDataHasChange
                ? this.getUserNotificationsPreferencesSaveRequest()
                : Promise.resolve({
                    tabId: "notifications",
                    isError: false,
                    isRequested: false
                });

            Promise.all([saveUserAccount, saveNotificationsPrefs])
                .then(results => {
                    const errors = results.filter((result) => result.isError);
                    const requested = results.filter((result) => result.isRequested);

                    if (errors.length === 0) {
                        this.toastr.success(this.translate.instant("manage.user.configurations.saved.successfully"));
                        this.panelsList.forEach(panel => panel.tabs.forEach((tab) => tab.hasError = false));
                        this.showSaveTip = false;
                    } else if (errors.length === 1 && errors[0].tabId === "userAccount") {
                        const titleKey = requested.length > 1
                            ? "manage.user.configurations.partial.save.error.title"
                            : "manage.user.configurations.user.account.save.error.title";

                        this.toastr.error(
                            this.translate.instant("manage.user.configurations.user.account.save.error.description"),
                            this.translate.instant(titleKey)
                        );
                    } else {
                        this.toastr.error(this.translate.instant("manage.user.configurations.save.error"));
                    }
                });
        }
    }

    confirmAndCancel(): void {
        this.showEditionDiscardConfirmationMessage(() => {
            this.window.history.back();
        });
    }

    private getManagerUserSaveRequest() {
        const userData = this.userConfigPreferencesFormGroup.controls["accountData"].value;
        const passwordData = this.passwordDataHasChange
            ? this.userConfigPreferencesFormGroup.controls["changePassword"].value
            : {password: "", confirmPassword: ""};

        return this.managerUsersService.editBasic({ ...userData, ...passwordData})
                .then(() => {
                    this.userData = userData;

                    if (this.userConfigPreferencesFormGroup.controls["changePassword"]) {
                        this.userConfigPreferencesFormGroup.controls["changePassword"]
                            .patchValue({currentPassword: "", password: "", confirmPassword: ""});
                    }

                    return {
                        tabId: "userAccount",
                        isError: false,
                        isRequested: true
                    }
                })
                .catch(error => {
                    if (error.data && error.data["invalid-current-password-error"]) {
                        this.panelsList.forEach(panel => {
                            const passwordTab = panel.tabs.find(tab => tab.id === "changePassword");
                            if (passwordTab) {
                                passwordTab.hasError = true;
                            }
                        });
                    }
                    return {
                        tabId: "userAccount",
                        isError: true,
                        isRequested: true
                    }
                });
    }

    private getUserNotificationsPreferencesSaveRequest() {
        let doNotDisturb = this.doNotDisturb;
        let purgeOlderInDays = this.purgeOlderInDays;

        if (this.userConfigPreferencesFormGroup.controls["general"]) {
            ({ doNotDisturb, purgeOlderInDays } = this.userConfigPreferencesFormGroup.controls["general"].value);
        }

        const notificationPreferenceRequest: NotificationPreferencesRequest = {
            doNotDisturb,
            purgeOlderInDays,
            notificationPreferences: this.getAllNotifications(this.notificationsByGroupCache)
        };

        return this.notificationPreferencesService.setPreferences(notificationPreferenceRequest).toPromise()
                .then(() => {
                    this.doNotDisturb = notificationPreferenceRequest.doNotDisturb;
                    this.doNotDisturbCache = this.doNotDisturb;
                    this.purgeOlderInDays = notificationPreferenceRequest.purgeOlderInDays;
                    this.currentNotificationsByGroup = angular.copy(this.notificationsByGroupCache);
                    return {
                        tabId: "notifications",
                        isError: false,
                        isRequested: true
                    }
                })
                .catch(error => {
                    return {
                        tabId: "notifications",
                        isError: true,
                        isRequested: true
                    }
                });
    }

    private fillTabs() {
        const notificationsTabs = this.getNotificationsTabs();
        this.panelsList = [
            {
                id: "userInfoHeaderTab",
                expansionTitle: this.translate.instant("manage.user.account.preferences.title"),
                expanded: true,
                onClick: () => {
                    const title = this.translate.instant("manage.user.account.preferences.account.data");
                    const tabId = "accountData";
                    this.showUserData(title, tabId);
                    this.setPanelActive();
                },
                tabs: [
                    {
                        onClick: (title, tabId) => this.showUserData(title, tabId),
                        id: "accountData",
                        title: this.translate.instant("manage.user.account.preferences.account.data")
                    },
                    {
                        onClick: (title, tabId) => this.showChangePassword(title, tabId),
                        id: "changePassword",
                        title: this.translate.instant("manage.user.account.preferences.change.password")
                    }
                ]
            },
            {
                id: "notificationsHeaderTab",
                expansionTitle: this.translate.instant("manage.user.notification.preferences.title"),
                onClick: () => {
                    const title = this.translate.instant("manage.user.general.title");
                    const tabId = "general";
                    this.showGeneralData(title, tabId);
                    this.setPanelActive();
                },
                tabs: [
                    {
                        onClick: (title, tabId) => this.showGeneralData(title, tabId),
                        id: "general",
                        title: this.translate.instant("manage.user.general.title")
                    },
                    ...notificationsTabs
                ]
            }
        ];
    }

    private showChangePassword(title: string, tabId: string) {
        this.activePanel = tabId;

        if(!this.userConfigPreferencesFormGroup.controls[tabId] && !this.isLdapMode){
            const userDataForm = this.userConfigPreferencesService.getChangePasswordFormGroup();
            this.userConfigPreferencesFormGroup.addControl(tabId, userDataForm);
        }
        const providers = [
            { provide: "formControl", useValue: this.userConfigPreferencesFormGroup.controls[tabId] },
            { provide: "isLdapMode", useValue: this.isLdapMode }
        ];
        this.setPanel(UserPasswordManagementComponent, providers, title);
    }

    private async showUserData(title: string, tabId: string) {
        this.activePanel = tabId;

        if (!this.userConfigPreferencesFormGroup.controls[tabId] && !this.isLdapMode) {
            const sessionData = await this.managerUsersService.getUser(this.rootScope.loggedUser);
            this.userData.user = sessionData.user.username;
            this.userData.name = sessionData.user.name;
            this.userData.email = sessionData.user.email;
            this.userData.phone = sessionData.user.phone;
            const userDataFormGroup = this.userConfigPreferencesService.getUserDataFormGroup(this.userData);
            this.userConfigPreferencesFormGroup.addControl(tabId, userDataFormGroup);
        }

        const providers = [
            { provide: "formControl", useValue: this.userConfigPreferencesFormGroup.controls[tabId] },
            { provide: "isLdapMode", useValue: this.isLdapMode }
        ];
        this.setPanel(UserDataComponent, providers, title)
    }

    private showGeneralData(title: string, tabId: string) {
        this.activePanel = tabId;

        if (!this.userConfigPreferencesFormGroup.controls[tabId]) {
            const generalGroupForm = this.userConfigPreferencesService.getGeneralDataFormGroup(
                this.doNotDisturb,
                this.purgeOlderInDays
            );
            this.userConfigPreferencesFormGroup.addControl(tabId, generalGroupForm);
        }

        const providers = [{ provide: "formControl", useValue: this.userConfigPreferencesFormGroup.controls[tabId] }];
        this.setPanel(GeneralUserPreferencesConfigComponent, providers, title);
    }

    private showNotification(title: string, tabId: string) {
        this.activePanel = tabId;

        if (!this.userConfigPreferencesFormGroup.controls[tabId]) {
            const notificationsGroupForm = this.userConfigPreferencesService.getNotificationFormGroups(
                angular.copy(this.currentNotificationsByGroup[tabId])
            );
            this.userConfigPreferencesFormGroup.addControl(tabId, notificationsGroupForm);
        }

        const providers = [
            { provide: "notifications", useValue: this.userConfigPreferencesFormGroup.controls[tabId].value["notifications"] },
            { provide: "formControl", useValue: this.userConfigPreferencesFormGroup.controls[tabId] },
            { provide: "isDoNotDisturbEnabled", useValue: this.doNotDisturbCache }
        ];
        this.setPanel(NotificationsUserPreferencesConfigComponent, providers, title);
    }

    private setPanel(component: any, providers: any[], title: string) {
        this.panelData = {
            dynamicInjector: Injector.create({
                providers,
                parent: this.injector
            }),
            component,
            title
        };

        this.removeErrorFromActiveTab();
    }

    /**
     * Filtra os grupos de notificações baseado nas preferências disponíveis
     * para o usuário (há diferença entre administradores e não administradores).
     * e reordena a lista de grupos de notificações baseado na relevância
     * para o usuário.
     *
     * @returns - Lista de abas de notificações
     */
    private getNotificationsTabs() {
        const notificationsByImportance = [
            NotificationGroup.DEVICE,
            NotificationGroup.DCB,
            NotificationGroup.CPE_ONU,
            NotificationGroup.TEMPLATE_APPLICATION,
            NotificationGroup.SCHEDULER,
            NotificationGroup.AUDIT_RETENTION,
            NotificationGroup.USERS_GROUPS
        ];

        return notificationsByImportance
            .filter((group) => this.currentNotificationsByGroup[group])
            .map((group) => {
                return {
                    onClick: (title, tabId) => this.showNotification(title, tabId),
                    id: group,
                    title: this.translate.instant(this.notificationGroupsTitles[group])
                }
            });
    }

    private getAllNotifications(notificationsByGroup: NotificationPreferencesByGroup): NotificationPreferences[] {
        return Object.values(notificationsByGroup).flat();
    }

    private getGroupTitles(notificationsByGroup: NotificationPreferencesByGroup): NotificationPreferencesGroupTitle {
        const notificationGroupTitles: NotificationPreferencesGroupTitle = {} as NotificationPreferencesGroupTitle;

        Object.keys(notificationsByGroup).forEach((key) => {
            const keySuffix = key.toLocaleLowerCase().replace(new RegExp("_", "g"), ".");
            notificationGroupTitles[key] = `manage.user.notification.preferences.group.title.${keySuffix}`;
        });

        return notificationGroupTitles;
    }

    private showEditionDiscardConfirmationMessage(callback: Function) {
        this.rootScope
            .showDialog({
                message: this.translate.instant("manage.user.notification.preferences.edition.discard.confirmation.message"),
                isConfirm: true
            })
            .then(callback);
    }

    private highlightTabsWithErrorAndShowMessage(): void {
        const invalidTabs = this.userConfigPreferencesService.getInvalidTabs(
            this.userConfigPreferencesFormGroup,
            this.panelsList
        );

        const translatedKey = this.translate.instant("manage.user.configurations.config.with.errors");
        const invalidTabsNames = invalidTabs.map((tab) => tab.title).join("<br />");
        const message = `${translatedKey} <br /><strong>${invalidTabsNames}</strong>`;
        this.nmsDialogservice
            .openDialog({
                description: message,
                acceptLabel: "OK",
                bindHtml: true,
                isConfirm: true
            })
            .then(() => {
                invalidTabs.forEach(tab => tab.hasError = true);
                this.removeErrorFromActiveTab();
            });
    }

    private removeErrorFromActiveTab(): void {
        const activeTab = this.panelsList.flatMap((panel) => panel.tabs).find((tab) => tab.id === this.activePanel);
        activeTab.hasError = false;
    }

    private hasConfigChanges(): boolean {
        const generalForm = this.userConfigPreferencesFormGroup.controls["general"];
        const changePasswordForm = this.userConfigPreferencesFormGroup.controls["changePassword"]
        const accountData = this.userConfigPreferencesFormGroup.controls["accountData"];
        this.hasSomeNotificationConfigChange = this.userConfigPreferencesService.hasSomeNotificationConfigChange(
            this.notificationsByGroupCache,
            this.currentNotificationsByGroup,
            this.userConfigPreferencesFormGroup
        );

        if (generalForm) {
            const { doNotDisturb, purgeOlderInDays } = generalForm.value;
            const hasDoNotDisturbChanged = this.doNotDisturb !== doNotDisturb;
            const hasPurgeDaysChanged = this.purgeOlderInDays !== purgeOlderInDays;
            this.doNotDisturbCache = doNotDisturb;

            this.generalDataHasChange = hasPurgeDaysChanged || hasDoNotDisturbChanged;
        }

        if (accountData) {
            const { name, phone, email } = accountData.value;
            const nameChanged = this.userData.name !== name;
            const phoneChanged = this.userData.phone !== phone;
            const emailChanged = this.userData.email !== email;
            this.accountDataHasChange = nameChanged || phoneChanged || emailChanged;
        }

        if (changePasswordForm) {
            const {currentPassword, password, confirmPassword} = changePasswordForm.value;

            this.passwordDataHasChange = currentPassword && password && confirmPassword;
        }

        return this.generalDataHasChange || this.hasSomeNotificationConfigChange
            || this.passwordDataHasChange || this.accountDataHasChange;
    }
}
