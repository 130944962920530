import { PythonCommonsExpressionsService } from "./python-commons-expressions.service";
import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import devicePythonTemplateExpressions from "./device-python-template-expressions";
import { CommandFormat, Expression } from "../../../template/template.interface";
import { ExpressionService } from "@nms-ng2/app/shared/models/expressions.interface";

/**
 * Serviço responsável por retornar funcionalidades do device na linguagem python.
 */
@Injectable({
    providedIn: "root"
})
export class DevicePythonExpressionService extends PythonCommonsExpressionsService implements ExpressionService {

    constructor(@Inject(ANGULARJS_TRANSLATE) translate: any) {
        super(translate);
    }

    getExpressions(): Expression[] | null {
        return [];
    }

    getExpressionsAggregatorProvider() {
       return this.loadExpressions();
    }

    createExpressions() {
        return [];
    }

    getShowInlineExpressions(): boolean {
        return false;
    }

    getCommands(commands: string, isRemovalEnabled: boolean): string {
        return this.getPythonCommands(commands, isRemovalEnabled, "device");
    }

    getFormatCommand(): CommandFormat | null {
        return this.getFormatCommandPython();
    }

    getVariableTooltip() {
        return this.translate.instant("templateform.variables.tooltips.python");
    }

    loadExpressions() {
        let expressionsAggregator = [
            {
                nameLabel: this.translate.instant("templateform.device.python.expressions.device.methods"),
                expressions: this.createEquipmentMethods(),
                name: "equipmentMethods"
            },
            {
                nameLabel: this.translate.instant("templateform.device.python.expressions.cliMethods"),
                expressions: this.createCliMethods(),
                name: "cliMethods"
            },
            {
                nameLabel: this.translate.instant("templateform.device.python.expressions.templateFunctions"),
                expressions: this.createTemplateFunctions(),
                name: "templateFunctions"
            },
            {
                nameLabel: this.translate.instant("templateform.device.python.expressions.statements"),
                expressions: this.createStatements(),
                name: "statements"
            },
        ];

        return expressionsAggregator;
    }

    createEquipmentMethods() {
        let functions = [
            {
                name: "device.getHostname()",
                fn: this.getDeviceIp,
                tooltip: this.translate.instant("templateform.device.python.commands.tooltip.getDeviceHostname")
            },
            {
                name: "device.getName()",
                fn: this.getDeviceName,
                tooltip: this.translate.instant("templateform.device.python.commands.tooltip.getDeviceName")
            },
            {
                name: "device.getModel()",
                fn: this.getDeviceModel,
                tooltip: this.translate.instant("templateform.device.python.commands.tooltip.getDeviceModel")
            },
            {
                name: "device.isDmOs()",
                fn: this.getDeviceIsDmos,
                tooltip: this.translate.instant("templateform.device.python.commands.tooltip.getDeviceIsDmos")
            },
            {
                name: "device.getFirmware()",
                fn: this.getDeviceFirmware,
                tooltip: this.translate.instant("templateform.device.python.commands.tooltip.getDeviceFirmware")
            }
        ];

        return functions;
    };

    createCliMethods() {
        return [{
                name: "executeCliCommand",
                fn: this.getCliCommand,
                tooltip: this.translate.instant("templateform.device.python.expressions.tooltip.executeCliCommand")
            }];
    };

    getDeviceIp = (): string => {
        return devicePythonTemplateExpressions.createGetDeviceIpEquipmentMethod();
    }

    getDeviceName = (): string => {
        return devicePythonTemplateExpressions.createGetDeviceNameEquipmentMethod();
    }

    getDeviceModel = (): string => {
        return devicePythonTemplateExpressions.createGetDeviceModelEquipmentMethod();
    }

    getDeviceIsDmos = (): string => {
        return devicePythonTemplateExpressions.createGetDeviceIsDmosEquipmentMethod();
    }

    getDeviceFirmware = (): string => {
        return devicePythonTemplateExpressions.createGetDeviceFirmwareEquipmentMethod();
    }

    getCliCommand = (): string => {
        return devicePythonTemplateExpressions.createGetCliCommandMethod();
    }

    getMainFunctionSample(): string {
        return devicePythonTemplateExpressions.createMainFunctionStatement();
    }

}