import { Observable, from } from "rxjs";
import { Onu } from "./gpon-onus-models";
import { TemplatesByKeyword } from "../template/components/ui/templates-by-keywords/template-by-keyword-models";

var app = angular.module("nms.gponOnus");

app.controller("GponOnusController", [
    "$rootScope",
    "$scope",
    "$state",
    "NgTableParams",
    "TableFilterService",
    "GponOnusService",
    "NMS_STATES",
    "$timeout",
    "DevicesActionsService",
    "DropdownDeviceService",
    "DropdownCpeService",
    "$translate",
    "UserPreferencesService",
    "TemplateService",
    "GponOnusDeviceInformationResolver",
    "monitoringSettings",
    "CPU_USAGE",
    "MEMORY_USAGE",
    "DeviceStatusService",
    "ZabbixMonitoringService",
    "AutoUpdaterService",
    "DeviceDropdownModelService",
    "DEVICE_PROPERTIES",
    "TABLE_PROPERTIES",
    "$log",
    "ONU_CONSTANTS",
    "TEMPLATE_TYPE",
    "RefreshIntervalService",
    "EquipmentsModalsActionsService",
    function (
        $rootScope,
        $scope,
        $state,
        NgTableParams,
        TableFilterService,
        GponOnusService,
        NMS_STATES,
        $timeout,
        DevicesActionsService,
        DropdownDeviceService,
        DropdownCpeService,
        $translate,
        UserPreferencesService,
        TemplateService,
        GponOnusDeviceInformationResolver,
        monitoringSettings,
        CPU_USAGE,
        MEMORY_USAGE,
        DeviceStatusService,
        ZabbixMonitoringService,
        AutoUpdaterService,
        DeviceDropdownModelService,
        DEVICE_PROPERTIES,
        TABLE_PROPERTIES,
        $log,
        ONU_CONSTANTS,
        TEMPLATE_TYPE,
        RefreshIntervalService,
        EquipmentsModalsActionsService
    ) {
        $scope.MAX_ONU_TO_SHOW_WITHOUT_WARNING = 30000;
        $scope.zabbixUrl = monitoringSettings.url;
        $scope.CPU_USAGE = CPU_USAGE;
        $scope.MEMORY_USAGE = MEMORY_USAGE;
        $scope.showMaxOnuLengthWarning = false;
        $scope.UI_PERFORMANCE_WARNING_MESSAGE = $translate.instant("gpon.onus.uiPerformanceWarning");

        $scope.device = $state.params.device;
        $scope.loadTablePreferences = $state.params.loadTablePreferences;
        $scope.isOneDeviceContext = !_.isEmpty($scope.device);

        const defaultGlobalConfig = EquipmentsModalsActionsService.getDefaultGlobalConfig();
        var globalConfig = $state.params.globalConfig ? $state.params.globalConfig : defaultGlobalConfig;
        $scope.dropdownTitle = $translate.instant($state.current.data.pageTitle);
        $scope.disabledShortcutTooltip = $translate.instant("gpon.onus.onuNotRegistered");
        $scope.shortcuts = DropdownDeviceService.getShortcutToGponOnus(globalConfig, $scope.device);
        $scope.tableBodyMessage = $translate.instant("gpon.onus.loadingOnus");
        $scope.globalFilter = "";
        $scope.showOnlyTR069 = false;
        $scope.keywords = ["GPON", "XGSPON"];
        $scope.searchTooltip = TableFilterService.tooltipText;

        /**
         * Realiza a interseção de templates, que contenha palavra-chave correlacionada (GPON E XGSPON) e cria um
         * novo atalho genérico para a palavra-chave, nos templates que não ocorrer a interseção, a palavra-chave deve permanecer
         * com nome original.
         *
         * Exemplo:
         * keywordsTemplates = [
         *  {
         *       keyword: "GPON_Onu",
         *       templatesIdentifiers: [template3]
         *   },
         *   {
         *       keyword: "GPON_Infra",
         *       templatesIdentifiers: [template1]
         *   },
         *   {
         *       keyword: "XGSPON_Infra",
         *       templatesIdentifiers: [template1, template2]
         *   }
         * ];
         * esperado = [
         *   {
         *       keyword: "GPON_Onu",
         *       templatesIdentifiers: [template3]
         *   },
         *   {
         *       keyword: "PON_Infra",
         *       templatesIdentifiers: [template1]
         *   }
         *   {
         *       keyword: "XGSPON_Infra",
         *       templatesIdentifiers: [template2]
         *   }
         * ];
         *
         **/
        $scope.handleKeywords = (keywordsTemplatesRequest: Array<TemplatesByKeyword>) => {
            const keywordsTemplatesResponse: Array<TemplatesByKeyword> = [];
            const replaceKeyword = (keyword) => keyword.replace(/GPON|XGSPON/ig, "PON");

            new Set(keywordsTemplatesRequest.map(keywordTemplate => replaceKeyword(keywordTemplate.keyword)))
                .forEach(keyword => {
                    let templatesByKeyword =
                        keywordsTemplatesRequest.filter(keywordTemplate => replaceKeyword(keywordTemplate.keyword) === keyword);

                    if (templatesByKeyword.length > 1) {
                        const templatesIdentifiers =
                            templatesByKeyword.flatMap(keywordTemplate => keywordTemplate.templatesIdentifiers);

                        // Busca ocorrências duplicadas verificando se existem elementos em diferentes posições da
                        // lista que utilizam o mesmo templateId.
                        let templateIdentifiersIntersection =
                            templatesIdentifiers.filter((templateIdentifier, index, allTemplatesIdentifiers) => {
                                return !(index === allTemplatesIdentifiers.findIndex((anotherTemplateIdentifier) => {
                                    return anotherTemplateIdentifier["templateId"] === templateIdentifier["templateId"];
                                }));
                            });

                        keywordsTemplatesResponse.push({
                            keyword,
                            templatesIdentifiers: templateIdentifiersIntersection
                        });

                        addTemplatesIdentifiersToKeywordsWithoutIntersection(templateIdentifiersIntersection,
                             templatesByKeyword, keywordsTemplatesResponse);
                    } else {
                        const templateByKeyword = templatesByKeyword[0];
                        keywordsTemplatesResponse.push({
                            keyword: templateByKeyword.keyword,
                            templatesIdentifiers: templateByKeyword.templatesIdentifiers
                        });
                    }
                });

            return keywordsTemplatesResponse;
        };

        const addTemplatesIdentifiersToKeywordsWithoutIntersection = function(intersectionTemplatesIdentifiers, templatesByKeyword,
                keywordsByTemplatesList) {

            const intersectionTemplateIds = intersectionTemplatesIdentifiers.map(templateIdentifier =>
                    templateIdentifier.templateId);

            templatesByKeyword.forEach(keywordByTemplates => {
                const templatesByKeywordSpecific = keywordByTemplates.templatesIdentifiers.filter(identifier =>
                     !intersectionTemplateIds.includes(identifier.templateId));

                if (templatesByKeywordSpecific.length > 0) {
                    keywordsByTemplatesList.push({
                        keyword: keywordByTemplates.keyword,
                        templatesIdentifiers: templatesByKeywordSpecific
                    });
                }
            });
       }

        var availableFilterPropertiesKeys = ["showOnlyTR069", "globalFilter"];
        var createOnuInfoConfigPathKeys = function (gponOnu) {
            var portIdValues = gponOnu.portId.split("/");
            var portId: any = { "chassis-id": portIdValues[0], "slot-id": portIdValues[1], "port-id": portIdValues[2] };
            var onuId: any = { id: gponOnu.onuId };

            return [portId, onuId];
        };

        $scope.openDeviceOnOnu = function (onu : Onu) {
            var pathKeys = createOnuInfoConfigPathKeys(onu);
            var schemaJsonPath = `$.containers.config>interface.lists.${onu.portModel}.template.lists.onu.template`;
            var displayName = onu.portViewName + " onu " + onu.onuId;

            var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
            DevicesActionsService.goToDeviceConfigWithParams(onu.deviceName, params);
        };

        $scope.tr069 = {
            dropdownTitle: $translate.instant("gpon.cpes.tableActions.tr069"),
            shortcuts: DropdownCpeService.getTR069Shortcuts($scope.openDeviceOnOnu)
        };

        $scope.columns = [
            { field: "deviceName", editable: false },
            { field: ONU_CONSTANTS["PORT_COLUMN_KEY"], editable: false, width: "85" },
            { field: "onuId", editable: false, width: "50" },
            { field: "status" },
            { field: "rxPower", width: "90" },
            { field: "txPower", width: "90" },
            { field: "alarms" },
            { field: "alarmsTimes", sortable: "mostRecentAlarmsTime" },
            { field: "serialNumber" },
            { field: "password", show: false },
            { field: "vendorId", show: false },
            { field: "equipmentId", show: false },
            { field: "onuName" },
            { field: "operationalState", show: false },
            { field: "primaryStatus", show: false },
            { field: "distance", show: false },
            { field: "ipv4Address" },
            { field: "cwmpCpeIp" },
            { field: "cwmpCpeLastInform", sortable: "cwmpCpeLastInformToSort" },
            { field: "tr069acsProfile", show: false },
            { field: "ipv4DefaultGateway", show: false },
            { field: "ipv4Mode", show: false },
            { field: "ipv4Vlan", width: "80" },
            { field: "rgProfile", show: false },
            { field: "snmpMode", show: false },
            { field: "upstreamFec", show: false },
            { field: "uptime", show: false },
            { field: "lastSeenOnline", show: false },
            { field: "version", show: false },
            { field: "autoProvisioned", show: false },
            { field: "lineProfile" },
            { field: "serviceProfile", show: false },
            { field: "serviceVlans" },
            { field: "fixedBandwidth", width: "90" },
            { field: "assuredFixedBandwidth" },
            { field: "activeFwFormatted" },
            { field: "standbyFwFormatted", show: false },
            { field: "softwareDownloadState", show: false },
            { field: "lastUpdated", show: false },
            { field: "eth1Link" },
            {
                field: "eth1InRate",
                sortable: "absoluteEth1InRate",
                filter: { "[eth1InRate, absoluteEth1InRate]": "text" }
            },
            {
                field: "eth1OutRate",
                sortable: "absoluteEth1OutRate",
                filter: { "[eth1OutRate, absoluteEth1OutRate]": "text" }
            },
            { field: "eth2Link", show: false },
            {
                field: "eth2InRate",
                show: false,
                sortable: "absoluteEth2InRate",
                filter: { "[eth2InRate, absoluteEth2InRate]": "text" }
            },
            {
                field: "eth2OutRate",
                show: false,
                sortable: "absoluteEth2OutRate",
                filter: { "[eth2OutRate, absoluteEth2OutRate]": "text" }
            },
            { field: "eth3Link", show: false },
            {
                field: "eth3InRate",
                show: false,
                sortable: "absoluteEth3InRate",
                filter: { "[eth3InRate, absoluteEth3InRate]": "text" }
            },
            {
                field: "eth3OutRate",
                show: false,
                sortable: "absoluteEth3OutRate",
                filter: { "[eth3OutRate, absoluteEth3OutRate]": "text" }
            },
            { field: "eth4Link", show: false },
            {
                field: "eth4InRate",
                show: false,
                sortable: "absoluteEth4InRate",
                filter: { "[eth4InRate, absoluteEth4InRate]": "text" }
            },
            {
                field: "eth4OutRate",
                show: false,
                sortable: "absoluteEth4OutRate",
                filter: { "[eth4OutRate, absoluteEth4OutRate]": "text" }
            }
        ];

        $scope.tableParams = new NgTableParams(
            {
                page: 1,
                count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
                sorting: { deviceName: "asc", portViewName: "asc", onuId: "asc" },
                ignoredColumns: $scope.isOneDeviceContext ? ["deviceName"] : []
            },
            {
                dataset: [],
                filterOptions: { filterFn: TableFilterService.getFilter($scope.columns) }
            }
        );

        $scope.selection = {
            id: "gpon-onus-selector",
            checked: [],
            table: $scope.tableParams
        };

        $scope.tableModel = {
            options: {
                translatePrefix: "gpon.onus.tableColumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        $scope.getVisibleColumnsLength = function () {
            var visibleColumns = _.filter($scope.columns, { show: true });

            return _.size(visibleColumns);
        };

        $scope.createDeviceDropdownModel = function (gponOnu) {
            return DeviceDropdownModelService.createModelFromGponOnu(gponOnu);
        };

        $scope.getAvailableFeatures = function (gponOnu) {
            return DeviceDropdownModelService.getAvailableFeatures(gponOnu.deviceId);
        };

        var unregisterUpdateDeviceHeader = $rootScope.$on("updateDeviceHeaderFinished", function () {
            $scope.warningSettings = ZabbixMonitoringService.getSettings($scope.zabbixUrl, $scope.device.monitoringStatus);
        });

        $scope.init = function () {
            RefreshIntervalService.getRefreshInterval((interval: number) => ($scope.autoUpdateInterval = interval));

            if ($scope.isOneDeviceContext) {
                globalConfig.properties.name = $scope.device.name;
                $scope.getGponOnusByDevice();
            }

            updateFilters($state.params.filters);
            setTimeout(() => {
                $rootScope.$broadcast("updateTableHeight");
            }, 1000)
        };

        /**
         * Carrega o valor da propriedade de filtro global das ONUs.
         *
         * @return {string} Caso a propriedade esteja salva, retorna a mesma, caso contrário, retorna um valor undefined.
         */
        var loadOnuGlobalFilter = function () {
            return UserPreferencesService.loadPreferences({}, "OnuGlobalFilter", availableFilterPropertiesKeys);
        };

        var updateFilters = function (filters) {
            if (filters) {
                $scope.tableModel.doNotPersist = true;
                _.forEach(filters, function (value, key) {
                    $scope.tableParams.filter()[key] = value;
                });
            } else {
                var optionsFilter = loadOnuGlobalFilter();
                $scope.showOnlyTR069 = optionsFilter.showOnlyTR069 || $scope.showOnlyTR069;
                $scope.globalFilter = optionsFilter.globalFilter || $scope.globalFilter;
            }
        };

        $scope.getGponOnusByDevice = function () {
            $rootScope.$broadcast("updateDeviceHeaderByDeviceId", $scope.device.id);

            GponOnusService.findOnusByResourceId($scope.device.id).then((onus) => $scope.reloadTable({ data: onus }));
        };

        $scope.applyGlobalFilter = function () {
            angular.extend($scope.tableParams.filter(), { $: $scope.globalFilter });
        };

        $scope.retrieveAllGponOnus = function (): Observable<any> {
            return from(GponOnusService.getAllOnus());
        };

        $scope.reloadTable = function (onus) {
            $log.log("Finished resolve device onus", new Date());
            $scope.tableParams.settings({ dataset: onus.data });
            $scope.tableParams.reload();
            $scope.tableBodyMessage = $translate.instant("general.noResultsFound");
            $scope.applyShowOnlyTR069($scope.tableParams, $scope.showOnlyTR069);
            $scope.showMaxOnuLengthWarning = onus.data.length > $scope.MAX_ONU_TO_SHOW_WITHOUT_WARNING;
            showWarningDialogIfNeeded();
        };

        function showWarningDialogIfNeeded() {
            if ($scope.showMaxOnuLengthWarning && GponOnusService.wasntPerformanceDialogWarningShown()) {
                GponOnusService.setGponOnuUiPreference({ dialogWasShown: true });

                $rootScope.showDialog({
                    translateKey: "gpon.onus.dialog.uiPerformanceWarning",
                    params: [$translate.instant("gpon.onus.base.uiPerformanceWarning")],
                    paramsInsideMessage: true,
                    isConfirm: true
                }).then(() => $scope.goToNewUI());
            }
        };

        $scope.applyShowOnlyTR069 = function () {
            var filterValue = undefined;
            if ($scope.showOnlyTR069) {
                filterValue = true;
            }
            angular.extend($scope.tableParams.filter(), { isCpeRegistered: filterValue });
        };

        $scope.autoUpdateModel = {
            updateFunction: $scope.retrieveAllGponOnus,
            updateFinishedCallback: $scope.reloadTable
        };

        if ($scope.isOneDeviceContext) {
            var updateModel = {
                updateFinishedCallback: $scope.getGponOnusByDevice,
                nextReloadTextTranslateKey: "device.polling.status.timeRemaining",
                loadingTextTranslateKey: "device.polling.status.loadingDataFromDevice",
                lastUpdateTextTranslateKey: "device.polling.status.lastPollingTime",
                updateButtonTranslateKey: "device.polling.status.loadDataFromDevice"
            };

            AutoUpdaterService.setAutoUpdaterModel(updateModel);
        }

        $scope.openAllGponOnus = function () {
            $state.go(NMS_STATES.gponOnus, { hostname: "", device: null, filters: null }, { inherit: false });
        };

        $scope.$on("$destroy", function () {
            if (!$scope.tableModel.doNotPersist) {
                var properties = _.pick($scope, availableFilterPropertiesKeys);

                UserPreferencesService.savePreferences(properties, "OnuGlobalFilter", availableFilterPropertiesKeys);
            }
            unregisterUpdateDeviceHeader();
        });

        $scope.showError = function (messageKey) {
            $rootScope.showDialog({
                translateKey: messageKey
            });
        };

        /*
         * Retorna o valor da porta para a onu especificada removendo o prefixo gpon se ela existir,
         * caso contrário retorna null.
         */
        var getPortValue = function (onu) {
            if (!_.isEmpty(onu.portViewName)) {
                return onu.portViewName.split(" ").pop();
            }

            return null;
        };

        /*
         * Retorna o valor do serial number para a onu especificada se ele existir e o status da onu for 'Descoberta',
         * caso contrário retorna null.
         */
        var getSerialNumber = function (onu) {
            if (!_.isEmpty(onu.serialNumber) && onu.statusKey === "DISCOVERED") {
                return onu.serialNumber;
            }

            return null;
        };

        var getOnusIds = function () {
            return $scope.selection.checked.map((onu) => onu.onuId);
        };

        var getSerialNumbers = function () {
            return $scope.selection.checked.filter(getSerialNumber);
        };

        var isOnusSamePort = function (onus: any[]) {
            var distinticPorts = _.uniq(onus.map((onu) => onu.portId));

            return distinticPorts.length === 1;
        };

        $scope.getParamsForAction = function (callback) {
            var devices = GponOnusDeviceInformationResolver.tryGetDevices(
                $scope.isOneDeviceContext,
                $scope.device,
                $scope.selection.checked,
                $scope.tableParams.filter(),
                $scope.tableParams.data
            );

            if (devices.length === 1) {
                var device = devices[0];
                device.availableFeatures = DeviceDropdownModelService.getAvailableFeatures(device.id);
                var variables = [];
                var onu = $scope.selection.checked[0];
                if ($scope.selection.checked.length === 1) {
                    variables = [
                        { name: "PORTA_PON", value: getPortValue(onu) },
                        { name: "PORTA_GPON", value: getPortValue(onu) },
                        { name: "ONU_ID", value: _.get(onu, "onuId", null) },
                        { name: "NUMERO_SERIE_ONU_DESCOBERTA", value: getSerialNumber(onu) }
                    ];
                } else if ($scope.selection.checked.length > 1) {
                    if (isOnusSamePort($scope.selection.checked)) {
                        variables = [
                            { name: "PORTA_PON", value: getPortValue(onu) },
                            { name: "PORTA_GPON", value: getPortValue(onu) },
                            { name: "ONU_ID", value: getOnusIds(), fillVariableWithMultipleValues: true },
                            {
                                name: "NUMERO_SERIE_ONU_DESCOBERTA",
                                value: getSerialNumbers(),
                                fillVariableWithMultipleValues: true
                            }
                        ];
                    } else {
                        $scope.showError("gpon.onus.tableActions.multiplePortSelectionError");
                        return;
                    }
                }

                callback(device, variables, TEMPLATE_TYPE.CLI);
            } else if (devices.length > 1) {
                $scope.showError("gpon.onus.tableActions.multipleDeviceSelectionError");
            } else {
                $scope.showError("gpon.onus.tableActions.deviceSelectionError");
            }
        };

        $scope.checkOnuIdIsValid = function (onuId) {
            return angular.isNumber(onuId);
        };

        $scope.goToNewUI = function() {
            GponOnusService.setGponOnuUiPreference({ ui: NMS_STATES.newGponOnus });
            $state.go(NMS_STATES.newGponOnus, { name: $scope.device.name }, { inherit: true });
        };

        $scope.init();
    }
]);
