import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { NotificationMessage } from "@nms-ng2/app/shared/services/nms-notification/nms-notification.models";
import { NotificationPreferences } from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences-model";

/**
 * Componente responsável pela renderização das preferências de notificações.
 */
@Component({
    templateUrl: "./notifications-user-preferences-config.component.html",
    styleUrls: ["./notifications-user-preferences-config.component.scss"]
})
export class NotificationsUserPreferencesConfigComponent implements OnInit {

    notificationsGroupForm: FormGroup;

    private readonly findUnderlineRegex = new RegExp("_", "g");
    private readonly findDashRegex = new RegExp("-", "g");

    constructor(@Inject("notifications") public notifications: Array<NotificationMessage>,
                @Inject("formControl") public formControl: FormGroup,
                @Inject("isDoNotDisturbEnabled") public isDoNotDisturbEnabled: boolean) {
        this.notificationsGroupForm = formControl;
    }

    ngOnInit(): void {
        const formArray = this.notificationsGroupForm.controls["notifications"] as FormArray;
        formArray.controls.forEach((control, index) => {
            this.enableDisableDelayField(index);
        });
    }

    getNotificationIdentifier(userNotification: NotificationPreferences) {
        const identifierByTypeAndStatus = `${userNotification.notificationType}_${userNotification.notificationStatus}`;
        return identifierByTypeAndStatus.toLocaleLowerCase().replace(this.findUnderlineRegex, "-");
    }

    getNotificationKeySuffix(userNotification: NotificationPreferences) {
        return this.getNotificationIdentifier(userNotification).replace(this.findDashRegex, ".");
    }

    changeAutoCloseValue(value: boolean, index: number) {
        const notifications = this.notificationsGroupForm.get("notifications") as FormArray;
        const notification = notifications.at(index);
        notification.get("autoClose").setValue(value);
        this.enableDisableDelayField(index);
    }

    enableDisableDelayField(index: number) {
        const notifications = this.notificationsGroupForm.controls["notifications"] as FormArray;
        const shouldDisableDelayField = !notifications.at(index).get("enabled").value
            || !notifications.at(index).get("autoClose").value;

        if (shouldDisableDelayField) {
            notifications.at(index).get("delayBeforeCloseInSeconds").disable();
        } else {
            notifications.at(index).get("delayBeforeCloseInSeconds").enable();
        }

    }
}