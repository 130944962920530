import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CwmpParameterPathModel }
    from "@nms-ng2/app/modules/device/cwmp-parameters/cwmp-path-parameters/cwmp-path-parameter-model";
import {
    USER_PREFERENCES_SERVICE,
    ANGULARJS_TRANSLATE,
    STATE,
    NMS_STATES,
    ANGULARJS_ROOTSCOPE,
    NMS_ERRORS,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import {
    CpeErrorResponseHandlerService,
} from "@nms-ng2/app/modules/device/cpe-error-response-handler.service";
import { ErrorDescriptionDetails } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { NmsToasterLink } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-model";


/** Representa uma requisição em andamento de parâmetros CWMP a um determinado CPE. */
class CwmpRunningRequest {
    serialNumber: string;
    cwmpOperationType: CwmpOperationType;
    startTime: Date;

    constructor(serialNumber: string, cwmpOperationType: CwmpOperationType) {
        this.serialNumber = serialNumber;
        this.cwmpOperationType = cwmpOperationType;
        this.startTime = new Date();
    }
}

/** Representa um interessado em atualizações de parâmetros CWMP de um determinado CPE. */
class CwmpRequestCallback {
    serialNumber: string;
    parametersCallback: Function;
    errorCallback: Function;

    constructor(
        serialNumber: string,
        parametersCallback: Function,
        errorCallback: Function
    ) {
        this.serialNumber = serialNumber;
        this.parametersCallback = parametersCallback;
        this.errorCallback = errorCallback;
    }
}

/** Representa as operações disponíveis ao parâmetros do CWMP a um determinado CPE. */
export enum CwmpOperationType {
    /** Coleta os parâmetros existentes no do CPE. */
    GET = "get",
    /** Atualiza novos valores aos parâmetros do CPE. */
    SET = "set",
    /** Adiciona novo objeto ao CPE. */
    ADD = "add",
    /** Remove o obejeto do CPE. */
    DELETE = "delete",
}

/**
 *  Serviço para controle de requisições de parâmetros CMPW.
 *  Este serviço é capaz de manipular várias requisições a múltiplos CPEs, bem como destinar
 *  eventos de atualizações aos respectivos interessados.
 *  O escopo do serviço é global, visando possibilitar o tratamento de requisições em background
 *  independente da feature acessada pelo cliente.
 */
@Injectable({
    providedIn: "root",
})
export class CwmpParametersService {
    runningRequests: Array<CwmpRunningRequest>;
    requestCallbacks: Array<CwmpRequestCallback>;

    constructor(private http: HttpClient,
                @Inject(USER_PREFERENCES_SERVICE) private userPreferenceService,
                private toastr: NmsToastrService,
                @Inject(ANGULARJS_TRANSLATE) private translate,
                @Inject(STATE) private $state: any,
                @Inject(NMS_STATES) private nmsStates: any,
                @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
                @Inject(NMS_ERRORS) private nmsErrors: any,
                private errorResponseHandlerService: CpeErrorResponseHandlerService) {
        this.runningRequests = new Array<CwmpRunningRequest>();
        this.requestCallbacks = new Array<CwmpRequestCallback>();
    }

    /**
     * Retorna os últimos dados de parâmetros recebidos com sucesso para o CPE com o dado SerialNumber.
     * Caso não haja nenhum parâmetro no local storage definido, ele retornará um array vazio.
     */
    public getLatestParameters(serialNumber: string): any {
        var preferences = this.userPreferenceService.loadPreferences({}, this.getParametersKey(serialNumber), ["parameters"]);

        return _.isEmpty(preferences) ? [] : preferences.parameters;
    }

    /**
     * Retorna os últimos parametersPaths inseridos ao requisitar os parâmetros de acordo com o serialNumber.
     * Caso não haja nenhum parametersPath será retornado um array contendo um objeto vazio do
     * tipo CwmpParameterPathModel.
     * Foi necessáro inserir o valor padrão aqui, pois caso o usuário selecionasse algum valor na página e saísse sem efetuar
     * nenhuma requisição, o sistema estava "setando" o path selecionado como default quando o local storage estava vazio.
     * Devido a essa funcionalidade => this.parametersPath[selectValue.index].path = selectValue.value localizada em cwmp-path-parameters;
     */
    public getLatestParametersPath(serialNumber: string): Array<CwmpParameterPathModel> {
        var preferences = this.userPreferenceService.loadPreferences({}, this.getParametersKey(serialNumber), ["parametersPath"]);

        if (_.isEmpty(preferences)) {
            return [{ path: "InternetGatewayDevice.", includeNextLevel: false }];
        }

        preferences.parametersPath.forEach(parameter => {
            parameter.path = parameter.path === '' ? this.translate.instant("cwmp.parametes.pathList.empty") : parameter.path
        });

        return preferences.parametersPath;
    }

    /**
     * Verifica se há uma requisição de parâmetros para o CPE com o dado SerialNumber.
     */
    public hasRunningRequest(serialNumber: string): boolean {
        return this.runningRequests.some(request => request.serialNumber === serialNumber);
    }

    public hasAddObjectRequest(serialNumber: string) {
        return this.runningRequests.some(request => request.serialNumber === serialNumber && request.cwmpOperationType === CwmpOperationType.ADD);
    }

    /**
     * Retorna a data/hora de inicio da requisição em andamento para o CPE com o dado SerialNumber
     * ou undefined, caso não haja nenhuma requisição em andamento.
     */
    public getRequestStartTime(serialNumber: string): Date {
        let request = this.runningRequests.find(request => request.serialNumber === serialNumber);
        return request ? request.startTime : undefined;
    }

    /**
     * Registra um callback para representar um 'interessado' em atualizações de parâmetros para o CPE com o dado SerialNumber.
     * A função de callback 'successCallback' passada como parâmetro, será executada sempre que foram recebidos dados novos do
     * CPE para notificar o componente 'interessado'.
     * Já a função errorCallback será executada quando ocorrer qualquer tipo de erro na requisição solicitada.
     * Retorna uma função para para viabilizar a remoção deste callback da lista gerenciada pelo serviço (pode ser utilizada
     * quando o componente for destruído).
     */
    public subscribe(serialNumber: string, cwmpSuccessCallback: Function, cwmpErrorCallback: Function): Function {
        this.requestCallbacks.push(new CwmpRequestCallback(serialNumber, cwmpSuccessCallback, cwmpErrorCallback));

        return () => {
            let index = this.requestCallbacks.findIndex(requestCallback => requestCallback.serialNumber === serialNumber);
            this.requestCallbacks.splice(index, 1);
        };
    }

    /**
     * Retorna o valor da data/hora inicial e da data/hora final de finalização da request realizada com sucesso.
     */
    public getLatestDateRequest(serialNumber: string): any {
        return this.userPreferenceService.loadPreferences({}, this.getParametersKey(serialNumber), ["lastRequestSuccessInitialDate", "lastRequestSuccessFinalDate"]);
    }

    /**
     * Inicia uma requisição de parâmetros CWMP para o CPE com o dado SerialNumber.
     * Quando a requisição é finalizada, os resultados são atualizados no Storage e os 'interessados' neste
     * evento (os que tiverem se registrado pela função 'subscribe') são notificados.
     */
    public getParameters(hostname: string, serialNumber: string, parameterPaths: Array<CwmpParameterPathModel>): void {
        let paths = _.cloneDeep(parameterPaths);

        if (this.hasRunningRequest(serialNumber)) {
            throw `There is already a cwmp request in progress for CPE ${serialNumber} started at ${this.getRequestStartTime(serialNumber)}.`;
        }

        this.runningRequests.push(new CwmpRunningRequest(serialNumber, CwmpOperationType.GET));
        this.requestParameteres(hostname, serialNumber, paths, null, CwmpOperationType.GET);
    }

    public setParameters(hostname: string, serialNumber: string, parametersValues: any) {
        if (this.hasRunningRequest(serialNumber)) {
            throw `There is already a cwmp request in progress for CPE ${serialNumber} started at ${this.getRequestStartTime(serialNumber)}.`;
        }

        this.runningRequests.push(new CwmpRunningRequest(serialNumber, CwmpOperationType.SET));
        let latestPatametersPath = this.getLatestParametersPath(serialNumber);

        this.http.post(`/acs-client/api/set-parameters/${serialNumber}`, parametersValues).subscribe(
            (setResponseStatus) => {
                const cwmpResponse = { responseStatus: setResponseStatus }
                this.requestParameteres(hostname, serialNumber, latestPatametersPath, cwmpResponse, CwmpOperationType.SET);
            },
            (error) => this.cwmpErrorCallback(hostname, serialNumber, "cwmp.parameters.set.error", error)
        );
    }

    public addObjectPath(hostname: string, serialNumber: string, path: string): void {
        if (this.hasRunningRequest(serialNumber)) {
            throw `There is already a cwmp request in progress for CPE ${serialNumber} started at ${this.getRequestStartTime(serialNumber)}.`;
        }

        this.runningRequests.push(new CwmpRunningRequest(serialNumber, CwmpOperationType.ADD));
        let latestPatametersPath = this.getLatestParametersPath(serialNumber);

        this.http.post(`/acs-client/api/add-object/${serialNumber}/${path}`, null).subscribe(
            (addResponse) => {
                addResponse["addedPath"] = path;
                this.requestParameteres(hostname, serialNumber, latestPatametersPath, addResponse, CwmpOperationType.ADD);
            },
            (error) => this.cwmpErrorCallback(hostname, serialNumber, "cwmp.parameters.addObject.error", error)
        );
    }

    public removeObjectPath(hostname: string, serialNumber: string, path: string): void {
        if (this.hasRunningRequest(serialNumber)) {
            throw `There is already a cwmp request in progress for CPE ${serialNumber} started at ${this.getRequestStartTime(serialNumber)}.`;
        }

        this.runningRequests.push(new CwmpRunningRequest(serialNumber, CwmpOperationType.DELETE));
        let latestPatametersPath = this.getLatestParametersPath(serialNumber);

        this.http.delete(`/acs-client/api/delete-object/${serialNumber}/${path}`).subscribe(
            (deleteResponseStatus) => {
                const cwmpResponse = { responseStatus: deleteResponseStatus }
                this.requestParameteres(hostname, serialNumber, latestPatametersPath,
                    cwmpResponse, CwmpOperationType.DELETE);
            },
            (error) => this.cwmpErrorCallback(hostname, serialNumber, "cwmp.parameters.removeObject.error", error)
        );
    }

    public requestParameteres = (hostname: string, serialNumber: string, parameterPaths: Array<CwmpParameterPathModel>,
        cwmpOperationResponse: any, cwmpOperationType: CwmpOperationType) => {

        this.http.post(`/acs-client/api/get-parameters/${serialNumber}`, parameterPaths).subscribe(
            (requestResponse) => {
                const cwmpResponse = { ...requestResponse, ...cwmpOperationResponse };

                this.cwmpOperationSuccess(serialNumber, hostname,
                    this.getTranslateKey(cwmpOperationType, cwmpResponse), parameterPaths, cwmpOperationType, cwmpResponse);
            },
            (error: any) => this.cwmpErrorCallback(hostname, serialNumber, "cwmp.parameters.request.error", error)
        );
    }

    /**
     * Insere os parametros e os paths requisitados de acordo com o serialNumber no local storage
     */
    public saveParametersToLocalStorage(serialNumber: string, parametersResponse: any, paths: any): void {
        var parameters = {
            parametersPath: paths,
            parameters: parametersResponse
        }

        this.userPreferenceService.savePreferences(parameters, this.getParametersKey(serialNumber), ["parametersPath", "parameters"]);
    }

    private cwmpOperationSuccess(serialNumber: string, hostname: string, translateKey: string,
        latestPatametersPath: Array<CwmpParameterPathModel>, parametersRequestType: CwmpOperationType, cwmpResponse: any) {
        this.showSuccess(translateKey, serialNumber, hostname, cwmpResponse, parametersRequestType);
        let request = this.removeCwmpOperationRequest(serialNumber);
        this.updateToLocalStorage(serialNumber, cwmpResponse.parameterValues, latestPatametersPath, request);

        this.requestCallbacks
            .filter(requestCallback => requestCallback.serialNumber === serialNumber)
            .forEach(requestCallback => requestCallback.parametersCallback(parametersRequestType, cwmpResponse));
    }

    private getTranslateKey(cwmpOperationType: CwmpOperationType, cwmpResponse: any): string {
        switch (cwmpOperationType) {
            case CwmpOperationType.GET:
                return "cwmp.parameters.request.success";

            case CwmpOperationType.SET:
                return this.chooseTranslationKey(cwmpResponse.responseStatus,
                    "cwmp.parameters.set.success", "cwmp.parameters.set.reboot.success");

            case CwmpOperationType.ADD:
                return this.chooseTranslationKey(cwmpResponse.responseStatus,
                    "cwmp.parameters.addObject.success", "cwmp.parameters.addObject.reboot.success");

            case CwmpOperationType.DELETE:
                return this.chooseTranslationKey(cwmpResponse.responseStatus,
                    "cwmp.parameters.removeObject.success", "cwmp.parameters.removeObject.reboot.success");
        }
    }

    private chooseTranslationKey(responseStatus: string, succesKey: string, rebootSuccessKey: string) {
        return (responseStatus === "SUCCESS") ? succesKey : rebootSuccessKey;
    }

    private cwmpErrorCallback = (hostname: string, serialNumber: string, translateKey: string, error: any) => {
        this.showError(translateKey, serialNumber, hostname, error)
        this.removeCwmpOperationRequest(serialNumber);

        console.error("It was not possible to connect to Server.", error);

        this.requestCallbacks
            .filter(requestCallback => requestCallback.serialNumber === serialNumber)
            .forEach(requestCallback => requestCallback.errorCallback(error));
    }

    private removeCwmpOperationRequest(serialNumber: string): CwmpRunningRequest {
        let request: CwmpRunningRequest = this.runningRequests.find(request => request.serialNumber == serialNumber);
        let index = this.runningRequests.findIndex(request => request.serialNumber === serialNumber);
        this.runningRequests.splice(index, 1);

        return request;
    }

    /**
     * Método utilizado para retornar a chave da property no local storage
     * Será composta por: <usuário> - cpe-<serialNumber>
     * O usuário é pego através do userPreferenceService - serviço responsável por setar e pegar
     * as properties do sistema.
     */
    private getParametersKey(serialNumber: string): string {
        return `cpe-${serialNumber}`;
    }

    /**
     * Configura os dados necessários para serem exibidos no toast de sucesso.
     * Será exibido a mensagem e o link 'Parâmetros' na qual será redirecionado
     * para a tela de Parâmetros
     */
    private showSuccess(translateKey: string, serialNumber: string, hostname: string,
        cwmpResponse: any, parametersRequestType: CwmpOperationType): void {
        let links: Array<NmsToasterLink> = [];
        let message = this.translate.instant(translateKey).replace("{0}", serialNumber).replace("{1}", hostname);

        if (CwmpOperationType.ADD === parametersRequestType) {
            message = message.replace("{2}", cwmpResponse.instanceNumber);
        }

        links.push(
            {
                id: serialNumber,
                title: this.translate.instant("cwmp.parameters.view.link"),
                action: () => this.$state.go(this.nmsStates.cwmpParameters, { serialNumber })
            });
        this.showToastSuccess(message, links);
    }

    /**
     * Exibe o toast de sucesso passando a opção enableHtml para que possam ser adicionadas quebras
     * de linha na mensagem
     * e o label com o link
     */
    private showToastSuccess(message: string, links: Array<NmsToasterLink>): void {
        this.toastr.success(message, null, {
            // @ts-ignore
            links: links
        }).onAction.subscribe(toastr => toastr.action());
    }

    /**
     * Ao exibir um toast de erro serão exibdos dois tipos de links
     * Parâmetros -  navega ate a tela de parâmetros
     * Detalhes - Se na mensagem de erro vier os detalhes, esse link será redirecionado para uma popup
     * exibindo os detalhes do erro - similar a tela de testar conectividade
     */
    private showError(translateKey: string, serialNumber: string, hostname: string, errorResponse: any): void {
        const errorMessageDetails: ErrorDescriptionDetails = this.errorResponseHandlerService
            .buildErrorDescriptionDetails(errorResponse.error, undefined, errorResponse.status);

        let links: Array<NmsToasterLink> = [];

        let translate = this.translate.instant(translateKey);
        let message = translate.replace("{0}", serialNumber).replace("{1}", hostname);

        /* Se o errorMessageDetails vier com descrição, exibi-la junto a mensagem como 'Motivo da falha: error-description' */
        if (errorMessageDetails.description) {
            let failTypeMask = this.translate.instant("cwmp.parameters.request.failType");
            let failTypeMessage = failTypeMask.replace("{0}", errorMessageDetails.description);
            message = `${message}\n${failTypeMessage}`;
        }

        /* Insere o link para redirecionar a tela de parâmetros */
        links.push(
            {
                id: serialNumber,
                title: this.translate.instant("cwmp.parameters.view.link"),
                action: () => this.$state.go(this.nmsStates.cwmpParameters, { serialNumber })
            });

        /* Caso venha os detalhes do erro na requisição, adicioná-lo como um novo link para que possa ser redirecionado a popup */
        let details: any = errorMessageDetails.details;
        if (details) {
            this.buildLinkDetails(links, details, serialNumber);
        }
        this.showToasterError(message, links);
    }

    private buildLinkDetails(links: Array<NmsToasterLink>, message: string, serialNumber: string): void {
        const type = "error";
        let linkDetails = {
            id: serialNumber,
            title: this.translate.instant("toastr.details.link"),
            action: () => this.$rootScope.showDialog({ type, message, insertScrollOnDetailsMessage: true })
        };
        links.splice(0, 0, linkDetails);
    }

    private updateToLocalStorage(serialNumber: string, parametersResponse: any, paths: any, request: CwmpRunningRequest) {
        this.saveLastRequestSuccessToLocalStorage(request);
        this.saveParametersToLocalStorage(serialNumber, parametersResponse, paths);
    }

    /**
     * Salva a data/hora inicial e a data/hora final da requisição realizada com sucesso no local storage
     */
    private saveLastRequestSuccessToLocalStorage(request: CwmpRunningRequest) {
        let dateStartRequest: Date = request.startTime;
        let dateFinalizeRequest: Date = new Date();
        var parameters = {
            lastRequestSuccessInitialDate: dateStartRequest,
            lastRequestSuccessFinalDate: dateFinalizeRequest
        };

        this.userPreferenceService.savePreferences(parameters, this.getParametersKey(request.serialNumber), ["lastRequestSuccessInitialDate", "lastRequestSuccessFinalDate"]);
    }

    /**
     * Exibe o toast de erro com o link e os detalhes caso houver.
     */
    private showToasterError(message: string, links: Array<NmsToasterLink>): void {
        this.toastr.error(message, null, {
            // @ts-ignore
            links: links
        }).onAction.subscribe(toastr => toastr.action());
    }
}