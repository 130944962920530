import { Component, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";

/**
 * Componente que representa a aba de dados do usuário.
 */
@Component({
    templateUrl: "./user-data.component.html",
    styleUrls: ["./user-data.component.scss"]
})
export class UserDataComponent {

    userDataForm: FormGroup
    constructor(@Inject("isLdapMode") public isLdapMode: boolean,
                @Inject("formControl") public formControl: FormGroup) {
        this.userDataForm = formControl;
    }
}