import { NES_ERROR_NAME } from "@nms-ng2/app/core/services/error-response-handler/error-names.constant";
import { NesError } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

/**
 * Service that handles errors reported by NES.
 *
 * @author bruno.vollino
 */
var app = angular.module("nms.dynamicDevice");

app.service("NesErrorHandlerService", ["$rootScope", "ngDialog", "$filter", "NmsToastrService",
    function($rootScope, ngDialog, $filter, nmsToastrService: NmsToastrService) {
        this.handleError = function(error, deviceId, deviceRequestFinishedCallback) {
            if (error.nesError === NES_ERROR_NAME[NesError.CONFIGURATION_CHANGED_ERROR]) {
                $rootScope.showDialog({
                    translateKey: "device.pollingConfigurationChanged"
                }).closePromise.then(deviceRequestFinishedCallback);
            } else if (error.details && error.details.length > 0) {
                openDetailsDialog(error);
            } else if (error.nesError !== NES_ERROR_NAME[NesError.NETWORK_ELEMENT_NOT_FOUND_IN_DB]) {
                nmsToastrService.error(error.description);
            }
        };

        /**
        * Displays a dialog with the error details.
        *
        * @param {Object} error The error to be displayed.
        * @return {undefined}
        */
        function openDetailsDialog(error) {
            error.details = _.map(error.details, function(detailsMessage) {
                return $filter("escapeTagsExceptLineAndThematicBreak")(detailsMessage.trim());
            });

            ngDialog.open({
                template: "templates/components/ui/popups/error-info.html",
                data: {
                    message: error.description,
                    details: error.details
                },
                className: "ngdialog-theme-default config-error"
            });
        }
    }
]);
