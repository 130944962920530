"use strict";

angular.module("nms.templates")
.factory("TemplateInstanceVariableService", ["$translate", "ConverterService", "$rootScope",
    "VARIABLES",
    function($translate, ConverterService, $rootScope, VARIABLES ) {
        const service: any = {};

        var textVariableHasRestrictions = function(variable) {
            var restrictions = [];

            if (variable.rangeMin !== null && variable.rangeMax !== null) {
                restrictions.push($translate.instant("templateinstanceform.templatedevices.sizeFrom")
                                + " " + variable.rangeMin + " "
                                + $translate.instant("templateinstanceform.templatedevices.sizeTo")
                                + " " + variable.rangeMax + " "
                                + $translate.instant("templateinstanceform.templatedevices.characteres"));
            }
            if (variable.rangeMin !== 0 && variable.rangeMax === null) {
                restrictions.push($translate.instant("templateinstanceform.templatedevices.sizeMinimum")
                                + " " + variable.rangeMin + " "
                                + $translate.instant("templateinstanceform.templatedevices.characteres"));
            }
            if (variable.rangeMax !== null && variable.rangeMin === null) {
                restrictions.push($translate.instant("templateinstanceform.templatedevices.sizeTo")
                                + " " + variable.rangeMax + " "
                                + $translate.instant("templateinstanceform.templatedevices.characteres"));
            }

            return restrictions;
        };

        var integerVariableHasRestrictions = function(variable) {
            var restrictions = [];

            if (variable.minValue !== null && variable.maxValue !== null) {
                restrictions.push(variable.minValue + "-" + variable.maxValue);
            }
            if (variable.minValue !== null && variable.maxValue === null) {
                restrictions.push($translate.instant("templateinstanceform.templatedevices.minimum")
                                + " " + variable.minValue);
            }
            if (variable.maxValue !== null && variable.minValue === null) {
                restrictions.push($translate.instant("templateinstanceform.templatedevices.maximum")
                                + " " + variable.maxValue);
            }
            if (variable.step !== null) {
                restrictions.push("step " + variable.step);
            }
            if (variable.baseExponent !== null) {
                restrictions.push($translate.instant("templateinstanceform.templatedevices.powerOf")
                                + " " + variable.baseExponent);
            }

            return restrictions;
        };

        var getVariableValue = function(variable, variablesWithValue, variableNameFunction) {
            var value = [""];

            var variableWithValue = _.find(variablesWithValue, function(variableWithValue) {
                var variableName = variableNameFunction(variableWithValue);

                return variableName === variable.name;
            });

            if (variableWithValue) {
                value = variableWithValue.values;

                if (variable.type === "INTEGER" || variable.type === "INTEGER_LIST") {
                    value = ConverterService.convertStringArrayToIntegerArray(variableWithValue.values);
                }
            }
            return value;
        };

        var getAutoSelectionValue = function(globalVarsList, findFunction) {
            let globalVar = globalVarsList.find(findFunction);
            return globalVar ? globalVar.autoSelection : undefined;
        };

        let getLoadSnmpTrapValue = function (globalVarsList, findFunction) {
            let globalVar = globalVarsList.find(findFunction);
            return globalVar ? globalVar.loadSnmpTrap : undefined;
        };

        service.updateGlobalVars = function(globalVarsList, currentGlobalVars, templateInstanceGlobalVars) {
            var globalVars = [];

            if (angular.isUndefined(templateInstanceGlobalVars.length)) {
                templateInstanceGlobalVars = globalVars;
            }

            globalVarsList.forEach(function(globalVar) {
                let value = [""];
                let autoSelection;
                let loadSnmpTrap;

                if (templateInstanceGlobalVars.length > 0) {
                    value = getVariableValue(globalVar, templateInstanceGlobalVars, function(templateInstanceGlobalVar) {
                        return templateInstanceGlobalVar.name;
                    });
                    autoSelection = getAutoSelectionValue(templateInstanceGlobalVars,
                            (templateInstanceGlobalVar) => templateInstanceGlobalVar.name === globalVar.name);
                    loadSnmpTrap = getLoadSnmpTrapValue(templateInstanceGlobalVars,
                        (templateInstanceGlobalVar) => templateInstanceGlobalVar.name === globalVar.name);

                } else if (currentGlobalVars.length > 0) {
                    value = getVariableValue(globalVar, currentGlobalVars, function(currentGlobalVar) {
                        return currentGlobalVar.variable.name;
                    });
                    autoSelection = getAutoSelectionValue(currentGlobalVars,
                        (currentGlobalVar) => currentGlobalVar.variable.name === globalVar.name);
                    loadSnmpTrap = getLoadSnmpTrapValue(currentGlobalVars,
                        (currentGlobalVar) => currentGlobalVar.name === globalVar.name);
                }

                globalVars.push({
                    variable: globalVar,
                    value: value,
                    autoSelection: autoSelection,
                    loadSnmpTrap: loadSnmpTrap
                });
            });

            return globalVars;
        };

        service.updateLocalVars = function(templateLocalVarsList, equipment) {
            var localVars = [];

            if (angular.isUndefined(equipment.localVars.length)) {
                equipment.localVars = localVars;
            }

            equipment.templateAssociations.forEach(function(template) {
                templateLocalVarsList.forEach(function(templateLocalVars) {
                    if (template.templateId === templateLocalVars.templateId) {
                        templateLocalVars.localVars.forEach(function(localVar) {
                            let value = [""];
                            let autoSelection;

                            if (equipment.localVars.length > 0) {
                                var tLocalVar = _.find(equipment.localVars, function(templatelocalVar) {
                                    return templatelocalVar.name === localVar.name;
                                });

                                if (tLocalVar) {
                                    value = tLocalVar.values;
                                    autoSelection = tLocalVar.autoSelection;
                                    if (localVar.type === "INTEGER" || localVar.type === "INTEGER_LIST") {
                                        value = ConverterService.convertStringArrayToIntegerArray(value);
                                    }
                                }
                            }

                            if (!_.some(localVars, {variable: localVar})) {
                                localVars.push({
                                    variable: localVar,
                                    value: value,
                                    autoSelection: autoSelection,
                                    loadSnmpTrap: false
                                });
                            }
                        });
                    }
                });
            });

            return localVars;
        };

        service.hasRestrictions = function(variable) {
            var restrictions: any = [];

            if (variable.type === "TEXT" || variable.type === "TEXT_LIST") {
                restrictions = textVariableHasRestrictions(variable);
            }

            if (variable.type === "INTEGER" || variable.type === "INTEGER_LIST") {
                restrictions = integerVariableHasRestrictions(variable);
            }

            if (restrictions.length !== 0) {
                restrictions = "(" + restrictions.toString().replace("/[!\[\]\"]/g", "").replace(",", ", ") + ")";
            } else {
                restrictions = restrictions.toString().replace("/[!\[\]\"]/g", "");
            }

            return restrictions;
        };

        /**
        * Caso a variável seja do tipo ACTION, o sistema retornará o
        * array vazio, pois o valor dessas variável não será armazenado,
        * nem irá aparecer na auditoria.
        **/
        const getVarValues = function(variable) {
            if (variable.variable.type === VARIABLES.ACTION) {
                return [""];
            }

            return variable.value || [];
        }

        service.convertTemplateInstanceVars = function(variablesToConvert) {
            return variablesToConvert.map(function(variable) {
                const values = getVarValues(variable);
                for (let i = 0; i < values.length; i++) {
                    const validValue = values[i] || "";
                    values.splice(i, 1, validValue);
                }

                return {
                    name: variable.variable.name,
                    values: _.flatten(values),
                    autoSelection: variable.autoSelection,
                    loadSnmpTrap: variable.loadSnmpTrap
                };
            });
        };

        service.validateVariablesForm = function(form, currentTab, localVars) {
            var emptyMandatoryVariables = [],
                restrictionsVariables = [],
                blockedVariables = [],
                loadingVariables = [],
                formsList = currentTab.forms;

            var formToValidate = _.find(formsList, function(formSelected) {
                return form[formSelected.name].$invalid;
            });

            if (formToValidate) {
                _.keys(form[formToValidate.name].$error).forEach(function(error) {
                    for (var i = 0; i < form[formToValidate.name].$error[error].length; i++) {
                        var variableName = form[formToValidate.name].$error[error][i].$name;
                        if (error === "required" && emptyMandatoryVariables.indexOf(variableName) === -1) {
                            emptyMandatoryVariables.push(variableName);
                        } else if (error === "blockValidation" && blockedVariables.indexOf(variableName) === -1) {
                            blockedVariables.push(variableName);
                        } else if (restrictionsVariables.indexOf(variableName) === -1) {
                            restrictionsVariables.push(variableName);
                        }
                    }
                });

                if (emptyMandatoryVariables.length > 0) {
                    var isGlobalFormValidation = _.isEqual(formToValidate.name, "globalVarsForm");
                    var messageKey = isGlobalFormValidation
                                    ? "templateinstanceform.error.mandatoryVariables"
                                    : "templateinstanceform.error.mandatoryVariablesForEquipment";

                    if (isGlobalFormValidation) {
                        showErrorMessage(messageKey, emptyMandatoryVariables);
                    } else {
                        var deviceWithEmptyVars = service.getDeviceWithEmptyMandatoryVariables(localVars);

                        if (!_.isEmpty(deviceWithEmptyVars)) {
                            showErrorMessage(messageKey, deviceWithEmptyVars);
                        }
                    }

                    return false;
                } else if (blockedVariables.length > 0) {
                    var messageKey = formToValidate.name === "globalVarsForm"
                                    ? "templateinstanceform.error.variablesBlocked"
                                    : "templateinstanceform.error.variablesBlockedForEquipments";
                    showErrorMessage(messageKey, blockedVariables);
                    return false;
                } else if (restrictionsVariables.length > 0) {
                    var messageKey = formToValidate.name === "globalVarsForm"
                                    ? "templateinstanceform.error.variablesInvalids"
                                    : "templateinstanceform.error.variablesInvalidsForEquipments";
                    showErrorMessage(messageKey, restrictionsVariables);
                    return false;
                }
            }

            return true;
        };

        service.getDeviceWithEmptyMandatoryVariables = function(localVars) {
            var deviceWithEmptyVars = [];

            localVars.equipmentLocalVars.forEach(function(device) {
                device.localVars.forEach(function(localVar) {
                    if (_.indexOf(localVar.value, "") !== -1 && localVar.variable.mandatory) {
                        deviceWithEmptyVars.push(device.equipmentDetails.name);
                    }
                });
            });

            return deviceWithEmptyVars;
        };

        function showErrorMessage(messageKey, errorParams) {
            $rootScope.showDialog({
                translateKey: messageKey,
                params: errorParams,
                maxChars: 128
            });
        }

        return service;
    }]);
