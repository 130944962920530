import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { PythonCommonsExpressionsService } from "./python-commons-expressions.service";
import cpePythonTemplateExpressions from "./cpe-python-template-expressions";
import { ExpressionService } from "@nms-ng2/app/shared/models/expressions.interface";
import { CommandFormat, Expression } from "../../../template/template.interface";

/**
 * Serviço responsável por retornar funcionalidades utilizadas nos templates TR-069 em Python.
 */
@Injectable({
    providedIn: "root"
})
export class CpePythonExpressionService extends PythonCommonsExpressionsService implements ExpressionService {

    constructor(@Inject(ANGULARJS_TRANSLATE) translate: any) {
        super(translate)
    }

    getExpressions(): Expression[] | null {
        return null;
    }

    getExpressionsAggregatorProvider() {
        return this.loadExpressions();
    }

    createExpressions() {
        // No implement method;
    }

    getShowInlineExpressions(): boolean {
        return false;
    }

    getCommands(commands: string = "", isRemovalEnabled: boolean): string {
        return this.getPythonCommands(commands, isRemovalEnabled, "cpe");
    }

    getFormatCommand(): CommandFormat | null {
        return this.getFormatCommandPython();
    }

    loadExpressions() {
        let expressionsAggregator = [
            {
                nameLabel: this.translate.instant("templateform.python.expressions.methodsTR069"),
                expressions: this.createMethodsTR069(),
                name: "tr069method"
            },
            {
                nameLabel: this.translate.instant("templateform.python.expressions.methodsCpe"),
                expressions: this.createMethodsCPE(),
                name: "cpe"
            },
            {
                nameLabel: this.translate.instant("templateform.python.expressions.templateFunctions"),
                expressions: this.createTemplateFunctions(),
                name: "templateFunctions"
            },
            {
                nameLabel: this.translate.instant("templateform.python.expressions.statements"),
                expressions: this.createStatements(),
                name: "statements"
            },
        ];

        return expressionsAggregator;
    }

    createMethodsTR069() {
        let methods = [
            {
                name: "addObject(String path)",
                fn: this.getAddObjectSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.addObject")
            },
            {
                name: "deleteObject(String path)",
                fn: this.getDeleteObjectSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.deleteObject")
            },
            {
                name: "download(String fileUrl, int fileType)",
                fn: this.getDownloadSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.download")
            },
            {
                name: "downloadConfigFile(String fileUrl)",
                fn: this.getDownloadSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.downloadConfigFile")
            },
            {
                name: "downloadFirmwareFile(String fileUrl)",
                fn: this.getDownloadSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.downloadFirmwareFile")
            },
            {
                name: "getParameterNames(String path, boolean nextlevel)",
                fn: this.getParameterNamesSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getParameterNames")
            },
            {
                name: "getParameterValues(String path)",
                fn: this.getParameterValuesSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getParameterValues.singlePath")
            },
            {
                name: "getParameterValues(List<String> paths)",
                fn: this.getMultipleParameterValuesSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getParameterValues.multiplePath")
            },
            {
                name: "setParameterValues(String path, Object value)",
                fn: this.getSetParameterValuesSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.setParameterValues.singlePath")
            },
            {
                name: "setParameterValues(Map<String, Object> parameterValues)",
                fn: this.getSetMultipleParameterValuesSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.setParameterValues.multiplePath")
            },
            {
                name: "reboot()",
                fn: this.getRebootSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.reboot")
            }
        ];

        return methods;
    }

    createMethodsCPE() {
        let methods = [
            {
                name: "getConnectionRequestUrl()",
                fn: this.getConnectionRequestUrlSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getConnectionRequestUrl")
            },
            {
                name: "getHardwareVersion()",
                fn: this.getHardwareVersionSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getHardwareVersion")
            },
            {
                name: "getHostname()",
                fn: this.getHostnameSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getHostname")
            },
            {
                name: "getLastInform()",
                fn: this.getLastInformSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getLastInform")
            },
            {
                name: "getManufacturer()",
                fn: this.getManufacturerSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getManufacturer")
            },
            {
                name: "getProductModel()",
                fn: this.getProductModelSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getProductModel")
            },
            {
                name: "getProvisioningCode()",
                fn: this.getProvisioningCodeSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getProvisioningCode")
            },
            {
                name: "getSerialNumber()",
                fn: this.getSerialNumberSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getSerialNumber")
            },
            {
                name: "getSoftwareVersion()",
                fn: this.getSoftwareVersionSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getSoftwareVersion")
            }
        ];

        return methods;
    }

    getVariableTooltip(): string {
        return this.translate.instant("templateform.variables.tooltips.python");
    }

    getMainFunctionSample = (): string => {
        return cpePythonTemplateExpressions.createMainFunctionStatement();
    }

    getAddObjectSample = (): string  => {
        return cpePythonTemplateExpressions.createAddObjectTr069Method();
    }

    getDeleteObjectSample = (): string => {
        return cpePythonTemplateExpressions.createDeleteObjectTr069Method();
    }

    getDownloadSample = (): string  => {
        return cpePythonTemplateExpressions.createDownloadTr069Method();
    }

    getParameterNamesSample = (): string => {
        return cpePythonTemplateExpressions.createGetParameterNamesTr069Method();
    }

    getParameterValuesSample = (): string  => {
        let parameters = `'InternetGatewayDevice.SomePath.SomeParameter'`;
        return cpePythonTemplateExpressions.createGetParameterValuesTr069Method(parameters);
    }

    getMultipleParameterValuesSample = (): string  => {
        let parameters = `['InternetGatewayDevice.SomePath.SomeParameter', 'InternetGatewayDevice.SomePath.SomeOtherParameter1']`;
        return cpePythonTemplateExpressions.createGetParameterValuesTr069Method(parameters);
    }

    getSetParameterValuesSample = (variables?) : string  => {
        let parameterValue = `'someValue'`;
        if (this.hasItems(variables)) {
            parameterValue = variables[0].name;

            if (variables.length > 1) {
                return this.getSetMultipleParameterValuesSample(variables);
            }
        }

        return cpePythonTemplateExpressions.createSetParameterValuesTr069Method(parameterValue);
    }

    getSetMultipleParameterValuesSample = (variables?) : string  => {
        let params = [
            `'InternetGatewayDevice.SomePath.SomeParameter': 'someValue'`,
            `'InternetGatewayDevice.SomePath.SomeOtherParameter1': 'someOtherValue1'`
        ];

        if (this.hasItems(variables)) {
            let parameterValues = variables.map(variable => variable.name);

            if (parameterValues.length === 1) {
                parameterValues.push(`'someOtherValue1'`);
            }
            params = parameterValues.map((parameterValue, index) => {
                let path = `'InternetGatewayDevice.SomePath.SomeParameter'`
                if (index >= 1) {
                    path = `'InternetGatewayDevice.SomePath.SomeOtherParameter${index}'`
                }
                return `${path}: ${parameterValue}`;
            });
        }

        return cpePythonTemplateExpressions.createMultipleSetParameterValuesTr069Method(params.join(", "));
    }

    getRebootSample = (): string  => {
        return cpePythonTemplateExpressions.createRebootTR069Method();
    }

    getConnectionRequestUrlSample = (): string => {
        return cpePythonTemplateExpressions.createGetConnectionRequestUrlCpeMethod();
    }

    getHardwareVersionSample = (): string  => {
        return cpePythonTemplateExpressions.createGetHardwareVersionCpeMethod();
    }

    getHostnameSample = (): string  => {
        return cpePythonTemplateExpressions.createGetHostnameCpeMethod();
    }

    getLastInformSample = (): string  => {
        return cpePythonTemplateExpressions.createGetLastInformCpeMethod();
    }

    getManufacturerSample = (): string  => {
        return cpePythonTemplateExpressions.createGetManufacturerCpeMethod();
    }

    getProductModelSample = (): string => {
        return cpePythonTemplateExpressions.createGetProductModelCpeMethod();
    }

    getProvisioningCodeSample = (): string => {
        return cpePythonTemplateExpressions.createGetProvisioningCodeCpeMethod();
    }

    getSerialNumberSample = (): string => {
        return cpePythonTemplateExpressions.createGetSerialNumberCpeMethod();
    }

    getSoftwareVersionSample = (): string => {
        return cpePythonTemplateExpressions.createGetSoftwareVersionCpeMethod();
    }

}