import commonPythonTemplateExpressions from "./commons-python-template-expressions";

/**
 * Classe utilizada para funcionalidades que possuem expressões similares para templates na linguagem Python.
 */
export abstract class PythonCommonsExpressionsService {

    private readonly INDENTATION_SPACE: string = "\n        ";
    private readonly formatCommandPython: any = {
        start: "",
        end: ""
    };
    translate;

    constructor($translate: any) {
        this.translate = $translate;
    }

    abstract getMainFunctionSample(): string;

    /**
     * O comando é inicializado incluindo a função default (de acordo com o tipo do template) "run(cpe) ou run(device)"
     * caso ela não exista.
     * Uma linha em branco é adicionada apenas quando há comandos pré-existentes sem a definição da função run.
     * Caso isRemovalEnabled seja false (ou seja a check "Comandos Remoção" esteja desmarcada) e o comando
     * inserido for igual ao da sugestão, esse comando será limpo para que a sugestão não fique aparecendo
     * quando a check estiver desmarcada assim como acontece quando a tela é aberta pela primeira vez.
     * @param commands
     * @param isRemovalEnabled
     * @param defaultFunction: cpe ou device
     */
    getPythonCommands(commands: string, isRemovalEnabled: boolean, defaultFunction: string): string {
        commands = commands || "";
        const commandsIncludeRun = _.some(commands.split("\n"), (line) => line.startsWith(`def run(${defaultFunction}):`));
        if (isRemovalEnabled && !commandsIncludeRun) {
            if (!_.isEmpty(commands.trim())) {
                commands += "\n";
            }
            commands += `def run(${defaultFunction}):\n    # your code here`;
        } else if (commands === `def run(${defaultFunction}):\n    # your code here`) {
            commands = "";
        }

        return commands;
    }

    createTemplateFunctions() {
        let functions = [
            {
                name: "hasValue()",
                fn: this.getHasValueSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.hasValue")},
            {
                name: "loadValue()",
                fn: this.getLoadValueSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.loadValue")},
            {
                name: "saveValue()",
                fn: this.getSaveValueSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.saveValue")
            },
            {
                name: "stop()",
                fn: this.getStopSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.stop")
            }
        ];

        return functions;
    }

    createStatements() {
        let expressions = [
            {
                name: this.translate.instant("templateform.buttons.insertComment"),
                fn: this.getCommentSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.comment")
            },
            {
                name: "For (loop)",
                fn: this.getForSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.for")
            },
            {
                name: "Function",
                fn: this.getFunctionSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.function")
            },
            {
                name: "If",
                fn: this.getIfSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.if")
            },
            {
                name: "If/else",
                fn: this.getIfElseSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.ifElse")
            },
            {
                name: "Main function",
                fn: this.getMainFunctionSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.main")
            },
            {
                name: "Print",
                fn: this.getPrintSample,
                tooltip: this.translate.instant("templateform.commands.tootlip.print")
            },
            {
                name: "While (loop)",
                fn: this.getWhileSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.while")
            }
        ]

        return expressions;
    }

    getCommentSample = () : string => {
        let comment = this.translate.instant("templateform.variables.messages.commentSample");
        return commonPythonTemplateExpressions.createCommentStatement(comment);
    };

    getFunctionSample = (variables?) : string => {
        let varsNames = this.hasItems(variables) ? variables.map(({ name }) => name) : [`'some message'`];
        let functionParams = varsNames.length === 0 ? "" : varsNames.join(", ");
        let varPrints = varsNames.map((varName) => `print(${varName})`).join(this.INDENTATION_SPACE);

        return commonPythonTemplateExpressions.createFunctionStatement(functionParams, varPrints);
    }

    getIfSample = (variables?) : string  => {
        let {firstVarName, varNames} = this.extractExpressionParams(variables);
        let printVars = `print('some message')`;

        if (this.hasItems(variables) && variables.length > 1) {
            printVars = varNames
                .map(name => `print(${name})`)
                .join(this.INDENTATION_SPACE);
        }

        return commonPythonTemplateExpressions.createIfStatement(firstVarName, printVars);
    }

    getIfElseSample = (variables?) : string => {
        let printVars = `print('some message')`;
        let someOtherMessage = "'some other message'";
        let {firstVarName, varNames} = this.extractExpressionParams(variables);

        if (this.hasItems(variables) && variables.length > 1) {
            printVars = varNames
                .map(name => `print(${name})`)
                .join(this.INDENTATION_SPACE);
        }

        return commonPythonTemplateExpressions.createIfElseStatement(firstVarName, printVars, someOtherMessage);
    }

    getForSample = (variables?) : string => {
        if (this.hasItems(variables)) {
            const { firstVarName, varNames } = this.extractExpressionParams(variables);
            const variable_item = `${firstVarName}_item`;
            if (variables.length > 1) {
                let printVars = varNames.map(name => `print(${name}[index])`).join(this.INDENTATION_SPACE);

                return commonPythonTemplateExpressions.createMultipleParameterizedForStatement(variable_item, firstVarName,
                    printVars);
            } else {
                return commonPythonTemplateExpressions.createParameterizedForStatement(variable_item, firstVarName);
            }
        }

        return commonPythonTemplateExpressions.createForStatement();
    }

    getPrintSample = () : string => {
        return commonPythonTemplateExpressions.createPrintStatement();
    }

    getWhileSample = (variables?) : string => {
        let someVariable = "some_variable";
        let loadVar = `print(${someVariable})`;

        if (this.hasItems(variables)) {
            loadVar = variables.map(({ name }) => `print(${name})`).join(this.INDENTATION_SPACE);
        }

        return commonPythonTemplateExpressions.createWhileStatement(someVariable, loadVar);
    }

    getHasValueSample = () : string => {
        return commonPythonTemplateExpressions.createHasValueTemplateFunction();
    }

    getLoadValueSample = () : string => {
        return commonPythonTemplateExpressions.createLoadValueTemplateFunction();
    }

    getSaveValueSample = () : string => {
        return commonPythonTemplateExpressions.createSaveValueTemplateFunction();
    }

    getStopSample = () : string => {
        return commonPythonTemplateExpressions.createStopTemplateFunction();
    }

    getFormatCommandPython() {
        return this.formatCommandPython;
    }

    /**
     * Extrai os parâmetros de uma expressão.
     * Retorna o nome da primeira variável separado e as demais em um array de nomes.
     */
    extractExpressionParams = (variables: undefined | {name: string}[]) : {firstVarName: string, varNames: string[]} => {
        if (!this.hasItems(variables)) {
            return {
                firstVarName: "some_variable",
                varNames: []
            };
        }

        let [firstVarName, ...varNames] = variables.map(({ name }) => name);
        return {
            firstVarName,
            varNames
        };
    }

    hasItems = (array) : boolean => {
        return array && array.length !== 0;
    }
}