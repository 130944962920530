import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationPreferencesRequest, NotificationPreferencesResponse } from "./notification-preferences-model";
import { NotificationStatus, NotificationType } from "../nms-notification/nms-notification.models";

/**
 * Classe responsável por realizar as requisições referentes aos CPEs
 */
@Injectable({
    providedIn: "root",
})
export class NotificationPreferencesService {
    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = "/notification/preferences";
    }

    public getPreferences(): Observable<NotificationPreferencesResponse> {
        return this.http.get<NotificationPreferencesResponse>(this.baseUrl);
    }

    public setPreferences(notificationsPreferences: NotificationPreferencesRequest): Observable<void> {
        return this.http.post<void>(this.baseUrl, notificationsPreferences);
    }

    public disableNotificationByTypeAndStatus(notificationType: NotificationType, status: NotificationStatus): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/disable/${notificationType}/${status}`, {});
    }

    public getDoNotDisturb(): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/doNotDisturb`);
    }

    public setDoNotDisturb(doNotDisturb: boolean): Observable<void> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.post<void>(`${this.baseUrl}/doNotDisturb`, doNotDisturb, httpOptions);
    }
}
