"use strict";

import {
    RetentionDeviceBackupModalComponent
} from "@nms-ng2/app/modules/device/device-backup/retention-device-backup-modal/retention-device-backup-modal.component";
import { AuditRetentionModalComponent } from "@nms-ng2/app/shared/components/modals/audit-retention/audit-retention-modal.component";

/*
* @ngdoc controller
* @name nms:NavbarController
* @description
* Controller para itens de navbar. Esta controller contém componentes que podem ser acessados por todo o sistema.
*/
var app = angular.module("nms");
app.controller("NavbarController", ["$scope", "ModalFactory", "GponOnusService",
    function ($scope, ModalFactory, GponOnusService) {

        /** Abre a modal de retenção de auditoria caso o usuário seja administrador, caso não seja, essa opção nem será visualizada */
        $scope.openAuditRetentionModal = function () {
            ModalFactory.openAsyncModal(AuditRetentionModalComponent, {});
        };

        /**
         * Abre a modal de retenção de backup de equipamentos caso o usuário seja administrador,
         * caso não seja, essa opção nem será visualizada.
         */
        $scope.openDcbRetentionModal = function () {
            ModalFactory.openAsyncModal(RetentionDeviceBackupModalComponent, {});
        };

        $scope.getGponOnuRoute = function() {
            return GponOnusService.getGponOnuUISref();
        };

    }]);
