import { Injectable, Inject } from "@angular/core";
import { TriggerType } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Classe utilitária para funções relacionadas ao Scheduler.
 **/
@Injectable({
    providedIn: "root"
})
export class TriggersService {

    public readonly LOCALE_EN_I18N = "en";
    public readonly LOCALE_PT_BR_I18N = "pt-br";
    public readonly DATE_FORMAT_US = "MM/DD/YYYY";
    public readonly DATE_FORMAT_BR = "DD/MM/YYYY";
    public readonly SATURDAY = 6;
    public readonly SUNDAY = 0;

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any
    ) {}

    isTimeTrigger(triggerType: TriggerType): boolean {
        return this.getTimeTriggers().includes(triggerType);
    }

    isCronTrigger(triggerType: TriggerType): boolean {
        return triggerType === TriggerType.CRON;
    }

    isInformTrigger(triggerType: TriggerType): boolean {
        return triggerType === TriggerType.TR069_EVENT;
    }

    isSnmpTrapTrigger(triggerType: TriggerType): boolean {
        return triggerType === TriggerType.TRAP;
    }

    isPtBr(locale: string): boolean {
        return /pt[-_]br/i.test(locale.toLowerCase());
    }

    /**
     * Formata a data nos seguintes formatos
     * Ex.:
     * PT - 21 de jan. de 2003 07:06
     * EN - Nov 11, 2022, 12:09 PM
     *
     * @param date - Data
     * @return
     */
    formatDateDescription(date: Date): string {
        if (date) {
            return new Intl.DateTimeFormat(this.getLocale(), {
                year: "numeric",
                month: "numeric",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
            }).format(date);
        }

        return "";
    }

    /**
     * Retorna o locale utilizado em i18n.
     */
    getLocale(): string {
        if (this.isPtBr(this.translate.use())) {
            return this.LOCALE_PT_BR_I18N;
        }
        return this.LOCALE_EN_I18N;
    }

    private getTimeTriggers(): TriggerType[] {
        return [
            TriggerType.ONCE,
            TriggerType.EACH_HOUR,
            TriggerType.DAILY,
            TriggerType.BUSINESS_DAY,
            TriggerType.WEEKLY,
            TriggerType.MONTHLY,
            TriggerType.YEARLY,
            TriggerType.CRON,
            TriggerType.ON_DEMAND
        ]
    }
}
