import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import {
    AsyncUpdater, NmsTableActions, NmsTableColumn, NmsTableColumnType,
    NmsTableConfig, RefreshActions
} from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import {
    DeviceBackupConfiguration,
    DeviceBackupConfigurationView,
    DevicesBackupListOptions,
    RemoveBackupStatus
} from "../device-backup.models";
import {
    ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE, AUTHENTICATION_SERVICE, NMS_FEATURES, USER_PREFERENCES_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Subject } from "rxjs";
import { TooltipOptions } from "ng2-tooltip-directive";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { DeviceBackupService } from "../device-backup.service";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import { CreateBackupModalComponent } from "../create-backup-modal/create-backup-modal.component";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { RestoreDeviceBackupModalComponent } from "../restore-device-backup-modal/restore-device-backup-modal.component";
import { BackupConfigurationViewModalComponent } from "../backup-configuration-view-modal/backup-configuration-view-modal.component";
import {
    RetentionDeviceBackupModalComponent
} from "../retention-device-backup-modal/retention-device-backup-modal.component";
import { DeviceBackupErrorResponseHandlerService } from "../device-backup-error-response-handler.service";
import { DcbError } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { TranslationKey } from "@nms-ng2/app/shared/models/translation.models";

/**
 * Tabela de backups de Equipamentos.
 */
@Component({
    selector: "device-backup-table",
    templateUrl: "./device-backup-table.component.html"
})
export class DeviceBackupTableComponent implements OnInit {
    @Input() refreshActions?: RefreshActions;
    columns: Array<NmsTableColumn>;
    backups: Array<DeviceBackupConfiguration>;
    tableActions: Array<NmsTableActions>;
    tableBodyMessage: string;
    asyncUpdater: AsyncUpdater;
    searchAllFilterText: string;
    tooltipOptions: TooltipOptions;
    backupsToImport: FileList;
    isLoading: boolean = false;

    @Input()
    private backupsProvider: Function;

    @ViewChild("importBackupsInput")
    private inputFileToImportBackups: ElementRef;

    readonly config: NmsTableConfig = {
        hideCustomizedTableComponents: true,
        tableId: "backup-devices-table",
        initialSort: { lastBackupDateTimestamp: "asc" },
        tableTranslatePrefix: "backup.devices.tablecolumn",
        selectionId: "backup-devices-table-selector",
        rowId: "cfgId"
    };

    readonly noBackupSelectedKey = "backup.devices.table.actions.message.noItemSelected";

    constructor(@Inject(USER_PREFERENCES_SERVICE) private readonly userPreferenceService: any,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        @Inject(AUTHENTICATION_SERVICE) private readonly authenticationService: any,
        @Inject(NMS_FEATURES) private readonly nmsFeatures: any,
        @Inject(ANGULARJS_TRANSLATE) public readonly translate,
        @Inject(ModalFactory) private readonly modalFactory: ModalFactory,
        private nmsDialogService: NmsDialogService,
        private toastr: NmsToastrService,
        private dcbService: DeviceBackupService,
        private errorResponseHandlerService: DeviceBackupErrorResponseHandlerService) {
        this.tooltipOptions = {
            "content-type": "html",
            placement: "bottom",
            offset: 15
        };
        this.backups = new Array<DeviceBackupConfigurationView>();

        this.asyncUpdater = {
            multipleRows: {
                responseObservable: new Subject()
            },
            searchAll: {
                responseObservable: new Subject()
            },
            singleRow: {
                responseObservable: new Subject()
            }
        };
    }

    ngOnInit(): void {
        const refreshActionsPlaceholder = {
            retrieveFn: () => { },
            startUpdatingFn: () => { },
            finishedUpdatingFn: () => { }
        };

        this.refreshActions = _.get(this, "refreshActions", refreshActionsPlaceholder);
        this.refreshActions.retrieveFn = () => this.loadDevicesBackup();
        this.tableBodyMessage = "backup.devices.table.retrieving.message";
        this.loadColumns();
        this.buildTableActions();
        this.loadDevicesBackup();
    }

    loadColumns(): void {
        this.columns = [
            {
                field: "jobName"
            },
            {
                field: "lastBackupDateView",
                sortable: "lastBackupDateTimestamp"
            },
            {
                field: "deviceName"
            },
            {
                field: "deviceModelName"
            },
            {
                field: "deviceFirmware"
            },
            {
                type: NmsTableColumnType.ACTIONS,
                editable: false,
                filter: false,
                field: "actions",
                actions: [
                    {
                        id: "view",
                        iconClass: "icon-result medium mt-0",
                        tooltip: "backup.devices.table.actions.view",
                        onAction: (deviceBackupConfiguration: DeviceBackupConfigurationView) => {
                            this.dcbService.executeIfDcbServiceOnline(() => {
                                this.openViewBackupDialog(deviceBackupConfiguration);
                            });

                        }
                    },
                    {
                        id: "export",
                        iconClass: "fa fa-download",
                        tooltip: "backup.devices.table.actions.export",
                        onAction: (deviceBackupConfiguration: DeviceBackupConfigurationView) => {
                            this.dcbService.executeIfDcbServiceOnline(() => {
                                this.exportBackups([deviceBackupConfiguration]);
                            });
                        }
                    },
                    {
                        id: "restore",
                        iconClass: "fa fa-undo",
                        tooltip: "backup.devices.table.actions.restore",
                        onAction: (deviceBackupConfiguration: DeviceBackupConfigurationView) => {
                            this.dcbService.executeIfDcbServiceOnline(() => {
                                this.openRestoreBackupDialog(deviceBackupConfiguration);
                            });

                        }
                    },
                    {
                        id: "delete",
                        iconClass: "glyphicon glyphicon-trash",
                        tooltip: "backup.devices.table.actions.delete",
                        onAction: (deviceBackupConfiguration: DeviceBackupConfigurationView) => {
                            this.dcbService.executeIfDcbServiceOnline(() => {
                                this.handleBackupsRemoving([deviceBackupConfiguration]);
                            });
                        }
                    },

                ]
            }
        ]
    }

    buildTableActions() {
        this.tableActions = [
            {
                id: "create",
                translateKey: "backup.devices.table.actions.create",
                ignoreSelectedItemsValidation: true,
                action: () => {
                    if (!this.authenticationService.hasPermission(this.nmsFeatures.scheduler.feature)) {
                        const message =
                            this.translate.instant("backup.devices.table.actions.create.scheduler.userHasNoPermission");
                        this.showDialog(message);
                        return;
                    }
                    this.dcbService.executeIfDcbServiceOnline(() => {
                        this.openCreateBackupDialog();
                    });
                }
            },
            {
                id: "view",
                translateKey: "backup.devices.table.actions.view",
                customNoSelectedItemTranslationKey: this.noBackupSelectedKey,
                action: (devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) => {
                    if (this.isOnlyOneBackupSelected(devicesBackupConfiguration)) {
                        const deviceBackupConfiguration = devicesBackupConfiguration[0];
                        this.dcbService.executeIfDcbServiceOnline(() => {
                            this.openViewBackupDialog(deviceBackupConfiguration);
                        });
                    }
                }
            },
            {
                id: "export",
                translateKey: "backup.devices.table.actions.export",
                customNoSelectedItemTranslationKey: this.noBackupSelectedKey,
                action: (devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) => {
                    this.dcbService.executeIfDcbServiceOnline(() => {
                        this.exportBackups(devicesBackupConfiguration);
                    });
                }
            },
            {
                id: "import",
                translateKey: "backup.devices.table.actions.import",
                ignoreSelectedItemsValidation: true,
                action: () => {
                    this.dcbService.executeIfDcbServiceOnline(() => {
                        this.inputFileToImportBackups.nativeElement.click();
                    });
                }
            },
            {
                id: "restore",
                translateKey: "backup.devices.table.actions.restore",
                customNoSelectedItemTranslationKey: this.noBackupSelectedKey,
                action: (devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) => {
                    if (this.isOnlyOneBackupSelected(devicesBackupConfiguration)) {
                        this.dcbService.executeIfDcbServiceOnline(() => {
                            this.openRestoreBackupDialog(devicesBackupConfiguration[0]);
                        });
                    }
                }
            },
            {
                id: "delete",
                translateKey: "backup.devices.table.actions.delete",
                customNoSelectedItemTranslationKey: this.noBackupSelectedKey,
                action: (devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) => {
                    this.dcbService.executeIfDcbServiceOnline(() => {
                        this.handleBackupsRemoving(devicesBackupConfiguration);
                    });
                }
            },
            {
                id: "retention",
                translateKey: "backup.devices.table.actions.retention",
                ignoreSelectedItemsValidation: true,
                action: () => {
                    this.dcbService.executeIfDcbServiceOnline(() => {
                        this.handleBackupRetention();
                    });
                }
            }
        ]
    }

    validateFilesToImport(event): void {
        this.backupsToImport = event.target.files;

        if (this.backupsToImport.length > 0) {
            this.isLoading = true;

            this.dcbService.importBackups(this.backupsToImport).subscribe(() => {
                let message = this.backupsToImport.length === 1
                    ? this.translate.instant("backup.devices.modal.success.import.dcb.one")
                    : this.translate.instant("backup.devices.modal.success.import.dcb.multiple");

                this.toastr.success(message);
                this.loadDevicesBackup();
                this.isLoading = false;
            }, (errorResponse: HttpErrorResponse) => {
                this.isLoading = false;
                let messageKey = this.backupsToImport.length === 1
                    ? "backup.devices.modal.error.import.dcb.one" : "backup.devices.modal.error.import.dcb.multiple"
                this.showErrorMessage(messageKey, errorResponse);
            });
        }
    }

    update(backups: Array<DeviceBackupConfigurationView>): void {
        this.backups = backups;
        this.asyncUpdater.multipleRows.responseObservable.next(this.backups);
    }

    searchAll(filterText: string): void {
        this.asyncUpdater.searchAll.responseObservable.next(filterText);
    }

    handleBackupRetention() {
        if (!this.$rootScope.isAdmin) {
            this.showDialog("backup.devices.table.actions.retention.message.noPermission");
            return;
        }

        this.modalFactory.openAsyncModal(RetentionDeviceBackupModalComponent);
    }

    getErrorMessage(errorResponse: HttpErrorResponse): Array<string> {
        let { description, details } = this.errorResponseHandlerService.buildErrorDescriptionDetails(errorResponse?.error);
        let errorMessage = [description];
        if (details) {
            errorMessage.push(details);
        }

        return errorMessage;
    }

    private readonly applyGlobalFilterUserPreferences = () => {
        const { globalFilter } = this.userPreferenceService.loadPreferences(
            {},
            DevicesBackupListOptions.FILTER_KEY,
            DevicesBackupListOptions.PROPERTIES_KEYS
        );
        this.searchAll(globalFilter);
        this.searchAllFilterText = globalFilter;
    };

    private exportBackups(devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) {
        this.isLoading = true;
        const ids = devicesBackupConfiguration.map(item => parseInt(item.cfgId));
        this.dcbService.exportBackups(ids).subscribe(
            (response: HttpResponse<Blob>) => {
                const fileName = this.getZipOrDcbFileName(response);
                const blob = new Blob([response.body], { type: "application/octet-stream" });
                FileSaver.saveAs(blob, fileName);
                this.isLoading = false;
            },
            (errorResponse) => {
                this.showErrorMessage("backup.devices.modal.error.export.dcb", errorResponse);
                this.isLoading = false;
            }
        );
    }

    private getZipOrDcbFileName(response: HttpResponse<Blob>): string {
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        const filenameMatch = contentDispositionHeader && contentDispositionHeader.match(/attachment; filename=(.+)/);

        if (filenameMatch && filenameMatch[1]) {
            return filenameMatch[1];
        }

        return "Unknown";
    }

    private successGetDevicesBackup(response: Array<DeviceBackupConfigurationView>): void {
        this.refreshActions.finishedUpdatingFn();
        this.update(response);
        this.tableBodyMessage = null;
        this.applyGlobalFilterUserPreferences();
    }

    private errorGetDevicesBackup(): void {
        this.refreshActions.finishedUpdatingFn();
        this.showDialog("http.error.serverError");
        this.tableBodyMessage = null;
    }

    private loadDevicesBackup(): void {
        this.refreshActions.startUpdatingFn();

        this.backupsProvider().subscribe(
            (response: Array<DeviceBackupConfigurationView>) => this.successGetDevicesBackup(response),
            (error) => this.errorGetDevicesBackup()
        );
    }

    private isOnlyOneBackupSelected(devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) {
        if (devicesBackupConfiguration.length > 1) {
            this.showDialog("backup.devices.table.actions.message.selectOnlyOne");
            return false;
        }

        return true;
    }

    private handleBackupsRemoving(devicesBackupConfiguration: Array<DeviceBackupConfigurationView>) {
        const messageKey = devicesBackupConfiguration.length === 1
            ? "backup.devices.table.actions.message.remove.one"
            : "backup.devices.table.actions.message.remove.multiple";
        this.showConfirmDialog(messageKey).then(() => {
            this.isLoading = true;
            const backupIds = devicesBackupConfiguration.map(item => parseInt(item.cfgId));
            this.dcbService.removeBackups(backupIds).subscribe((removeBackupStatusList: Array<RemoveBackupStatus>) => {
                const wasRemoved = removeBackupStatusList.filter(backup => backup.wasRemoved);
                const wasNotRemoved = removeBackupStatusList.filter(backup => !backup.wasRemoved);
                if (wasRemoved.length > 0) {
                    this.showSuccessMessageRemovedBackups(wasRemoved.length);
                }
                if (wasNotRemoved.length > 0) {
                    this.showDialogConfirmMessageWithErrorRemovedBackups(wasNotRemoved);
                }
                this.isLoading = false;
            },
                (errorResponse: HttpErrorResponse) => {
                    this.showErrorMessage("backup.devices.modal.error.remove.dcb", errorResponse);
                    this.isLoading = false;
                });
        });
    }

    private showSuccessMessageRemovedBackups(amountOfRemoved: number) {
        this.toastr.success(this.getSuccessRemoveMessage(amountOfRemoved));
        this.loadDevicesBackup();
    }

    private showDialogConfirmMessageWithErrorRemovedBackups(wasNotRemoved: Array<RemoveBackupStatus>) {
        const backupIds = wasNotRemoved.map(backup => backup.backupId);
        const backupNames = this.backups.filter(backup => backupIds.includes(parseInt(backup.cfgId)))
            .map(item => item.jobName);
        this.showDialog("backup.devices.table.action.toastr.removed.error", backupNames);
    }

    private getSuccessRemoveMessage = (amountOfRemoved: number): string => {
        return amountOfRemoved > 1
            ? this.translate
                .instant("backup.devices.table.action.toastr.removed.multiple")
                .replace("{0}", amountOfRemoved)
            : this.translate.instant("backup.devices.table.action.toastr.removed.one");
    };

    /**
     * Verifica se o equipamento é gerenciado pelo NES.
     */
    private async isDeviceModelSupportedByOperation(cfgId: string) {
        return await this.dcbService.isDeviceSupported(parseInt(cfgId)).toPromise();
    }

    private openCreateBackupDialog() {
        this.modalFactory.openAsyncModal(CreateBackupModalComponent);
    }

    private openRestoreBackupDialog({ cfgId, deviceHostname }: DeviceBackupConfiguration): void {
        this.validateAndOpenModal(cfgId, deviceHostname, RestoreDeviceBackupModalComponent);
    }

    private openViewBackupDialog({ deviceHostname, cfgId }: DeviceBackupConfiguration): void {
        this.validateAndOpenModal(cfgId, deviceHostname, BackupConfigurationViewModalComponent);
    }

    private async validateAndOpenModal(cfgId: string, deviceHostname: string, component: any) {
        try {
            if (await this.isDeviceModelSupportedByOperation(cfgId)) {
                this.modalFactory.openAsyncModal(component, { deviceHostname, cfgId: parseInt(cfgId) });
            }
        } catch (errorResponse) {
            const httpError: HttpErrorResponse = errorResponse;
            const errorMessage = this.getErrorMessage(httpError);
            if (httpError?.error?.code === DcbError.DCB_MODEL_OPERATION_ERROR) {
                this.showDialog(errorMessage[0]);
            }
        }
    }

    private showDialog(key: TranslationKey, parameters?: Array<string>): void {
        let msg = this.translate.instant(key);

        if (parameters != undefined) {
            msg += this.buildParametersMessage(parameters);
        }
        this.nmsDialogService.openDialog({ description: msg, acceptLabel: "OK", bindHtml: true }, { maxWidth: "800px" });
    }

    private showErrorMessage(mainMessage: TranslationKey, errorResponse: HttpErrorResponse) {
        if (errorResponse.error) {
            const errorMessage = this.getErrorMessage(errorResponse);
            this.showDialog(mainMessage, errorMessage);
        }
    }

    private showConfirmDialog(key: TranslationKey): Promise<any> {
        return this.$rootScope.showDialog({ translateKey: key, isConfirm: true });
    }

    private buildParametersMessage(parameters: Array<string>) {
        return "<br>" + parameters.join("<br>");
    }
}
