"use strict";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import {
    DeviceManagementProtocolsModalComponent
} from "@nms-ng2/app/shared/components/modals/device-management-protocols/device-management-protocols-modal.component";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import { Promise } from "bluebird";

/**
 * @ngdoc service
 * @name nms.CredentialsService
 * @description - Serviço para gerenciamento de credenciais.
 * Factory in the nms.
 */
var app = angular.module("nms");

app.factory("CredentialsService", [
    "$rootScope",
    "CredentialsRestService",
    "ngDialog",
    "$translate",
    "$q",
    "SNMP_DATA",
    "ModalFactory",
    "NmsToastrService",
    "TranslationHelperService",
    function ($rootScope, CredentialsRestService, ngDialog, $translate, $q, SNMP_DATA, modalFactory,
        nmsToastrService: NmsToastrService, translationHelperService: TranslationHelperService) {
        const service: any = {};
        var forms: any = { global: {}, specific: {} };
        var globalCredentials;
        var specificCredentials;

        /**
         * Retorna as credenciais especificas a partir de um device.
         *
         * @param {object} device Device que terá as credenciais atualizadas.
         */
        var resolveDeviceSpecificCredentials = function (device) {
            specificCredentials = CredentialsRestService.getSpecificCredentials(device.hostname);

            return specificCredentials;
        };

        /**
         * Retorna as credenciais globais.
         */
        var resolveGlobalCredentials = function () {
            globalCredentials = CredentialsRestService.getGlobalCredentials();

            return globalCredentials;
        };

        /**
         * Atualiza as credenciais de um device.
         *
         * @param {object} device Device que terá as credenciais atualizadas.
         */
        var updateDeviceCredentials = function (devicesCredentials, isGlobal) {
            return CredentialsRestService.updateCredentials(devicesCredentials, isGlobal);
        };

        service.clearDefaultUserAndPasswordForSnmpV3 = function (credentials) {
            var snmpCredentials = credentials.snmpCredential;

            if (snmpCredentials && !_.isEqual(snmpCredentials.version, SNMP_DATA.SNMP_VERSION.V3)) {
                snmpCredentials.passPhrase = "";
                snmpCredentials.userPassword = "";
                snmpCredentials.username = "";
            }
        };

        /**
         * Itera sob as credenciais para popular os dados do formulário da modal.
         *
         * @param {object} credenciais Credenciais atuais.
         * @param {object} form Formulário a ser preenchido (global ou específico).
         */
        var buildCredentials = function (credentials, form) {
            if (credentials) {
                fillSnmpCredentials(credentials.snmpCredential, form);
                fillTelnetCredentials(credentials.telnetCredential, form);
                fillNetconfCredentials(credentials.netconfCredential, form);
                fillSftpCredentials(credentials.sftpCredential, form);
                fillHttpCredentials(credentials.httpCredential, form);
                fillPcgaCredentials(credentials.pcgaCredential, form);
                fillSshCredentials(credentials.sshCredential, form);
            }
            return forms;
        };

        var fillSnmpCredentials = function (credential, form) {
            form.readCommunitySnmpV1 = credential.readCommunity;
            form.writeCommunitySnmpV1 = credential.writeCommunity;
            form.readCommunitySnmpV2C = credential.readCommunity;
            form.writeCommunitySnmpV2C = credential.writeCommunity;
            form.userSNMPv3 = credential.username;
            form.passSNMPv3 = credential.userPassword;
            form.privacyPass = credential.passPhrase;
            form.version = credential.version;
            form.authType = credential.authType;
            form.timeoutSnmp = credential.timeout;
            form.retriesSnmp = credential.retries;
            form.privacy = !_.isEqual(credential.cypher, SNMP_DATA.CYPHER.NONE);
            form.cypher = _.isEqual(credential.cypher, SNMP_DATA.CYPHER.NONE) ? SNMP_DATA.CYPHER.DES : credential.cypher;
        };

        var fillTelnetCredentials = function (credential, form) {
            form.userCliOverTelnet = credential.username;
            form.passCliOverTelnet = credential.password;
            form.timeoutCliOverTelnet = credential.timeout;
            form.retriesCliOverTelnet = credential.retries;
        };

        var fillNetconfCredentials = function (credential, form) {
            form.userNetConfOverSsh = credential.username;
            form.passNetConfOverSsh = credential.password;
            form.timeoutNetConfOverSsh = credential.timeout;
        };

        var fillSftpCredentials = function (credential, form) {
            form.passSFTP = credential.password;
            form.timeoutSFTP = credential.timeout;
        };

        var fillHttpCredentials = function (credential, form) {
            form.userHTTP = credential.username;
            form.passHTTP = credential.password;
            form.timeoutHTTP = credential.timeout;
            form.retriesHTTP = credential.retries;
        };

        var fillPcgaCredentials = function (credential, form) {
            form.userNMS = credential.pcgaUser;
            form.passNMS = credential.pcgaPassword;
            form.timeoutNMS = credential.timeout;
            form.retriesNMS = credential.retries;
        };

        var fillSshCredentials = function (credential, form) {
            form.userCliOverSsh = credential.username;
            form.passCliOverSsh = credential.password;
            form.portCliOverSsh = credential.port;
            form.timeoutCliOverSsh = credential.timeout;
            form.retriesCliOverSsh = credential.retries;
        };

        var fillForm = function (credentials) {
            var currentGlobalCredentials = credentials[0];
            var currentSpecificCredentials = credentials[1] ? credentials[1] : currentGlobalCredentials;
            buildCredentials(currentGlobalCredentials.plain(), forms.global);
            buildCredentials(currentSpecificCredentials.plain(), forms.specific);

            return forms;
        };

        var provideUpdatedDeviceCredentials = function (device) {
            var promises = [];

            promises.push(resolveGlobalCredentials());
            promises.push(resolveDeviceSpecificCredentials(device));

            return $q.all(promises).then(function (data) {
                return fillForm(data);
            });
        };

        var provideUpdatedMultipleDevicesCredentials = function (device) {
            var promises = [];
            promises.push(resolveGlobalCredentials());

            return $q.all(promises).then(function (data) {
                return fillForm(data);
            });
        };

        service.provideUpdatedCredentials = function (currentGlobalCredentials, currentSpecificCredentials) {
            buildCredentials(currentGlobalCredentials, forms.global);
            buildCredentials(currentSpecificCredentials, forms.specific);

            return forms;
        };

        var updateDevice = function (device, credentialsConfig) {
            service.updateCredentialsHostname(credentialsConfig.data, device.hostname);
            device.credentials = credentialsConfig.data;
            device.globalConfig = credentialsConfig.globalConfig;
            updateDeviceCredentials([device.credentials], credentialsConfig.globalConfig);

            const message =  translationHelperService.translate("toastr.deviceSuccessfullyEdited");

            nmsToastrService.info(message);
        };

        /**
         * Abre modal para um device.
         *
         * @param {object} device Device a ter credenciais editadas.
         */
        service.openDeviceCredentials = function (device) {
            var options: any = {
                isMultipleSelection: false,
                formData: provideUpdatedDeviceCredentials(device),
                guiSettings: {
                    subtitle: device.hostname
                },
                finishCallback: function (credentials) {
                    updateDevice(device, credentials);
                },
                specificCredentials: specificCredentials,
                globalCredentials: globalCredentials
            };

            service.openGenericCredentialsModal(options);
        };

        var fillDevicesCredentials = function (devices, credentialsConfig) {
            var credentials = credentialsConfig.data;
            return _.map(devices, function (device) {
                var deviceCredentials = angular.copy(credentials);
                service.updateCredentialsHostname(deviceCredentials, device.hostname);
                return deviceCredentials;
            });
        };

        var updateDevices = function (devicesCredentials, isGlobal) {
            updateDeviceCredentials(devicesCredentials, isGlobal).then(function (updatedDevices) {
                if (devicesCredentials.length === updatedDevices.length) {
                    const message = translationHelperService.translateWithReplacement("toastr.devicesSuccessfullyEdited",
                        devicesCredentials.length);
                    nmsToastrService.success(message);
                } else if (_.isEmpty(updatedDevices)) {
                    const message = translationHelperService
                        .translate("modals.managementProtocols.credentials.noChangesInConfigs");
                    nmsToastrService.info(message);
                } else {
                    const message = translationHelperService
                        .translateWithReplacement("modals.managementProtocols.credentials.devicesPartiallyUpdated",
                        updatedDevices.length);
                    nmsToastrService.success(message);
                }
            });
        };

        /**
         * Abre modal para multiplos devices.
         *
         * @param {Array} device Devices a terem credenciais editadas.
         */
        service.openDevicesCredentials = function (devices) {
            var options: any = {
                isMultipleSelection: true,
                formData: provideUpdatedMultipleDevicesCredentials(devices),
                guiSettings: {
                    subtitle: $translate.instant("modals.managementProtocols.modalSubTitleMultipleHosts"),
                    tipMessage: $translate.instant("modals.managementProtocols.credentials.popoverDefaultConfig")
                },
                finishCallback: function (credentials) {
                    var updatedDevicesArray = fillDevicesCredentials(devices, credentials);
                    updateDevices(updatedDevicesArray, credentials.globalConfig);
                },
                specificCredentials: [],
                globalCredentials: globalCredentials
            };

            service.openGenericCredentialsModal(options);
        };

        /**
         * Método genérico para abertura da modal de credenciais.
         *
         * @param {object} options Objeto que representa os dados para abertura da modal.
         */
         service.openGenericCredentialsModal = async function (options) {
            let solvedProperties = await Promise.props(options);
            solvedProperties = {
                ... solvedProperties,
                successCallback(credentialsConfig) {
                    options.finishCallback(credentialsConfig);
                }
            }

            modalFactory.openAsyncModal(DeviceManagementProtocolsModalComponent, solvedProperties);
        };

        /**
         * Atualiza todos os campos hostname de uma credencial.
         * Deve ser usado para incluir os hostnames em credenciais que foram copiadas das
         * credenciais globais (que não possuem informação de hostname).
         * @param {object} credential Objeto de credencial que terá seus campos hostname atualizados.
         * @param {string} hostname Hostname que será atribuido a todos campos hostname da credencial.
         */
        service.updateCredentialsHostname = function (deviceCredential, hostname) {
            deviceCredential.hostname = hostname;
            _.forEach(deviceCredential, function (protocolCredential) {
                if (_.isObject(protocolCredential) && protocolCredential.hasOwnProperty("hostname")) {
                    protocolCredential.hostname = hostname;
                }
            });
        };

        return service;
    }
]);
