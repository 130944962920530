import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

/**
 * Service that maintains the data used to generate the configuration view.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

app.service("NesDataCacheService", ["NesRestService", "$rootScope", "$state",
    "NesHttpMessageProvider", "NesErrorHandlerService", "NMS_STATES", "NmsToastrService",
    function(NesRestService, $rootScope, $state, NesHttpMessageProvider, NesErrorHandlerService, NMS_STATES,
        nmsToastrService: NmsToastrService) {
        var currentHash;
        var originalData;
        var currentData;
        var currentDataObserver;
        var schema;

        var notifyDataChanges = function() {
            $rootScope.$broadcast("current-nes-data-changed");
        };

        var updateCache = function(response) {
            var dataString = angular.toJson(response);
            originalData = angular.fromJson(dataString);
            currentData = angular.fromJson(dataString);
            currentHash = originalData.configurationHash;
            if (currentDataObserver) {
                currentDataObserver.unobserve();
            }
            currentDataObserver = jsonpatch.observe(currentData, notifyDataChanges);
            notifyDataChanges();
        };

        /**
         * Function to be executed when the REST request made to NES return successfully.
         *
         * @param {function} deviceRequestFinishedCallback - Callback to be executed after request finished.
         * @param {object} response - Result data obtained from NES.
         */
        function defaultSuccessHandler(deviceRequestFinishedCallback, response?) {
            updateCache(response);
            deviceRequestFinishedCallback();
        }

        /**
         * Function to be executed when an error occured with the REST request made to NES.
         * In this case an error notification is shown and the user is redirected to Devices List.
         *
         * @param {number} deviceId - device identifier
         * @param {Function} deviceRequestFinishedCallback - callback to be executed.
         * @param {object} response - Error result returned by NES.
         */
        function defaultErrorHandler(deviceId, deviceRequestFinishedCallback?, response?) {
            if (angular.isObject(response.data)) {
                NesErrorHandlerService.handleError(response.data, deviceId, deviceRequestFinishedCallback);
            } else {
                var message = NesHttpMessageProvider.errorMessageFor(response);

                nmsToastrService.error(message);
            }
            $state.go(NMS_STATES.manageDevices);
        }

        /**
         * Responsible to verify if the device's configuration hash has changed and invoke the callback.
         *
         * @param {int} deviceId - device identifier
         * @param {Function} configurationChangedCallback - callback to be executed when the configuration hash has changed.
         * @param {Function} errorCallback - callback to be executed when an error occur.
         *      Executed when an error occured with the REST request made to NES.
         *      It is necessary to allow the loading panel action in case of others REST requests.
         */
        this.reloadDataIfConfigurationChanged = function(deviceId, configurationChangedCallback, errorCallback) {
            // TODO - Refatorar este trecho para não realizar o getDevice para verificar a configurationHash.
            NesRestService.getConfigurationData(deviceId, success, errorCallback);

            /**
             * Function to resolve the action to be executed based on the device's configuration hash.
             *
             * @param {object} response - Result data obtained from NES.
             */
            function success(response) {
                if (response.configurationHash !== currentHash) {
                    updateCache(response);
                    configurationChangedCallback();
                }
            }
        };

        var wrapHandlersWithDefaults = function(onCacheUpdateAndActivationCallback, deviceId) {
            var augmentedSuccessHandler = _.curry(defaultSuccessHandler)(onCacheUpdateAndActivationCallback);
            var augmentedErrorHandler = _.curry(defaultErrorHandler)(deviceId, onCacheUpdateAndActivationCallback);

            var handlers: any = {success: augmentedSuccessHandler, error: augmentedErrorHandler};

            return handlers;
        };

        this.reloadData = function(deviceId, onCacheUpdateAndActivationCallback) {
            var handlers = wrapHandlersWithDefaults(onCacheUpdateAndActivationCallback, deviceId);

            NesRestService.getConfigurationData(deviceId, handlers.success, handlers.error);
        };

        this.compareCurrentData = function() {
            return jsonpatch.compare(originalData, currentData);
        }

        this.loadSchema = function(deviceId, onSchemaUpdatedCallback) {
            var errorHandler = _.curry(defaultErrorHandler)(deviceId, angular.noop);

            var successHandler = function(response) {
                schema = response;
                onSchemaUpdatedCallback()
            };

            NesRestService.getSchema(deviceId, successHandler, errorHandler);
        };

        this.getOriginalData = function() {
            return originalData;
        };

        this.getCurrentData = function() {
            return currentData;
        };

        this.getSchema = function() {
            return schema;
        };

        this.clear = function() {
            originalData = undefined;
            currentData = undefined;
            schema = undefined;
        };
    }
]);
