import { Injectable } from "@angular/core";
import { IncludeBlockOptions, InclusionBlockingParams, Variable } from "../../../template-instance/variables/variables.models";

/**
 * Serviço responsável por tratamentos referentes aos comandos
 * de variáveis
 */
@Injectable({
    providedIn: "root"
})
export class CommandsVariablesService {

    constructor() { }

    public getVariablesCommands(variable: Variable): Array<string> {
        switch (variable.includeAndBlockOptionMode) {
            case IncludeBlockOptions.INCLUDE:
                return this.getExtractionParamsCommands(variable.inclusionParams);
            case IncludeBlockOptions.BLOCK:
                return this.getExtractionParamsCommands(variable.blockingParams);
            case IncludeBlockOptions.INCLUDE_AND_BLOCK:
                return _.union(
                    this.getExtractionParamsCommands(variable.inclusionParams),
                    this.getExtractionParamsCommands(variable.blockingParams)
                );
            default:
                return [];
        }
    }

    private getExtractionParamsCommands(extractionParamsCommands: InclusionBlockingParams): Array<string> {
        return _.chain([extractionParamsCommands.commands, extractionParamsCommands.transformationLogicCommands])
            .filter(_.negate(_.isEmpty))
            .value();
    }

}
