"use strict";

/**
* @ngdoc service
* @name nms.PollingService
* @description
* # PollingService
*
* Serviço que realiza a chamada REST para o serviços de polling e controla o
* websocket responsável por receber os status de polling.
*/
var app = angular.module("nms");

app.factory("PollingService", ["$window", "$websocket", "DevicePollingRESTService", "POLLING_STATES", "WEBSOCKET_STATES",
    function($window, $websocket, DevicePollingRESTService, POLLING_STATES, WEBSOCKET_STATES) {
        var service: any = {
            websocket: null
        };

        var createPollingRequests = function(devices) {
            return _.map(devices, function(device) {
                return {
                    resourceId: device.id,
                    devNo: device.devNo,
                    devLocalId: device.devLocalId,
                    hostname: device.hostname,
                    modelCode: device.modelCode
                };
            });
        };

        /**
        * Conecta no websocket e trata mensagens de polling feedback.
        *
        * @param {Function} notifyDeviceUpdated função que processa um equipamento atualizado. Deve receber como parâmetro o
        * device atualizado, recebido via payload da mensagem de polling feedback na finalização de um polling, seja esse com
        * sucesso ou com falha.
        *
        * @param {Function} notifyDevicesPollingRunningStatus função que processa uma mudança de status de polling. Deve receber
        * como parâmetro uma lista de resourceIds que devem ser atualizados e o status do andamento do polling.
        */
        service.connectOnWebSocket = function(notifyDeviceUpdated, notifyDevicesPollingRunningStatus) {
            service.websocket = $websocket("wss://" + $window.location.host + "/service/broadcast/device/notification");

            service.websocket.onMessage(function(event) {
                var pollingFeedback = JSON.parse(event.data);
                var isPollingRunning = pollingFeedback.percentage >= POLLING_STATES.STARTED
                    && pollingFeedback.percentage < POLLING_STATES.FINISHED;

                notifyDevicesPollingRunningStatus([pollingFeedback.resourceId], isPollingRunning);

                if (!_.isEmpty(pollingFeedback.payload)) {
                    notifyDeviceUpdated(pollingFeedback.payload);
                }
            });

            service.websocket.onOpen(function() {
                DevicePollingRESTService.getDevicesPollingUnderway().then(function(resourceIds) {
                    notifyDevicesPollingRunningStatus(resourceIds, true);
                });
            });
        };

        service.disconnect = function() {
            if (service.websocket != null) {
                service.websocket.close();
            }
        };

        service.requestPollings = function(devices) {
            var pollingRequests = createPollingRequests(devices);

            return DevicePollingRESTService.requestPollings(pollingRequests);
        };

        service.isWebSocketConnected = function() {
            return service.websocket != null && service.websocket.readyState === WEBSOCKET_STATES.OPEN;
        };

        return service;
    }
]);
