import { Component, Input, OnInit } from "@angular/core";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { TimepickerConfig } from "ngx-bootstrap/timepicker";
import {
    FrequencyTriggerType,
    TimeTriggerData,
    Trigger,
    TriggerType
} from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";
import { TimeTriggerService } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/time-trigger.service";

export function getTimepickerConfig(): TimepickerConfig {
    return Object.assign(new TimepickerConfig(), {
        minuteStep: 1,
        showMeridian: false,
        mousewheel: true,
        showMinutes: true,
        showSeconds: false,
        showSpinners: true,
        labelHours: "Hours",
        labelMinutes: "Minutes",
        labelSeconds: "Seconds"
    });
}

/**
 * Classe responsável por exibir o formulário do agendamento por data/hora e expressão cron
 */
@Component({
    selector: "time-trigger",
    templateUrl: "./time-trigger.component.html",
    styleUrls: ["./time-trigger.component.scss"],
    providers: [{ provide: TimepickerConfig, useFactory: getTimepickerConfig }]
})
export class TimeTriggerComponent implements OnInit {
    /**
     * O tema 'theme-dark-blue' é o tema base usado na customização do componente
     * de calendário de acordo com as cores do DmView Maestro.
     * A alteração desta classe, removerá toda a customização, deixando o estilo
     * default da biblioteca.
     */
    private readonly THEME_DARK_BLUE = "theme-dark-blue";

    private readonly DAYS_AFTER_INITIAL_DATE = 7;

    locale: string;
    dataLayoutConfig: any = {};
    frequencies: FrequencyTriggerType[];
    minimumDate: Date;

    @Input("timeTrigger") trigger: Trigger;
    @Input()
    hasSchedulerPermission: boolean;

    /** Exibe o checkbox 'Agendar aplicação por data/hora' em alguns casos, esse componente não é necessário */
    @Input()
    showDataTimeCheck: boolean;

    constructor(
        private readonly localeService: BsLocaleService,
        private readonly timeTriggerService: TimeTriggerService,
        private readonly triggersService: TriggersService
    ) {}

    ngOnInit(): void {
        this.locale = this.triggersService.getLocale();
        this.localeService.use(this.locale);
        this.dataLayoutConfig.containerClass = this.THEME_DARK_BLUE;
        this.dataLayoutConfig.dateInputFormat = this.triggersService.isPtBr(this.locale)
            ? this.triggersService.DATE_FORMAT_BR
            : this.triggersService.DATE_FORMAT_US;
        this.minimumDate = new Date();
        this.dataLayoutConfig.showWeekNumbers = false;
        this.loadSchedulerFrequency();
    }

    isCronSelected(): boolean {
        return this.trigger.triggerType === TriggerType.CRON;
    }

    setFinalDate(eventDate: Date) {
        if (eventDate) {
            this.timeTriggerService.setFinalDate(this.trigger, eventDate, this.DAYS_AFTER_INITIAL_DATE);
        }
    }

    setFinalDateWhenDisable(onChangeEvent: Event & { target: HTMLInputElement }) {
        const timeTriggerData = this.trigger.triggerData as TimeTriggerData;

        if (onChangeEvent.target.checked === false && timeTriggerData.initialDate >= timeTriggerData.finalDate) {
            this.timeTriggerService.setFinalDate(this.trigger, timeTriggerData.initialDate);
        }
    }

    loadSchedulerFrequency(eventDate?: Date): void {
        const initialDate = eventDate || (this.trigger.triggerData as TimeTriggerData).initialDate || new Date();
        this.frequencies = Object.keys(TriggerType)
            .filter((triggerType) => this.filterInvalidFrequency(triggerType))
            .map((key) => {
                const type = key as TriggerType;
                const label = this.timeTriggerService.getFrequencyTriggerTypeLabel(type, initialDate);
                return { id: type, label };
            });
    }

    /**
     * Agendamentos do tipo ON_DEMAND não tem data final definida.
     * Este tipo de agendamento mantém no servidor agendado para a maior data possível.
     */
    canShowDateForm(): boolean {
        return this.trigger.triggerType !== TriggerType.ON_DEMAND;
    }

     /**
     * Filtra os valores TR069_EVENT e TRAP para que não apareçam no combo-box da frequência do agendamento
     */
     private filterInvalidFrequency(triggerType: string) {
        return triggerType !== TriggerType.TR069_EVENT && triggerType !== TriggerType.TRAP;
    }

}
