import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NmsNotificationFeedbackService } from "./nms-notification-feedback.service";
import { NotificationMessage } from "./nms-notification.models";
import { webSocket } from "rxjs/webSocket";

/**
 * Service que se conecta a um Websocket para recebimento de notificações do usuário.
 */
@Injectable({
    providedIn: "root"
})
export class NmsNotificationWebSocketService {
    private webSocketUrl: string;
    private connectedWebSocket: Subject<NotificationMessage>;

    constructor(@Inject(DOCUMENT) private document: Document,
        private nmsNotificationFeedbackService: NmsNotificationFeedbackService) { }

    public connect = (username: string): void => {
        const { location: { host } } = this.document;
        this.webSocketUrl = `wss://${host}${username}`;
        this.connectedWebSocket = webSocket(this.webSocketUrl);
        this.connectedWebSocket.subscribe(
            (notification: NotificationMessage) => this.onMessage(notification), this.onError, this.onClose);
    }

    public disconnect = (): void => {
        this.connectedWebSocket.unsubscribe();
    }

    private onMessage(notification: NotificationMessage): void {
        this.nmsNotificationFeedbackService.showNotification(notification);
    }

    private onError(err: any): void {
        console.error(err);
    }

    private onClose(): void {
        console.log(`WebSocket ${this.webSocketUrl} was closed.`);
    }
}