import { Component, Inject, OnInit } from "@angular/core";
import { StatusConfiguration } from "@nms-angular-toolkit/nms-status";
import { SimpleModalComponent } from "ngx-simple-modal";
import { DeviceBackupService } from "../device-backup.service";
import { SchedulerListService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.service";
import { DcbBackupResult, DcbResultStatus } from "../device-backup.models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsProgressBarModel, NmsProgressBarSegment } from "@nms-angular-toolkit/nms-progress-bar";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";

/**
 * Dados necessários para abertura modal de backup de equipamento.
 */
export interface BackupJobResultRequest {
    schedulerJobId: string;
}
/**
 * Modal de resultados de tarefa de criação de backups de equipamentos.
 */
@Component({
    selector: "device-result-backup-modal",
    templateUrl: "./device-result-backup-modal.component.html",
    styleUrls: ["./device-result-backup-modal.component.scss"]
})
export class DeviceResultBackupModalComponent extends SimpleModalComponent<BackupJobResultRequest, any>
    implements OnInit, BackupJobResultRequest {

    schedulerJobId: string;
    backupResults: Array<DcbBackupResult> = [];
    isLoading: boolean = true;
    progressBarModel: NmsProgressBarModel;

    private readonly deviceStatusConfigMap: Partial<{ [status in DcbResultStatus | "FAIL"]: StatusConfiguration }>;
    private readonly failStatusMessageMap: Partial<{ [status in DcbResultStatus]: string }>;

    constructor(private readonly deviceBackupService: DeviceBackupService,
        private readonly schedulerList: SchedulerListService,
        private readonly nmsDialogService: NmsDialogService,
        @Inject(ANGULARJS_TRANSLATE) private readonly $translate: any) {
        super();

        this.deviceStatusConfigMap = {
            SUCCESS: {
                bgColor: "#1d9d23",
                label: $translate.instant("backup.devices.result.status.SUCCESS"),
                color: "#fff"
            },
            NOT_NECESSARY: {
                bgColor: "#72e378",
                color: "#333",
                label: $translate.instant("backup.devices.result.status.NOT_NECESSARY")
            },
            FAIL: {
                bgColor: "#b12525",
                color: "#fff",
                label: $translate.instant("backup.devices.result.status.FAIL")
            }
        };

        this.failStatusMessageMap = {
            AWAITING_JOB_EXECUTION: $translate.instant("backup.devices.result.status.error.AWAITING_JOB_EXECUTION"),
            SQL_ERROR: $translate.instant("backup.devices.result.status.error.SQL_ERROR"),
            DAO_ERROR: $translate.instant("backup.devices.result.status.error.DAO_ERROR"),
            UNKNOWN_ERROR: $translate.instant("backup.devices.result.status.error.UNKNOWN_ERROR"),
            BLOCK_POLLING_REQUEST_ERROR: $translate.instant("backup.devices.result.status.error.BLOCK_POLLING_REQUEST_ERROR"),
            DEVICE_ACCESS_ERROR: $translate.instant("backup.devices.result.status.error.DEVICE_ACCESS_ERROR"),
            SERVER_ACCESS_ERROR: $translate.instant("backup.devices.result.status.error.SERVER_ACCESS_ERROR"),
            DEVICE_LOCK_ERROR: $translate.instant("backup.devices.result.status.error.DEVICE_LOCK_ERROR")
        };
    }

    async ngOnInit(): Promise<void> {
        try {
            const { backupResultIds } = await this.schedulerList.getSchedulerJobResultById(this.schedulerJobId).toPromise();
            this.backupResults = await this.deviceBackupService.getBackupResults(backupResultIds).toPromise();
            this.updateProgressBarData();
            this.isLoading = false;
        } catch (error) {
            this.nmsDialogService.openDialog({
                description: this.$translate.instant("backup.devices.table.actions.message.error.unableToRetrieveDcbResults"),
                acceptLabel: "OK"
            });
            this.close();
        }
    }

    convertStatusConfiguration(status: DcbResultStatus): StatusConfiguration {
        const statusConfiguration = this.deviceStatusConfigMap[status];

        if (statusConfiguration) {
            return statusConfiguration;
        }

        return {
            ...this.deviceStatusConfigMap["FAIL"],
            tooltip: this.failStatusMessageMap[status]
        };
    }

    private updateProgressBarData(): void {
        const values = this.buildProgressBarValues();

        this.progressBarModel = {
            description: this.$translate.instant("backup.devices.result.progress.total"),
            values,
            precision: 0,
            sorted: false,
            width: "100%",
            minWidth: "400px",
            tooltipText: this.$translate.instant("backup.devices.result.progress.bar.not.necessary.tooltip")
        };
    }

    /**
     * Constrói o modelo de dados para o componente nms-progress-bar.
     *
     * Devido ao requisito de ordem de apresentação dos valores da barra, esta função depende da ordem
     * definida em this.deviceStatusConfigMap. A ordem das chaves desse objeto será a ordem utilizada
     * pelo componente de barra de progresso.
     */
    private buildProgressBarValues() {
        const progressBarValuesMap: Partial<{ [status in DcbResultStatus | "FAIL"]: NmsProgressBarSegment }> = {};
        const deviceStatusKeys = Object.keys(this.deviceStatusConfigMap);
        const resultsByStatus = _.groupBy(this.backupResults, (result) => {
            return deviceStatusKeys.includes(result.status) ? result.status : "FAIL"
        });
        const resultStatusKeys = Object.keys(resultsByStatus);

        for (const statusKey of deviceStatusKeys) {
            if (resultStatusKeys.includes(statusKey)) {
                const { bgColor, color, label } = this.deviceStatusConfigMap[statusKey];

                progressBarValuesMap[statusKey] = {
                    amount: resultsByStatus[statusKey].length,
                    label,
                    bgColor,
                    textColor: color
                };
            }
        }

        return Object.keys(progressBarValuesMap).map(value => progressBarValuesMap[value]);
    }
}
