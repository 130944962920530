import { EventEmitter, Inject, Injectable } from "@angular/core";
import { ReplaceLineBreakPipe } from "../../pipes/replace-line-break.pipe";
import { ANGULARJS_TRANSLATE } from "../upgraded-provider/upgraded-providers";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";

/**
 * Utilitário para tratar as mensagens das notificações e seus detalhes.
 */
@Injectable({
    providedIn: "root"
})
export class NmsNotificationUtilsService {

    private readonly BREAK_LINE_ASCII_CODE = "&#10;";
    private readonly BREAK_LINE_HTML_TAG = "<br>|<br />";
    private readonly NEW_LINE_CHARACTER = "\n";
    private readonly MAX_LENGTH_WORD = 70;
    private readonly STRONG_OR_CODE_TAG = "</?(strong|code)>";
    public notificationCentralCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private replaceLineBreakPipe: ReplaceLineBreakPipe,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        private nmsDialogService: NmsDialogService) { }

    /**
      * Reduz mensagens maiores que MAX_NUMBER_CHARACTERS carecteres e com mais de MAX_LINES linhas
      * e adiciona "...".
      */
    public prepareMessageToShow(message: string, maxNumberOfLines: number, maxNumberCharacters: number) {
        const maxLines = _.take(message.split(this.NEW_LINE_CHARACTER), maxNumberOfLines);
        const messageComplete = maxLines.join(this.NEW_LINE_CHARACTER).replace(new RegExp(this.STRONG_OR_CODE_TAG, "g"), "");

        const cutMessage = messageComplete.substring(0, maxNumberCharacters).trim();
        if (maxLines.length > maxNumberOfLines || message.length > maxNumberCharacters) {
            return cutMessage.concat("...");
        }

        return cutMessage;
    }

    /**
     * Recebe a mensagem com quebras de linha usando "&#10;", "<br>" ou "<br />" e converte para "\n".
     */
    replaceBreakLineFromAsciiAndHTMLToNewLine(message: string) {
        return message.replace(new RegExp(`${this.BREAK_LINE_ASCII_CODE}|${this.BREAK_LINE_HTML_TAG}`, "g"), this.NEW_LINE_CHARACTER);
    }

    /**
     * Avalia as condições para a exibição do link "Veja mais".
     */
    public verifySeeMoreLink(message: any, maxNumberOfLines: number, maxNumberCharacters: number) {
        return message.length > maxNumberCharacters
            || message.split(this.BREAK_LINE_ASCII_CODE).length > maxNumberOfLines
            || message.split(this.NEW_LINE_CHARACTER).length > maxNumberOfLines
            || this.verifyLongWord(message);
    }

    /**
     * Abre a modal de detalhes da notificação.
     * @param message - Detalhes a serem exibidos pela modal.
     */
    openSeeMoreLink(message: string) {
        this.nmsDialogService.openDialog(
            {
                title: this.translate.instant("nms-notification.details"),
                description: this.replaceLineBreakPipe.transform(message),
                acceptLabel: "OK",
                bindHtml: true
            },
            {
                maxWidth: "710px",
                maxHeight: "100vh"
            }
        );
    }

    /**
     * Verifica se alguma palavra é grande demais para que, se for, seja mostrado o link "Ver mais"
     */
    verifyLongWord(message: string): boolean {
        return message.split(' ').some(word => word.length > this.MAX_LENGTH_WORD);
    }
}