import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResponse } from "@nms-angular-toolkit/nms-table";
import { NotificationRequest, NotificationStateChangeEvent, NotificationSummary } from "./nms-notification.models";

/**
 * Classe responsável por realizar as requisições referentes as notificações do usuário
 */
@Injectable({
    providedIn: "root"
})
export class NmsNotificationService {

    private baseUrl: string;
    public removeItemList: EventEmitter<NotificationStateChangeEvent> = new EventEmitter<NotificationStateChangeEvent>();

    constructor(private http: HttpClient) {
        this.baseUrl = "/notifications";
    }

    public getNotificationSummary(): Observable<NotificationSummary> {
        return this.http.get<NotificationSummary>(`${this.baseUrl}/summary`);
    }

    public setAllRead(): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/read-all`, {});
    }

    public setAllUnread(): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/unread-all`, {});
    }

    public deleteAllNotifications(): Observable<void> {
        return this.http.delete<void>(this.baseUrl);
    }

    public deleteAllReadNotifications(): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/read`);
    }

    public deleteAllUnreadNotifications(): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/unread`);
    }

    public getNotificationsList(notificationRequest: NotificationRequest): Observable<PaginatedResponse> {
        return this.http.post<PaginatedResponse>(`${this.baseUrl}`, notificationRequest);
    }

    public getUnseenNotificationsAmount(): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/unseen/amount`);
    }

    public setAllAsSeen(): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/mark-all-as-seen`, {});
    }

    public toggleReadProperty(read: boolean, notificationId: string): Observable<void> {
        const readOrUnread = read ? "unread" : "read";
        const url = `${this.baseUrl}/${readOrUnread}/${notificationId}`;
        return this.http.post<void>(url, {});
    }

    public removeNotificationById(notificationId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${notificationId}`, {});
    }
}