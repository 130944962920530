"use strict";

import {
    DeletionStatus,
    ExecutionOrigin,
    TemplateInfo,
    TemplateInstance,
    TotalKeywords
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { PaginationResponse } from "app/shared/utils/rest-pagination/rest-pagination";
import { TemplateInstanceService } from "./template-instance-service";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

/**
 * @ngdoc function
 * @name nms.templates.controller:TemplateinstancelistingCtrl
 * @description
 * # TemplateinstancelistingCtrl
 * Controller of the nms.templates
 */
var app = angular.module("nms.templates");

app.controller("TemplateInstanceListCtrl", [
    "$scope",
    "$rootScope",
    "$translate",
    "$timeout",
    "$state",
    "TemplateInstanceService",
    "TemplateInstanceValidatorService",
    "ngDialog",
    "searchCriteria",
    "ACTIONS_LIST",
    "NMS_STATES",
    "ServerPaginationService",
    "APPLICATION_STATUS",
    "UserPreferencesService",
    "TEMPLATE_TYPE",
    "APPLICATION_COMMANDS",
    "EXECUTION_ORIGIN",
    "BROADCAST_EVENTS",
    "NmsToastrService",
    function (
        $scope,
        $rootScope,
        $translate,
        $timeout,
        $state,
        TemplateInstanceService: TemplateInstanceService,
        TemplateInstanceValidatorService,
        ngDialog,
        searchCriteria,
        ACTIONS_LIST,
        NMS_STATES,
        ServerPaginationService,
        APPLICATION_STATUS,
        UserPreferencesService,
        TEMPLATE_TYPE,
        APPLICATION_COMMANDS,
        EXECUTION_ORIGIN,
        BROADCAST_EVENTS,
        nmsToastrService: NmsToastrService
    ) {
        $scope.error = false;
        $scope.checked = { templateInstances: [], selectAllCheckbox: false };
        $scope.templateInstances = [];
        $scope.filters = ServerPaginationService.getFilters({
            origin: "templateInstance",
            column: "lastModified",
            currentType: "TEXT",
            searchTermFinal: "",
            searchTerm: "",
            searchColumn: ""
        });
        $scope.pageDetails = ServerPaginationService.getPageDetails({ numberOfElements: 0 });
        $scope.status = status;
        $scope.searchCriteria = searchCriteria;
        $scope.templateInstanceId;
        $scope.search = {};
        $scope.previousSearch = {};
        $scope.datePicker = {};
        $scope.datePicker.date = { startDate: null, endDate: null };
        $scope.templateCliType = TEMPLATE_TYPE.CLI.name;
        $scope.templateTr069Type = TEMPLATE_TYPE.TR_069.name;

        /**
         * Método utilizado para atualizar o status das aplicações de templates.
         * O status da mensagem é o status final da aplicação, se ele for nulo significa que a aplicação
         * ainda está sendo executada.
         */
        const unregister = $rootScope.$on(BROADCAST_EVENTS.UPDATE_TEMPLATE_APPLICATION_STATUS, function (event, message) {
            $scope.templateInstances.forEach((templateInstance) => {
                if (templateInstance.id === message.templateInstanceId) {
                    templateInstance.status = message.status === null ? APPLICATION_STATUS.APPLYING : message.status;
                }
            });
            $rootScope.$apply();
        });

        $scope.setSearchType = function () {
            $scope.filters.currentType = TemplateInstanceService.getSearchType($scope.searchCriteria[$scope.search.field]);
            if ($scope.filters.currentType !== "OPTION") {
                delete $scope.filters.searchTermFinal;
                $scope.search.value = "";
            }
        };

        $scope.getTemplateTypeLabel = function (templateType) {
            return $translate.instant(TEMPLATE_TYPE[templateType].translationKey);
        };

        $scope.setHasSearchValue = function (value) {
            $timeout(function () {
                $scope.hasSearch = value;
            }, 1);
        };

        $scope.defineTemplateNamesFilterIfNecessary = function () {
            if ($state.params.templateNames) {
                var searchField = "equipmentAssociations.templateAssociations.templateName";
                var searchValue = $state.params.templateNames
                    .map(function (item) {
                        return '"' + item + '"';
                    })
                    .join(" ");

                $scope.defineSearchParameters(searchField, searchValue);
            }
        };

        $scope.defineEquipmentsNamesFilterIfNecessary = function () {
            var searchField;
            var searchValue;
            if ($state.params.devicesNames) {
                searchField = "equipmentAssociations.equipmentIdentifier.resourceId";
                searchValue = $state.params.devicesNames
                    .map(function (item) {
                        return '"' + item + '"';
                    })
                    .join(" ");

                $scope.defineSearchParameters(searchField, searchValue);
            }

            if ($state.params.cpesSerialNumbers) {
                searchField = "equipmentAssociations.equipmentIdentifier.serialNumber";
                searchValue = $state.params.cpesSerialNumbers
                    .map(function (item) {
                        return '"' + item + '"';
                    })
                    .join(" ");

                $scope.defineSearchParameters(searchField, searchValue);
            }
        };

        $scope.defineSearchParameters = function (searchField, searchValue) {
            $scope.search.field = searchField;
            $scope.search.value = searchValue;
            $scope.filters.searchTerm = searchValue;
            $scope.filters.searchColumn = searchField;

            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
        };

        $scope.edit = function (templateInstance) {
            $state.go(NMS_STATES.templateInstance.edit, {
                templateInstance
            });
        };

        $scope.showConfirmMessage = function (templateInstance) {
            $rootScope
                .showDialog({
                    translateKey: "popups.confirm.templateInstance.cancelConfirmation",
                    isConfirm: true
                })
                .then(function () {
                    $state.go(NMS_STATES.templateInstance.edit, {
                        templateInstance
                    });
                });
        };

        $scope.loadPage = function () {
            $scope.getTotalKeywords();
            TemplateInstanceService.getPaginated($scope.filters).then($scope.successGetPaginated);
        };

        $scope.multipleDeleteFromNMS = function () {
            var isValid = TemplateInstanceValidatorService.validateTemplateInstancesChecked($scope.checked.templateInstances);

            if (isValid) {
                var applyingTemplateInstances = $scope.getApplyingTemplateInstances();

                var message = $scope.getMultipleDeleteConfirmationMessage($scope.checked.templateInstances);

                if (applyingTemplateInstances.length > 0) {
                    message += " " + $translate.instant("popups.alert.templateInstancesApplyingDelete");
                }

                $rootScope
                    .showDialog({
                        message: message,
                        isConfirm: true
                    })
                    .then($scope.confirmedMultipleDeleteFromNMS);
            }
        };

        $scope.getMultipleDeleteConfirmationMessage = function (templateInstances) {
            if (templateInstances.length > 1) {
                return (
                    $translate.instant("popups.confirm.deleteManyMessage") +
                    $scope.checked.templateInstances.length +
                    " " +
                    $translate.instant("popups.alert.templateInstances") +
                    "?"
                );
            } else {
                return $scope.getDeleteConfirmationMessage(templateInstances[0]);
            }
        };

        $scope.getDeleteConfirmationMessage = function (templateInstance) {
            return ($translate.instant("popups.confirm.deleteOneTemplateInstanceMessage") + templateInstance.name + "?");
        };

        $scope.getApplyingTemplateInstances = function () {
            var applyingTemplateInstances = [];
            $scope.checked.templateInstances.forEach(function (templateInstance) {
                if (templateInstance.status === APPLICATION_STATUS.APPLYING) {
                    applyingTemplateInstances.push(templateInstance.name);
                }
            });

            return applyingTemplateInstances;
        };

        $scope.confirmedMultipleDeleteFromNMS = function () {
            const templateInstancesId = TemplateInstanceService.getTemplateInstanceIds($scope.checked.templateInstances);

            TemplateInstanceService.removeFromNMS(templateInstancesId).then($scope.successDeleteFromNMS);
        };

        $scope.successDeleteFromNMS = function (response: Array<DeletionStatus>) {
            const fails = TemplateInstanceService.getFailureIdsDeletions(response);

            if (fails.length > 0) {
                var names = TemplateInstanceService.getTemplateInstanceNamesFromIds(fails, $scope.templateInstances);
                $rootScope.showDialog({
                    translateKey: "popups.confirm.removeTemplateInstancePartial",
                    params: names,
                    maxChars: 40
                });
            } else {
                if (
                    $scope.pageDetails.numberOfElements === $scope.checked.templateInstances.length &&
                    $scope.filters.pageNumber > 0
                ) {
                    $scope.filters.pageNumber--;
                    $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;
                    $scope.setPageFiltersSession();
                }
                const message = $translate.instant("toastr.templateInstancesDeleted");
                nmsToastrService.success(message);
            }
            $scope.loadPage();
        };

        $scope.remove = function (templateInstance) {
            if (templateInstance.status === APPLICATION_STATUS.APPLYING) {
                $rootScope.showDialog({
                    translateKey: "popups.alert.templateInstanceIsApplying"
                });
                return;
            }

            $scope.templateToRemove = templateInstance;

            TemplateInstanceValidatorService.validateTemplateInstanceRemovePermission()
                .then(() => TemplateInstanceValidatorService.validateForDelete(templateInstance.id))
                .then(() =>
                    TemplateInstanceService.verifyRemoveCommands(templateInstance.id).then($scope.successVerifyRemoveCommands)
                )
                .catch(() => $rootScope.showDialog({ translateKey: "templateinstancelisting.userHasNoPermission" }));
        };

        $scope.successVerifyRemoveCommands = function (response: TemplateInfo) {
            if (response.totalTemplates === response.totalTemplatesWithoutRemoveCommands) {
                $rootScope.showDialog({
                    translateKey: "templateinstancelisting.delete.error.thereAreNoRemoveCommandsInTemplates"
                });
            } else if (response.totalTemplatesWithoutRemoveCommands > 0) {
                $rootScope
                    .showDialog({
                        translateKey: "templateinstancelisting.delete.confirm.templateWithoutRemovalCommands",
                        params: response.templateWithoutRemoveCommandsNames,
                        maxChars: 37,
                        isConfirm: true
                    })
                    .then($scope.confirmRemoveTemplateInstance);
            } else {
                const templateName = $scope.templateToRemove.name;
                const message = $translate.instant("popups.confirm.deleteOneTemplateInstanceMessage") + templateName + "?";

                $rootScope
                    .showDialog({
                        message: message,
                        isConfirm: true
                    })
                    .then($scope.confirmRemoveTemplateInstance);
            }
        };

        $scope.confirmRemoveTemplateInstance = function () {
            $scope.templateInstanceId = $scope.templateToRemove.id;
            $scope.templateType = $scope.templateToRemove.type;

            var successFunction = function (response) {
                $scope.openShowRemoveResults(response, $scope.templateType);
                TemplateInstanceService.removeTemplateInstance($scope.templateInstanceId);
            };

            $scope.retrieveRemoveResults(successFunction);
        };

        /** ** CALL BACKS *****/
        $scope.successGetPaginated = function ({
            pageElements,
            pageSize,
            pageNumber,
            totalElements,
            numberOfElements
        }: PaginationResponse<TemplateInstance>) {
            $scope.templateInstances = pageElements;
            $scope.filters = ServerPaginationService.updateFilters($scope.filters, pageSize, pageNumber);
            $scope.pageDetails = ServerPaginationService.updatePageDetails(
                $scope.pageDetails,
                totalElements,
                pageNumber,
                pageSize
            );
            $scope.pageDetails.numberOfElements = numberOfElements;
            $scope.checked = { templateInstances: [], selectAllCheckbox: false };
            /* Set previous search criteria */
            $scope.previousSearch.value = $scope.search.value;
            $scope.previousSearch.field = $scope.search.field;

            setTimeout(() => {
                $rootScope.$broadcast("updateTableWidth");
            }, 500);
        };

        $scope.readPageFiltersFromSessionStorage = function () {
            $scope.filters = UserPreferencesService.loadPageFilterValues($scope.filters, $scope.filters.origin);
            $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;
            $scope.search.value = $scope.filters.searchTerm ? decodeURIComponent($scope.filters.searchTerm) : undefined;
            $scope.search.field = $scope.filters.searchColumn;
            $scope.hasSearch = TemplateInstanceService.hasSearchCriteria($scope.filters);
            if ($scope.filters.currentType === "OPTION") {
                $scope.setSearchType();
            }
        };

        $scope.setPageFiltersSession = function () {
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
        };

        $scope.checkUncheckAll = function () {
            $scope.checked.templateInstances = TemplateInstanceService.checkUncheckAll(
                $scope.checked.selectAllCheckbox,
                $scope.templateInstances
            );
        };

        $scope.orderByTable = function (column) {
            if ($scope.filters.column !== column) {
                $scope.filters.column = column;
                $scope.filters.direction = "ASC";
            } else {
                $scope.filters.direction = $scope.filters.direction === "ASC" ? "DESC" : "ASC";
            }
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
            $scope.loadPage();
        };

        $scope.openResult = function (templateInstance) {
            if (templateInstance.dirty) {
                $scope.templateType = templateInstance.type;
                $scope.retrieveRemoveResults($scope.openShowRemoveResults);
            } else {
                $scope.showResults(templateInstance);
            }
        };

        $scope.applyTemplateInstance = function (templateInstance) {
            if (templateInstance.status === APPLICATION_STATUS.APPLYING) {
                $rootScope.showDialog({
                    translateKey: "templateinstancelisting.execute.one.applying"
                });
            } else {
                TemplateInstanceService.isTemplateInstanceApplying(templateInstance.id).then((isApplying) => {
                    const messageKey = isApplying ? "templateinstancelisting.execute.one.applyingByAnother"
                        : "templateinstancelisting.execute.one.confirm";
                    $rootScope.showDialog({
                        translateKey: messageKey,
                        isConfirm: true
                    }).then(() => {
                        const templateInstancesIds = [templateInstance.id];
                        TemplateInstanceService.executeTemplateInstances(templateInstancesIds);
                    });
                });
            }
        };

        $scope.tryApplyTemplateInstances = function () {
            var isValid = TemplateInstanceValidatorService.validateTemplateInstancesChecked($scope.checked.templateInstances);

            if (isValid) {
                if ($scope.checked.templateInstances.length === 1) {
                    $scope.applyTemplateInstance($scope.checked.templateInstances[0]);
                } else {
                    const templateInstancesToApply = $scope.checked.templateInstances
                        .filter((templateInstance: TemplateInstance) => {
                            return templateInstance.status !== APPLICATION_STATUS.APPLYING;
                        })
                        .map((templateInstance: TemplateInstance) => templateInstance.id);

                    if (templateInstancesToApply.length === 0) {
                        $rootScope.showDialog({
                            translateKey: "templateinstancelisting.execute.multi.applying"
                        });
                    } else {
                        $rootScope.showDialog({
                            translateKey: "templateinstancelisting.execute.multi.confirm",
                            isConfirm: true
                        }).then(() => {
                            TemplateInstanceService.executeTemplateInstances(templateInstancesToApply);
                        });
                    }
                }
            }
        };

        $scope.retrieveRemoveResults = function (successFunction) {
            var templateToRemove = $scope.templateInstanceId;
            let templateType = $scope.templateType;

            TemplateInstanceService.getRemoveResultForTemplateInstance(templateToRemove, "REMOVAL").then((response) =>
                successFunction(response, templateType)
            );
        };

        $scope.openShowRemoveResults = function (response, templateType) {
            if (response.equipmentAssociationResults.length > 0) {
                ngDialog
                    .open({
                        template: "templates/features/template/template-instance/modals/remove-results-modal.html",
                        controller: "ResultsModalCtrl",
                        className: "big-modal-remove",
                        resolve: {
                            results: function () {
                                return response;
                            },
                            templateType: function () {
                                return templateType;
                            },
                            hideResultDetails() {
                                return false;
                            },
                            executionOrigin() {
                                return EXECUTION_ORIGIN.TEMPLATE_APPLICATION;
                            },
                            templateInstanceId() {
                                return null;
                            }
                        }
                    })
                    .closePromise.then($scope.loadPage);
            } else {
                $rootScope.showDialog({
                    translateKey: "templateinstancelisting.results.notApplied"
                });
            }
        };

        $scope.getRemoveResultForTemplateInstance = function () {
            return TemplateInstanceService.getRemoveResultForTemplateInstance($scope.templateInstanceId);
        };

        $scope.showResults = function (templateInstance) {
            if (templateInstance.status !== APPLICATION_STATUS.NOT_REQUESTED) {
                const origin = ExecutionOrigin.TEMPLATE_APPLICATION;
                TemplateInstanceService.getResultForTemplateInstance(templateInstance.id, origin, "APPLY").then((response) => {
                    const resultsSize = response.equipmentAssociationResults.length;
                    const hideResultDetails =
                        templateInstance.status === APPLICATION_STATUS.APPLYING &&
                        resultsSize > APPLICATION_COMMANDS.MAX_EQUIPMENTS_TO_SHOW_RESULT_DETAILS;
                    ngDialog.open({
                        template: "templates/features/template/template-instance/modals/results-modal.html",
                        controller: "ResultsModalCtrl",
                        className: `${hideResultDetails ? "medium-modal hide-details" : "big-modal"}`,
                        closeByNavigation: true,
                        resolve: {
                            results() {
                                return response;
                            },
                            templateType() {
                                return templateInstance.type;
                            },
                            hideResultDetails() {
                                return hideResultDetails;
                            },
                            executionOrigin() {
                                return EXECUTION_ORIGIN.TEMPLATE_APPLICATION;
                            },
                            templateInstanceId() {
                                return templateInstance.id;
                            }
                        }
                    });
                });
            } else {
                $rootScope.showDialog({ translateKey: "templateinstancelisting.results.notApplied" });
            }
        };

        $scope.getTotalKeywords = function () {
            TemplateInstanceService.getTotalKeywords().then(function (response: TotalKeywords) {
                $scope.keywords = response;
            });
        };

        $scope.isAllStillSelected = function () {
            $timeout(function () {
                $scope.isAllStillSelectedCallback();
            }, 10);
        };

        $scope.isAllStillSelectedCallback = function () {
            $scope.checked.selectAllCheckbox = $scope.checked.templateInstances.length === $scope.templateInstances.length;
        };

        $rootScope.$on(ACTIONS_LIST.reload, function () {
            $scope.loadPage();
        });

        $scope.fillSearchParams = function () {
            if ($rootScope.queryParams && $rootScope.queryParams.indexOf("devicehostnames") != -1) {
                fillSearch("equipmentAssociations.equipmentIdentifier.resourceId");
            } else if ($rootScope.queryParams && $rootScope.queryParams.indexOf("cpes") != -1) {
                fillSearch("equipmentAssociations.equipmentIdentifier.serialNumber");
            }
        };

        let fillSearch = (searchColumn) => {
            $scope.filters.searchColumn = searchColumn;
            $scope.filters.searchTerm = decodeURIComponent(
                $rootScope.queryParams.substring($rootScope.queryParams.indexOf("=") + 1)
            );
            $scope.filters.searchTerm = $scope.filters.searchTerm.replace(/\\"/g, '"');
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
            $rootScope.queryParams = null;
        };

        $scope.getTemplateInstanceStatusLabel = function (templateInstance) {
            switch (templateInstance.status) {
                case APPLICATION_STATUS.APPLYING:
                    return $translate.instant("templateinstanceform.viewapply.templateStatus.applying");
                case APPLICATION_STATUS.PARTIALLY_APPLIED:
                    return $translate.instant("templateinstanceform.viewapply.templateStatus.partial");
                case APPLICATION_STATUS.NOT_APPLIED:
                    return $translate.instant("templateinstanceform.viewapply.templateStatus.fail");
                case APPLICATION_STATUS.FULLY_APPLIED:
                    return $translate.instant("templateinstanceform.viewapply.templateStatus.success");
                default:
                    return $translate.instant("templateinstanceform.viewapply.templateStatus.notRequested");
            }
        };

        $scope.defineTemplateNamesFilterIfNecessary();
        $scope.defineEquipmentsNamesFilterIfNecessary();
        $scope.fillSearchParams();
        $scope.readPageFiltersFromSessionStorage();
        $scope.loadPage();
        $scope.$on("$destroy", function() {
            unregister();
        });
    }
]);
