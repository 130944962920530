import { Component, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";

/**
 * Componente responsável por gerenciar a visibilidade e
 * validação de senhas em um formulário de dados do usuário.
 */
@Component({
    selector: "app-user-data",
    templateUrl: "./password-management-component.html",
    styleUrls: ["./password-management-component.scss"]
})
export class UserPasswordManagementComponent {
    userDataForm: FormGroup;
    currentPasswordVisible: boolean = false;
    passwordVisible: boolean = false;
    confirmPasswordVisible: boolean = false;

    constructor(@Inject("formControl") public formControl: FormGroup,
                @Inject("isLdapMode") public isLdapMode: boolean) {
        this.userDataForm = formControl;
    }

    toggleVisibility(field: string): void {
        const property = `${field}Visible`;
        this[property] = !this[property];
    }
}
