import { Component, Input, Inject, OnInit, Output, EventEmitter } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import {
    NmsNotificationFadeState, NotificationFilter, NotificationMessage, NotificationStateChangeEvent, NotificationStatus
} from "@nms-ng2/app/shared/services/nms-notification/nms-notification.models";
import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import { NmsNotificationService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification.service";
import { NmsToastrService } from "../nms-toastr/nms-toastr.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { NmsNotificationUtilsService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-utils.service";

/**
 * Componente de notificação.
 */
@Component({
    selector: "nms-notification",
    templateUrl: "./nms-notification.component.html",
    styleUrls: ["./nms-notification.component.scss"],
    animations: [
        trigger("fade", [
            state("in", style({ opacity: 1 })),
            transition("in => out", [
                animate(2000, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class NmsNotificationComponent implements OnInit {

    private readonly MAX_LINES = 4;
    private readonly MAX_NUMBER_CHARACTERS = 140;

    @Input()
    notificationMessage: NotificationMessage;
    @Input()
    activeFilter: NotificationFilter;

    markAsReadTooltip: string;
    markAsUnreadTooltip: string;
    title: string = "";
    extraInfo: string;
    fullExtraInfo: string;
    formatedDate: string;
    variant: NotificationStatus;
    fadeState: NmsNotificationFadeState = "in"; // controla a animação durante a remoção.
    showMoreLink: boolean = false;

    constructor(@Inject(ANGULARJS_TRANSLATE) private translate: any,
        private translationHelper: TranslationHelperService,
        private nmsNotificationService: NmsNotificationService,
        private nmsToastr: NmsToastrService,
        private nmsNotificationUtilsService: NmsNotificationUtilsService) { }

    ngOnInit(): void {
        this.markAsReadTooltip = this.translate.instant("nms-notification.mark.as.read");
        this.markAsUnreadTooltip = this.translate.instant("nms-notification.mark.as.unread");
        this.formatedDate = this.formatDatetimeDescription(this.notificationMessage.createdAt);
        this.variant = this.notificationMessage?.status || NotificationStatus.INFO;

        if (this.notificationMessage.detailedResponse) {
            this.normalizeDetailedResponse();
            this.showMoreLink = this.nmsNotificationUtilsService
                .verifySeeMoreLink(this.fullExtraInfo, this.MAX_LINES, this.MAX_NUMBER_CHARACTERS);
        }
    }

    getTooltip(): string {
        if (this.notificationMessage.read) {
            return this.markAsUnreadTooltip;
        }

        return this.markAsReadTooltip;
    }

    openDetails(): void {
        this.nmsNotificationUtilsService.openSeeMoreLink(this.fullExtraInfo);
    }

    /**
     * Formata a data e hora da notificação para ser mostrado na header do componente de notificação.
     * @param dateString Data/hora em que a notificação foi gerada. Segue o formato ISO Date
     *                   'yyyy-MM-dd'T'HH:mm:ss. SSSXXX'
     */
    formatDatetimeDescription(dateString: string): string {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        };

        return this.translationHelper.formatDateDescription(date, options);
    }

    async toggleReadProperty(isReaded: boolean, notificationId: string): Promise<void> {
        try {
            const successMessage = isReaded
                ? "nms-notification.toastr.single.mark.unread.success"
                : "nms-notification.toastr.single.mark.read.success";
            await this.nmsNotificationService.toggleReadProperty(isReaded, notificationId).toPromise();
            this.fadeOutNotificationIfNeeded();
            this.nmsToastr.success(this.translate.instant(successMessage));
            this.emitRemoveFromListEvent({
                notificationId,
                read: !isReaded,
                runFadeOutAnimation: this.activeFilter !== NotificationFilter.ALL
            });
        } catch {
            const errorMessage = isReaded
                ? "nms-notification.toastr.single.mark.unread.error"
                : "nms-notification.toastr.single.mark.read.error";
            this.nmsToastr.error(this.translate.instant(errorMessage,))
        }
    }

    async remove(notificationId: string): Promise<void> {
        try {
            await this.nmsNotificationService.removeNotificationById(notificationId).toPromise();
            this.fadeState = "out"
            this.nmsToastr.success(this.translate.instant("nms-notification.toastr.single.remove.success"));
            this.emitRemoveFromListEvent({notificationId, read: null, runFadeOutAnimation: true});
        } catch {
            this.nmsToastr.error(this.translate.instant("nms-notification.toastr.single.remove.error"));
        }
    }

    getRemoveTooltip(): string {
        return this.translate.instant("nms-notification.remove.single.tooltip");
    }

    private normalizeDetailedResponse() {
        const details = this.notificationMessage.detailedResponse;
        this.title = this.translationHelper
            .translateWithReplacement(details.titleKey, ...details.titleParameters);
        const extraInfo = this.translationHelper
            .translateWithReplacement(details.detailsKey, ...details.detailsParameters);
        this.fullExtraInfo = this.nmsNotificationUtilsService.replaceBreakLineFromAsciiAndHTMLToNewLine(extraInfo);
        this.extraInfo = this.nmsNotificationUtilsService
            .prepareMessageToShow(this.fullExtraInfo, this.MAX_LINES, this.MAX_NUMBER_CHARACTERS);
    }

    /**
     * Método necessário para aguardar animação.
     * Quando o filtro ativo é "Todos" e não for uma remoção, a animação
     * não ocorre, pois a notificação não é removida da lista, sendo assim
     * o timeout para envio do evento é setado para 0, de maneira
     * que a execução da ação passa a ser feita de maneira imediata.
     */
    private emitRemoveFromListEvent(event: NotificationStateChangeEvent) {
        const timeout = event.runFadeOutAnimation ? 1000 : 0;
        setTimeout(() => this.nmsNotificationService.removeItemList.emit(event), timeout);
    }

    /**
     * Quando o filtro ativo da Central de Notificação não for "TODOS"
     * e o botão 'Marcar como lida/não lida' for pressionado
     * é realizada uma animação de fadeout para "remover" (apenas visualmente)
     * a notificação da lista.
     */
    private fadeOutNotificationIfNeeded() {
        if (this.activeFilter !== NotificationFilter.ALL) {
            this.fadeState = "out"
        }
    }
}
