/**
* @ngdoc directive
* @name nms.templates.directive:variables
* @description Diretiva para criação do componente de variáveis na aplicação de template.
*
* #templateInstance  - Obrigatório - Objeto que representa uma instancia de template a ser aplicada.
* #templates         - Obrigatório - Templates a serem usados na aplicação.
* #variableTypes     - Obrigatório - Dependência necessária para o funcionamento da diretiva.
*                                   Define todos os tipos de variáveis possíveis.
*                                   Essa dependência existe por 2 motivos:
*                                     1 - A informação de tipos de variáveis vem do back-end a partir de uma requisição REST.
*                                     2 - Ela precisa ser resolvida no nms-routes quando a controller é instanciada porque
*                                         o angularjs não possui resolves para diretivas.
* #deviceSelected    - Obrigatório - Device que será usado como default para a seleção de devices quando o(s) template(s)
*                                   possuir(em) variáveis locais.
* #variablesModel    - Opcional    - Possui todas as variáveis relevantes do componente.
*                                   Esse parâmetro é necessário devido a alta dependência do componente de template-instance
*                                   com o de variáveis, sendo a alternativa de menor custo, permitir que todas as variáveis do
*                                   componente sejam acessíveis e controladas pelo controller do template-instance.
* #initVariables     - Opcional    - Lista que contem as variáveis e seus valores a serem inicializados (ver Condições)
*                                   no componente.
*
*                                   Condições:
*                                          - Variável com comandos de inclusão: Caso a opção não esteja disponível na
*                                            combo, a primeira opção da combo será selecionada.
*                                          - Variável com comandos de bloqueio: A opção será selecionada mesmo que ela
*                                            esteja marcada como 'bloqueada'.
*                                          - Variavel sem comandos de inclusão/bloqueio: Caso a opção não
*                                            esteja disponível na combo, a primeira opção será selecionada.
*
*                                   Formato: list[object{
*                                       name:    "variável"   - Nome da variável que deverá ser inicializada.
*                                       value:   "valor"      - Valor da variável.
*                                   }]
* #schedulerJob      - Opcional    - Objeto que representa a instancia de uma tarefa agendada.
* #snmpTrapsSelected - Opcional    - Objeto que representa as traps SNMP selecionadas.
*/

// TODO [Agendamento CLI/TR069][US-3989] Ajustar referências de device -> equipment
angular.module("nms.templates")
.directive("variables", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/template/components/ui/variables/variables.html",
        controller: "VariablesController",
        scope: {
            templateInstance: "=",
            templates: "=",
            variableTypes: "=",
            deviceSelected: "=",
            initVariables: "=?",
            variablesModel: "=?",
            schedulerJob: "=?",
            snmpTrapsSelected: "=?"
        }
    };
});
