import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsInputNumberComponent } from "@nms-angular-toolkit/nms-input-number";
import { NmsToggleComponent } from "@nms-angular-toolkit/nms-toggle";
import { Subscription } from "rxjs";

/**
 * Componente para a aba de preferências gerais.
 */
@Component({
    templateUrl: "./general-user-preferences-config.component.html",
    styleUrls: ["./general-user-preferences-config.component.scss"]
})
export class GeneralUserPreferencesConfigComponent implements AfterViewInit, OnDestroy {

    @ViewChild("purgeOlderFieldComponent")
    purgeOlderFieldComponent: NmsInputNumberComponent;

    @ViewChild(NmsToggleComponent)
    doNotDisturbField: NmsToggleComponent;

    userGeneralDataform: FormGroup
    purgeOlderLabel: string;
    purgeRemoveNotification: string;
    purgeOlderInDays: number;
    doNotDisturb: boolean;

    readonly minPurgeOlderNumber: number = 1;
    readonly maxPurgeOlderNumber: number = 30;

    private subscriptions: Array<Subscription> = [];

    constructor(
        @Inject("formControl") public formControl: FormGroup,
        @Inject(ANGULARJS_TRANSLATE) public translate: any
    ) {
        this.purgeOlderLabel = this.translate.instant("manage.user.general.notifications.period.retention");
        this.purgeRemoveNotification = this.translate.instant("manage.user.general.notifications.remove.automatic");
        this.userGeneralDataform = formControl;
        this.purgeOlderInDays = this.userGeneralDataform.controls["purgeOlderInDays"].value;
        this.doNotDisturb = this.userGeneralDataform.controls["doNotDisturb"].value;
    }

    ngAfterViewInit(): void {
        const doNotDisturbSubscription = this.doNotDisturbField.valueChange.subscribe(value =>
            this.userGeneralDataform.controls["doNotDisturb"].setValue(value)
        );

        const purgeOlderSubscription = this.purgeOlderFieldComponent.inputModel.valueChanges.subscribe(value =>
            this.userGeneralDataform.controls["purgeOlderInDays"].setValue(value)
        );

        this.subscriptions.push(doNotDisturbSubscription, purgeOlderSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}