"use strict";

/**
* @ngdoc directive
* @name nms.directive:variableDropdown
* @description Diretiva para criar componente para representação variáveis de template em menu
* dropdown padrão.
*/
angular.module("nms")
.directive("variableDropdown", ["$translate", "$filter", "TEMPLATE_TYPE", "TEMPLATE_LANGUAGE", "VARIABLES", "$rootScope",
    function($translate, $filter, TEMPLATE_TYPE, TEMPLATE_LANGUAGE, VARIABLES, $rootScope) {
        return {
            restrict: "E",
            templateUrl: "templates/components/ui/variable/variable-dropdown.html",
            scope: {
                availableVariables: "=?",
                selectionCallBack: "=",
                label: "@",
                templateType: "=",
                templateLanguage: "="
            },
            link: {
                pre: function($scope) {
                    var actionShortcuts = createActionShortcut($scope.availableVariables, $scope.selectionCallBack, $scope.templateType);

                    $scope.optionsDataSource = {
                        get: function(index, count, success) {
                            var arrayIndex = index - 1;
                            var sliceIndex = arrayIndex < 0 ? 0 : arrayIndex;
                            var sliceCount = arrayIndex + count;
                            var items = actionShortcuts.slice(sliceIndex, sliceCount);
                            success(items);
                        }
                    };

                    $scope.selectedVariable = function(event, shortcut) {
                        if (isVariableDisabled(shortcut.variableType, $scope.templateType)) {
                            $scope.isOpenDropdown = true;
                            event.stopPropagation();
                            return;
                        }

                        if ($scope.templateLanguage === TEMPLATE_LANGUAGE.PYTHON && shortcut.hasVariableCommands) {
                            $rootScope.showDialog({
                                message: $filter("translate")("templateform.variables.clone.favorite.messages.removeCommands"),
                                isConfirm: true
                            }).then(() => {
                                $scope.openModalConfirm(event, shortcut);
                            });

                            return;
                        }

                        $scope.openModalConfirm(event, shortcut);
                    };

                    $scope.openModalConfirm = function(event, shortcut) {
                        $scope.isOpenDropdown = false;
                        event.preventDefault();
                        shortcut.action();
                    }

                    $scope.getTooltipVariableDisabled = function(shortcut) {
                        if (isVariableDisabled(shortcut.variableType, $scope.templateType)) {
                            return (shortcut.variableType === VARIABLES.ACTION)
                                        ? $filter("translate")("tooltips.template.favorite.variable.action.disabled")
                                        : $filter("translate")("tooltips.template.favorite.variable.readonly.disabled");
                        }
                    }
                }
            }
        };

        function createActionShortcut(variables, selectionCallBack, currentTemplateType) {
            var shortcuts = variables.map(function(variable) {
                var template = $translate.instant("templateform.variables.template");
                var templateType = $translate.instant(TEMPLATE_TYPE[variable.templateType].translationKey);
                var shortcut = {
                    label: variable.variableName + " (" + template + templateType + ": "+ variable.templateName + ")",
                    variableType: variable.variableType,
                    hasVariableCommands: variable.hasVariableCommands,
                    favoriteVariableDisabled: isVariableDisabled(variable.variableType, currentTemplateType),
                    action: function() {
                        selectionCallBack(variable);
                    }
                };
                return shortcut;
            });
            return shortcuts;
        }

        function isVariableDisabled(variableType, templateType) {
            return (variableType === VARIABLES.READ_ONLY || variableType === VARIABLES.ACTION)
                     && templateType === TEMPLATE_TYPE.TR_069.name;
        }
    }
]);
