"use strict";

import { IdentifierType, EquipmentIdentifier } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

/**
 * @ngdoc controller
 * @name nms.templates.components.accordion:TemplateInstanceAccordionDirectiveCtrl
 * @description
 * # TemplateInstanceAccordionDirectiveCtrl
 * Classe de controle para a diretiva de accordion.
 */
const app = angular.module("nms.templates");

app.controller("TemplateInstanceAccordionDirectiveCtrl", [
    "$scope",
    "$translate",
    "$rootScope",
    "DeviceDropdownModelService",
    "TemplateInstanceAccordionDeviceChangesService",
    "PRESENTATION_MODE",
    "APPLICATION_STATUS",
    "BROADCAST_EVENTS",
    "TemplateInstanceIdentifierService",
    "TemplateInstanceUtils",
    function (
        $scope,
        $translate,
        $rootScope,
        DeviceDropdownModelService,
        TemplateInstanceAccordionDeviceChangesService,
        PRESENTATION_MODE,
        APPLICATION_STATUS,
        BROADCAST_EVENTS,
        TemplateInstanceIdentifierService,
        TemplateInstanceUtils
    ) {
        $scope.presentationModeDefault = PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;

        /*
         * Dados e funções requeridas pelo componente.
         */
        $scope.templateInstance = $scope.accordionOwner.getTemplateInstance
            ? $scope.accordionOwner.getTemplateInstance()
            : undefined;
        $scope.results = $scope.accordionOwner.getResults ? $scope.accordionOwner.getResults() : undefined;
        $scope.accordionData = $scope.accordionOwner.getAccordionData();
        $scope.selectedData = $scope.accordionOwner.getSelectedData();
        $scope.presentationMode = $scope.accordionOwner.getPresentationMode();
        $scope.selectAllEquipmentAssociationsCheckbox = { isIndeterminate: false, checked: false };
        $scope.showReloadButton = $scope.accordionOwner.isShowReloadButton ? $scope.accordionOwner.isShowReloadButton() : false;
        $scope.data = $scope.accordionOwner.getData ? $scope.accordionOwner.getData() : undefined;
        $scope.processDataModel = $scope.accordionOwner.processDataModel;
        $scope.reload = $scope.accordionOwner.reload ? $scope.accordionOwner.reload : undefined;
        $scope.getResultForEquipment = $scope.accordionOwner.getResultForEquipment;
        $scope.isManualApplicationDisabled = $scope.accordionOwner.isManualApplicationDisabled
            ? $scope.accordionOwner.isManualApplicationDisabled
            : undefined;
        $scope.variablesThatNeedToBeReloaded = $scope.accordionOwner.getVariablesThatNeedToBeReloaded
            ? $scope.accordionOwner.getVariablesThatNeedToBeReloaded()
            : [];
        $scope.equipmentIdsWithInvalidVariables = [];

        $scope.translationKeys = $scope.getSpecificTranslationKeys();

        $scope.showDetailsTrigger = function () {
            if (!$scope.selectedData || !$scope.selectedData.childId) {
                const parentAccordionData = $scope.parentController
                    ? $scope.parentController.accordionData
                    : $scope.$parent.accordionData;

                if ($scope.selectedData.parentId) {
                    $scope.showDetails($scope.selectedData.parentId);
                } else if (!_.isEmpty(parentAccordionData)) {
                    $scope.showDetails(parentAccordionData[0].id);
                }
            }
        };

        $scope.loadData = function (data, childLabel) {
            $scope.accordionData.splice(0, $scope.accordionData.length);

            if ($scope.isDefaultVisualizationMode()) {
                $scope.loadDataForEquipmentsByTemplateVisualizationMode(data, childLabel);
            } else {
                $scope.loadDataForTemplatesByEquipmentVisualizationMode(data, childLabel);
            }
            $scope.synchronizedTableOrder();
        };

        $scope.loadDataForTemplatesByEquipmentVisualizationMode = function (data, childLabel) {
            data.forEach(function (equipment) {
                const currentParent: any = {
                    id: equipment.equipmentIdentifier,
                    label: equipment.equipmentDetails.model,
                    children: [],
                    status: [],
                    expand: true,
                    apply: equipment.apply
                };

                equipment[childLabel].forEach(function (template) {
                    const templateIdentifier = {
                        id: template.templateId,
                        type: IdentifierType.TEMPLATE_IDENTIFIER
                    };
                    const templateStatus = template.status;
                    currentParent.children.push({
                        id: templateIdentifier,
                        label: template.templateName,
                        description: template.templateDescription,
                        status: templateStatus,
                        apply: template.apply,
                        executedBy: template.executedBy,
                        executedAt: template.executedAt,
                        statusInfo: template.statusInfo
                    });
                    currentParent.status[templateStatus] = $scope.addOneOccurrenceToTemplateStatus(
                        currentParent.status[templateStatus]
                    );
                });

                $scope.accordionData.push(currentParent);
            });
        };

        $scope.loadDataForEquipmentsByTemplateVisualizationMode = function (data, childLabel) {
            data.forEach(function (equipmentAssociation) {
                equipmentAssociation[childLabel].forEach(function (templateAssociation) {
                    let currentTemplate = _.find($scope.accordionData, function (data) {
                        return TemplateInstanceUtils.resolveAccordionIdentifier(data.id) === templateAssociation.templateId;
                    });

                    const templateIdentifier = {
                        id: templateAssociation.templateId,
                        type: IdentifierType.TEMPLATE_IDENTIFIER
                    };

                    if (!currentTemplate) {
                        currentTemplate = {
                            id: templateIdentifier,
                            label: templateAssociation.templateName,
                            description: templateAssociation.templateDescription,
                            children: [],
                            status: [],
                            expand: true
                        };
                        $scope.accordionData.push(currentTemplate);
                    }

                    const status = angular.copy(templateAssociation.status);

                    currentTemplate.children.push({
                        id: equipmentAssociation.equipmentIdentifier,
                        label: $scope.createEquipmentLabel({ id: equipmentAssociation.equipmentIdentifier }),
                        status: status,
                        apply: templateAssociation.apply,
                        executedBy: templateAssociation.executedBy,
                        executedAt: templateAssociation.executedAt,
                        statusInfo: templateAssociation.statusInfo
                    });
                    currentTemplate.status[status] = $scope.addOneOccurrenceToTemplateStatus(currentTemplate.status[status]);
                });
            });
        };

        $scope.addOneOccurrenceToTemplateStatus = function (templateStatus) {
            return templateStatus ? templateStatus + 1 : 1;
        };

        $scope.synchronizedTableOrder = function () {
            const parentAccordionData = $scope.parentController
                ? $scope.parentController.accordionData
                : $scope.$parent.accordionData;

            if (parentAccordionData) {
                if ($scope.templateInstance && $scope.isDefaultVisualizationMode()) {
                    $scope.data.forEach(function (parent) {
                        const accordionParent = _.find(parentAccordionData, function (accordionParent) {
                            const accordionParentId = TemplateInstanceUtils.resolveAccordionIdentifier(accordionParent.id);
                            const parentId = TemplateInstanceUtils.resolveAccordionIdentifier(parent.id);
                            return accordionParentId === parentId;
                        });
                        $scope.reorderChildren(accordionParent, parent);
                    });
                } else if ($scope.templateInstance && !$scope.isDefaultVisualizationMode()) {
                    const synchronizedTable = [];
                    $scope.data.forEach(function (parent) {
                        const currentParent = _.find(parentAccordionData, function (accordionParent) {
                            const accordionParentId = TemplateInstanceUtils.resolveAccordionIdentifier(accordionParent.id);
                            const parentId = TemplateInstanceUtils.resolveAccordionIdentifier(parent.id);
                            return accordionParentId === parentId;
                        });
                        synchronizedTable.push(currentParent);
                    });
                    $scope.accordionData.splice(0, $scope.accordionData.length);
                    synchronizedTable.forEach(function (parent) {
                        $scope.accordionData.push(parent);
                    });
                }

                $scope.showDetailsTrigger();
            }
        };

        $scope.reorderChildren = function (accordionParent, scopeParent) {
            if (accordionParent && !_.isEmpty(scopeParent.children)) {
                const orderedChildren = [];

                scopeParent.children.forEach(function (child) {
                    const currentChild = _.find(accordionParent.children, function (currentChild) {
                        const currentChildId = TemplateInstanceUtils.resolveAccordionIdentifier(currentChild.id);
                        const childId = TemplateInstanceUtils.resolveAccordionIdentifier(child.id);
                        return currentChildId == childId;
                    });
                    if (currentChild) {
                        orderedChildren.push(currentChild);
                    }
                });

                accordionParent.children.splice(0, accordionParent.children.length);
                orderedChildren.forEach(function (child) {
                    accordionParent.children.push(child);
                });
            }
        };

        $scope.toggleAllChildren = function (parent, checked) {
            const hasChildFail = parent.children.some((child) => child.status === APPLICATION_STATUS.FAIL);

            parent.children.forEach(function (child) {

                const isExtractDeviceOptionsInvalid = $scope.isExtractDeviceOptionsInvalid(parent.id, child.id);
                const isValid = isExtractDeviceOptionsInvalid ? !hasChildFail : !isExtractDeviceOptionsInvalid;

                if (angular.isDefined(checked)) {
                    checked = isValid ? checked : false;
                } else {
                    checked = isValid ? !parent.apply : isValid;
                }

                child.apply = checked;
                $scope.toggleApplication(parent.id, child.id, checked);
            });
        };

        $scope.isExtractDeviceOptionsInvalid = function (parentId, childId) {
            let equipment: EquipmentIdentifier =
                $scope.presentationMode.value === PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE ? childId : parentId;

            let identifier = TemplateInstanceIdentifierService.resolveIdentifier(equipment);

            return _.includes($scope.equipmentIdsWithInvalidVariables, identifier);
        };

        $scope.hasChildApplying = function (parent) {
            return parent.children.some((child) => child.status === APPLICATION_STATUS.APPLYING);
        };

        $scope.hasChildThatContainsVariableToReload = function (parent) {
            if ($scope.isDefaultVisualizationMode()) {
                return parent.children.some((child) => $scope.equipmentIdsWithInvalidVariables.includes(child.id.resourceId));
            }
            return $scope.equipmentIdsWithInvalidVariables.includes(parent.id.resourceId);
        };

        $scope.hasApplicationFail = function (parent) {
            return !!parent.status[APPLICATION_STATUS.FAIL];
        };

        $scope.blockInternalCheckBox = function (parent) {
            return (
                $scope.parentController?.hasTrapVariableAssociation ||
                $scope.hasChildApplying(parent) ||
                ($scope.hasApplicationFail(parent) && $scope.hasChildThatContainsVariableToReload(parent))
            );
        };

        $scope.tooltipInternalCheckBoxBlockedMessage = function (parent) {
            if ($scope.parentController?.hasTrapVariableAssociation) {
                return "templateinstanceform.viewapply.templateStatus.disablesnmptrap";
            } else if ($scope.hasApplicationFail(parent) && $scope.hasChildThatContainsVariableToReload(parent)) {
                return "templateinstanceform.viewapply.templateStatus.disableInvalidConfiguration.header";
            }

            return "templateinstanceform.viewapply.templateStatus.disableapplying";
        };

        $scope.isChildApplying = function (child) {
            return child.status === APPLICATION_STATUS.APPLYING;
        };

        $scope.isChildDisabled = function(parent: any, child: any): boolean {
            return $scope.parentController?.hasTrapVariableAssociation
                || $scope.isChildApplying(child)
                || ($scope.isExtractDeviceOptionsInvalid(parent.id, child.id) && child.status == APPLICATION_STATUS.FAIL);
        };

        $scope.getChildDisabledTooltipMessage = function(child: any): string {
            if ($scope.parentController?.hasTrapVariableAssociation) {
                return "templateinstanceform.viewapply.templateStatus.disablesnmptrap";
            }

            return $scope.isChildApplying(child)
                ? "templateinstanceform.viewapply.templateStatus.disableapplying"
                : "templateinstanceform.viewapply.templateStatus.disableInvalidConfiguration";
        };

        $scope.createHeaderInformation = function (parent) {
            const applicationStatus = parent.status;
            let total = 0;
            let results: any = {};
            Object.keys(applicationStatus).forEach(function (key) {
                total += applicationStatus[key] ? applicationStatus[key] : 0;

                results[key] = applicationStatus[key];
            });

            const childType = $scope.isDefaultVisualizationMode()
                ? $translate.instant($scope.translationKeys.equipments)
                : $translate.instant("templateinstanceform.viewapply.templates");

            const headerDetails =
                (($scope.templateInstance && $scope.templateInstance.id) || $scope.results) && $scope.showAccordionChildren
                    ? `: ${$scope.createStatusApplicationLabelOrderedByStatus(results)}`
                    : "";

            const headerStatus = $scope.results ? $scope.getEquipmentApplicationStatus(parent) : "";
            const header = `${headerStatus}${childType} (${total})${headerDetails}`;

            return header;
        };

        $scope.getEquipmentApplicationStatus = function (parent) {
            let status = "templateinstanceform.viewapply.templateStatus.notStarted";

            const hasChildWithApplyingStatus = _.some(parent.children, function (child) {
                return child.status === APPLICATION_STATUS.APPLYING;
            });

            const hasChildWithFailOrSuccessStatus = _.some(parent.children, function (child) {
                return child.status === APPLICATION_STATUS.SUCCESS || child.status === APPLICATION_STATUS.FAIL;
            });

            if (hasChildWithApplyingStatus) {
                status = "templateinstanceform.viewapply.templateStatus.applying";
            } else if (hasChildWithFailOrSuccessStatus) {
                status = "templateinstanceform.viewapply.templateStatus.completed";
            }

            return $translate.instant(status) + ". ";
        };

        $scope.createStatusApplicationLabelOrderedByStatus = function (results) {
            let header = "";
            const orderBy = [
                APPLICATION_STATUS.SUCCESS,
                APPLICATION_STATUS.FAIL,
                APPLICATION_STATUS.APPLYING,
                APPLICATION_STATUS.NOT_STARTED,
                APPLICATION_STATUS.NOT_REQUESTED,
                APPLICATION_STATUS.NOT_EXECUTED,
                APPLICATION_STATUS.APPLYING_BY_ANOTHER_USER,
                null,
                undefined
            ];
            for (let index = 0; index < orderBy.length; index++) {
                const key = orderBy[index];
                if (results[key]) {
                    if (header !== "") {
                        header += ", ";
                    }
                    const translateKey = $scope.getTranslateKeyForApplicationStatus(key);

                    header += $translate.instant(translateKey) + " (" + results[key] + ")";
                }
            }

            return header;
        };

        $scope.getTranslateKeyForApplicationStatus = function (key) {
            switch (key) {
                case APPLICATION_STATUS.SUCCESS:
                    return "templateinstanceform.viewapply.templateStatus.success";
                case APPLICATION_STATUS.FAIL:
                    return "templateinstanceform.viewapply.templateStatus.fail";
                case APPLICATION_STATUS.NOT_STARTED:
                    return "templateinstanceform.viewapply.templateStatus.notStarted";
                case APPLICATION_STATUS.APPLYING:
                    return "templateinstanceform.viewapply.templateStatus.applying";
                case APPLICATION_STATUS.NOT_EXECUTED:
                    return "templateinstanceform.viewapply.templateStatus.notExecuted";
                case APPLICATION_STATUS.APPLYING_BY_ANOTHER_USER:
                    return "templateinstanceform.viewapply.templateStatus.applyingByAnotherUser";
                default:
                    return "templateinstanceform.viewapply.templateStatus.notRequested";
            }
        };

        $scope.createEquipmentLabel = function (selectedEquipment) {
            let currentEquipment;
            if ($scope.templateInstance) {
                currentEquipment = $scope.findEquipmentById($scope.templateInstance.equipmentAssociations, selectedEquipment.id);
            } else {
                currentEquipment = $scope.findEquipmentById($scope.results.equipmentAssociationResults, selectedEquipment.id);
            }

            const equipmentString = $translate.instant($scope.translationKeys.equipment);
            const equipmentName = currentEquipment.equipmentDetails.name;
            const modelString = $translate.instant("templateinstanceform.model");
            const equipmentModel = currentEquipment.equipmentDetails.model;

            return `${equipmentString}: ${equipmentName}, ${modelString}: ${equipmentModel}`;
        };

        $scope.createTemplateLabel = function (templateName) {
            return $translate.instant("templateinstanceform.template") + ": " + templateName;
        };

        $scope.createChildLabel = function (childName) {
            if ($scope.isDefaultVisualizationMode()) {
                return childName;
            } else {
                return $scope.createTemplateLabel(childName);
            }
        };

        $scope.translateExecutedByValue = function (executedBy) {
            if (/TR069_EVENT/i.test(executedBy)) {
                const [_, events] = executedBy.split("TR069_EVENT");
                return $translate.instant("templateinstanceform.viewapply.executedBy.tr-069").replace("{0}", events.trim());
            } else if (/^SCHEDULER$/i.test(executedBy)) {
                return $translate.instant("templateinstanceform.viewapply.executedBy.scheduler");
            } else if (/TRAP/i.test(executedBy)) {
                const trap = executedBy.split("TRAP")[1].replace("(", "").replace(")", "");
                const trapTitle = $translate.instant("scheduler.table.snmpTrap");
                const trapType = $translate.instant(`scheduler.trap.trigger.${trap.trim()}`)
                return `${trapTitle} (${trapType})`;
            }

            return executedBy;
        };

        $scope.expandAll = function (expand) {
            $scope.accordionData.forEach(function (data) {
                data.expand = expand;
            });
        };

        $scope.toggleAll = function () {
            $scope.accordionData.forEach(function (data) {
                $scope.toggleAllChildren(data, $scope.selectAllEquipmentAssociationsCheckbox.checked);
            });

            $scope.areAllParentChecked();
        };

        $scope.isSelected = function (parentId, childId) {
            return $scope.selectedData && $scope.selectedData.parentId === parentId && $scope.selectedData.childId === childId;
        };

        $scope.showDetails = function (parentId, childId) {
            $rootScope.$broadcast("scrollToTop", {});
            $scope.selectedData.parentId = parentId;
            $scope.selectedData.childId = childId;

            let equipmentId = childId;
            let templateId = parentId;

            if (!$scope.isDefaultVisualizationMode()) {
                equipmentId = parentId;
                templateId = childId;
            }

            if ($scope.templateInstance) {
                const templateIds = templateId ? [templateId] : undefined;

                if ($scope.parentController) {
                    $scope.parentController.showDetails(equipmentId, templateIds);
                } else {
                    $scope.$parent.showDetails(equipmentId, templateIds);
                }
            } else {
                $scope.templateId = templateId;

                if ($scope.parentController) {
                    $scope.parentController.deviceId = equipmentId;
                } else {
                    $scope.$parent.deviceId = equipmentId;
                }

                const equipmentAssociationRequests = [];

                if ($scope.isDefaultVisualizationMode()) {
                    if (equipmentId) {
                        equipmentAssociationRequests.push({
                            equipmentIdentifier: equipmentId,
                            templateId: templateId
                        });
                    } else {
                        $scope.results.equipmentAssociationResults.forEach(function (equipmentAssociation) {
                            const templatesAssociations = equipmentAssociation.templateResults
                                ? equipmentAssociation.templateResults
                                : equipmentAssociation.templateIdentifiers;
                            const hasTemplate = _.some(templatesAssociations, function (templateAssociation) {
                                return (
                                    templateAssociation.templateId == TemplateInstanceUtils.resolveAccordionIdentifier(templateId)
                                );
                            });

                            if (hasTemplate) {
                                equipmentAssociationRequests.push({
                                    equipmentIdentifier: equipmentAssociation.equipmentIdentifier,
                                    templateId: templateId
                                });
                            }
                        });
                    }
                } else {
                    const selectedDevice = _.find($scope.results.equipmentAssociationResults, function (equipmentAssociation) {
                        return _.isEqual(equipmentAssociation.equipmentIdentifier, equipmentId);
                    });

                    if (templateId) {
                        equipmentAssociationRequests.push({
                            equipmentIdentifier: selectedDevice.equipmentIdentifier,
                            templateId: templateId
                        });
                    } else {
                        const templateAssociations = selectedDevice.templateResults
                            ? selectedDevice.templateResults
                            : selectedDevice.templateIdentifiers;
                        templateAssociations.forEach(function (templateAssociation) {
                            equipmentAssociationRequests.push({
                                equipmentIdentifier: selectedDevice.equipmentIdentifier,
                                templateId: { id: templateAssociation.templateId, type: IdentifierType.TEMPLATE_IDENTIFIER }
                            });
                        });
                    }
                }
                $scope.getResultForEquipment($scope.results.templateInstanceId, equipmentAssociationRequests);
            }
            $scope.showAllDataToParentId = angular.isUndefined(childId) ? parentId : undefined;
        };

        $scope.loadApplicationColumnLabel = function () {
            if ($scope.templateInstance) {
                $scope.applicationColumnLabel = $translate.instant(
                    $scope.templateInstance.id
                        ? "templateinstanceform.viewapply.status"
                        : $scope.presentationMode.value === PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
                        ? $scope.translationKeys.totalEquipmentsByTemplate
                        : $scope.translationKeys.totalTemplatesByEquipment
                );
            }
        };

        $scope.findEquipmentById = function (equipmentAssociations, equipmentIdentifier) {
            return _.find(equipmentAssociations, function (equipmentAssociation) {
                return _.isEqual(equipmentAssociation.equipmentIdentifier, equipmentIdentifier);
            });
        };

        $scope.findTemplateAssociationByTemplateId = function (templateAssociations, templateIdentifier) {
            return _.find(templateAssociations, function (templateAssociation) {
                return templateAssociation.templateId === templateIdentifier.id;
            });
        };

        /**
         * Desmarca todas as aplicações caso existam associações de traps e variáveis e tenha algum equipamento/template marcado
         * para aplicação.
         */
        const uncheckAllWhenHasTrapVariableAssociationAndAnyApplyChecked = () => {
            if ($scope.parentController?.hasTrapVariableAssociation
                && $scope.accordionData.some(({ apply, children }) => apply || children.some(({ apply }) => apply))) {
                    $scope.selectAllEquipmentAssociationsCheckbox.checked = false;
                    $scope.toggleAll();
            }
        };

        $scope.initAccordion = function () {
            if ($scope.accordionData.length === 0) {
                if ($scope.templateInstance) {
                    $scope.loadData($scope.templateInstance.equipmentAssociations, "templateAssociations");
                } else {
                    $scope.loadData($scope.results.equipmentAssociationResults, $scope.getTemplateIdentifier());
                }

                $scope.loadApplicationColumnLabel();
            } else {
                uncheckAllWhenHasTrapVariableAssociationAndAnyApplyChecked();
                $scope.showDetailsTrigger();
            }
        };

        $scope.isDefaultVisualizationMode = function () {
            return !$scope.presentationMode || $scope.presentationMode.value === PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        };

        /*
         * O valor do parâmetro checked define o valor do apply nos equipamentos para a aplicação de template
         * O parâmetro checked, referente a checkbox pai, possui 3 estados (true, false, undefined):
         *   true      -> check marcada
         *   false     -> check desmarcada
         *   undefined -> existe ao menos uma checkbox filha marcada, mas não todas
         */
        $scope.toggleApplication = function (parentId, childId, checked) {
            const deviceIdentifier = $scope.isDefaultVisualizationMode() ? childId : parentId;
            const templateIdentifier = $scope.isDefaultVisualizationMode() ? parentId : childId;
            const equipmentAssociations = $scope.templateInstance.equipmentAssociations;
            const currentDevice = $scope.findEquipmentById(equipmentAssociations, deviceIdentifier);
            const index = _.findIndex(currentDevice.templateAssociations, function (templateAssociation) {
                return templateAssociation.templateId === templateIdentifier.id;
            });

            const currentTemplate = angular.copy(
                $scope.findTemplateAssociationByTemplateId(currentDevice.templateAssociations, templateIdentifier)
            );

            /*
             * Caso seja marcado um filho o valor que estava setado
             * anteriormente no filho deve ser invertido, caso seja marcado o pai,
             * usa o valor do pai para setar o apply no filho.
             */
            let childCheckboxToApply = checked === undefined ? !currentTemplate.apply : checked;

            currentTemplate.apply =
                $scope.isExtractDeviceOptionsInvalid(parentId, childId) && currentTemplate.status == APPLICATION_STATUS.FAIL
                    ? false
                    : childCheckboxToApply;

            currentDevice.templateAssociations[index] = currentTemplate;
        };

        $scope.checkParentIfAllChildrenAreChecked = function (newValue) {
            if ($scope.templateInstance && newValue) {
                newValue.forEach(function (parent) {
                    if (parent) {
                        const hasUncheckedElements = _.some(parent.children, { apply: false });
                        parent.apply = !hasUncheckedElements;
                    }
                });

                $scope.areAllParentChecked();
            }
        };

        $scope.areAllParentChecked = function () {
            if ($scope.accordionData) {
                const hasUncheckedElements = _.some($scope.accordionData, { apply: false });
                let isIndeterminate = $scope.isIndeterminate($scope.accordionData);

                if (hasUncheckedElements && !isIndeterminate) {
                    $scope.accordionData.forEach(function (data) {
                        const hasUncheckedElements = $scope.isIndeterminate(data.children);

                        if (hasUncheckedElements) {
                            isIndeterminate = true;
                        }
                    });
                }

                $scope.selectAllEquipmentAssociationsCheckbox.indeterminate = isIndeterminate;
                $scope.selectAllEquipmentAssociationsCheckbox.checked = !hasUncheckedElements;
            }
        };

        $scope.isIndeterminate = function (element) {
            const containsUncheckedElements = _.some(element, { apply: false });
            const containsCheckedElements = _.some(element, { apply: true });

            return containsUncheckedElements && containsCheckedElements;
        };

        $scope.getTemplateStatusLabel = function (status, statusInfo) {
            if (status == APPLICATION_STATUS.NOT_STARTED) {
                return $translate.instant("templateinstanceform.viewapply.templateStatus.notStarted");
            } else if (status == APPLICATION_STATUS.APPLYING) {
                return $translate.instant("templateinstanceform.viewapply.templateStatus.applying");
            } else if (status == APPLICATION_STATUS.NOT_EXECUTED) {
                return $translate.instant("templateinstanceform.viewapply.templateStatus.notExecuted");
            } else if (status == APPLICATION_STATUS.NOT_REQUESTED) {
                return $translate.instant("templateinstanceform.viewapply.templateStatus.notRequested");
            } else if (status == APPLICATION_STATUS.APPLYING_BY_ANOTHER_USER) {
                return $translate.instant("templateinstanceform.viewapply.templateStatus.applyingBy").replace("{0}", statusInfo);
            }
        };

        $scope.changeVisualizationMode = function (lastVisualizationMode) {
            if ($scope.selectedData && $scope.selectedData.childId) {
                const parentId = $scope.selectedData.childId;
                const childId = $scope.selectedData.parentId;

                $scope.selectedData.parentId = parentId;
                $scope.selectedData.childId = childId;
            } else {
                $scope.selectedData.parentId = null;
                $scope.selectedData.childId = null;
            }

            if ($scope.templateInstance) {
                $scope.processDataModel(lastVisualizationMode);
                $scope.loadData($scope.templateInstance.equipmentAssociations, "templateAssociations");
            } else {
                $scope.loadData($scope.results.equipmentAssociationResults, $scope.getTemplateIdentifier());
            }
        };

        $scope.getTemplateIdentifier = function () {
            return $scope.results.equipmentAssociationResults &&
                $scope.results.equipmentAssociationResults.length > 0 &&
                $scope.results.equipmentAssociationResults[0].templateResults
                ? "templateResults"
                : "templateIdentifiers";
        };

        $scope.preventDefaults = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        };

        $scope.getStatusClassForEquipmentApplication = function (parent) {
            const childrenStatus = _.map(parent.children, "status");

            if ($scope.showAccordionChildren) {
                if (_.includes(childrenStatus, APPLICATION_STATUS.APPLYING)) {
                    return APPLICATION_STATUS.APPLYING;
                } else if (
                    _.includes(childrenStatus, APPLICATION_STATUS.FAIL) &&
                    _.includes(childrenStatus, APPLICATION_STATUS.SUCCESS)
                ) {
                    return APPLICATION_STATUS.PARTIALLY_APPLIED;
                } else if (_.includes(childrenStatus, APPLICATION_STATUS.FAIL)) {
                    return APPLICATION_STATUS.NOT_APPLIED;
                } else if (_.includes(childrenStatus, APPLICATION_STATUS.SUCCESS)) {
                    return APPLICATION_STATUS.FULLY_APPLIED;
                }
            }

            return "";
        };

        $scope.createDeviceDropdownModel = function (deviceId) {
            if ($scope.templateInstance) {
                const device = $scope.findEquipmentById($scope.templateInstance.equipmentAssociations, deviceId);
                return DeviceDropdownModelService.createModelFromTemplateInstanceDevice(device);
            } else {
                const applicationResult = $scope.findEquipmentById($scope.results.equipmentAssociationResults, deviceId);
                return DeviceDropdownModelService.createModelFromTemplateApplicationResult(applicationResult);
            }
        };

        $scope.getAvailableFeatures = function (equipmentIdentifier) {
            if (TemplateInstanceIdentifierService.isDeviceIdentifier(equipmentIdentifier.type)) {
                const equipmentId = TemplateInstanceIdentifierService.resolveIdentifier(equipmentIdentifier);
                return DeviceDropdownModelService.getAvailableFeatures(equipmentId);
            }
            return [];
        };

        this.setEquipmentIdsWithInvalidVariables = function (variablesThatNeedToBeReloaded) {
            $scope.equipmentIdsWithInvalidVariables.splice(0, $scope.equipmentIdsWithInvalidVariables.length);
            const equipmentIds = _.map(variablesThatNeedToBeReloaded, "equipmentId");

            equipmentIds.forEach(function (equipmentId) {
                $scope.equipmentIdsWithInvalidVariables.push(equipmentId);
            });
        };

        const unregisterDevicePropertiesChangeListener = $scope.$on(
            BROADCAST_EVENTS.DEVICE_NAME_CHANGED,
            function (event, payload) {
                const updatedDevice = payload.device;
                TemplateInstanceAccordionDeviceChangesService.updateAccordionDeviceModels(
                    updatedDevice,
                    $scope.data,
                    $scope.presentationMode.value
                );
                TemplateInstanceAccordionDeviceChangesService.updateAccordionDeviceModels(
                    updatedDevice,
                    $scope.accordionData,
                    $scope.presentationMode.value
                );
                TemplateInstanceAccordionDeviceChangesService.updateTemplateInstanceDeviceModels(
                    updatedDevice,
                    $scope.templateInstance
                );
            }
        );

        const retrieveCommandsDetails = $rootScope.$on("retrieveCommandsDetails", () => {
            $scope.$apply(() => $scope.showDetailsTrigger());
        });

        $scope.$watch("presentationMode.value", $scope.loadApplicationColumnLabel);
        $scope.$watch("accordionData", $scope.checkParentIfAllChildrenAreChecked, true);
        $scope.$watchCollection("variablesThatNeedToBeReloaded", this.setEquipmentIdsWithInvalidVariables);
        $scope.$on("$destroy", function () {
            unregisterDevicePropertiesChangeListener();
            retrieveCommandsDetails();
        });

        $scope.initAccordion();
    }
]);
