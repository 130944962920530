"use strict";

import { DatabaseError, HttpStatusCode } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

var app = angular.module("nms");

app.config([
    "$httpProvider",
    "BROADCAST_EVENTS",
    function ($httpProvider, BROADCAST_EVENTS) {
        var handleAuthenticationError = function (rejection, $rootScope) {
            var isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
            var errorOnLoginPage = rejection.config.url.includes("login");

            if (isAuthenticated) {
                $rootScope.prepareToNewLogin(true);
            } else if (errorOnLoginPage) {
                $rootScope.loginError = rejection.data || "authentication.alert.accessDenied";
            }
        };

        $httpProvider.interceptors.push([
            "$q",
            "$rootScope",
            "DialogService",
            function ($q, $rootScope, DialogService) {
                return {
                    responseError: function (rejection) {
                        if (rejection.config && !rejection.config.bypassInterceptor) {
                            if (rejection.status === HttpStatusCode.Unauthorized) {
                                handleAuthenticationError(rejection, $rootScope);
                            } else {
                                var translateKey;
                                var options: any = {};

                                if (rejection.data && rejection.data.code === DatabaseError.DATABASE_ERROR) {
                                    translateKey = "unavailable.database.message";
                                } else if (rejection.data) {
                                    options.message = rejection.data.description || rejection.data;
                                }

                                if (rejection.status === 404 && rejection.config.notFoundBehaviorMessage) {
                                    options.message = `${options.message} <br> ${rejection.config.notFoundBehaviorMessage}`;
                                }

                                switch (rejection.status) {
                                    case -1:
                                        translateKey = "error.networkProblem";
                                        break;
                                    case 0:
                                    case 504:
                                        translateKey = "error.serverConnection";
                                        break;
                                    case 400:
                                        translateKey = "http.error.badRequest";
                                        break;
                                    case 403:
                                        translateKey = "http.error.forbidden";
                                        break;
                                    case 404:
                                        translateKey = "http.error.notFound";
                                        break;
                                    case 409:
                                        translateKey = "http.error.conflict";
                                        break;
                                    case 422:
                                        translateKey = "http.error.unprocessable";
                                        break;
                                    default:
                                        options.message = undefined;
                                        translateKey = translateKey || "http.error.serverError";
                                        break;
                                }

                                if (_.get(rejection, "config.redirectToOnError")) {
                                    $rootScope.redirectTo(rejection.config.redirectToOnError);
                                }

                                options.ngDialogOptions = { name: translateKey };
                                options.translateKey = translateKey;
                                DialogService.openDialog(options).finally(() => {
                                    $rootScope.$broadcast(BROADCAST_EVENTS.HTTP_ERROR_MODAL_CLOSED, rejection);
                                });
                            }
                        }

                        return $q.reject(rejection);
                    }
                };
            }
        ]);
    }
]);
