import { Component, Input, OnChanges } from "@angular/core";

/**
 * Componente responsável por exibir os warnings referentes a compilação
 * dos comandos na tela de Ver/Aplicar da Aplicação de Template.
 */
@Component({
    selector: "commands-alert",
    templateUrl: "./commands-alert.component.html"
})
export class CommandsAlertComponent implements OnChanges {

    @Input() templateCommands: any[];
    @Input() visualizationMode: string;

    hasNotCompiledCommands: boolean = false;
    messages: string[] = [];

    constructor() { }

    ngOnChanges() {
        this.checkNotCompiledCommands();
    }

    /**
     * Apenas no modo de visualização 'templatesByDevice', é possível que mais
     * de uma mensagem seja exibida, visto que os comandos de todos os templates
     * associados ao equipamento serão exibidos ao mesmo tempo.
     *
     * Exemplo: quando houver mais de um template,
     * e um deles for python e outro possuir variáveis SNMP.
     *
     * @returns - lista de mensagens de warning.
     */
    private checkNotCompiledCommands() {
        const isMultipleDeviceTemplateCommands = this.templateCommands.length > 1;
        this.hasNotCompiledCommands = false;
        this.messages.splice(0, this.messages.length);

        const notCompiledDueToPythonCommands = this.templateCommands.some((command) => command.applicationCommands
            ? command.applicationCommands.pythonCommand
            : command.removalCommands.pythonCommand);

        if (notCompiledDueToPythonCommands) {
            this.hasNotCompiledCommands = true;
            this.messages.push(`tooltips.templateinstance.viewapply.${this.visualizationMode}.pythonNotCompiled`);

            if (!isMultipleDeviceTemplateCommands) {
                return;
            }
        }

        const notCompiledDueToSnmpVarsCommands = this.templateCommands.some((command) => command.applicationCommands
            ? command.applicationCommands.useRawCommand && !command.applicationCommands.pythonCommand
            : command.removalCommands.useRawCommand && !command.removalCommands.pythonCommand);

        if (notCompiledDueToSnmpVarsCommands) {
            this.hasNotCompiledCommands = true;
            this.messages.push(`tooltips.templateinstance.viewapply.${this.visualizationMode}.snmpTrapsNotCompiled`);
        }
    };
}
