import { NmsTableModule } from "@nms-angular-toolkit/nms-table";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UpgradeModule } from "@angular/upgrade/static";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";

import { NmsCommonsModule } from "@nms-angular-toolkit/nms-commons";
import { NmsDialogModule } from "@nms-angular-toolkit/nms-dialog";
import { NmsWizardModule } from "@nms-angular-toolkit/nms-wizard";

import { KeepAliveService } from "@nms-ng2/app/shared/services/security/keep-alive.service";
import { SecurityService } from "@nms-ng2/app/shared/services/security/security.service";
import { NmsAutoUpdaterComponent } from "@nms-ng2/app/shared/components/elements/nms-auto-updater/nms-auto-updater.component";
import { NmsCounterComponent } from "@nms-ng2/app/shared/components/elements/nms-counter/nms-counter.component";
import {
    NmsInfoConfigFilterComponent
} from "@nms-ng2/app/modules/device/info-config/nms-info-config-filter/nms-info-config-filter.component";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { TooltipModule } from "ng2-tooltip-directive";
import { ClipboardModule } from "ngx-clipboard";
import { ShowHidePasswordModule } from "ngx-show-hide-password";
import { defaultSimpleModalOptions, SimpleModalModule } from "ngx-simple-modal";
import { ToastrModule } from "ngx-toastr";
import { SortablejsModule } from "ngx-sortablejs";
import { TranslatePipe } from "./shared/pipes/translate.pipe";
import { TranslateService } from "@ngx-translate/core";
import { AcsCredentialsModalComponent } from "./shared/components/modals/acs-credentials-modal/acs-credentials-modal.component";
import { DeviceManagementProtocolsModalComponent }
    from "./shared/components/modals/device-management-protocols/device-management-protocols-modal.component";
import { CwmpConnectivityTestModalComponent }
    from "./shared/components/modals/cwmp-connectivity-test/cwmp-connectivity-test-modal.component";
import { CwmpConnectivityTestModalService }
    from "./shared/components/modals/cwmp-connectivity-test/cwmp-connectivity-test-modal.service";
import { ModalFactory } from "./shared/services/modal/modal.factory";
import { NmsDropdownOld } from "./shared/directives/nms-dropdown.upgraded.directive";
import { NmsOldPageTitle } from "./shared/directives/nms-old-page-title.upgraded.directive";
import { NmsPageTitleComponent } from "./shared/components/elements/nms-page-title/nms-page-title.component";
import { NmsTable } from "./shared/directives/nms-table.upgraded.directive";
import { NmsTimerComponent } from "./shared/components/elements/nms-timer/nms-timer.component";
import { CpeErrorResponseHandlerService } from "./modules/device/cpe-error-response-handler.service";
import { NgIncludeWrapper } from "./shared/directives/ng-include-wrapper.upgraded.directive";
import { AcsCredentialsComponent } from "./modules/settings/acs-credentials/acs-credentials.component";
import { AcsCredentialsService } from "./modules/settings/acs-credentials/acs-credentials.service";
import { AcsUrlStatusComponent } from "./modules/settings/acs-credentials/acs-url-status/acs-url-status.component";
import { TemplateInstanceComponent } from "./modules/template/template-instance/template-instance.component";
import { CpesComponent } from "./modules/device/cpes/cpes.component";
import { DownloadComponent } from "./modules/device/cpes/download/download.component";
import { RebootService } from "./modules/device/cpes/reboot/reboot.service";
import { CwmpParametersResolverService } from "./modules/device/cwmp-parameters/cwmp-parameters-resolver.service";
import { CwmpParametersComponent } from "./modules/device/cwmp-parameters/cwmp-parameters.component";
import { CwmpParametersService } from "./modules/device/cwmp-parameters/cwmp-parameters.service";
import {
    CwmpPathParametersComponent
} from "./modules/device/cwmp-parameters/cwmp-path-parameters/cwmp-path-parameters.component";
import { HttpErrorInterceptor } from "./core/interceptors/http-error-interceptor";
import { InventoryService } from "./shared/services/inventory/inventory.service";
import { LicenseModalComponent } from "./modules/license/license-modal.component";
import { NmsSelectComponent } from "./shared/components/elements/nms-select/nms-select.component";
import { NmsToastrComponent } from "./shared/components/elements/nms-toastr/nms-toastr.component";
import { ManagementProtocolsComponent } from "./shared/components/elements/management-protocols/management-protocols.component";
import {
    GlobalManagementProtocolsComponent
} from "./modules/settings/global-management-protocols/global-management-protocols.component";
import { RefreshIntervalService } from "./shared/services/refresh-interval/refresh-interval.service";
import { RouterModule } from "@angular/router";
import {
    DeviceFreemarkerExpressionService
 } from "@nms-ng2/app/modules/template/services/expressions/freemarker/device-freemarker-expression.service";
import { TemplateComponent } from "./modules/template/template/template.component";
import { TemplateInstanceIdentifierService } from "./modules/template/template-instance/template-instance-identifier.service";
import {
    CpePythonExpressionService
} from "@nms-ng2/app/modules/template/services/expressions/python/cpe-python-expression.service";
import { CodeBlockService } from "@nms-ng2/app/modules/template/services/code-block.service";
import { CpesTableComponent } from "./modules/device/cpes/table/cpes-table.component";
import { TemplateInfoHeaderComponent } from "./shared/components/elements/template-info-header/template-info-header.component";
import { TemplateValidatorTypeService } from "@nms-ng2/app/modules/template/services/template-validator-type.service";
import { ActionVariableValidatorService } from "./modules/template/services/variable/action-variable-validator.service";
import { UserPreferencesComponent } from "@nms-ng2/app/modules/user/user-preferences/user-preferences.component";
import { DialogService } from "./shared/services/dialog/dialog.service";
import { MatchingRulesComponent } from "./shared/components/elements/matching-rules/matching-rules.component";
import { MatchingRulesDifferService } from "./shared/components/elements/matching-rules/matching-rules-differ.service";
import { SelectionDropdownComponent } from "./shared/components/elements/selection-dropdown/selection-dropdown.component";
import { CpesActionsService } from "./shared/services/actions/cpes-actions.service";
import { PermissionsActionsService } from "./shared/services/actions/permissions-actions.service";
import { DevicesActionsService } from "./shared/services/actions/devices-actions.service";
import { TemplateActionsService } from "./shared/services/actions/template-actions.service";
import { EquipmentsModalsActionsService } from "./shared/services/actions/equipments-modals-actions.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { ptBrLocale } from "ngx-bootstrap/locale";
import { SchedulerComponent } from "./modules/scheduler/scheduler.component";
import { SchedulerTableComponent } from "./modules/scheduler/scheduler-list/scheduler-table/scheduler-table.component";
import { SchedulerListComponent } from "./modules/scheduler/scheduler-list/scheduler-list.component";
import { TimeTriggerComponent } from "./modules/scheduler/triggers/time-trigger/time-trigger.component";
import { InformTriggerComponent } from "./modules/scheduler/triggers/inform-trigger/inform-trigger.component";
import { CronTriggerComponent } from "./modules/scheduler/triggers/time-trigger/cron-trigger/cron-trigger.component";
import { AuditRetentionModalComponent } from "./shared/components/modals/audit-retention/audit-retention-modal.component";
import { TemplateStepBasicComponent } from "./modules/template/template/steps/1_basic/template-step-basic.component";
import { TemplateStepCommandsComponent } from "./modules/template/template/steps/2_commands/template-step-commands.component";
import { NmsTagsInput } from "./shared/directives/tags-input.upgraded.directive";
import { TemplateCommandsStepWrapper } from "./shared/directives/template-commands-step-wrapper.upgraded.directive";
import { NmsInputNumberModule } from "@nms-angular-toolkit/nms-input-number";
import { NmsProgressBarModule } from "@nms-angular-toolkit/nms-progress-bar";
import { NmsTextViewerModule } from "@nms-angular-toolkit/nms-text-viewer";
import { NmsStatusModule } from "@nms-angular-toolkit/nms-status";
import { NmsDropdownModule } from "@nms-angular-toolkit/nms-dropdown";
import { NmsToggleModule } from "@nms-angular-toolkit/nms-toggle";
import { NmsButtonModule } from "@nms-angular-toolkit/nms-button";
import { GponOnusComponent } from "./modules/device/gpon-onus/gpon-onus.component";
import {
    ACTIONS_LIST, ACTIVATION_SERVICE, ANGULARJS_LOCATION, ANGULARJS_ROOTSCOPE, ANGULARJS_SCOPE, ANGULARJS_TIMEOUT,
    ANGULARJS_TRANSLATE, APPLICATION_BRAND_ICONS, APPLICATION_COMMANDS, APPLICATION_STATUS, AUTHENTICATION_SERVICE,
    BROADCAST_EVENTS, CONFIG_REST_SERVICE, CONNECTIVITY_TEST_SERVICE, CONNECTIVITY_TEST_STATUS, CONVERTER_SERVICE,
    CREDENTIALS_SERVICE, CUSTOM_DEVICE, CUT_STRING_FILTER, CWMP_DOWNLOAD_FILE_TYPE, DEVICES_TABLE_SERVICE,
    DEVICE_DISCOVER_SERVICE, DEVICE_DROPDOWN_MODEL_SERVICE, DEVICE_OPTIONS_EXTRACTION_SERVICE, DEVICE_PERMISSION_SERVICE,
    DEVICE_SERVICE, FILTER, LICENSE_SERVICE, LOCATION, MANAGEMENT_DEVICE_REST_SERVICE, MONITORING_SERVICE,
    NG_DIALOG, NMS_ERRORS, NMS_FEATURES, NMS_LICENSES, NMS_STATES, OPTIONS_EXTRACTION_STATUS, PRESENTATION_MODE,
    PROPERTIES_SERVICE, SNMP_DATA, STATE, SYSTEM_KEYWORD, TEMPLATE, TEMPLATE_INSTANCE,
    TEMPLATE_INSTANCE_ACCORDION_DEVICE_CHANGES_SERVICE, TEMPLATE_INSTANCE_SERVICE, TEMPLATE_INSTANCE_VALIDATOR_SERVICE,
    TEMPLATE_INSTANCE_VARIABLE_SERVICE, TEMPLATE_LANGUAGE, TEMPLATE_SERVICE, TEMPLATE_TYPE, USER_PREFERENCES_SERVICE,
    VARIABLES, VARIABLE_SERVICE, VARIABLE_TYPES, WEBSOCKET, WINDOW, WORKFLOW_SERVICE, GPON_ONU_SERVICE, GPON_ONUS_STATUS_CONFIG,
    LABEL_COLOR_SERVICE,
    LOADING_PANE_SERVICE,
    DEVICE_DROPDOWN_SHORTCUTS_SERVICE,
    MANAGER_USERS_SERVICE,
    SYSTEM_USER_CONFIGURATION_SERVICE,
    LDAP_MODE
} from "./shared/services/upgraded-provider/upgraded-providers";
import { DeviceBackupComponent } from "./modules/device/device-backup/device-backup.component";
import { DeviceBackupTableComponent } from "./modules/device/device-backup/device-backup-table/device-backup-table.component";
import { CreateBackupModalComponent } from "./modules/device/device-backup/create-backup-modal/create-backup-modal.component";
import { BackupDevicesTabComponent } from "./modules/device/device-backup/create-backup-modal/tabs/backup-devices-tab/backup-devices-tab.component";
import { BackupSchedulerTabComponent } from "./modules/device/device-backup/create-backup-modal/tabs/backup-scheduler-tab/backup-scheduler-tab.component";
import { BackupGeneralTabComponent } from "./modules/device/device-backup/create-backup-modal/tabs/backup-general-tab/backup-general-tab.component";
import {
    DeviceResultBackupModalComponent
} from "./modules/device/device-backup/device-result-backup-modal/device-result-backup-modal.component";
import {
    RestoreDeviceBackupModalComponent
} from "./modules/device/device-backup/restore-device-backup-modal/restore-device-backup-modal.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
    BackupConfigurationViewModalComponent
} from "./modules/device/device-backup/backup-configuration-view-modal/backup-configuration-view-modal.component";
import {
    RetentionDeviceBackupModalComponent
} from "./modules/device/device-backup/retention-device-backup-modal/retention-device-backup-modal.component";
import {
    DeviceBackupErrorResponseHandlerService
} from "./modules/device/device-backup/device-backup-error-response-handler.service";
import { NotificationsModalComponent } from "./modules/notification/notifications-modal/notifications-modal.component";
import { NmsNotificationComponent } from "./shared/components/elements/nms-notification/nms-notification.component";
import { MatCardModule } from "@angular/material/card";
import { ReplaceLineBreakPipe } from "./shared/pipes/replace-line-break.pipe";
import { SnmpTrapTriggerComponent } from "./modules/scheduler/triggers/snmp-trap-trigger/snmp-trap-trigger.component";
import { NotificationsBellComponent } from "./modules/notification/notifications-bell/notifications-bell.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import {
    SnmpTrapObjectSelectionComponent
} from "./modules/template/template-instance/variables/snmp-trap-object-selection/snmp-trap-object-selection.component";
import { NmsAutocompleteModule } from "@nms-angular-toolkit/nms-autocomplete";
import { NmsAlertModule }  from "@nms-angular-toolkit/nms-alert";
import {
    NotificationsUserPreferencesConfigComponent
} from "./modules/user/user-preferences/preferences/notifications-user-preferences-config.component";
import { UserDataComponent } from "./modules/user/user-preferences/user-data/user-data.component";
import { GeneralUserPreferencesConfigComponent } from "./modules/user/user-preferences/preferences/general-user-preferences-config.component";
import {
    UserPasswordManagementComponent
} from "./modules/user/user-preferences/user-password-management/password-management-component";
import { CommandsAlertComponent } from "./modules/template/template-instance/commands/commands-alert/commands-alert.component";

// This function receives the injector internally when passed as a useFactory
// The name parameter is for custom use, it comes from a .bind(null, name)
// This validation was necessary to keep this function generic,
// because $scope is not injected directly, it's created from $rootScope
function createService(name: string, injector) {
    return name !== ANGULARJS_SCOPE ? injector.get(name) : injector.get(ANGULARJS_ROOTSCOPE).$new();
}

// This function is responsible to create the provider by giving it a service name.
// The useFactory property expects a function that should do a injector.get()
// In order to generically pass a name as a second parameter it was necessary to use .bind()
function createProvider(injection: string) {
    return {
        provide: injection,
        useFactory: createService.bind(null, injection),
        deps: ["$injector"]
    };
}
@NgModule({
    declarations: [
        DeviceManagementProtocolsModalComponent,
        LicenseModalComponent,
        TranslatePipe,
        AcsCredentialsComponent,
        AcsUrlStatusComponent,
        NmsTable,
        NmsDropdownOld,
        NmsPageTitleComponent,
        CwmpConnectivityTestModalComponent,
        AcsCredentialsModalComponent,
        AuditRetentionModalComponent,
        CwmpParametersComponent,
        CwmpPathParametersComponent,
        NmsTimerComponent,
        NmsToastrComponent,
        NmsSelectComponent,
        CpesComponent,
        NmsCounterComponent,
        NmsAutoUpdaterComponent,
        DownloadComponent,
        NmsInfoConfigFilterComponent,
        ManagementProtocolsComponent,
        GlobalManagementProtocolsComponent,
        TemplateInstanceComponent,
        NmsOldPageTitle,
        NgIncludeWrapper,
        NmsTagsInput,
        TemplateComponent,
        CpesTableComponent,
        TemplateInfoHeaderComponent,
        UserPreferencesComponent,
        MatchingRulesComponent,
        SelectionDropdownComponent,
        SchedulerComponent,
        TimeTriggerComponent,
        InformTriggerComponent,
        CronTriggerComponent,
        SchedulerTableComponent,
        SchedulerListComponent,
        TemplateStepBasicComponent,
        TemplateStepCommandsComponent,
        TemplateCommandsStepWrapper,
        GponOnusComponent,
        DeviceBackupComponent,
        DeviceBackupTableComponent,
        CreateBackupModalComponent,
        BackupDevicesTabComponent,
        BackupSchedulerTabComponent,
        BackupGeneralTabComponent,
        DeviceResultBackupModalComponent,
        RestoreDeviceBackupModalComponent,
        BackupConfigurationViewModalComponent,
        RetentionDeviceBackupModalComponent,
        NotificationsModalComponent,
        NmsNotificationComponent,
        SnmpTrapTriggerComponent,
        NotificationsBellComponent,
        SnmpTrapObjectSelectionComponent,
        NotificationsUserPreferencesConfigComponent,
        UserDataComponent,
        UserPasswordManagementComponent,
        GeneralUserPreferencesConfigComponent,
        CommandsAlertComponent
    ],
    imports: [
        NgbModule,
        BrowserModule,
        FormsModule,
        UpgradeModule,
        NgbTooltipModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        SimpleModalModule.forRoot(
            { container: "modal-container" },
            {
                ...defaultSimpleModalOptions,
                ...{
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                    animationDuration: 300,
                    wrapperDefaultClasses: "modal centralized-modal"
                }
            }
        ),
        ShowHidePasswordModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            onActivateTick: true,
            positionClass: "toast-bottom-right",
            toastComponent: NmsToastrComponent
        }),
        HttpClientModule,
        ClipboardModule,
        TooltipModule.forRoot({
            theme: "light",
            "tooltip-class": "ng2-tooltip"
        }),
        NgSelectModule,
        MatTabsModule,
        NmsWizardModule,
        NmsCommonsModule,
        NmsDialogModule,
        SortablejsModule.forRoot({ animation: 100 }),
        RouterModule.forRoot([]),
        NmsInputNumberModule,
        NmsTableModule,
        NmsStatusModule,
        MatTooltipModule,
        NmsProgressBarModule,
        NmsTextViewerModule,
        NmsDropdownModule,
        NmsToggleModule,
        NmsButtonModule,
        MatCardModule,
        MatTooltipModule,
        MatExpansionModule,
        InfiniteScrollModule,
        NmsAutocompleteModule,
        NmsAlertModule,
        MatExpansionModule
    ],
    entryComponents: [
        DeviceManagementProtocolsModalComponent,
        LicenseModalComponent,
        AcsCredentialsComponent,
        CwmpConnectivityTestModalComponent,
        AuditRetentionModalComponent,
        AcsCredentialsModalComponent,
        CwmpParametersComponent,
        NmsToastrComponent,
        CpesComponent,
        DownloadComponent,
        GlobalManagementProtocolsComponent,
        NmsAutoUpdaterComponent,
        TemplateInstanceComponent,
        TemplateComponent,
        CpesTableComponent,
        TemplateInfoHeaderComponent,
        UserPreferencesComponent,
        MatchingRulesComponent,
        SelectionDropdownComponent,
        SchedulerComponent,
        TimeTriggerComponent,
        CronTriggerComponent,
        InformTriggerComponent,
        NmsCounterComponent,
        SchedulerListComponent,
        GponOnusComponent,
        DeviceBackupComponent,
        NotificationsUserPreferencesConfigComponent,
        UserDataComponent,
        UserPasswordManagementComponent,
        GeneralUserPreferencesConfigComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        ModalFactory,
        createProvider(DEVICES_TABLE_SERVICE),
        createProvider(SNMP_DATA),
        createProvider(ANGULARJS_SCOPE),
        createProvider(ANGULARJS_ROOTSCOPE),
        createProvider(ANGULARJS_TRANSLATE),
        createProvider(ANGULARJS_LOCATION),
        createProvider(STATE),
        createProvider(FILTER),
        AcsCredentialsService,
        { provide: Window, useValue: window },
        CwmpConnectivityTestModalService,
        createProvider(CONNECTIVITY_TEST_STATUS),
        CwmpParametersResolverService,
        CwmpParametersService,
        NmsToastrService,
        createProvider(USER_PREFERENCES_SERVICE),
        createProvider(NMS_ERRORS),
        createProvider(NMS_STATES),
        createProvider(NMS_FEATURES),
        createProvider(CUSTOM_DEVICE),
        createProvider(BROADCAST_EVENTS),
        CpeErrorResponseHandlerService,
        DeviceBackupErrorResponseHandlerService,
        createProvider(CWMP_DOWNLOAD_FILE_TYPE),
        createProvider(LICENSE_SERVICE),
        createProvider(NMS_LICENSES),
        createProvider(APPLICATION_BRAND_ICONS),
        InventoryService,
        RebootService,
        KeepAliveService,
        SecurityService,
        TemplateInstanceIdentifierService,
        createProvider(AUTHENTICATION_SERVICE),
        RefreshIntervalService,
        { provide: APP_BASE_HREF, useValue: "/" },
        createProvider(ACTIONS_LIST),
        createProvider(APPLICATION_STATUS),
        createProvider(APPLICATION_COMMANDS),
        createProvider(ACTIVATION_SERVICE),
        createProvider(CONFIG_REST_SERVICE),
        createProvider(CONVERTER_SERVICE),
        createProvider(DEVICE_DROPDOWN_MODEL_SERVICE),
        createProvider(DEVICE_DROPDOWN_SHORTCUTS_SERVICE),
        createProvider(DEVICE_SERVICE),
        createProvider(MONITORING_SERVICE),
        createProvider(PRESENTATION_MODE),
        createProvider(TEMPLATE_INSTANCE_ACCORDION_DEVICE_CHANGES_SERVICE),
        createProvider(TEMPLATE_INSTANCE_SERVICE),
        createProvider(TEMPLATE_INSTANCE_VALIDATOR_SERVICE),
        createProvider(TEMPLATE_SERVICE),
        createProvider(VARIABLES),
        createProvider(WORKFLOW_SERVICE),
        createProvider(CUT_STRING_FILTER),
        createProvider(NG_DIALOG),
        createProvider(TEMPLATE_INSTANCE),
        createProvider(VARIABLE_TYPES),
        createProvider(VARIABLE_SERVICE),
        createProvider(SYSTEM_KEYWORD),
        createProvider(TEMPLATE_TYPE),
        createProvider(TEMPLATE_LANGUAGE),
        DeviceFreemarkerExpressionService,
        CpePythonExpressionService,
        CodeBlockService,
        createProvider(ANGULARJS_TIMEOUT),
        createProvider(WEBSOCKET),
        createProvider(WINDOW),
        createProvider(OPTIONS_EXTRACTION_STATUS),
        createProvider(TEMPLATE_INSTANCE_VARIABLE_SERVICE),
        createProvider(DEVICE_OPTIONS_EXTRACTION_SERVICE),
        TemplateValidatorTypeService,
        ActionVariableValidatorService,
        DialogService,
        MatchingRulesDifferService,
        createProvider(DEVICE_DISCOVER_SERVICE),
        createProvider(MANAGEMENT_DEVICE_REST_SERVICE),
        createProvider(DEVICE_PERMISSION_SERVICE),
        createProvider(CREDENTIALS_SERVICE),
        createProvider(PROPERTIES_SERVICE),
        createProvider(CONNECTIVITY_TEST_SERVICE),
        createProvider(LOCATION),
        CpesActionsService,
        CpesActionsService,
        PermissionsActionsService,
        DevicesActionsService,
        TemplateActionsService,
        EquipmentsModalsActionsService,
        createProvider(GPON_ONU_SERVICE),
        createProvider(TEMPLATE),
        createProvider(GPON_ONUS_STATUS_CONFIG),
        createProvider(LABEL_COLOR_SERVICE),
        createProvider(LOADING_PANE_SERVICE),
        createProvider(MANAGER_USERS_SERVICE),
        createProvider(SYSTEM_USER_CONFIGURATION_SERVICE),
        createProvider(LDAP_MODE),
        ReplaceLineBreakPipe
    ],
    bootstrap: []
})

/**
 * Main module of Angular application.
 *
 * It has all necessary declaration, imports, etc. And declares an empty function for internal migration purposes.
 */
export class AppModule {

    constructor(private upgrade: UpgradeModule, private translate: TranslateService) {}

    ngDoBootstrap() {
        /**
         * É possivel usar idiomas diferentes usando o método "use" do BsLocaleService conforme
         * documentação https://valor-software.com/ngx-bootstrap/#/components/datepicker?tab=overview#locales.
         * Para permitir o uso de um locale diferente, é preciso importar a função defineLocale da
         * lib ngx-bootstrap/chronos e então definir o locale desejado na função.
         */
        defineLocale("pt-br", ptBrLocale);
        this.upgrade.bootstrap(document.body, ["nms"]);

        this.translate.setDefaultLang("en");

        if (navigator.language.toLocaleLowerCase().startsWith("pt")) {
            this.translate.use("pt");
        } else {
            this.translate.use("en");
        }
    }
}
