import { OnDestroy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
    NG_DIALOG, SYSTEM_KEYWORD, TEMPLATE_TYPE, TEMPLATE_LANGUAGE, VARIABLE_SERVICE,
    VARIABLES, NMS_STATES, ANGULARJS_SCOPE, STATE, ANGULARJS_TRANSLATE, TEMPLATE_SERVICE,
    USER_PREFERENCES_SERVICE, WORKFLOW_SERVICE, ANGULARJS_ROOTSCOPE
}
    from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { KeepAliveService } from "@nms-ng2/app/shared/services/security/keep-alive.service";
import { DialogService } from "@nms-ng2/app/shared/services/dialog/dialog.service";
import { TemplateComponentResolverService } from "./template-component-resolver.service";
import {
    ChangeOptionsTemplate,
    ChangeOptionsValue,
    ExternalOptions,
    TemplateInterface,
    TemplateLanguage,
    TemplateOptions,
    TemplateType } from "./template.interface";
import {
    RuleFieldsSet,
    RuleType,
    RuleTypesSet
} from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { TranslationMap } from "@nms-ng2/app/shared/models/translation.models";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { SortableOptions } from "sortablejs";
import { NmsWizardComponent } from "@nms-angular-toolkit/nms-wizard";
import { CpeFieldValues, DeviceFieldValues } from "../template-instance/template-instance-models";
import { Subject } from "rxjs";
import { TemplateUtils } from "./template.utils";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

/**
 * Interface que representa o teste de comando
 */
interface CommandTest {
    showTest: boolean;
    removeResult: any;
    removeError: boolean;
    applyResult: any;
    applyError: any;
}

/**
 * Reponsável pela adição, edição e clonagem de Templates
 */
@Component({
    selector: "template",
    templateUrl: "./template.component.html",
    styleUrls: ["./template.component.scss"]
})
export class TemplateComponent implements OnInit, OnDestroy {
    private ALLOWED_TEMPLATE_STATUS_FOR_EDITION = ["PRODUCTION", "DEPRECATED"];

    applicationNameSuggestionModel = {
        varSugestionCheck: false,
        selectedVariable: null
    };
    template: any;
    keepAlive: boolean;
    templateForm = null;
    templateCommandsStepForm = null;
    tabs: any[];
    isNmsTemplate: boolean;
    hasTemplateConfigPermission: boolean;
    equipmentModelRestrictionTypes: RuleTypesSet;
    variableTypes: any;
    cliCommandOptionExtractionFilterTypes: any;
    sortableOptions: SortableOptions;
    commandTest: CommandTest;
    advancedOptions = { showOptions: false };
    matchingRulesTooltipKeys: TranslationMap;
    templateTypeDefault: any;
    templateType: { value: TemplateType };
    isTR069Template: boolean;
    templateOptions: TemplateOptions;
    availableFields: RuleFieldsSet;
    filterRuleTypes: RuleType[];
    templateTypeChanged$: Subject<any>;
    templateTabsTitle: string;
    isPythonTemplate: boolean;

    @ViewChild(NmsWizardComponent, { static: false })
    nmsWizard: NmsWizardComponent;

    constructor(
        @Inject(USER_PREFERENCES_SERVICE) public userPreferenceService: any,
        @Inject(WORKFLOW_SERVICE) public workFlowService: any,
        @Inject(TEMPLATE_SERVICE) public templateService: any,
        @Inject(ANGULARJS_SCOPE) public $scope: any,
        @Inject(ANGULARJS_TRANSLATE) public translate: any,
        @Inject(ANGULARJS_ROOTSCOPE) public $rootScope: any,
        @Inject(STATE) public $state: any,
        @Inject(NMS_STATES) public nmsStates: any,
        @Inject(NG_DIALOG) public ngDialog: any,
        @Inject(VARIABLE_SERVICE) public variableService: any,
        @Inject(VARIABLES) public variables: any,
        @Inject(SYSTEM_KEYWORD) public systemKeyword: string,
        @Inject(TEMPLATE_TYPE) public templateTypeProvider: any,
        @Inject(TEMPLATE_LANGUAGE) public templateLanguageProvider: any,
        public keepAliveService: KeepAliveService,
        public dialogService: DialogService,
        public readonly templateTypeResolverService: TemplateComponentResolverService,
        public nmsDialogService: NmsDialogService,
        private templateUtils: TemplateUtils,
        private nmsToastrService: NmsToastrService
    ) {
        this.tabs = [
            {
                id: "basic",
                translateId: "templateform.basic",
            },
            {
                id: "commands",
                tooltip: true,
                translateId: "templateform.commands",
            }
        ];

        const initialTemplate: any = {
            variables: [],
            status: "PRODUCTION",
            type: this.templateTypeProvider.CLI.name,
            language: this.templateLanguageProvider.FREEMARKER,
            equipmentRestrictionFilter: {
                equipmentRuleOption: [{ ruleType: RuleType.CONTAINS, values: [""] }],
                rulesMatchingMode: "ALL"
            },
            restrictTemplate: false,
            applicationNameManual: false,
            used: false,
            removeCommandsEnabled: false,
            applyRemoveCommandsOnEditVariables: false
        };

        this.template = this.getParentScopeVariable("template") || initialTemplate;
        this.templateType = {
            value: _.get(this, "template.type", this.templateTypeProvider.CLI.name)
        };
        this.templateOptions = this.getTemplateOptions();

        this.template.keepRunning = this.template.keepRunning || false;
        this.template.applyRemoveCommandsWatchedVariables = this.template.applyRemoveCommandsWatchedVariables || [];

        this.matchingRulesTooltipKeys = {
            isBlocked: this.getTooltipForBlockedTranslationKey(),
            matchingRules: "templateForm.basic.device.model.restriction",
            criteriaBelow: "templateForm.basic.device.model.restriction.criteria.below",
            atLeastOneRule: "popups.alert.atLeastOneRestriction"
        };


        this.hasTemplateConfigPermission = this.getParentScopeVariable("hasTemplateConfigPermission");
        this.loadCommonsTemplateOptions(this.templateType);
        this.templateTypeChanged$ = new Subject();
    }

    ngOnInit() {
        this.templateTabsTitle = this.translate.instant("templateform.template");
        const isKeepAlive = this.loadKeepAlivePreferences();
        this.keepAlive = isKeepAlive.keepAlive == undefined ? true : isKeepAlive.keepAlive;
        this.handleKeepAliveRequests(this.keepAlive);
    }

    getIndex() {
        if (this.nmsWizard) {
            return this.nmsWizard.activeTabIndex;
        }

        return 0;
    }

    isFirstStep() {
        return this.getIndex() == 0;
    }

    isSecondStep() {
        return this.getIndex() == 1;
    }

    getTemplateComponent(templateType?: { value: TemplateType }) {
        let typeToUse = (templateType) ? templateType : this.templateType;

        return this.templateTypeResolverService.resolve(typeToUse);
    }

    getParentScopeVariable(variableName: string) {
        return this.$scope.$parent.$resolve[variableName];
    }

    getTemplateOptions(templateType?: { value: TemplateType }) {
        let externalOptions = this.$scope.$parent.$resolve as ExternalOptions;
        externalOptions.templateLanguage = this.template.language;
        return this.getTemplateComponent(templateType).createTemplateOptions(externalOptions);
    }

    cancel() {
        this.confirmCancel();
    }

    private hasRuleOptionsCliType() {
        return _.some(this.template.equipmentRestrictionFilter.equipmentRuleOption, function (rule) {
            return _.includes(rule.ruleType, RuleType.IS_DMOS) || _.includes(rule.ruleType, RuleType.NOT_DMOS);
        });
    }

    private loadCommonsTemplateOptions(templateType: { value: TemplateType }) {
        this.templateOptions = this.getTemplateOptions(templateType);
        this.equipmentModelRestrictionTypes = this.templateOptions.equipmentModelRestrictionTypes;
        this.variableTypes = this.templateOptions.variableTypes;
        this.isTR069Template = this.templateOptions.isTR069Template;
        this.isPythonTemplate = this.template.language === this.templateLanguageProvider.PYTHON;
        this.matchingRulesTooltipKeys.matchingRules = this.templateOptions.matchingRulesKey;
        this.availableFields = this.getParentScopeVariable("availableFields")[this.templateType.value];
        this.filterRuleTypes = this.createRuleTypesFilter(this.equipmentModelRestrictionTypes);

        /**
         * Algumas regras de opções são disponíveis apenas para templates do tipo CLI e
         * devem ser removidas para templates do tipo TR-069.
         * Caso exista alguma regra exclusiva de CLI atribuida em templates TR-069,
         * o atributo equipmentRuleOption terá seus valores prévios removidos
         * e será adicionado um único valor em equipmentRuleOption utilizando o ruleType "CONTAINS" iniciando no campo
         * "SERIAL_NUMBER".
         */
        if (this.isTR069Template && this.hasRuleOptionsCliType()) {
            this.template.equipmentRestrictionFilter = {
                equipmentRuleOption: [{
                    ruleType: RuleType.CONTAINS,
                    values: [""],
                    equipmentField: { type: "cpe", value: CpeFieldValues.SERIAL_NUMBER }
                }]
            };
        }
    }

    private createRuleTypesFilter(ruleTypes: RuleTypesSet) {
        let mapOmitField: any = {
            [this.templateTypeProvider.CLI.name]: {
                MODEL: [
                    RuleType.AFTER, RuleType.BEFORE, RuleType.BEFORE,
                    RuleType.BETWEEN, RuleType.NOT_BETWEEN, RuleType.GREATER_THAN_OR_EQUAL,
                    RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
                ],
                FIRMWARE: [RuleType.AFTER, RuleType.BEFORE, RuleType.IS_DMOS, RuleType.NOT_DMOS]
            },
            [this.templateTypeProvider.TR_069.name]: {
                PRODUCT_CLASS: [
                    RuleType.GREATER_THAN_OR_EQUAL, RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN,
                    RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN, RuleType.IS_DMOS, RuleType.NOT_DMOS
                ],
                SW_VERSION: [
                    RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE
                ],
            }
        };

        return function (field) {
            const mapOmitFieldForTemplateType = mapOmitField[this.templateType.value];
            return _.omit(ruleTypes, mapOmitFieldForTemplateType[field]);
        }.bind(this);
    }

    private getLoadOptionsByConfirmModal(changeOptions: ChangeOptionsValue) {
        return (changeOptions: ChangeOptionsValue) => {
            const { currentValue } = changeOptions;

            if (this.isTemplateLanguageChanged(currentValue)) {
                this.adaptTemplateDueToLanguageChange(currentValue as TemplateLanguage);
            } else {
                this.adaptTemplateDueToTypeChange(currentValue as TemplateType);
            }

            this.clearAppyAndRemoveCommands();
        }
    }

    private adaptTemplateDueToTypeChange(currentTemplateType: TemplateType) {
        const templateType = { value: currentTemplateType };
        this.template.type = currentTemplateType;
        this.isPythonTemplate = templateType.value === this.templateTypeProvider.TR_069.name;
        this.template.language = this.isPythonTemplate
            ? this.templateLanguageProvider.PYTHON
            : this.templateLanguageProvider.FREEMARKER;
        this.loadCommonsTemplateOptions(templateType);
        this.templateTypeChangedNotifier(currentTemplateType);
    }

    private adaptTemplateDueToLanguageChange(currentTemplateLanguage: TemplateLanguage) {
        this.template.language = currentTemplateLanguage;
        this.isPythonTemplate = currentTemplateLanguage === this.templateLanguageProvider.PYTHON;
        this.loadCommonsTemplateOptions(this.templateType);
    }

    private clearAppyAndRemoveCommands() {
        this.template.applyCommands = "";
        this.template.removeCommands = "";
        this.clearRemoveCommands();
    }

    /**
     * Notifica o componente de filtros a troca do tipo de aplicação de template para que os valores possíveis de filtros sejam
     * carregados corretamente.
     */
    private templateTypeChangedNotifier(templateTypeValue) {
        const field: DeviceFieldValues | CpeFieldValues = this.templateUtils.isCliTemplate(templateTypeValue)
            ? DeviceFieldValues.MODEL
            : CpeFieldValues.PRODUCT_CLASS;

        this.templateTypeChanged$.next(field);
    }

    private getConfirmationChangeOptions(changeOptionsValue: ChangeOptionsValue) {
        return (changeOptionsValue: ChangeOptionsValue) => {
            let confirmationChanges;
            const {currentValue} = changeOptionsValue;

            if (this.isTemplateLanguageChanged(currentValue)) {
                confirmationChanges = {
                    [this.templateLanguageProvider.FREEMARKER]: {
                        confirmationModalFn: () => { return this.hasDefaultApplyOrRemoveCommandsWhenChangeLanguage(); }
                    },
                    [this.templateLanguageProvider.PYTHON]: {
                        confirmationModalFn: () => { return this.hasApplyReadOnlyOrVariablesWithCommands(); },
                        postConfirmationFn: () => { this.hasReadOnlyIncludeOrBlockCommands(); }
                    }
                };
            } else {
                confirmationChanges = {
                    [this.templateTypeProvider.CLI.name]: {
                        confirmationModalFn: () => { return this.hasDefaultApplyOrRemoveCommandsWhenChangeType(); }
                    },
                    [this.templateTypeProvider.TR_069.name]: {
                        confirmationModalFn: () => { return this.hasApplyReadOnlyOrVariablesWithCommands(); },
                        postConfirmationFn: () => { this.hasReadOnlyIncludeOrBlockCommands(); }
                    }
                };
            }

            return confirmationChanges[currentValue];
        };
    }

    private getCancelationLanguageCallbackFn() {
        return (changeOptionsValue: ChangeOptionsValue) => {
             const {originalValue} = changeOptionsValue;

             if (this.isTemplateLanguageChanged(originalValue)) {
                 this.template.language = originalValue;
                 this.templateOptions.templateLanguage = this.template.language;
             } else {
                this.templateType = { value: originalValue as TemplateType };
             }
        };
     }

    private hasDefaultApplyOrRemoveCommands(defaultCommands): boolean {
        return (this.template.applyCommands && defaultCommands !== this.template.applyCommands) ||
               (this.template.removeCommands && defaultCommands !== this.template.removeCommands);
    }

    private hasApplyReadOnlyOrVariablesWithCommands(): boolean {
        let hasApplyOrRemoveCommands = this.template.applyCommands || this.template.removeCommands;
        const hasReadOnlyVariables = this.template.variables.some(
            ({ type: templateType }) => templateType === this.variables.READ_ONLY
        );
        const hasVariablesWithCommands = this.template.variables.some(
            ({ commands, includeAndBlockOptionMode }) =>
                commands || this.variables.includeAndBlockOptionsModes.DO_NOT_GET !== includeAndBlockOptionMode
        );

        return hasApplyOrRemoveCommands || hasReadOnlyVariables || hasVariablesWithCommands;
    }

    private isTemplateLanguageChanged(value: string): boolean {
        return value === this.templateLanguageProvider.FREEMARKER || value === this.templateLanguageProvider.PYTHON;
    }

    private hasReadOnlyIncludeOrBlockCommands() {
        this.template.variables = this.template.variables.filter(({ type: templateType, commands, inclusionParams,
            blockingParams }) => {
                return (templateType !== this.variables.READ_ONLY && !commands && !inclusionParams && !blockingParams);
            });
    }

    private hasDefaultApplyOrRemoveCommandsWhenChangeLanguage() {
        let defaultCommands = this.getTemplateComponent().getCommands("", this.template.removeCommandsEnabled,
            this.isPythonTemplate);
        return this.hasDefaultApplyOrRemoveCommands(defaultCommands);
    }

    private hasDefaultApplyOrRemoveCommandsWhenChangeType() {
        let defaultCommands = this.templateTypeResolverService
            .resolve({ value: this.templateTypeProvider.TR_069.name })
            .getCommands("", this.template.removeCommandsEnabled, this.isPythonTemplate);
        return this.hasDefaultApplyOrRemoveCommands(defaultCommands);
    }

    private loadKeepAlivePreferences() {
        const LOGOUT_KEEP_ALIVE_PROPERTIES_KEY = ["keepAlive"];
        return this.userPreferenceService.loadPreferences({}, "template", LOGOUT_KEEP_ALIVE_PROPERTIES_KEY);
    }

    processKeepAlivePreferenceChange() {
        this.saveKeepAlivePreferenceChange();
        this.handleKeepAliveRequests(this.keepAlive);
    }

    handleKeepAliveRequests(status: boolean) {
        status ? this.keepAliveService.stop() : this.keepAliveService.start();
    }

    saveKeepAlivePreferenceChange() {
        const LOGOUT_KEEP_ALIVE_PROPERTIES_KEY = ["keepAlive"];
        const properties = { keepAlive: this.keepAlive };
        this.userPreferenceService.savePreferences(properties, "template", LOGOUT_KEEP_ALIVE_PROPERTIES_KEY);
    }

    disabledForEditing(template) {
        return this.isNmsTemplate || template.used || !this.hasTemplateConfigPermission;
    }

    /**
     * Adiciona mensagem ao tooltip para campos bloqueados caso seja um template Padrão do NMS ou quando o usuário não tiver
     * a permissão templateConfig.
     * @return {string} mensagem a ser mostrada no tooltip caso o template seja Padrão do NMS ou o usuário não tenha a
     * permissão de templateConfig ou vazia caso contrário.
     */
    getTooltipForNmsTemplates() {
        var translationKey = this.getTooltipForBlockedTranslationKey();
        return _.isEmpty(translationKey) ? translationKey : this.translate.instant(translationKey);
    }

    getTooltipForBlockedTranslationKey() {
        if (this.isNmsTemplate) {
            return "templateform.notEditableFieldForNmsTemplate";
        } else if (!this.hasTemplateConfigPermission) {
            return "templatelisting.userHasNoPermission";
        }

        return "";
    }

    disableStatusForEditing(key) {
        const enabledTemplateStatusForEdition = _.union(this.ALLOWED_TEMPLATE_STATUS_FOR_EDITION, [this.template.status]);
        return (
            (!this.hasTemplateConfigPermission && key !== this.template.status) ||
            ((this.isNmsTemplate || this.template.used) && !_.contains(enabledTemplateStatusForEdition, key))
        );
    }

    clearRemoveCommands() {
        this.template.removeCommands = this.getTemplateComponent().getCommands(
            this.template.removeCommands,
            this.template.removeCommandsEnabled,
            this.isPythonTemplate
        );
        this.commandTest.removeResult = null;
        this.commandTest.removeError = false;
    }

    changePageSuccessSaved() {
        this.$state.go(this.nmsStates.template.list);
        const message = this.translate.instant("toastr.templateSavedSuccessfully");
        this.nmsToastrService.success(message);
    }

    confirmCancel() {
        this.$rootScope
            .showDialog({
                translateKey: "popups.confirm.cancelConfirmation",
                isConfirm: true
            })
            .then(() => this.$state.go(this.nmsStates.template.list));
    }

    changeLanguage(templateLanguage) {
        const changeOptions = {
            changeOptionsValue: {
                originalValue: this.template.language as TemplateLanguage,
                currentValue: templateLanguage as TemplateLanguage
             },
             confirmMessageKey: "popups.confirm.changeLanguageCliType"
        } as ChangeOptionsTemplate;
        this.tryToChangeOptions(changeOptions);
    }

    changeType(templateType: { value: TemplateType }) {
        const confirmMessage = templateType.value === "TR_069"
            ? "popups.confirm.changeTemplateTypeToTr069"
            : "popups.confirm.changeTemplateTypeToCli"
        const changeOptions = {
            changeOptionsValue: {
                originalValue: this.template.type as TemplateType,
                currentValue: templateType.value as TemplateType,
             },
             confirmMessageKey: confirmMessage
        } as ChangeOptionsTemplate;
        this.tryToChangeOptions(changeOptions);
    }

    tryToChangeOptions(changeOptions: ChangeOptionsTemplate) {
        let templateInterface: TemplateInterface;
        const {currentValue} = changeOptions.changeOptionsValue

        if (currentValue as TemplateType) {
            const templateType = {value: currentValue as TemplateType};
            templateInterface = this.templateTypeResolverService.resolve(templateType);
        } else {
            templateInterface = this.getTemplateComponent();
        }
        const options = {
            changeOptions,
            loadOptionsByTemplate: this.getLoadOptionsByConfirmModal(changeOptions.changeOptionsValue),
            confirmationModalFn: this.getConfirmationChangeOptions(changeOptions.changeOptionsValue),
            cancelationCallbackFn: this.getCancelationLanguageCallbackFn()
        };
        const { confirmationCallbackFn, cancelationCallbackFn, confirmationModalFn, confirmationTranslationKey } =
            templateInterface.createChangeOptionsConfirmationConfig(options);

        this.dialogService.confirm(
            confirmationModalFn,
            confirmationTranslationKey,
            confirmationCallbackFn,
            cancelationCallbackFn
        );
    }

    save(): void {
        if (this.applicationNameSuggestionModel.varSugestionCheck) {
            this.template.applicationNameVarSuggestion = this.applicationNameSuggestionModel.selectedVariable.name;
        } else {
            this.template.applicationNameVarSuggestion = null;
        }

        this.template.type = this.templateType.value;
        this.templateService.save(this.template).then(() => this.changePageSuccessSaved());
    }

    ngOnDestroy() {
        this.keepAliveService.stop();
    }
}
