"use strict";

import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

var app = angular.module("nms.management");

app.controller("MonitoringConfigurationController", ["$scope", "$rootScope", "MonitoringService", "NMS_STATES", "$state",
    "$window", "TEST_CONNECTION_STATES", "$translate", "NmsToastrService",
    function($scope, $rootScope, MonitoringService, NMS_STATES, $state, $window, TEST_CONNECTION_STATES, $translate,
        nmsToastrService: NmsToastrService) {
        $scope.testConnectionStatus = TEST_CONNECTION_STATES.NOT_STARTED;
        $scope.monitoringConnectionSettings = {
            associateTemplates: true,
            useDefaultTemplate: true,
            templateNameToUse: ""};
        $scope.hasErrorInformationFields = false;
        $scope.disableTestConnectivity = false;
        $scope.hasRequiredFields = false;

        $scope.init = function() {
            MonitoringService.getMonitoringSettings().then(setMonitoringConfigurationSettings);
        };

        $scope.testConnectivity = function() {
            if (!$scope.hasErrorInformationFields) {
                $scope.testConnectionStatus = TEST_CONNECTION_STATES.STARTED;
                $rootScope.hideLoadingPanel = true;

                MonitoringService.testConnection($scope.monitoringConnectionSettings).then(function(response) {
                    $scope.testConnectionStatus = TEST_CONNECTION_STATES[response];
                    setDefaultValues();
                }, function() {
                    setDefaultValues();
                    $scope.testConnectionStatus = TEST_CONNECTION_STATES.CONNECTION_FAIL;
                });
            }
        };

        $scope.updateMonitoringSettings = function() {
            if ($scope.areMonitoringConnectionSettingsEmpty()) {
                $rootScope.showDialog({
                    translateKey: "monitoring.configuration.popup.update.confirmation.message",
                    params: [
                        $translate.instant("monitoring.configuration.popup.update.confirmation.affected.feature.packet.traffic"),
                        $translate.instant("monitoring.configuration.popup.update.confirmation.affected.feature.cpu.memory"),
                        $translate
                            .instant("monitoring.configuration.popup.update.confirmation.affected.feature.optical.power.history")
                    ],
                    isConfirm: true
                }).then(function() {
                    tryUpdateMonitoringSettings();
                });
            } else {
                tryUpdateMonitoringSettings();
            }
        };

        $scope.cancelMonitoringSettingsUpdate = function() {
            $rootScope.showDialog({
                translateKey: "monitoring.configuration.dialog.update.cancel.message",
                isConfirm: true
            }).then(function() {
                if (_.isEmpty($rootScope.previousState) || _.isNull($rootScope.previousState)
                    || NMS_STATES.monitoring === $rootScope.previousState) {
                    $state.go(NMS_STATES.manageDevices);
                } else {
                    $window.history.back();
                }
            });
        };

        $scope.areMonitoringConnectionSettingsEmpty = function() {
            return _.isEmpty($scope.monitoringConnectionSettings.url)
                  && _.isEmpty($scope.monitoringConnectionSettings.username)
                  && _.isEmpty($scope.monitoringConnectionSettings.password);
        };

        $scope.$watch("monitoringForm.$invalid", function(invalid) {
            $scope.hasErrorInformationFields = _.isBoolean(invalid) && invalid;
        });

        $scope.$watch("monitoringConnectionSettings", function() {
            $scope.testConnectionStatus = TEST_CONNECTION_STATES.NOT_STARTED;
            $scope.hasRequiredFields = !$scope.areMonitoringConnectionSettingsEmpty();
        }, true);

        $scope.$watch("testConnectionStatus", function(newValue) {
            $scope.disableTestConnectivity = _.isEqual(newValue, TEST_CONNECTION_STATES.STARTED);
        }, true);

        function setDefaultValues() {
            $scope.hasChangeFields = false;
            $rootScope.hideLoadingPanel = false;
        }

        function setMonitoringConfigurationSettings(monitoringConnectionSettings) {
            $scope.monitoringConnectionSettings.url = angular.copy(monitoringConnectionSettings.url);
            $scope.monitoringConnectionSettings.username = angular.copy(monitoringConnectionSettings.username);
            $scope.monitoringConnectionSettings.password = angular.copy(monitoringConnectionSettings.password);
            $scope.monitoringConnectionSettings.associateTemplates
                = angular.copy(monitoringConnectionSettings.associateTemplates);
            $scope.monitoringConnectionSettings.useDefaultTemplate
                = angular.copy(monitoringConnectionSettings.useDefaultTemplate);
            $scope.monitoringConnectionSettings.templateNameToUse = angular.copy(monitoringConnectionSettings.templateNameToUse);
            $scope.hasRequiredFields = !$scope.areMonitoringConnectionSettingsEmpty();
            $rootScope.$broadcast("maskPassword");
        }

        function tryUpdateMonitoringSettings() {
            if (!invalidCoherences()) {
                MonitoringService.updateMonitoringSettings($scope.monitoringConnectionSettings).then(function() {
                    const message = $translate.instant("monitoring.configuration.popup.update.succes.message");
                    nmsToastrService.success(message);
                });
            }
        }

        function invalidCoherences() {
            if ($scope.monitoringForm.$invalid) {
                $rootScope.showDialog({
                    translateKey: "monitoring.configuration.popup.update.error.message"
                });

                return true;
            }

            if ($scope.associateTemplatesForm.$invalid) {
                $rootScope.showDialog({
                    translateKey: "monitoring.configuration.popup.update.error.template.associate"
                });

                return true;
            }

            return false;
        }

        $scope.init();
    }
]);
