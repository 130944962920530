"use strict";

/**
* @ngdoc service
* @name nms.deviceSummary.DeviceSummaryResolver
* @description
* # DeviceSummaryResolver - Resolver para as informações de slot e port da Summary.
*/
var app = angular.module("nms.deviceSummary");

app.service("DeviceSummaryResolver", ["$translate", "$filter", "UnitConverterService", "STATUS_KEYS",
    function($translate, $filter, UnitConverterService, STATUS_KEYS) {
        // TODO Corrigir a serialização dessas enums no backend, para não incluir o nome do pacote.
        var CARD_PROPERTIES_PRODUCT_MODEL = "nms-status-api-domain-property-cards-CommonCardProperty-PRODUCT_MODEL";
        var CARD_PROPERTIES_PHYSICAL_CARD_MODEL = "nms-status-api-domain-property-cards-CommonCardProperty-PHYSICAL_CARD_MODEL";
        var CARD_PROPERTIES_SERIAL_NUMBER = "nms-status-api-domain-property-cards-CommonCardProperty-SERIAL_NUMBER";
        var SLOT_PROPERTIES_VIEW_NAME = "nms-status-api-domain-property-slots-CommonSlotProperty-VIEW_NAME";
        var SLOT_PROPERTIES_STATUS = "nms-status-api-domain-property-slots-CommonSlotProperty-STATUS";
        var PORT_PROPERTIES_MODEL = "nms-status-api-domain-property-ports-CommonPortProperty-MODEL";
        var PORT_PROPERTIES_DESCRIPTION = "nms-status-api-domain-property-ports-CommonPortProperty-DESCRIPTION";
        var PORT_PROPERTIES_TRANSCEIVER_SUPPORT = "nms-status-api-domain-property-ports-CommonPortProperty-TRANSCEIVER_SUPPORT";
        var PORT_PROPERTIES_VIEW_NAME = "nms-status-api-domain-property-ports-CommonPortProperty-VIEW_NAME";
        var PORT_PROPERTIES_TOTAL_ONUS = "nms-status-api-domain-property-ports-GponProperty-TOTAL_ONUS";
        var PORT_PROPERTIES_ID = "nms-status-api-domain-property-ports-CommonPortProperty-ID";
        var PORT_PROPERTIES_PORT_CHANNEL = "nms-status-api-domain-property-ports-CommonPortProperty-PORT_CHANNEL";
        var IN_RATE_L1 = "nms-status-api-domain-property-ports-CommonPortProperty-IN_RATE_L1";
        var OUT_RATE_L1 = "nms-status-api-domain-property-ports-CommonPortProperty-OUT_RATE_L1";
        var IN_BCAST_PKTS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_IN_BROADCAST_PKTS";
        var IN_MCAST_PKTS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_IN_MULTICAST_PKTS";
        var IN_OCTETS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_IN_OCTETS";
        var IN_UCAST_PKTS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_IN_UCAST_PKTS";
        var OUT_BCAST_PKTS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_OUT_BROADCAST_PKTS";
        var OUT_MCAST_PKTS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_OUT_MULTICAST_PKTS";
        var OUT_OCTETS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_OUT_OCTETS";
        var OUT_UCAST_PKTS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-HC_OUT_UCAST_PKTS";
        var IN_DISCARDS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-IN_DISCARDS";
        var IN_ERRORS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-IN_ERRORS";
        var IN_UNKNOWN_PROTOS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-IN_UNKNOWN_PROTOS";
        var OUT_DISCARDS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-OUT_DISCARDS";
        var OUT_ERRORS_VALUE = "nms-status-api-domain-property-ports-MonitoringPortValueProperty-OUT_ERRORS";

        var IN_BCAST_PKTS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_IN_BROADCAST_PKTS";
        var IN_MCAST_PKTS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_IN_MULTICAST_PKTS";
        var IN_OCTETS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_IN_OCTETS";
        var IN_UCAST_PKTS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_IN_UCAST_PKTS";
        var OUT_BCAST_PKTS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_OUT_BROADCAST_PKTS";
        var OUT_MCAST_PKTS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_OUT_MULTICAST_PKTS";
        var OUT_OCTETS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_OUT_OCTETS";
        var OUT_UCAST_PKTS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-HC_OUT_UCAST_PKTS";
        var IN_DISCARDS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-IN_DISCARDS";
        var IN_ERRORS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-IN_ERRORS";
        var IN_UNKNOWN_PROTOS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-IN_UNKNOWN_PROTOS";
        var OUT_DISCARDS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-OUT_DISCARDS";
        var OUT_ERRORS_LINK = "nms-status-api-domain-property-ports-MonitoringPortLinkProperty-OUT_ERRORS";

        /**
        * Resolve um modelo de porta para as informações de porta da summary.
        *
        * @param {Object} objeto contendo os dados necessários para a resolução das informações.
        *
        * Exemplo: {
        *    identifier: portProperty.portIdentifier,
        *    properties: portProperty.properties,
        *    transceiverProperties: transceiverProperties,
        *    states: portStates,
        *    model: model,
        *    identifierPredicate: {portIdentifier: portProperty.portIdentifier},
        *    shortcuts: shortcus,
        *    shortcutAction: shortcutAction
        * }
        */
        this.resolvePort = function(portResolverModel) {
            var transceiverProperty
                = findElementByIdentifier(portResolverModel.transceiverProperties, portResolverModel.identifierPredicate);
            var portStatus = findElementByIdentifier(portResolverModel.states, portResolverModel.identifierPredicate);
            var portModel = portResolverModel.properties[PORT_PROPERTIES_MODEL];
            var transceiverSupport = portResolverModel.properties[PORT_PROPERTIES_TRANSCEIVER_SUPPORT];
            var portViewName = portResolverModel.properties[PORT_PROPERTIES_VIEW_NAME];
            var portDescription = portResolverModel.properties[PORT_PROPERTIES_DESCRIPTION];
            var portViewNameLabel = getPortViewNameLabel(portResolverModel.properties);
            var portId = portResolverModel.properties[PORT_PROPERTIES_ID];
            var portInBCast = $filter("packetsFormatter")(portResolverModel.properties[IN_BCAST_PKTS_VALUE]);
            var linkInBCast= portResolverModel.properties[IN_BCAST_PKTS_LINK];
            var portInMCast = $filter("packetsFormatter")(portResolverModel.properties[IN_MCAST_PKTS_VALUE]);
            var linkInMCast = portResolverModel.properties[IN_MCAST_PKTS_LINK];
            var portInRateL1 = $filter("bytesFormatter")(portResolverModel.properties[IN_RATE_L1]);
            var absolutePortInRateL1 = UnitConverterService.getFixedAbsoluteValue(
                portResolverModel.properties[IN_RATE_L1]);
            var portOutRateL1 = $filter("bytesFormatter")(portResolverModel.properties[OUT_RATE_L1]);
            var absolutePortOutRateL1 = UnitConverterService.getFixedAbsoluteValue(
                portResolverModel.properties[OUT_RATE_L1]);
            var portInRateL2 = $filter("bytesFormatter")(portResolverModel.properties[IN_OCTETS_VALUE]);
            var absolutePortInRateL2 = UnitConverterService.getFixedAbsoluteValue(
                portResolverModel.properties[IN_OCTETS_VALUE]);
            var linkInRateL2 = portResolverModel.properties[IN_OCTETS_LINK];
            var portInUCast = $filter("packetsFormatter")(portResolverModel.properties[IN_UCAST_PKTS_VALUE]);
            var linkInUCast = portResolverModel.properties[IN_UCAST_PKTS_LINK];
            var portInDiscard = $filter("packetsFormatter")(portResolverModel.properties[IN_DISCARDS_VALUE]);
            var linkInDiscard = portResolverModel.properties[IN_DISCARDS_LINK];
            var portInErrors = $filter("packetsFormatter")(portResolverModel.properties[IN_ERRORS_VALUE]);
            var linkInErrors = portResolverModel.properties[IN_ERRORS_LINK];
            var portUnknownProtos = $filter("packetsFormatter")(portResolverModel.properties[IN_UNKNOWN_PROTOS_VALUE]);
            var linkUnknownProtos = portResolverModel.properties[IN_UNKNOWN_PROTOS_LINK];
            var portOutDiscard = $filter("packetsFormatter")(portResolverModel.properties[OUT_DISCARDS_VALUE]);
            var linkOutDiscard = portResolverModel.properties[OUT_DISCARDS_LINK];
            var portOutBCast = $filter("packetsFormatter")(portResolverModel.properties[OUT_BCAST_PKTS_VALUE]);
            var linkOutBCast = portResolverModel.properties[OUT_BCAST_PKTS_LINK];
            var portOutMCast = $filter("packetsFormatter")(portResolverModel.properties[OUT_MCAST_PKTS_VALUE]);
            var linkOutMCast = portResolverModel.properties[OUT_MCAST_PKTS_LINK];
            var portOutRateL2 = $filter("bytesFormatter")(portResolverModel.properties[OUT_OCTETS_VALUE]);
            var absolutePortOutRateL2 = UnitConverterService.getFixedAbsoluteValue(
                portResolverModel.properties[OUT_OCTETS_VALUE]);
            var linkOutRateL2 = portResolverModel.properties[OUT_OCTETS_LINK];
            var portOutUCast = $filter("packetsFormatter")(portResolverModel.properties[OUT_UCAST_PKTS_VALUE]);
            var linkOutUCast = portResolverModel.properties[OUT_UCAST_PKTS_LINK];
            var portOutErrors = $filter("packetsFormatter")(portResolverModel.properties[OUT_ERRORS_VALUE]);
            var linkOutErrors = portResolverModel.properties[OUT_ERRORS_LINK];

            if ("true" === transceiverSupport) {
                var messageKey = angular.isDefined(transceiverProperty)
                    ? "summary.status.transceiver.present"
                    : "summary.status.transceiver.notPresent";

                portModel += $translate.instant(messageKey);
            }

            return {
                id: portId,
                name: portViewName,
                description: portDescription,
                nameLabel: portViewNameLabel,
                fullNameLabel: portViewName + portViewNameLabel,
                portIdentifier: portResolverModel.identifier,
                model: portResolverModel.model ? portResolverModel.model : portModel,
                status: $translate.instant(STATUS_KEYS[portStatus.state]),
                stateKey: portStatus.state,
                faultReason: formatFaultReasons(portStatus),
                portInRateL1: portInRateL1,
                absolutePortInRateL1: absolutePortInRateL1,
                portInRateL2: portInRateL2,
                absolutePortInRateL2: absolutePortInRateL2,
                portOutRateL1: portOutRateL1,
                absolutePortOutRateL1: absolutePortOutRateL1,
                portOutRateL2: portOutRateL2,
                absolutePortOutRateL2: absolutePortOutRateL2,
                portInUCast: portInUCast,
                portOutUCast: portOutUCast,
                portInBCast: portInBCast,
                portOutBCast: portOutBCast,
                portInMCast: portInMCast,
                portOutMCast: portOutMCast,
                portInDiscard: portInDiscard,
                portOutDiscard: portOutDiscard,
                portInErrors: portInErrors,
                portOutErrors: portOutErrors,
                portUnknownProtos: portUnknownProtos,
                linkInRateL2: linkInRateL2,
                linkOutRateL2: linkOutRateL2,
                linkInUCast: linkInUCast,
                linkOutUCast: linkOutUCast,
                linkInBCast: linkInBCast,
                linkOutBCast: linkOutBCast,
                linkInMCast: linkInMCast,
                linkOutMCast: linkOutMCast,
                linkInDiscard: linkInDiscard,
                linkOutDiscard: linkOutDiscard,
                linkInErrors: linkInErrors,
                linkOutErrors: linkOutErrors,
                linkUnknownProtos: linkUnknownProtos,
                shortcuts: portResolverModel.shortcuts,
                shortcutAction: portResolverModel.shortcutAction
            };
        };

        /**
        * Resolve um modelo de slot para as informações de slot da summary.
        *
        * @param {Object} objeto contendo os dados necessários para a resolução das informações.
        *
        * Exemplo: {
        *    chassisIdentifier: chassi.identifier,
        *    slotIdentifier: slot.identifier,
        *    slotProperties: slotProperties,
        *    cardProperties: cardProperties,
        *    cardStates: status.cardStates,
        *    ports: ports,
        *    identifierPredicate: {slotIdentifier: slotIdentifier},
        *    shortcuts: shortcuts,
        *    shortcutAction: shortcutAction
        * }
        */
        this.resolveSlot = function(slotResolverModel) {
            var cardProperty = findElementByIdentifier(slotResolverModel.cardProperties, slotResolverModel.identifierPredicate);
            var provisionedCardModel = cardProperty.properties[CARD_PROPERTIES_PRODUCT_MODEL];
            var physicalCardModel = cardProperty.properties[CARD_PROPERTIES_PHYSICAL_CARD_MODEL];
            var cardSerialNumber = cardProperty.properties[CARD_PROPERTIES_SERIAL_NUMBER];
            var cardStatus = findElementByIdentifier(slotResolverModel.cardStates, slotResolverModel.identifierPredicate);

            var slotProperty = findElementByIdentifier(slotResolverModel.slotProperties, slotResolverModel.identifierPredicate);
            var slotViewName = slotProperty.properties[SLOT_PROPERTIES_VIEW_NAME];

            var slotStatus = slotProperty.properties[SLOT_PROPERTIES_STATUS];

            return {
                name: slotViewName,
                slotStatus: getSlotStatus(slotStatus),
                fullNameLabel: slotViewName + getSlotStatus(slotStatus),
                slotIdentifierName: slotResolverModel.slotIdentifier.name,
                chassisIdentifierName: slotResolverModel.chassisIdentifier.name,
                model: getCardModelLabel(provisionedCardModel, physicalCardModel),
                serial: cardSerialNumber,
                status: getStatusLabel(cardStatus),
                faultReason: formatFaultReasons(cardStatus),
                statusKey: getStatus(cardStatus),
                ports: slotResolverModel.ports,
                expand: !_.isEmpty(slotResolverModel.ports),
                shortcuts: slotResolverModel.shortcuts,
                shortcutAction: slotResolverModel.shortcutAction
            };
        };

        this.hasSerialNumber = function (cardProperties) {
            return cardProperties.some(({properties = {}}) => properties[CARD_PROPERTIES_SERIAL_NUMBER]);
        };

        var findElementByIdentifier = function(data, predicate) {
            return _.find(data, predicate);
        };

        var getPortViewNameLabel = function(properties) {
            if (_.has(properties, PORT_PROPERTIES_PORT_CHANNEL)) {
                var portChannel = properties[PORT_PROPERTIES_PORT_CHANNEL];
                return _.template(" (member of ${portChannel})")({portChannel: portChannel});
            } else if (_.has(properties, PORT_PROPERTIES_TOTAL_ONUS)) {
                var onusNumber = properties[PORT_PROPERTIES_TOTAL_ONUS];
                return _.template(" (${onusNumber} ONUs)")({onusNumber: onusNumber});
            }
            return "";
        };

        var getSlotStatus = function(slotStatus) {
            return slotStatus ? " (" + slotStatus + ")" : "";
        };

        var getCardModelLabel = function(provisionedCardModel, physicalCardModel) {
            if (!provisionedCardModel && !physicalCardModel) {
                return "";
            }

            if (provisionedCardModel === physicalCardModel) {
                return provisionedCardModel;
            }

            return getCardModelMismatchLabel(provisionedCardModel, physicalCardModel);
        };

        var getCardModelMismatchLabel = function(provisionedCardModel, physicalCardModel) {
            var provisionedLabel = provisionedCardModel ? provisionedCardModel
                                                        : $translate.instant("device.card.provisioned.notProvisioned");
            var physicalLabel = physicalCardModel ? physicalCardModel : $translate.instant("device.card.physical.notPresent");
            var labelMask = $translate.instant("device.card.model.mismatch");

            return labelMask.replace("{0}", provisionedLabel).replace("{1}", physicalLabel);
        };

        var formatFaultReasons = function(status) {
            if (angular.isUndefined(status)) {
                return "";
            }

            return status.stateReasons.join(", ");
        };

        var getStatusLabel = function(cardStatus) {
            return angular.isUndefined(cardStatus) ? "" : $translate.instant(STATUS_KEYS[cardStatus.state]);
        };

        var getStatus = function(cardStatus) {
            return angular.isUndefined(cardStatus) ? null : cardStatus.state;
        };
    }
]);
